.shareLearningPage * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

}

.hamburger {
    position: absolute;
    top: 1.35rem;
    right: 20px;
    padding: 0;
    display: none;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
  }
  .hamburger:hover {
    opacity: 0.7;
  }
  .hamburger.is-active:hover {
    opacity: 0.7;
  }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #000;
  }
  
  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
    top: 0.05em;
  }
  
  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -1px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 28px;
    height: 3px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    display: block;
  }
  .hamburger-inner::before {
    top: -10px;
  }
  .hamburger-inner::after {
    bottom: -10px;
  }
  
  .hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease,
      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .hamburger--squeeze.active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .hamburger--squeeze.active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }
  .hamburger--squeeze.active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease,
      transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #18A0FB;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #18A0FB transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  


  .bodyContainer {
    padding: 0 82px;
    box-sizing: border-box;
  }
  
  .navbar {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 82px;
    color: black;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-width: 1px;
  }
  
  .shareLearningPageContainer .navbar {
    background-color: #18A0FB;
  }
  .brandTitle {
    width: 86px;
    height: 34px;
  }
  
  .navbarLinks ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbarLinks ul li:first-child, .navbarLinks ul li:nth-child(2) {
    margin-right: 26px;
  }
  
  .navbarLinks ul li:nth-child(3) {
  
    margin-right: 56px;
  }
  .navbarLinks ul li a {
    text-decoration: none;
    color: black;
    padding: 1rem;
    display: block;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
  }

  .shareLearningPageContainer .navbarLinks ul li a {
    color: white;
  }
  

  .shareLearningPageContainer .navbarLinks ul li:nth-child(3) a {
    color: white;
  }
  
  .navbarLinks li:hover {
    cursor: pointer;
  }

  .shareLearningPageContainer .demo-button {
    background-color: transparent;
    border-color: #F1F1F1;
    color: white;
    border-width: 2px;
  }
  
  .shareLearningHero {
    /* width: 100%; */
    height: 375px;
    background-color: #18A0FB;
    border-top: 1px solid white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    gap: 40px;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 82px;
  }

  .shareLearningHeroText {
    display: flex;
    flex-direction: column;
    gap: 70px;
    margin-bottom: 40px;
  }

  .shareLearningHeroText p:first-child {
    width: 709px;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    color: #EEEEEE;
    
  }

  .shareLearningHeroText .demo-button {
    background-color: transparent;
    border-color: #F1F1F1;
    color: white;
    border-width: 2px;

    width: 300px;
    padding: 13px 42px;
    font-size: 18px;
  }

  .shareLearningHeroImage {
    position: relative;
  }

  .womanImage {
    position: absolute;
    top: 70px;
    left: 50%;
  }

  .stayFocusedImage {
    position: absolute;
    top: 20px;
    left: 20%;
    background-color: white;
    padding-top: 20px;
    width: 180px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .stayFocusedImage p {
    font-size: 18px;
    line-height: 102.02%;
    text-align: center;
    letter-spacing: -0.01em;

    color: #201D1D;
  }
  
  
  .learningPlans {
    height: 580px;
  }

  .shareLearningPageContainer .lastGrid {
    margin: 0 82px;
    width: auto;
    background: #00FFD1;
    border: none;
    margin-bottom: 20px;
  }

  .shareLearningPageContainer .lastGrid .gridSubtitle {
    color: #000;
    font-weight: 600;
    letter-spacing: -0.01em;
  }

  .shareLearningPageContainer .lastGrid .gridDescription {
    line-height: 106%;
    font-weight: 500;
    
    color: #39393C;
  }

  .shareLearningPageContainer .individualPlan {
    margin: 20px 82px;
  }

  .createYours {
    border: none;
    outline: none;
    background: #161617;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 141.02%;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 12px 20px;
  }

  .createYours:hover {
    cursor: pointer;
  }

  .createYours:active {
    transform: translateY(4px);
  }


  .shareLearningPlanTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 82px;
    margin-top: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid  #E4EAF2;
  }

  .shareLearningPlanTitle p {
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    color: #000;
  }
  @media (max-width: 1130px) {
    .hamburger {
      display: inline-block;
    }
    .bodyContainer {
      padding: 0 32px;
    }
  
    .brandTitle {
      width: 86px;
      height: 34px;
    }
  
    .navbarLinks {
      display: none;
      width: 100%;
    }
  
    .navbar {
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 32px;
      z-index: 2;
    }
  
    .navbarLinks ul {
      flex-direction: column;
      width: 100%;
      gap: 8px;
    }
  
    .navbarLinks ul li:first-child {
      margin-right: 0;
    }
    
    .navbarLinks ul li:nth-child(3) {
      margin-right: 0;
    }
  
    .navbarLinks li {
      text-align: center;
    }
  
    .navbarLinks li a {
      padding: 0.5rem 1rem;
    }
  
    .navbarLinks.active {
      display: flex;
    }

    .shareLearningHero .shareLearningHeroImage {
        display: none;
    }
  
    .shareLearningHeroText p:first-child {
        width: auto;
        font-size: 32px;
    }

    .shareLearningHero {
        padding: 0 32px;
        gap: 10px;
    }

    .shareLearningPageContainer .lastGrid {
        margin: 0;
        margin-bottom: 20px;
    }

    .shareLearningPageContainer .individualPlan {
        margin: 10px 20px;
      }


    .shareLearningPlanTitle {
        margin: 0 20px;
        margin-top: 20px;
       
    }

    .shareLearningPlanTitle p {
        font-size: 14px;
    }

    .shareLearningPlanTitle .createYours {
        padding: 4px 8px;
        font-size: 12px;
    }

  }
  
.dashHome * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.dashHome {
  margin-top: 20px;
  width: 100%;
  padding: 10px 0;
  position: relative;
}

.switcher-con {
  position: fixed;
  width: calc(100% - 280px);
  height: 100vh;
  background-color: #f4faff;
  top: 0;
  z-index: 2;
  pointer-events: none;
}

.switcher-con .con {
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
}

.dashHome.hide {
  display: none;
}

.topPerforming.main {
  margin: 0px 0 15px;
}

.topPerforming {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  line-height: 1.25;
  margin: 30px 0 15px;
  text-transform: uppercase;
  color: #0b1c83;
}

.employeeCard {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;
  flex-basis: 300px;
  height: 300px;
  padding: 20px;
}

.employeeProfile {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.employeeImage,
.skillsImage {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.employeeDetails,
.skillsDetails {
  display: flex;
  gap: 16px;
  align-items: center;
}

.employeeName,
.skillsName {
  font-weight: 500;
  font-size: 14px;
  align-self: center;
}

.employeeRole,
.skillsRole {
  font-weight: 400;
  font-size: 12px;
  opacity: 0.4;
}

.three-dots-button {
  background: transparent;
  border: none;
  align-self: flex-start;
}

.three-dots-button:hover {
  cursor: pointer;
}

.three-dots-button:active {
  box-shadow: 0px 5px 10px transparent;
  transform: translateY(1px);
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #ccc;
  display: inline-block;
  margin: 0 3px;
}

.employeeSkills {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.skillAndPercentage {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.skillAndPercentage p {
  font-weight: 400;
  font-size: 14px;
}

.topEmployees {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 8px;
  margin-bottom: 40px;
}

.topEmployees .departmentGrid {
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
}

.topEmployees .departmentGrid .departmentFlex {
  display: flex;
  padding: 20px;
  justify-content: space-between;
}

.topEmployees .departmentGrid .departmentFlex .departmentDetails {
  width: calc(100% - 45px);
}

.maj.departmentGrid .departmentFlex .departmentDetails {
  width: calc(100% - 60px);
}

.maj .departmentIcon {
  width: 45px;
  height: 45px;
}

.topEmployees .departmentStaffDetails {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topEmployees .departmentStaffDetails div p:first-child {
  width: max-content;
}

.topEmployees .departmentStaffDetails div p {
  opacity: 1 !important;
  font-size: 23px !important;
}

.table-background {
  background-color: #fff;
  height: fit-content;
  margin-bottom: 20px;
  border: 1px solid #e4eaf2;
  border-radius: 0px 0px 8px 8px;
}

.table-wrapper {
  padding: 20px;
}

.table-search {
  margin-bottom: 10px;
  width: 200px;
  height: 32px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #d8dde6;
  border-radius: 4px;
}
table {
  border-spacing: 0px;
}

.table {
  width: 100%;
  text-align: center;
}

table thead {
  background: #f1f1f1;
  height: 48px;
  border-radius: 10px;
}

table thead th {
  color: #465174;
  font-weight: 500;
  font-size: 14px;
}

tbody tr td {
  color: #465174;
  font-size: 14px;
}

table tbody tr {
  height: 70px;
  cursor: pointer;
}

tbody td {
  color: #465174;
}

tbody tr:nth-child(even) {
  background-color: #f1f1f1;
  height: 50px;
  margin: 12.5px 0;
}

.pagination {
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: flex-end;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #465174;
  border-color: #f1f1f1;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #465174;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #465174;
  border-color: #465174;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #626b89;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.learning-summary {
  background-color: #fff;
  padding: 24px 0px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.learning-summary-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 24px 20px;
  margin-bottom: 15px;
}

.learning-summary-header .side-prof {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.learning-summary-header .side-prof .switch {
  display: flex;
  margin-right: 5px;
}

.learning-summary-header .side-prof .switch p {
  color: #1c2533;
  font-size: 12px;
  font-weight: 600;
  margin: 0 5px;
}

.learning-summary-header p,
.barchartHeader p {
  font-weight: 600;
  font-size: 14px;
  color: #434f61;
  line-height: 1.3;
}

#time-periods {
  background-color: transparent;
  outline: none;
  margin-left: 10px;
  border: 1px solid #d8dde5;
  border-radius: 5px;
  padding: 4px 5px;
  color: #1c2533;
  font-weight: 600;
  font-size: 11px;
}

.circle {
  height: 14px;
  width: 14px;
  border-radius: 50%;
}

.barchartLegend {
  margin-top: 36px;
  display: flex;
  gap: 40px;
  margin-bottom: 20px;
}

.individualLegend {
  display: flex;
  gap: 6px;
  align-items: center;
}

.individualLegend p {
  font-weight: 400;
  font-size: 14px;
}

.learning-summary-grid {
  margin-top: 45px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 40px;
  padding: 0 24px;
}

.learning-summary-grid .individualBoxes {
  border-bottom: 1px solid #ccc;
  padding-bottom: 40px;
}

.individualBoxes div:first-child p {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  color: #434f61;
  text-transform: capitalize;
}

.learning-summary-grid div div:first-child {
  display: flex;
  justify-content: space-between;

  margin-bottom: 20px;
}

.skillsDetails {
  margin-top: 20px;
  gap: 4px;
}

.skillsImage {
  width: 40px;
  height: 40px;
}

.skillsRole {
  text-transform: uppercase;
}

.switch input[type="checkbox"] {
  display: none;
}

.switch input[type="checkbox"] + label {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 12px;
  background-color: #fff;
  border-radius: 7.5px;
  cursor: pointer;
  border: 1px solid #292d32;
  transition: all 0.3s ease-out;
}

.switch.gree input[type="checkbox"] + label {
  width: 19px;
  height: 14px;
}

.switch input[type="checkbox"] + label::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  left: unset;
  border-radius: 100%;
  background: #18a0fb;
  position: absolute;
  top: -6px;
  right: 2px;
  transition: all 0.3s ease-out;
}

.switch.gree input[type="checkbox"] + label::before {
  width: 5px;
  height: 5px;
  right: 3px;
}

.switch.gree input[type="checkbox"] + label::before {
  background: #19a662;
}

.switch input[type="checkbox"] + label::after {
  content: "";
  color: white;
  position: absolute;
  top: 2px;
  left: 4px;
  right: unset;
  transition: all 0.3s ease-out;
}

.switch input[type="checkbox"]:checked + label {
  background-color: #18a0fb;
}

.switch.gree input[type="checkbox"]:checked + label {
  background-color: #19a662;
}

.switch input[type="checkbox"]:checked + label::before {
  right: 10px !important;
  left: unset !important;
  background-color: #fff;
}

.switch.gree input[type="checkbox"]:checked + label::before {
  right: 8px !important;
}

/* .switch input[type="checkbox"]:checked + label::after {
  content: "";
  left: 56px !important;
  right: unset !important;
} */

.home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}

.home-header .top h1 {
  font-size: 25px;
}

.home-header .top p {
  font-size: 13px;
}

.home-header .up {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 0.5px solid #465174;
}

.home-header .up .img {
  background: #ffffff;
  border: 0.2px solid #534f4f;
  border-radius: 50%;
  padding: 7px 7px 5px;
}

.home-header .up .img img {
  width: 18px;
  height: 18px;
}

.home-header .up .name {
  font-weight: 500;
  font-size: 13px;
  color: #534f4f;
}

.home-header .up .name {
  color: #5372ea;
  font-weight: 500;
  font-size: 12px;
}

.home-header .down {
  display: flex;
  margin-top: 8px;
}

.home-header .down p {
  color: #18a0fb;
  font-weight: 500;
  font-size: 9px;
  margin-left: 10px;
  margin-top: 2px;
}

.learning-summary .recharts-responsive-container {
  transform: translate(-14px);
  margin: 20px 0 40px;
}

.learning-summary .recharts-text.recharts-cartesian-axis-tick-value {
  opacity: 0.6;
  font-size: 13px;
}

.learning-summary .recharts-curve.recharts-line-curve {
  stroke-width: 2px;
}

.learning-summary .recharts-dot.recharts-line-dot,
.learning-summary .yAxis .recharts-cartesian-axis-line,
.learning-summary .recharts-cartesian-grid-horizontal,
.learning-summary .recharts-cartesian-axis-tick-line {
  display: none;
}

.learning-summary text[orientation="bottom"] {
  transform: translateY(15px);
}

.learning-summary text[orientation="left"] {
  transform: translateX(-15px);
}

.learning-summary .recharts-curve.recharts-tooltip-cursor {
  stroke: #bf2523;
}

.learning-summary .recharts-default-tooltip {
  background: linear-gradient(
    180deg,
    #f4faff 0%,
    rgba(240, 238, 255, 0.72) 100%
  );
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 20px !important;
  border: none !important;
}

.learning-summary .recharts-tooltip-wrapper:focus-visible,
.learning-summary .recharts-tooltip-wrapper {
  outline: none !important;
}

.learning-summary .recharts-default-tooltip p {
  font-weight: 500;
  font-size: 14px;
  color: #000;
  margin-bottom: 10px !important;
}

.learning-summary .recharts-default-tooltip ul li {
  font-size: 11px;
  line-height: 12px;
}

.notice {
  margin: -15px 20px 0;
  background: lightpink;
  padding: 15px;
  border-radius: 5px;
  font-size: 13px;
  color: red;
}

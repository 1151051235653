.dash-profile {
  min-width: calc(100% - 315px);
  margin-top: 60px;
}

.backButton {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.backButton:hover {
  cursor: pointer;
}

.backButton:active {
  box-shadow: 0px 5px 10px transparent;
  transform: translateY(1px);
}
.backButton p {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;

  color: #434f61;
}

.employeeDetails {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 36px;
}

.employeeImage {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.employeeName {
  font-weight: 500;
  font-size: 14px;
}

.employeeRole {
  font-weight: 400;
  font-size: 12px;
  opacity: 0.4;
}

.main-container {
  display: flex;
  gap: 4px;
  align-items: flex-start;
}

.columnLayout {
  /* display: flex;
  flex-direction: column;
  gap: 4px; */

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 4px;
  width: 100%;
}

.columnLayout .currentSkillSet,
.columnLayout .percentageOfSkillsAcquired {
  background-color: white;
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 20px;
}

.columnLayout .boxHeader {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
}

.columnLayout.biz .boxHeader {
  margin-bottom: 5px;
}

.currentSkillAndPercentage {
  margin-top: 20px;
  display: flex;
  margin: 15px 0;
  min-height: 40px;
}

.currentSkillAndPercentage .currentSkill {
  font-weight: 400;
  font-size: 11px;
  line-height: 1.36;
  opacity: 0.4;
  width: 70px;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  margin: 0 25px 10px 0;
}

.currentSkillAndPercentage .progress-bar-container {
  height: 12px;
}

.dash-profile .skillContainer {
  overflow: scroll;
  height: 165px;
}

.dash-profile .scale {
  display: flex;
  justify-content: space-between;
  margin-left: 90px;
  font-size: 11px;
  line-height: 1.36;
  opacity: 0.4;
}

.dash-profile .dragIcon svg {
  display: flex;
  align-items: center;
  margin: auto;
  transform: rotate(-90deg);
}

.percentageOfSkillsAcquired .employeeSkillsGained {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.25;

  color: #000000;

  mix-blend-mode: normal;
  opacity: 0.4;
}

.circularProgress {
  margin-top: 50px;

  display: flex;
  align-items: center;
  gap: 30px;
}

.circleAndLabel {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

.learningSummary {
  width: 428px;
  min-height: 688px;
  height: fit-content;
  background-color: white;
  border-radius: 8px;
  padding: 24px;
}

.learningSummaryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  border-bottom: 1px solid #e4eaf2;
}

.learningSummaryHeader p {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
}

.learningSummaryBoxes div:first-child {
  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;
}

.learningSummaryBoxes {
  border-bottom: 1px solid #ccc;
}

.learningSummaryBoxes div:first-child p {
  font-weight: 600;
  font-size: 12px;
}

.learningSummaryBoxes .progress-bar-container {
  height: 6px;
}

.learningSummaryBoxes div:last-child .skillsName,
.learningSummaryBoxes div:last-child .skillsRole {
  margin: 0;
}

.learningSummaryBoxes div:last-child .skillsName {
  font-weight: 600;
  font-size: 12px;
}

.learningSummaryBoxes div:last-child .skillsRole {
  font-size: 10px;
}

.learningSummary div:last-child {
  border: none;
}

.deleteAccountModal {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.areYouSureTitle {
  font-size: 18px;
}

.areYouSureButtons {
  display: flex;
  gap: 8px;
}

.areYouSureButtons button {
  outline: none;
  border: none;
  width: 80px;
  padding: 10px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.areYouSureButtons button:first-child {
  background-color: #18a0fb;
  color: white;
}

.areYouSureButtons button:last-child {
  background-color: #c5292a;
  color: white;
}

.one-home .departments {
  min-width: calc(100% - 315px);
  margin-top: 25px;
}

.one-home.depts {
  padding: 45px 0;
}

.one-home .deptInfo {
  margin-top: 10px;
}

.one-home .departments * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.allDepartments {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-transform: uppercase;

  color: #0b1c83;
}

.one-home .departments .departmentsGrid {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 13px;
}

.one-home .main .departments .departmentsGrid {
  margin-bottom: 20px;
  display: flex;
  gap: unset;
  justify-content: space-between;
}

.one-home .departments .departmentGrid {
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
}

.one-home .main .departments .departmentGrid {
  width: calc(50% - 15px);
}

.one-home .departments .departmentGrid .departmentFlex {
  display: flex;
  gap: 15px;
  padding: 15px;
  align-items: center;
}

.departmentIcon {
  width: 35px;
  height: 35px;
  background-color: #d0e3ff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.departmentName {
  font-size: 15px;
  line-height: 19px;
  font-weight: 600;
  color: #1c2533;
}

.departmentEmployeeDetail {
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #757f8f;
  text-transform: uppercase;
}

.one-home .departments .departmentStaffDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px 15px 65px;
}

.one-home .main .departments .departmentStaffDetails {
  margin: 15px 20px 20px 65px;
}

.departmentStaffDetails div p:first-child {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #000000;
  margin: 5px 0;
  mix-blend-mode: normal;
  opacity: 0.4;
}

.departmentStaffDetails div p:last-child {
  font-weight: 500;
  font-size: 23px;
  line-height: 29px;
  /* identical to box height */
  opacity: 1;
  color: #000000;
}

.deptInfo .switch.gree input[type="checkbox"] + label {
  height: 8px;
  width: 14px;
}

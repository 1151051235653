.table-paginator {
  display: flex;
  position: relative;
  bottom: 0;
  width: 100%;
  left: 0;
  justify-content: center;
  flex-direction: row;
  padding: 1rem;
  align-items: center;
  cursor: pointer;
  /* background: #fff; */
  /* box-shadow: 0 0 1px 1px #dedede; */
}

.table-paginator button {
  padding: 1rem;
  background: white;
  border-radius: 0.01rem;
  border: none;
  color: #465174;
  font-size: 14px;
  cursor: pointer;
}

.table-paginator button:first-child,
.table-paginator button:last-child {
  margin: 0 10px;
}

.table-paginator button:disabled {
  color: white;
}

.table-paginator button:nth-child(1) {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.table-paginator button:nth-last-child(1) {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.table-paginator button:focus {
  outline: none;
}

.table-paginator button.current {
  background-color: #f1f1f1;
  color: #626b89;
}

.table-paginator p {
  margin: 1rem;
  font-size: 2rem;
}

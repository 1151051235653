.onboardingMain {
    display: flex;
 
}

.onboardingMain * {
    box-sizing: border-box;
}

.onboardingMain p, .onboardingMain div, .onboardingMain img {
    margin: 0 ;
    padding: 0;
}

.onboardingMain .onboardingLeft {
    width: 50vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
   
}

.onboardingMain .onboardingLeft {
    background-color: 
    #18A0FB;
    color: #F4FAFF;
    padding: 80px 0;
    overflow: hidden;
}


.onboardingLeft .logo {
    display: flex;
    gap: 8px;
    align-items: center;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 30px;
}

.onboardingLeft .logo .trailLogo {
    width: 16px;
    height: 16px;
}

.onboardingLeft .onboardingWelcome {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.11;

    color: #FFFFFF;
    margin-bottom: 20px;
}

.onboardingLeft .onboardingLeftLastParagraph {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: #FFFFFF;
    margin: 0 80px;
}

.onboardingMain .onboardingRight {
    width: 50vw;
    height: 100vh;
    padding: 20px 0;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.onboardingRight .loginDiv {
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 16px;
}

.onboardingRight .loginDiv  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.29;
    color: #292D32;

}

.onboardingRight .loginDiv .multipleShareButton {
    padding: 8px 16px;
    color: 
    #2D54E0;
    font-weight: 600;
    font-size: 14px;
}

.onboardingRight .mainView {
    margin-top: 64px;
}

.onboardingRight .mainView .mainViewText {
    font-size: 20px;
    line-height: 1.17;
    text-align: center;
    color: #1C2533;
}

.onboardingRight .mainView .mainViewSubText {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    width: 390px;
    line-height: 1.29;
    text-align: center;
    color: #757F8F;

}


.mainView .formHolder {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
}

.onboardingMain .formElements {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.onboardingMain .businessInitialDetails .formElements:first-child {
    margin-top: 0;
}

.mainView .formHolder .formElements .formLabel {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.14;
    color: #1C2533;
    text-transform: capitalize;


}



.formHolder .formElements .formLabel span {
    color: #D4266F;
}

.formHolder .formElements .formInput {

    border: none;
    background-color: #F3FAFF;
    padding: 7px 0 6px 14px;
    height: 28px;
    font-size: 12px;
}

.formHolder .formElements div.formInput {
    height: auto ;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

.formHolder .formElements div.formInput .fileUploadTitles .mainTitle {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #1C2533;
}

.formHolder .formElements div.formInput .fileUploadTitles .subTitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
   
    color: #757F8F;
}

.onboardingMain .businessInitialDetails {
    padding-bottom: 24px;
    
}

.onboardingMain .teamsRegion {
    border-top: 1px solid rgba(141, 144, 145, 0.2) ;
    
    padding: 24px 0;
}

.onboardingMain .teamsHeader {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.14;

    color: #979797;

}

.onboardingMain .formElements .teamsAddButton {
    outline: none;
    border: none;
    margin-top: 24px;
    background-color:#18A0FB;
    color: #FFFFFF;
    padding: 8px 25px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    align-self: flex-end;
}

.onboardingMain .formElements .disabledTeamsAddButton {
    margin-top: 24px;
    outline: none;
    border: none;
    background-color:#ccc;
    padding: 8px 25px;
    color: #666;
    font-weight: 600;
    font-size: 12px;
    border-radius: 4px;
    align-self: flex-end;
    cursor: not-allowed;
}
.teamsAddButton:hover {
    cursor: pointer;
}

.teamsAddButton:active {
    transform: translateY(4px);
}

.onboardingMain .onboardingRight .formHolder .teamsContainer {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

}
.onboardingMain .onboardingRight .formHolder .noOfTeamsBox {
    display: flex;
    position: relative;
    align-items: center;
    gap: 4px;
    padding: 10px;
    background-color: #F4F4F4;
    width: 153px;
    border-radius: 4px;
}

.noOfTeamsBox div p:first-child {
    font-size: 12px;
    line-height: 16px;
    color: #1C2533;

}

.noOfTeamsBox div p:last-child {
    margin-top: 4px;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    color: #757F8F;
}

.noOfTeamsBox .teamsDeleteButton {
    position: absolute;
    top: -8px;
    right: -6px;
}

.noOfTeamsBox .teamsDeleteButton:hover, .deleteEmployee:hover {
    cursor: pointer;
}

.noOfTeamsBox .teamsDeleteButton:active, .deleteEmployee:active {
    transform: translateY(2px);
}

.onboardingMain .onboardingRight .formHolder .formCheckBox {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.formCheckBox input[type="checkbox"] {
    display: inline-block !important;
}

.formCheckBox p {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.29;
    width: 293px;

    color: #757F8F;
}

.formCheckBox p span {
    font-weight: 600;
    color: rgba(28, 37, 51, 1);
}

.onboardingMain .onboardingRight .formHolder .joinTeamButton {
    margin-top: 24px;
    outline: none;
    border: none;
    background-color: #18A0FB;
    padding: 12px;
    color: white;
    font-weight: 600;
    font-size: 12px;
    border-radius: 4px;
}

.onboardingMain .onboardingRight .formHolder .disabledTeamButton {
    margin-top: 24px;
    outline: none;
    border: none;
    background-color:#ccc;
    padding: 12px;
    color: #666;
    font-weight: 600;
    font-size: 12px;
    border-radius: 4px;
    cursor: not-allowed;
}

.joinTeamButton:hover {
    cursor: pointer;
}

.joinTeamButton:active {
    transform: translateY(4px);
}

.joinTeamButton:disabled {
    margin-top: 24px;
    outline: none;
    border: none;
    background-color:#ccc;
    padding: 12px;
    color: #666;
    font-weight: 600;
    font-size: 12px;
    border-radius: 4px;
    cursor: not-allowed;
}


.onboardingMain .onboardingRight .mainView .formHolder .orRegion {
    display: flex;
    flex-direction: column;
}



.onboardingLinksFormsDivider {
    margin-top: 28px;
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    align-items: center;
  }
  
  .onboardingLinksFormsDivider div {
    /* width: 100px; */
    flex-basis: 344px;
    height: 0;
    border: 1px solid #8D9091;
    opacity: 0.2;
  }
  
  .onboardingLinksFormsDivider p {
    font-weight: 500;
    font-size: 14px;
    line-height: 2.29;
  
    color: #979797;
  }
  

  .onboardingMain .onboardingRight .employeesRegion {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid rgba(141, 144, 145, 0.2);
  }

  .onboardingMain .onboardingRight .employeeDiv {
    margin-top: 24px;
    width: 100%;
    height: 72px;
    background: #F5F7FA;
    border: 0.2px dashed #D8DDE6;
    border-radius: 8px;
    padding: 0 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .employeeName {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #1C2533;
  }

  .employeePost {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #757F8F;

  }
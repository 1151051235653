@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

.dash-hero .dash-container.one {
  width: calc(100% - 281px);
  left: 186px;
  min-height: 100vh;
}

.dash-container .divide {
  position: absolute;
  width: calc(100% - 93px);
  height: 0.2rem;
  background: #979797;
  top: 75px;
  margin-left: -10px;
}

.dash-container .search .up input {
  width: 325px;
  height: 20px;
  padding: 7px 60px 7px 25px;
  border: 1px solid #979797;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 100;
}

.dash-container .search .up input::placeholder {
  color: #bbbbbb;
  font-size: 13px;
}

.dash-container .search .up .label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #d9d9d9;
  font-weight: 600;
  left: calc(50% + 154px);
  font-size: 14px;
  width: 50px;
  background: #f1f1f1;
  height: calc(100% - 2px);
  border-radius: 0 6px 6px 0;
  border-left: 1px solid #979797;
}

.dash-container .search .up svg.search {
  right: -9px;
}

.dash-container .search .up {
  width: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dash-container .search .down {
  position: absolute;
  right: 25px;
  display: flex;
  align-items: center;
}

.dash-container .search .bottom {
  max-width: unset;
  min-width: unset;
  width: 165px;
  height: 33px;
  border-radius: 6px;
  margin-right: 15px;
}

.dash-container .bottom .addResource {
  height: 100%;
  font-size: 12px;
}

.dash-container .bottom .addResource .iconcontainer {
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 3px;
}

.dash-container .bottom .iconcontainer span {
  width: 10px;
  height: 1px;
  background: #18a0fb;
}

.dash-container .bottom .iconcontainer span:nth-child(1) {
  height: 10px;
  width: 1px;
}

.dash-container .down .profile-img {
  width: 33px;
  height: 33px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  transform: translateX(-50%);
  right: -17px;
  margin: 0;
}

.dash-container .down .profile-img img {
  width: 33px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dash-container .search .up .results-tab {
  width: 100%;
  top: 55px;
}

.inner .addResource .iconcontainer span {
  background: #18a0fb;
}

.dash-container.one .progress {
  width: 100%;
}

.dash-container.one .progress.hide {
  display: none;
}

.dash-container.one .progress .filter-tab {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dash-container.one .progress .filter-tab .item {
  margin: 0 7px;
  color: #606060;
  background: #f1f1f1;
  padding: 7px 20px;
  font-size: 11px;
  cursor: pointer;
  border-radius: 15px;
  transition: all ease 0.5s;
}

.dash-container.one .progress .filter-tab .item.active:hover,
.dash-container.one .progress .filter-tab .item.active {
  background: #18a0fb;
  color: white;
}

.dash-container.one .progress .filter-tab .item:hover {
  background: #9bd6fd;
  color: white;
}

.progress .card-container .card {
  width: calc(25% - 30px);
  min-height: 245px;
  background: #fff;
  color: #181717;
  overflow: hidden;
  border-radius: 7px;
  padding: unset;
  margin: 10px 15px;
}

.progress .card-container .card .img {
  margin: 0;
  border-radius: 0;
  height: 110px;
}

.progress .card-container .card .resourceCard .title {
  font-size: 17px;
  font-weight: 700;
  margin: 10px;
  line-height: 20px;
  font-family: "Lato", sans-serif;
}

.progress .card-container .card .a-bout {
  min-height: 90px;
}

.progress .card-container .card .resourceCard .subtitle {
  font-size: 13px;
  font-weight: 400;
  margin: 10px;
  color: #3f3e3e;
  line-height: 20px;
  font-family: "Lato", sans-serif;
}

.progress .card-container .card .band {
  display: flex;
  flex-direction: column;
  height: calc(100% - 120px);
  justify-content: space-between;
}

.progress .card-container .card .info {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: unset;
}

.progress .card-container .card .resourceCard {
  cursor: pointer;
}

.progress .card-container .card .resourceCard a {
  text-decoration: none;
  color: #181717;
}
.progress .card-container .card .info svg {
  margin-right: 11px;
  width: 16px;
}

.progress .card-container .card .info .resourceCrud:hover {
  cursor: pointer;
}

.dash-container .card-container .inner {
  height: calc(100vh - 140px);
}

.card-container .inner .addResource .iconcontainer {
  background: white;
}

.tagItem {
  text-transform: capitalize;
  cursor: pointer;
}

/* Progress bar */

.progress-bar-container {
    width: 100%;
    height: 8px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #e0e0e0;
  }
  
  .progress-bar-fill {
    height: 100%;
    border-radius: inherit;
  }
  
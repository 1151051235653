.dash-navOne {
  width: 15vw;
  min-width: 256px;
  background: #0b1c83;
  color: black;
  padding: 10px 18px;
  position: fixed;
  top: 0px;
  left: 0px;
  height: calc(100vh - 20px);
  border-radius: 0;
  transition: all 0.3s ease;
  z-index: 100;
}

.dash-navOne .contain {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: scroll;

  height: 100%;
}

.dash-navOne .contain {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.dash-navOne .contain::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.dash-divider {
  width: 100%;
  border-bottom: 0.3px solid #bcbcbc;
  margin-bottom: 20px;
}

.bottom-items {
  margin-top: 20px;
}

.general-category {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7;
  margin-left: 18px;

  color: #7b839c;
}
.dash-navOne .contain .sm {
  display: none;
}

.dash-navOne .top {
  display: flex;
  margin: 15px 0 50px;
}

.dash-navOne .bottom {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.dash-navOne .top .brand {
  display: flex;
  margin-left: 25px;
}

.dash-navOne .top .brand {
  margin: 0;
}

.dash-navOne .top .brand img {
  width: 35px;
  height: 35px;
  padding-top: 2px;
}

.dash-navOne .top .brand img {
  width: 100px;
  height: unset;
}

.dash-navOne .top .brand .title {
  position: relative;
  top: 5px;
  font-size: 30px;
  padding-left: 15px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.dash-navOne .top .brand .title {
  top: 9px;
  font-size: 25px;
}

.dash-navOne .top .cancel {
  display: none;
}

.dash-navOne .top .icon i {
  position: relative;
  top: 3px;
  left: 3px;
}

.dash-navOne a {
  color: #000;
}

.dash-navOne .nav-list {
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  margin: 15px 0;
  padding: 0 10px;
  height: 45px;
  color: #5a4d4d;
  align-items: center;
  transition: all 0.3s ease;
}

.dash-navOne .nav-list {
  color: #fff;
  margin: 8px 0;
}

.dash-navOne .bottom .nav-list {
  margin: 0;
}

.dash-navOne .nav-list.out {
  color: red;
}

.dash-navOne .nav-list.out {
  color: #fff;
  margin-bottom: 20px;
}

.dash-navOne .nav-list.out.hide {
  display: none;
}

.dash-navOne .nav-list.alt.hide {
  opacity: 0;
  padding-left: 0;
  pointer-events: none;
  transition: none;
}

.dash-navOne .nav-list .nav-item {
  padding-left: 15px;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
}

.dash-navOne .nav-list .nav-item {
  font-size: 16px;
  font-weight: 500;
}

.dash-navOne .nav-list.active .nav-item {
  color: #0b1c83;
}

.dash-navOne .bottom .nav-list:hover {
  background: unset;
}

.dash-navOne .nav-list:hover {
  color: white;
  background: #0e183a;
}

.dash-navOne .nav-list.active {
  color: white;
  background: #0e183a;
}

.dash-navOne .nav-list.active {
  color: #1e1e21;
  background: #fff;
}

.dash-navOne .nav-list .icon i.fa-headset,
.dash-navOne .nav-list .icon i.fa-sign-out-alt,
.dash-navOne .nav-list .icon i.fa-film {
  font-size: 18px;
}

.dash-navOne .nav-list .icon {
  color: black;
  height: 25px;
  pointer-events: none;
  display: flex;
  align-items: center;
}

.dash-navOne .nav-list .icon svg {
  /* fill: none; */
}

.dash-navOne .bottom .nav-list .icon {
  position: relative;
}

.dash-navOne .nav-list .icon img {
  width: 25px;
  pointer-events: none;
}

.dash-navOne .bottom .nav-list img {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  transform: translateY(-50%);
  top: 50%;
  border-radius: 50%;
}

.dash-navOne .bottom .nav-list .nav-item {
  padding-left: 50px;
}

.wish * {
  margin: 0;
  padding: 0;
}

.wish {
  min-height: 100vh;
}

.wish .learning-summary {
  min-height: 700px;
  margin-bottom: 40px;
}

.wish .individualBoxes div:first-child p {
  font-family: "Inter";
  font-weight: 600;
  font-size: "14px";
  line-height: "18px";
}

.skillsScale {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.overflowHiddenAndScroll {
  overflow-x: hidden;
  overflow-y: scroll;
}

.clickMeButton {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #18a0fb;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.arrowDownSvg {
  width: 20px;
  height: 20px;
  fill: #fff;
  margin-left: 5px;
}

.arrowUpSvg {
  width: 20px;
  height: 20px;
  fill: #fff;
  margin-left: 5px;
  transform: rotate(180deg);
}

.currentSkillSet {
  display: flex;
  flex-direction: column;
}

html {
    min-height: 100%;  

   
}

.onboardUserMain * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.onboardUserMain {
    background-color: #0B1C83;
    min-height: 100vh;
    padding: 22px 29px; 
}

.onboardUserMain .loginDiv {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    align-items: center;
}

.onboardUserMain .loginDiv p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #E6EBFF;

}

.onboardUserMain .loginDiv .multipleShareButton {
    padding: 8px 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #2D54E0;
    height: 32px;

}

.onboardUserMain .onboardUserContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.onboardUserMain .onboardUserContent .logo {
    margin-top: 30px;
    display: flex;
    gap: 8px;
    align-items: center;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 30px;
    color:  #F4FAFF;
}

.onboardUserMain .onboardUserContent .welcomeMessage {
    margin-top: 32px;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    color: #FFFFFF;
}

.onboardUserMain .onboardUserContent .formArea {
    margin-top: 25px;
    padding: 32px 50px;
    background: #FFFFFF;
    border-radius: 8px;
}

.onboardUserMain .onboardUserContent .formArea .businessInitialDetails {
    display: flex;
    flex-direction: column;
}

.onboardUserMain .onboardUserContent .formArea .businessInitialDetails .formElements {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

}

.onboardUserMain .onboardUserContent .formArea .businessInitialDetails .formLabel {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #1C2533;
}

.onboardUserMain .onboardUserContent .formArea .accordion {
    margin-bottom: 12px;
    color: #444;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.onboardUserMain .onboardUserContent .formArea .accordion .accordionContent {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.onboardUserMain .onboardUserContent .formArea .accordion .accordionContent p:first-child {
    font-weight: 400;
    font-size: 12px;
    color: #465174;
}

.onboardUserMain .onboardUserContent .formArea .accordion .accordionContent p:nth-child(2) {
    font-weight: 400;
    font-size: 10px;
    color: #9EA8B8;
}

.onboardUserMain .onboardUserContent .formArea .panel {
   
    background-color: white;
    overflow: hidden;
    margin-bottom: 12px;
}

.onboardUserMain .onboardUserContent .formArea input[type="checkbox"] {
    display: block;
}

.panel-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #9EA8B8;
}

.onboardUserMain .onboardUserContent .formArea .joinTeamButton {
    background:#18A0FB;
    border-radius: 4px;
    padding: 12px 24px;
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
}

.onboardUserMain .onboardUserContent .formArea  .disabledTeamButton {
    margin-top: 24px;
    outline: none;
    border: none;
    background-color:#ccc;
    padding: 12px;
    color: #666;
    font-weight: 600;
    font-size: 12px;
    border-radius: 4px;
    cursor: not-allowed;
}

.joinTeamButton:hover {
    cursor: pointer;
}

.joinTeamButton:active {
    transform: translateY(4px);
}


.onboardUserMain .onboardUserContent .formArea .formCheckBox {
    display: flex;
    gap: 8px;
    margin-bottom: 18px;
}

.onboardUserMain .landingImageOne {
    display: none;
}

.onboardUserMain .landingImageTwo {
    display: none;
}

@media only screen and (min-width: 1024px) {
    .onboardUserMain .landingImageOne {
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
       transform: translateY(-50%);
    }
    
    .onboardUserMain .landingImageTwo {
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
       transform: translateY(-50%);
    }
    
}
.learningPlanContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
}

.plan {
  padding: 10px 0;
  height: fit-content;
  background-color: #f7fafc;
  min-width: calc(100% - 315px);
  /* width: calc(75% - 20px); */
  /* max-width: calc(100% - 270px); */
}

.buttonDiv {
  display: flex;
  justify-content: space-between;
}

.buttonDiv button {
  align-content: flex-end;
}

.createPlanButton {
  border: none;
  outline: none;
  padding: 10px 18px;
  color: white;
  background: #18a0fb;
  border-radius: 5.07273px;
}

.createPlanButton:hover {
  cursor: pointer;
}

.createPlanButton:active {
  box-shadow: 0px 5px 10px transparent;
  transform: translateY(1px);
}

.learningPlanBody {
  width: calc(100% - 45px);
  height: fit-content;
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 20px;
}

.learningSummaryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  border-bottom: 1px solid #e4eaf2;
  margin-bottom: 20px;
}

.learningSummaryHeader p {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
}

.learningPlanGrid {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.individualPlan {
  grid-template-columns: 1fr 1fr;
}
.individualLearningPlan {
  cursor: pointer;
  padding: 24px 16px;
  background-color: #fafbfd;
  border-radius: 8px;
  border: 0.5px solid #979797;
  width: calc(33% - 47px);
  margin: 8px;
}

.courseAndView {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.courseAndView p {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.29;

  color: #434f61;
}

.courseAndView div {
  font-weight: 500;
  font-size: 10px;
  line-height: 1.2;
  border-bottom: 1px solid #0b1c83;
  padding-bottom: 4px;
  cursor: pointer;
  color: #0b1c83;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.courseAndView .timeSpent {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;

  color: #ffb200;
}

.individualLearningPlan .progress-bar-container {
  height: 8px;
}

.individualLearningPlan .skillsDetails div p {
  margin: 0;
}

.individualLearningPlan .skillsDetails .skillsName {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.67;

  color: #1c2533;
}

.addLearningPlanModal {
  min-height: 475px;
  /* width: 428px; */
}

.addLearningPlanModal h2 {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.78;
  padding-bottom: 21px;

  border-bottom: 1px solid rgba(141, 144, 145, 0.2);

  color: #023059;
}

.addLearningPlanModal form {
  margin-top: 25px;
}

.addLearningPlanFormElements label,
.addLearningPlanFormElements input {
  margin: 0;
  padding: 0;
}

.addLearningPlanFormElements {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.addLearningPlanFormElements label {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  color: #023059;
  margin-bottom: 6px;
}

.addLearningPlanFormElements input,
.addLearningPlanFormElements textarea {
  border: none;
  background-color: #f3faff;
  padding: 7px 0 6px 14px;
  height: 40px;
  font-size: 12px;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
}

.addLearningPlanFormElements textarea {
  height: 89px;
}

.dropDownSelect {
  background-color: #f3faff;
  border: none !important;
}

.dropDownSelect .react-dropdown-select-input {
  width: fit-content;
}

.dropDownSelect .react-dropdown-select-content {
  height: 28px;
}

.dropDownSelect .react-dropdown-select-content span {
  line-height: 28px;
  font-size: 12px;
  padding-left: 6px;
}

.dropDownSelect .react-dropdown-select-item {
  height: 28px;
  border-bottom: 1px solid #333;
  font-size: 12px;
}
.dropDownSelect
  .react-dropdown-select-item
  .react-dropdown-select-item-selected,
.dropDownSelect .react-dropdown-select-item.react-dropdown-select-item-active {
  border-bottom: 1px solid #333;
  color: #fff;
  font-weight: bold;
}

.addLearningPlanModal .addButton {
  display: flex;
  justify-content: flex-end;
}

.addLearningPlanModal .addButton button {
  border: none;
  outline: none;
  background-color: #18a0fb;
  padding: 10px 56px;
  border-radius: 6px;
  color: white;
}

.addLearningPlanModal .addButton button:hover {
  cursor: pointer;
}

.addLearningPlanModal .addButton button:active {
  transform: translateY(2px);
}

.toastTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.14;
  color: #212934;
  margin-bottom: 8px;
}

.toastSubtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  color: #616078;
}
.sharingLinkText {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  color: #0b1c83;
}

.personalLink {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 2.29;

  color: #023059;
  margin-top: 32px;
  margin-bottom: 16px;
}

.sharingLinksFormsDivider {
  margin-top: 28px;
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  align-items: center;
}

.sharingLinksFormsDivider div {
  width: 169px;
  height: 0;
  border: 1px solid #8d9091;
  opacity: 0.2;
}

.sharingLinksFormsDivider p {
  font-weight: 500;
  font-size: 14px;
  line-height: 2.29;

  color: #023059;
}

.multipleShare {
  display: flex;
  align-items: center;
  gap: 12px;
}

.sm-icon {
  cursor: pointer;
}

.sm-icon:active {
  transform: translateY(2px);
}

.multipleShareButton {
  outline: none;
  border: none;
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #d8dde6;
  border-radius: 4px;
}

.multipleShareButton:hover {
  cursor: pointer;
}

.multipleShareButton:active {
  transform: translateY(2px);
}

.saveLearningPlan {
  margin-top: 24px;
  border-top: 0.3px solid #bcbcbc;
  padding-top: 32px;
}

.addedLearningPlan {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 12px 20px 21px;
  margin-bottom: 25px;
  /* min-height: 70px; */
  background: #f5f7fa;
  border-radius: 8px;
}

.addedLearningPlan img {
  align-self: flex-start;
}

.learningPlanDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.learningPlanDetails p:first-child,
.learningPlanDetails p:last-child {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.29;
  color: #434f61;
}

.learningPlanDetails p:nth-child(2) {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;

  color: #979797;
}

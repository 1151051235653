@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap");

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */
}

iframe {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
}
body {
  margin: 0 auto;
  max-width: 1920px;
  font-family: "Inter", "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

:root {
  color-scheme: light !important;
  --toastify-color-success: #1ac79c;
  --toastify-toast-min-height: 96px;
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-toast-background: #f0fff8;
}

.Toastify__toast {
  border-left: 5px solid #1ac79c;
}

.Toastify__toast-container {
  width: 400px !important;
}
.container {
  padding: 0;
}

.nav {
  display: flex;
  justify-content: space-between;
  height: 75px;
  position: absolute;
  top: 0;
  z-index: 2;
  width: 100%;
  background: transparent;
}

.nav.alt {
  background: #18a0fb;
  padding: 0 45px;
  width: calc(100% - 90px);
  border-bottom: 1px solid white;
}

.nav.whit {
  position: fixed;
  background: white;
}

.nav .nav-top,
.nav .nav-bottom,
.nav .nav-auth {
  display: flex;
}

.nav .nav-top .navbar-header {
  display: flex;
  align-items: center;
}

.onboard-nav .navbar-header a,
.nav .navbar-header a {
  display: flex;
  color: black;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.nav .navbar-header a img {
  width: 23px;
  height: 21px;
  margin: 17px 10px 17px 21px;
}

.onboard-nav .navbar-header a .brand,
.nav .navbar-header a .brand {
  color: #464646;
  line-height: 60px;
  font-weight: 700;
  font-size: 18px;
}

.nav.alt .navbar-header a .brand {
  color: #fff;
  font-size: 24px;
}

.dash-hero .dash-container .top-nav .bottom .nav-info,
.nav .nav-info {
  margin: auto 18px;
  font-size: 16px;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  transition: 0.5s all ease;
}

.nav.alt .nav-info {
  color: #f1f1f1;
  font-size: 21px;
}

.nav.alt .nav-info.hide {
  display: none;
}

.nav .nav-bottom .alo {
  margin: auto 30px auto 10px;
}

.nav .nav-bottom a {
  margin: auto 0;
  color: black;
}

.nav.alt .nav-bottom a {
  color: #f1f1f1;
}

.nav .nav-bottom .nav-info {
  margin: auto 25px;
}

.nav.alt .nav-bottom .nav-info {
  margin: auto 35px;
  font-size: 23px;
}

.nav .nav-link {
  margin: auto;
}

.nav .nav-link.active .nav-info,
.side-nav .nav-link.active .nav-info,
.nav .nav-info.show {
  color: #1339ff;
}

.nav .nav-info.show.error {
  color: red;
}

.nav .nav-info.hide {
  display: none !important;
}

.nav .nav-search input {
  border: 1px solid #1339ff;
  width: 200px;
  height: 20px;
  padding: 8px 8px 8px 15px;
  font-weight: 300;
  font-family: "Poppins";
}

.nav .nav-search input:placeholder-shown {
  font-weight: 100;
  font-family: "Poppins";
}

.available .container form input::placeholder,
.nav .nav-search input::placeholder {
  color: #464646;
}

.nav .nav-search i {
  padding: 4px 20px;
  font-size: 14px;
  background-color: #1339ff;
  border: 1px solid #1339ff;
  top: 1px;
  padding: 9px 17px 12px 16px;
  font-size: 15px;
  position: relative;
  top: -1px;
  color: white;
}

.side-nav .nav-bottom .signup,
.nav .nav-auth .signup {
  display: inline-block;
  height: 32px;
  line-height: 30px;
  font-size: 14px;
  padding: 0 30px;
  text-decoration: none;
  font-weight: 400;
  border: 2px solid #1339ff;
  background-color: transparent;
  border-radius: 10px;
  transition: all 0.5s ease;
  cursor: pointer;
  margin: auto 30px auto 10px;
}

.nav.alt .nav-auth .signup {
  border: 2px solid #ededed;
  border-radius: 5px;
}

.side-nav .nav-bottom .signup {
  margin: 0;
  font-size: 14px;
}

.side-nav .nav-bottom .signup:hover,
.nav .nav-auth .signup:hover {
  background-color: #1339ff;
}

.nav.alt .nav-auth .signup:hover {
  background-color: #ededed;
}

.side-nav .nav-bottom .signup:hover a,
.nav .nav-auth .signup:hover a {
  color: #fff;
}

.nav.alt .nav-auth .signup:hover a {
  color: #000;
}

.side-nav .nav-bottom .signup a,
.nav .nav-auth .signup a {
  color: #1339ff;
  font-weight: 600;
}

.nav.alt .nav-auth .signup a {
  color: #f1f1f1;
  font-size: 19px;
}

.side-nav .hamburger {
  width: 30px;
  height: 20px;
  opacity: 0;
  pointer-events: none;
  margin: auto 25px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  cursor: pointer;
  z-index: 100;
  position: fixed;
  top: 17px;
  right: 5px;
  transition: none;
}

.side-nav .hamburger.dash {
  right: 230px;
  top: 25px;
}

.side-nav .hamburger.open {
  opacity: 1;
  pointer-events: unset;
  transition: all 0.1s ease 0.3s;
}

.side-nav .hamburger span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #1339ff;
  border-radius: 15px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.side-nav .hamburger span:nth-child(2),
.side-nav .hamburger span:nth-child(3) {
  top: 8px;
}

.side-nav .hamburger span:nth-child(4) {
  top: 16px;
}

.side-nav .hamburger.open span:nth-child(1) {
  top: 8px;
  width: 0%;
  left: 50%;
}

.side-nav .hamburger.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.side-nav .hamburger.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.side-nav .hamburger.open span:nth-child(4) {
  top: 8px;
  width: 0%;
  left: 50%;
}

.onboard-nav .nav-info i.fa-chevron-right {
  font-size: 9px;
}

.nav .nav-info i.fa-chevron-down {
  padding: 0px 4px;
  font-size: 9px;
  position: relative;
  top: -1px;
  transition: all 0.5s ease;
}

.side-nav {
  z-index: 5;
  position: fixed;
  height: calc(100vh - 80px);
  background: #f2f5ff;
  right: 0px;
  width: 265px;
  padding: 55px 25px 25px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 30%);
  overflow-x: scroll;
  cursor: default;
  transition: 0.5s all ease;
}

.side-nav.mob {
  display: none;
}

.dash-nav .contain::-webkit-scrollbar,
.side-nav::-webkit-scrollbar {
  display: none;
}

.side-nav.open {
  right: -325px;
}

.side-nav .cancel-nav {
  width: calc(100% - 315px);
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: unset;
  transition: all 0.1s ease 0.2s;
}

.side-nav .cancel-nav.open {
  opacity: 0;
  pointer-events: none;
  transition: none;
}

.side-nav .nav-info {
  margin: 10px 0;
  font-size: 18px;
  cursor: pointer;
  color: #464646;
  transition: 0.5s all ease;
}

.side-nav .img {
  width: 150px;
  min-height: 150px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin: 20px 0;
}

.side-nav .img .icon {
  border: 3px solid black;
  background: black;
  color: white;
  border-radius: 50%;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-nav .img .icon i {
  font-size: 65px;
}

.side-nav h1 {
  font-size: 27px;
  margin: 18px 0 0;
}

.side-nav p {
  margin: 4px 0;
  font-weight: 300;
}

.side-nav .intro {
  font-weight: 500;
  font-family: "Inter", sans-serif;
  text-align: left;
  width: 100%;
  margin: 40px 0 0;
}

.side-nav .item {
  display: flex;
  margin: 15px 0;
}

.side-nav .item .bg,
.side-nav .item img {
  display: none;
}

.side-nav .item .date {
  width: 65px;
  height: 62px;
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 30%);
  border-radius: 10px;
  background: #fff;
}

.side-nav .item .date .no {
  font-size: 26px;
  text-align: center;
  color: #464646;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  padding-top: 5px;
}

.side-nav .item .date .day {
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  margin-top: -2px;
}

.side-nav .item .info {
  width: 70%;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.side-nav .item .info .title {
  font-weight: 500;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  line-height: 20px;
}

.side-nav .item .info .dec {
  font-weight: 100;
  font-size: 15px;
}

.side-nav .settings {
  margin: 70px 0 10px;
  width: 100%;
  font-weight: 300;
  cursor: pointer;
}

.side-nav .settings i {
  padding-right: 15px;
  pointer-events: none;
}

.side-nav .settings span {
  pointer-events: none;
}

.side-nav .logout {
  width: 100%;
  font-weight: 300;
  cursor: pointer;
}

.side-nav .logout.ref {
  display: none;
}

.side-nav .settings:hover,
.side-nav .logout:hover {
  color: #1339ff;
}

.side-nav .logout i {
  padding: 0 15px 0 3px;
}

input:focus-visible,
a:focus-visible {
  outline: none;
}

.nav .nav-info:hover i.fa-chevron-down {
  top: 2px;
  transition: all 0.5s ease;
}

.side-nav .nav-info:hover,
.nav .nav-info:hover {
  color: #1339ff;
}

.navlist {
  display: flex;
  color: #464646;
  position: absolute;
  display: none;
  background: white;
  top: -2000px;
  transition: all 1s ease;
}

.navlist.show {
  top: 0;
}

.navlist .navlist-cover {
  padding: 100px 50px 0;
}

.navlist .navlist-cover .navlist-title {
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.8px;
  font-size: 16px;
  position: relative;
}

.navlist .navlist-cover .navlist-title::after {
  content: " ";
  display: block;
  position: absolute;
  height: 1px;
  background: #464646;
  width: 415px;
  top: 63px;
}

.navlist .navlist-cover .list-item {
  margin-top: 85px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(50vw - 120px);
}

.navlist .navlist-cover .list-item .cover {
  display: flex;
  margin: 20px 0;
}

.navlist .navlist-cover .list-item .cover .description {
  width: calc(((50vw - 120px) * 0.45) - 40px);
  font-weight: 100;
  font-size: 15px;
}

.navlist .navlist-cover .list-item .cover .nav-text {
  padding: 0 20px;
}

.navlist .navlist-cover .list-item .cover .title {
  margin: 0 0 10px;
}

.career,
.firstpage {
  padding: 55px 0 0;
  display: flex;
  height: calc(100vh - 55px);
}

.firstpage.rot {
  height: unset;
}

.firstpage.alt {
  flex-direction: row-reverse;
}

.firstpage.alt .image {
  width: 55%;
}

.firstpage.wait .image {
  width: 42%;
}

.firstpage .image,
.firstpage .image img {
  height: calc(100vh - 55px);
  max-width: 45vw;
  min-width: 450px;
}

.firstpage .image img.load {
  height: 430px;
  width: 430px;
}

.firstpage .image img.sm {
  display: none;
}

.firstpage .image img.tin {
  height: 410px;
  margin: 80px 0 0;
}

.firstpage .image img.abo {
  height: 350px;
  margin: 80px 0 0;
}

.firstpage.rot .image {
  width: 40%;
}

.firstpage.rot .image img {
  width: 263px;
  height: 645px;
  transform: rotate(18deg);
  margin: 45px 0px 0 45px;
  min-width: unset;
}

.firstpage .content {
  width: 700px;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.firstpage.alt .content {
  justify-content: center;
  margin: 0 auto;
}

.firstpage.rot .content {
  margin: 55px auto 0;
}

.firstpage .content h1 {
  font-weight: 700;
  font-size: 55px;
  line-height: 58px;
  width: 100%;
  font-family: "Inter", sans-serif;
  margin: 0 0 30px;
}

.firstpage.alt .content h1 {
  margin: 30px 0 30px;
}

.firstpage.alt .content h1.load {
  width: 510px;
  height: 110px;
}

.firstpage.alt .content h1 .bluu {
  color: #0c1e5a;
}

.firstpage .content p {
  font-size: 20px;
  font-weight: 400;
  position: relative;
  width: 90%;
  margin: 0 0 45px;
}

.firstpage .content p.load {
  width: 480px;
  height: 116px;
}

.firstpage .content .links {
  width: 500px;
}

.firstpage .content .links.hide {
  display: none;
}

.firstpage.alt .lead {
  margin: 125px 0 0;
}

.firstpage.alt .image .lead {
  display: none;
}

.firstpage.alt .lead p {
  margin: 0 0 15px;
}

.firstpage.alt .lead .img {
  margin: 30px 0 70px;
  display: flex;
  flex-wrap: wrap;
}

.firstpage.alt .lead .img img {
  max-width: 100%;
}

.firstpage.alt .lead .img .img-icon {
  width: 155px;
  height: 40px;
  margin: 0 0px 5px 0;
  display: flex;
}

.waitpage {
  height: calc(100vh - 200px);
  min-height: 400px;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("https://dqe80zoqbuyqe.cloudfront.net/root/New+Illustration+Assets-17.png")
    no-repeat center fixed;
  background-size: cover;
}

.waitpage p {
  font-size: 43px;
  color: white;
  text-align: center;
  width: 990px;
  font-weight: 300;
}

.waitpage p .sub {
  font-size: 20px;
  width: 90%;
  margin: 20px auto 0;
  line-height: 26px;
}

.firstpage .error {
  color: red;
  font-size: 16px;
  font-weight: 400;
  width: 530px;
}

.webinar .search,
.firstpage .search {
  padding: 10px 0 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.webinar .search input,
.firstpage .search input {
  width: 340px;
  height: 45px;
  padding: 0 20px 5px;
  background-color: white;
  border: 2px solid #1339ff;
  border-right: none;
  color: black;
  font-size: 15px;
  font-weight: 300;
  font-family: "Poppins";
  transition: all 0.3s ease;
}

.webinar .search.error input,
.firstpage .search.error input {
  border: 2px solid red;
  border-right: none;
}

.webinar .search label,
.firstpage .search label {
  background-color: #1339ff;
  color: white;
  padding: 14px 20px 19px;
  font-size: 15px;
  font-weight: 100;
  width: 106px;
  min-width: 107px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.webinar .search label .hide,
.firstpage .search label .hide {
  display: none;
}

.firstpage .search.error label {
  padding: 12px 20px 17px;
  border: 2px solid red;
  border-left: none;
  color: white;
}

.firstpage .search label:hover {
  background-color: #010c35;
  color: white;
}

.firstpage .search label:hover + input {
  border: 2px solid #010c35;
  border-right: none;
}

.webinar .search.error label:hover + input,
.firstpage .search.error label:hover + input {
  border: 2px solid red;
  border-right: none;
}

.waitpage .video {
  margin: 50px 0 0;
  color: white;
  font-size: 21px;
  font-weight: 300;
  cursor: pointer;
  transition: all 0.5s ease;
}

.waitpage .video:hover {
  transform: scale(1.1);
}

.waitpage .video i {
  padding-left: 10px;
  font-size: 25px;
}

.waitform {
  background-color: #010c35;
  color: white;
  padding: 50px 100px;
}

.waitform .title {
  text-align: center;
  font-size: 39px;
  font-weight: 500;
}

.waitform p {
  text-align: center;
  font-size: 18px;
  font-weight: 100;
  margin: 10px 0 40px;
  letter-spacing: 0.8px;
}

.waitform .error {
  color: red;
  font-size: 16px;
  font-weight: 100;
}

.waitform .form {
  margin: 0 0 40px;
  display: flex;
  justify-content: center;
}

.waitform .form .career {
  width: 600px;
}

.waitform .form .career p {
  font-size: 16px;
  font-weight: 100;
  text-align: unset;
  letter-spacing: unset;
  margin: 20px 0;
}

.waitform .form .career input {
  background-color: transparent;
  border: 1px solid white;
  height: 40px;
  width: 70%;
  padding: 4px 15px 9px;
  color: white;
  font-size: 15px;
  font-weight: 300;
  font-family: "Poppins";
}

.waitform .form .career.err textarea,
.waitform .form .career.err input {
  border: 1px solid red;
}

.waitform .form .career textarea {
  background-color: transparent;
  border: 1px solid white;
  height: 140px;
  width: 80%;
  margin: 0px;
  padding: 9px 15px;
  color: white;
  font-size: 15px;
  font-weight: 300;
}

.waitfeature {
  padding: 75px 100px;
}

.waitfeature .title {
  text-align: center;
  font-size: 36px;
  padding: 45px 0 0;
  color: #1339ff;
  font-weight: 500;
  margin: 0;
}

.waitfeature p {
  text-align: center;
  margin: 5px 0 20px;
  font-size: 18px;
  color: #464646;
}

.waitfeature .benefits {
  margin: 60px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.waitfeature .benefits .feature {
  width: calc(50vw - 230px);
  margin: 0 65px 65px;
}

.waitfeature .benefits .feature:nth-child(2n - 1) .icon {
  margin: 0 0 50px calc(100% - 600px);
}

.waitfeature .benefits .feature .icon {
  width: 90px;
  height: 50px;
  margin: 0 0 50px;
}

.waitfeature .benefits .feature .icon img {
  width: 100px;
}

.waitfeature .benefits .feature .title {
  font-size: 27px;
  font-weight: 500;
  color: #464646;
  margin: 0 0 15px;
  text-align: unset;
  padding: 0;
  width: 600px;
}

.waitfeature .benefits .feature:nth-child(2n - 1) .title,
.waitfeature .benefits .feature:nth-child(2n - 1) .description {
  margin: 0 0 15px calc(100% - 600px);
}

.waitfeature .benefits .feature .description {
  font-size: 20px;
  font-weight: 100;
  line-height: 30px;
  margin: 0 0 15px;
  width: 600px;
}

.waitfeature .benefits .feature a {
  color: #1339ff;
}

.waitfeature .benefits .feature:nth-child(2n - 1) a {
  margin: 0 0 0 calc(100% - 600px);
}

.waitfeature .benefits .feature a i {
  padding-left: 12px;
}

.waitsuccess .inner .links {
  justify-content: center;
  margin: 20px 0;
}

.career .links,
.cta-break .content .links,
.land-2.alt .content .links,
.prof .content .links,
.waitsuccess .inner .links,
.mentor-page .home .content .links,
.available .container form .links,
.null .content .links,
.course .top .links,
.auth-container .auth .links,
.firstpage .content .links {
  display: flex;
}

.blog .container .sale .mail .btn,
.career .links a,
.cta-break .content a,
.land-2.alt .content .links a,
.prof .content .links .btn,
.prof .content .links .disc,
.waitform .submit,
.mentor-page .home .content .links .join,
.waitsuccess .inner .links .join,
.available .container form .links a,
.null .content .links a,
.course .top .links a,
.auth-container .auth .links .btn,
.firstpage .content .links a {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  padding: 0 40px;
  text-decoration: none;
  font-weight: 400;
  border: 1px solid #1339ff;
  background-color: #1339ff;
  transition: all 0.5s ease;
  cursor: pointer;
  margin: 0 20px 0 0;
  color: white;
}

.waitform .submit {
  border: 1px solid #fff;
  background-color: #fff;
  color: #000;
  width: 56px;
  margin: 20px 0 20px calc(50% - 52px);
}

.waitform .submit .hide {
  display: none;
}

.waitsuccess .inner .links .join {
  padding: 0 50px;
}

.waitsuccess .inner .links .join.hide {
  display: none;
}

.prof .content .links .disc:hover,
.waitform .submit:hover,
.waitsuccess .inner .links .join.cancel:hover {
  background-color: #1339ff;
  color: #fff;
}

.waitform .submit:hover {
  border: 1px solid #1339ff;
}

.blog .container .sale .mail .btn:hover,
.land-2.alt .content .links a:hover,
.prof .content .links .btn:hover,
.prof .content .links .disc,
.waitsuccess .inner .links .join.cancel,
.mentor-page .home .content .links .join:hover,
.waitsuccess .inner .links .join:hover,
.available .container form .links a:hover,
.null .content .links a:hover,
.course .top .links a:hover,
.firstpage .content .links a:hover {
  color: #1339ff;
  background-color: #fff;
}

.auth-container .auth .links .btn:hover {
  background-color: #010c32;
  border: 1px solid #010c32;
}

.mentor-page .home .content .links .join,
.waitsuccess .inner .links .join,
.available .container form .links a,
.null .content .links a,
.course .top .links a {
  font-weight: 400;
  font-size: 14px;
}

.auth-container .auth .links .btn {
  padding: 0px 125px;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  font-size: 20px;
  margin: 0;
}

.available .container form .links a {
  padding: 0 40px;
}

.prof .content .links .btn.hide,
.onboard-form .content .links .continue.hide,
.auth-container .auth .links .btn.hide {
  display: none;
}

.nota,
.hire,
.secondpage {
  padding: 85px 50px 75px;
}

.fifthpage.hide,
.cta-break.hide,
.hire.hide,
.sim.hide,
.nota.hide {
  display: none;
}

.sim {
  padding: 0 50px;
}

.nota .title,
.sim .title,
.hire .title,
.secondpage .title {
  width: 50%;
  font-size: 32px;
  font-size: 40px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 47px;
}

.hire p,
.secondpage p {
  font-size: 18px;
  margin: 15px 0;
  width: 50%;
  font-weight: 300;
}

.sim .company-logo,
.hire .company-logo,
.secondpage .company-logo {
  display: flex;
  flex-wrap: wrap;
}

.hire .company-logo .img,
.secondpage .company-logo .img {
  width: 212px;
  height: 50px;
  margin: 10px 15px;
  display: flex;
}

.hire .company-logo .img img,
.secondpage .company-logo .img img {
  max-width: 100%;
  max-height: 45px;
  margin: auto;
}

.sim p {
  margin: 20px;
  font-size: 35px;
  font-weight: 600;
  width: 150px;
  line-height: 40px;
  font-family: "Font Awesome 5 Brands";
}

.thirdpage {
  height: 40vh;
  min-height: 450px;
  display: flex;
  padding: 0 80px;
  justify-content: center;
  flex-direction: row;
}

.thirdpage:nth-child(2n - 1) {
  flex-direction: row-reverse;
}

.thirdpage .img {
  display: flex;
  width: 600px;
  align-items: center;
}

.thirdpage .img img {
  width: 80%;
}

.thirdpage .img img.sm {
  display: none;
}

.thirdpage .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(630px - 100px);
  margin: 0 0 0 100px;
  color: #464646;
}

.thirdpage:nth-child(2n - 1) .content {
  width: 630px;
  margin: 0;
}

.thirdpage .content .intro-text {
  font-size: 18px;
  font-weight: 400;
  padding: 0 0 15px;
}

.thirdpage .content .title {
  font-size: 33px;
  color: #373737;
  padding: 0 0 20px;
  font-weight: 400;
  width: 500px;
  line-height: 42px;
}

.thirdpage .content .description {
  font-size: 20px;
  font-weight: 300;
  color: #373737;
  line-height: 30px;
  padding: 0 0 30px;
  width: 70%;
}

.thirdpage .content a {
  display: block;
  height: 45px;
  line-height: 43px;
  font-size: 15px;
  padding: 0 33px;
  text-decoration: none;
  font-weight: 100;
  border: 1px solid #1339ff;
  background-color: #1339ff;
  transition: all 0.5s ease;
  cursor: pointer;
  color: white;
  width: 80px;
}

.thirdpage .content a:hover {
  border: 1px solid #001999;
  background-color: #001999;
}

.fourthpage {
  padding: 35px 0 100px;
  background: #bfecff;
  position: relative;
  overflow: hidden;
}

.stats::before,
.fourthpage::before {
  content: " ";
  position: absolute;
  top: -11px;
  left: -49px;
  transform: rotate(135deg);
  border-top: 75px solid white;
  border-right: 75px solid transparent;
  border-left: 75px solid transparent;
  border-bottom: none;
}

.stats::after,
.fourthpage::after {
  content: " ";
  position: absolute;
  bottom: -11px;
  right: -49px;
  transform: rotate(-45deg);
  border-top: 75px solid white;
  border-right: 75px solid transparent;
  border-left: 75px solid transparent;
  border-bottom: none;
}

.fourthpage h1 {
  font-size: 40px;
  padding: 40px 0 0 95px;
  color: black;
  font-family: "Inter", sans-serif;
  width: 465px;
  font-weight: 700;
  margin: 0;
}

.fourthpage p {
  margin: 5px 0 0 95px;
  font-size: 18px;
  width: 500px;
}

.fourthpage .card-cover {
  padding: 50px 50px 0;
  display: flex;
  justify-content: center;
}

.fourthpage .card-cover .card {
  width: 210px;
  text-align: left;
  padding: 60px 23px 20px;
  background: white;
  margin: 0 15px;
  position: relative;
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 30%);
}

.fourthpage .card-cover .card::before {
  content: " ";
  position: absolute;
  top: -13px;
  left: -50px;
  transform: rotate(135deg);
  border-top: 60px solid #bfecff;
  border-right: 60px solid transparent;
  border-left: 60px solid transparent;
  border-bottom: none;
}

.fourthpage .card-cover .card::after {
  content: " ";
  position: absolute;
  bottom: -25px;
  right: -60px;
  transform: rotate(-45deg);
  border-top: 70px solid #bfecff;
  border-right: 70px solid transparent;
  border-left: 70px solid transparent;
  border-bottom: none;
}

.fourthpage .card-cover .card .icon {
  font-size: 25px;
  padding-bottom: 21px;
  color: #333333;
  display: flex;
}

.fourthpage .card-cover .card .icon img {
  width: 50px;
  margin-left: 5px;
}

.fourthpage .card-cover .card .title {
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 10px;
  font-family: "Inter", sans-serif;
  min-height: 46px;
}

.fourthpage .card-cover .card .description {
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 20px;
}

.fifthpage {
  padding: 80px 200px;
}

.fifthpage h1 {
  text-align: center;
  font-size: 39px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  margin: 0;
}

.fifthpage .description {
  color: #373737;
  width: 450px;
  font-weight: 400;
  text-align: center;
  margin: 14px auto;
  font-size: 20px;
}

.fifthpage .links {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}

.fifthpage .links a {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  padding: 0 25px;
  text-decoration: none;
  font-weight: 400;
  border: 1px solid #1339ff;
  background-color: #1339ff;
  transition: all 0.5s ease;
  cursor: pointer;
  margin: 0 20px 0 0;
  color: white;
}

.fifthpage .links a:hover {
  background-color: #fff;
  color: #1339ff;
}

.footer {
  padding: 100px 30px 0;
  background: #010c35;
  color: white;
  border-radius: 50px 50px 0 0;
}

.footer .footer-list {
  display: flex;
  justify-content: space-between;
  max-width: 1500px;
  margin: 0 auto;
}

.footer .footer-list .over {
  display: flex;
  justify-content: space-around;
  width: 50%;
}

.footer .footer-list .over .group {
  margin: 0 10px;
}

.footer .footer-list .title {
  font-weight: 500;
  font-size: 18px;
  padding: 0 0 35px;
  text-transform: uppercase;
}

.footer .footer-list .title:hover {
  color: #1339ff;
}

.footer .footer-list .item {
  padding: 4px 0px;
  font-size: 16px;
  font-weight: 300;
  color: white;
}

.footer hr {
  margin: 45px 0;
}

.footer p {
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.footer .contact {
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  flex-wrap: wrap;
}

.footer .contact .rights {
  font-size: 13px;
  padding: 5px 0 0;
  font-weight: 100;
  margin: 10px 20px 0 0;
}

.footer .contact .info {
  margin: 10px 0 0;
}

.footer .contact .social-links {
  margin: 10px 20px 0 0;
}

.waitsuccess .inner .links .social-links {
  align-items: center;
  margin: 0 20px 0 0;
}

.waitsuccess .inner .links .social-links.hide {
  display: none;
}

.waitsuccess .inner .links .social-links,
.footer .contact .social-links {
  display: flex;
}

.waitsuccess .inner .links .social-links a {
  height: 30px;
  width: 30px;
  background-color: black;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid black;
  line-height: 32px;
  text-align: center;
  margin: 0 5px;
  padding: 0;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.waitsuccess .inner .links .join {
  border-radius: 5px;
  border: 2px solid #1339ff;
}

.footer .contact .social-links a {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid white;
  line-height: 36px;
  text-align: center;
  margin: 0 5px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.waitsuccess .inner .links .social-links a {
  background-color: #010c35;
  border: 1px solid #010c35;
}

.waitsuccess .inner .links .social-links a i,
.footer .contact .social-links a i {
  color: white;
  font-size: 20px;
  transition: color 0.5s ease;
  -webkit-transition: color 0.5s ease;
}

.footer .contact .social-links a i {
  font-size: 16px;
}

.waitsuccess .inner .links .social-links a:hover {
  background-color: transparent;
  border: 1px solid transparent;
}

.footer .contact .social-links a:hover {
  background-color: white;
}

.waitsuccess .inner .links .social-links a:hover i {
  color: #000;
}

.footer .footer-list .item:hover,
.footer .contact .info a:hover,
.footer .contact .social-links a:hover i,
.waitsuccess .inner .links .social-links a:hover i {
  color: #1339ff;
}

.footer .contact .info a {
  margin: 0 0 0 30px;
  color: white;
  font-weight: 300;
  transition: all ease 0.5s;
}

.footer .contact .info a:nth-child(1) {
  margin: 0 30px 0 0;
}

.footer .contact .info a i {
  padding-right: 8px;
  font-size: 18px;
}

.sixthpage {
  /* darkblue */
  background-color: #010c35;
  color: white;
  padding: 50px 35px 50px 70px;
}

.sixthpage h1 {
  text-align: center;
  font-size: 37px;
  padding: 35px 0 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin: 0;
}

.sixthpage .sub-text {
  text-align: center;
  padding: 5px 0 65px;
  font-weight: 300;
}

.sixthpage .tag {
  padding: 0 0 0 15px;
}

.sixthpage .cata-sub-nav {
  padding: 25px 15px 75px;
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  max-width: 2100px;
}
.sixthpage .cata-sub-nav::-webkit-scrollbar {
  display: none;
}
.sixthpage .cata-sub-nav ul {
  margin: 0;
  display: flex;
  -webkit-padding-start: 0px;
}
.sixthpage .cata-sub-nav li {
  display: inline-table;
  margin: 0 15px;
  font-size: 16px;
}
.sixthpage .cata-sub-nav li:nth-child(1) {
  margin: 0 40px 0 0;
}
.sixthpage .nav-prev {
  width: 45px;
  height: 45px;
  position: absolute;
  left: 10px;
  margin-top: 95px;
  border: 1px solid white;
  border-radius: 50%;
  cursor: pointer;
}

.sixthpage .nav-prev .fas {
  position: relative;
  top: 11px;
  left: 14px;
}

.sixthpage .nav-next .fas {
  position: relative;
  top: 11px;
  left: 16px;
}

.sixthpage .nav-next {
  width: 45px;
  height: 45px;
  position: absolute;
  right: 9px;
  margin-top: -185px;
  border: 1px solid white;
  border-radius: 50%;
  cursor: pointer;
}

.onboard.test .content .top-nav .progress-bar.hide,
.onboard.test .content .top-nav .back.hide,
.sixthpage .arrow.hide {
  display: none;
}

.sixthpage .arrow .fas {
  font-size: 21px;
  pointer-events: none;
}

.sixthpage .card {
  width: 275px;
}

.sixthpage .img img {
  width: 275px;
}

.sixthpage .img .title {
  font-size: 20px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  margin: 15px 0;
  text-align: center;
}

.sixthpage .img a {
  border: 1px solid #1339ff;
  padding: 7px 15px;
  color: white;
  font-weight: 300;
  background: #1339ff;
  font-size: 15px;
  width: 77px;
  display: flex;
  margin: auto;
  transition: all ease 0.5s;
}

.sixthpage .img a.ment {
  width: 102px;
}

.sixthpage .img a:hover {
  border: 1px solid #fff;
  color: #1339ff;
  background: #fff;
}

.onboard {
  display: flex;
  background: #f2f5ff;
}

.onboard .content {
  width: 80%;
}

.onboard .content .alert-danger {
  padding-left: 140px;
  color: red;
}

.onboard.test {
  background-color: #f2f5ff;
}

.result .top-nav,
.onboard.test .content .top-nav {
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.result .top-nav .back,
.onboard.test .content .top-nav .back {
  margin: 20px 0 0 120px;
  border: 2px solid black;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.result .top-nav .back:hover i,
.onboard.test .content .top-nav .back:hover i {
  padding: 6px 0 0 2px;
}

.result .top-nav .back i,
.onboard.test .content .top-nav .back i {
  padding: 6px 0 0 6px;
  transition: all ease 0.5s;
}

.onboard.test .content .top-nav .progress-bar {
  width: 740px;
  height: 18px;
  border-radius: 20px;
  background: #bfecff;
  position: fixed;
  top: 21px;
  z-index: 2;
  left: calc((100vw - 740px) / 2);
}

.onboard.test .content .top-nav .progress-bar .bar {
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(to right, #3a4978, #071235);
  transition: all ease 1s;
}

.result .top-nav .user .img,
.onboard.test .content .top-nav .user .img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: 20px 40px 0 0;
  position: fixed;
  overflow: hidden;
}

.result .top-nav .user .img {
  top: 5px;
  right: 0;
}

.result .top-nav .user .img .icon,
.onboard.test .content .top-nav .user .img .icon {
  width: calc(40px - 4px);
  height: calc(40px - 4px);
  border: 2px solid #010c35;
  background: #010c35;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.result .top-nav .user .img .icon i,
.onboard.test .content .top-nav .user .img .icon i {
  padding: 0 1px 3px 0px;
}

.onboard .content .onboard-nav {
  width: 100%;
  display: flex;
  margin: 40px 5px 20px 150px;
}

.onboard .content .onboard-nav .navbar-header {
  padding-right: 50px;
}

.onboard .content .onboard-nav .nav-info {
  margin: auto 67px auto 0;
  font-size: 13px;
  cursor: pointer;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
}

.onboard .content .onboard-nav .nav-info.show.error:hover,
.onboard .content .onboard-nav .nav-info.error:hover,
.onboard .content .onboard-nav .nav-info.error {
  color: red;
}

.onboard .content .onboard-nav .nav-info .icon {
  width: 18px;
  height: 18px;
  border: 2px solid #000;
  border-radius: 50%;
  margin-left: 25px;
  margin-bottom: 10px;
  pointer-events: none;
  transition: all ease 0.1s;
}

.onboard .content .onboard-nav .nav-info.col .icon {
  border: 2px solid #34c240;
}

.onboard .content .onboard-nav .nav-info.error .icon,
.onboard .content .onboard-nav .nav-info.error.col .icon {
  border: 2px solid red;
}

.onboard .content .onboard-nav .nav-info .icon i {
  font-size: 10px;
  position: relative;
  left: 4px;
  color: #34c240;
  transform: scale(0);
  pointer-events: none;
  transition: all cubic-bezier(0.83, 0.98, 0.86, 1.23) 0.5s;
}

.onboard .content .onboard-nav .nav-info.col .icon i {
  transform: scale(1);
}

.onboard .content .onboard-nav .nav-info.error .icon i,
.onboard .content .onboard-nav .nav-info.error.col .icon i {
  transform: scale(0);
}

.onboard .content .onboard-nav .nav-info .line {
  position: relative;
  width: 175px;
  top: -39px;
  left: 59px;
  pointer-events: none;
  height: 2px;
  background: black;
}

.onboard .content .onboard-nav .nav-info .line .fill {
  width: 0;
  height: 100%;
  pointer-events: none;
  background: #34c240;
  transition: all ease 0.5s;
}

.onboard .content .onboard-nav .nav-info.col .line .fill {
  width: 100%;
}

.onboard .content .onboard-nav .nav-info.error .line .fill,
.onboard .content .onboard-nav .nav-info.error.col .line .fill {
  width: 0;
}

.onboard .content .onboard-nav .nav-info:nth-child(3) {
  margin: auto 38px auto 0;
}

.onboard .content .onboard-nav .nav-info:nth-child(3) .icon {
  margin-left: 4px;
}

.onboard .content .onboard-nav .nav-info:nth-child(4) .icon {
  margin-left: 14px;
}

.onboard .content .onboard-nav .nav-info:nth-child(3) .line {
  left: 39px;
}

.onboard .img {
  width: 290px;
  height: 100vh;
  position: fixed;
  right: 0px;
  z-index: 2;
}

.onboard .img img {
  width: 100%;
  height: 100%;
}

.onboard-form {
  position: absolute;
  width: 100%;
  left: 0;
  height: 620px;
  overflow: hidden;
  background: #f2f5ff;
}

.onboard-form.test {
  width: 100%;
  background-color: #f2f5ff;
  height: 680px;
}

.onboard-form.test .content {
  width: calc(100% - 150px);
  max-width: 1452px;
  left: 0;
}

.onboard-form.test .content .hom,
.onboard-form.test .content .intro {
  display: flex;
}

.onboard-form.test .content .intro h1 {
  width: 610px;
  font-size: 40px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin: 70px 0 0 50px;
  line-height: 53px;
}

.onboard-form.test .content .sm {
  display: none;
}

.onboard-form.test .content .intro img {
  width: 283px;
  margin: 0 0 0 94px;
  transform: rotate(25deg);
}

.onboard-form.test .content .hom {
  margin-top: 10px;
}

.onboard-form.test .content .hom p {
  margin: 10px 30px 0 50px;
  font-size: 25px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.onboard-form.test .content .hom img {
  width: 60px;
  height: 52px;
}

.onboard-form.test .content .detail {
  display: flex;
  margin: 25px 50px 55px;
  justify-content: space-between;
}

.onboard-form.test .content .detail .info {
  width: 350px;
  display: flex;
  margin: 0 5px;
}

.onboard-form.test .content .detail .info .icon {
  width: 7px;
  height: 7px;
  margin: 20px 10px 0;
  border: 4px solid black;
  border-radius: 50%;
}

.onboard-form.test .content .detail .info p {
  font-weight: 400;
  width: calc(100% - 27px);
}

.onboard-form .content {
  padding: 20px 75px;
  position: absolute;
  width: calc(80% - 150px);
  right: 15vw;
  transition: all 1s ease;
  opacity: 1;
}

.onboard-form .content.back {
  display: flex;
}

.onboard-form .content.back .arrow .bach {
  position: absolute;
  top: 42px;
  left: 25px;
  border: 1px solid;
  width: 27px;
  cursor: pointer;
  height: 24px;
  transition: all 0.5s ease;
}

.onboard-form .content.back .arrow i {
  font-size: 14px;
  position: relative;
  top: -2px;
  left: 8px;
  pointer-events: none;
  transition: all 0.5s ease;
}

.onboard-form .content.back .arrow .bach:hover i {
  left: 5px;
}

.onboard-form .content.show {
  right: -850px;
  opacity: 0;
}

.onboard-form.test .content.show {
  left: 1500px;
}

.onboard-form .content.hide {
  right: 1288px;
  opacity: 0;
}

.onboard-form.test .content.hide {
  right: 0;
  left: -1500px;
}

.onboard-form .content .title {
  font-size: 40px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  padding: 10px 0;
}

.onboard-form.test .content .title {
  font-size: 19px;
  font-weight: 300;
  padding: 8px 0;
  margin-top: 30px;
  margin-left: 50px;
}

.onboard-form.test .content .input {
  margin-left: 50px;
}

.onboard-form .content .sub-title {
  font-weight: 100;
  padding: 0 0 25px;
  font-size: 19px;
  width: 590px;
}

.onboard-form .content .input .label {
  font-size: 10px;
  padding: 0 0 5px;
  color: #222222;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.onboard-form.test .content .input .label {
  font-size: 30px;
  padding: 0 0 5px;
  width: 600px;
  font-family: "Inter", sans-serif;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 500;
  margin-bottom: 40px;
}

.onboard-form .content .input input {
  height: 35px;
  width: 600px;
  margin: 5px 0 25px;
  font-weight: 300;
  border-radius: 4px;
  border: 1px solid lightgray;
  padding: 4px 20px;
  font-family: "Poppins";
}

.onboard-form.test .content .input input {
  margin: 5px 0 125px;
}

.onboard-form .content .input input.error {
  border: 1px solid red;
}

.onboard-form .content .input input.goal {
  margin: 5px 0 60px;
}

.onboard-form .content .input textarea {
  width: 598px;
  height: 168px;
  margin: 5px 0px 40px;
  padding: 10px 20px;
  color: #464646;
  border: 1px solid lightgray;
  border-radius: 10px;
}

.onboard-form .content .input textarea.bio {
  height: 125px;
  margin: 5px 0px 20px;
}

.onboard-form .content .input textarea.error:focus-visible,
.onboard-form .content .input textarea.error {
  border: 1px solid red;
}

.onboard-form .content .input textarea:focus-visible,
.onboard-form .content .input textarea.error:focus-visible {
  outline: none;
}

.auth-container .auth input:placeholder-shown,
.onboard-form .content .input input:placeholder-shown {
  font-weight: 300;
  padding: 4px 20px;
  font-family: "Inter", sans-serif;
}

.auth-container .auth input::placeholder,
.onboard-form .content .input input::placeholder {
  color: #464646;
}

.onboard-form .content .links {
  display: flex;
}

.onboard-form.test .content .links {
  margin-top: 50px;
  margin-left: 50px;
}

.onboard-form .content .links.start {
  justify-content: center;
}

.onboard-form .content .links.plan {
  margin: 60px 0 0;
  justify-content: center;
}

.onboard-form .content .links.plan .continue {
  padding: 0 34px;
}

.onboard-form .content .links.submit {
  justify-content: center;
}

.onboard-form .content .links .continue {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  padding: 0 40px;
  text-decoration: none;
  font-weight: 300;
  border: 1px solid #1339ff;
  background-color: #1339ff;
  transition: all 0.5s ease;
  cursor: pointer;
  margin: 0 20px 0 0;
  color: white;
}

.onboard-form .content .links.start .continue {
  display: inline-block;
  height: 55px;
  line-height: 50px;
  font-size: 16px;
  letter-spacing: 1.5px;
  padding: 0 90px;
  font-weight: 400;
  border-radius: 7px;
}

.onboard-form .content .links.start .continue.hide {
  display: none;
}

.onboard-form .content .links.start .continue:hover {
  background-color: #010c35;
  color: #fff;
  border: 1px solid #010c35;
}

.onboard-form .content .links .continue:hover {
  background-color: #fff;
  color: #1339ff;
}

.mentor-page .home .content .links .video,
.available .container form .links .home,
.null .content .links .more,
.auth-container .auth .links .google,
.course .top .links .mark,
.onboard-form .content .links .home {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  padding: 0 26px;
  text-decoration: none;
  font-weight: 300;
  border: 1px solid #1339ff;
  transition: all 0.5s ease;
  cursor: pointer;
  margin: 0 20px 0 0;
  color: #1339ff;
}

.mentor-page .home .content .links .video:hover,
.available .container form .links .home:hover,
.null .content .links .more:hover,
.auth-container .auth .links .google:hover,
.course .top .links .mark:hover,
.onboard-form .content .links .home:hover {
  color: #fff;
  background-color: #1339ff;
}

.mentor-page .home .content .links .video:hover a {
  color: #fff;
}

.mentor-page .home .content .links .video a {
  color: #1339ff;
}

.auth-container .auth .links .google {
  padding: 0px 35px;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
}

.auth-container .auth .links .google.bac {
  padding: 0px 70px;
}

.radio {
  margin: -9px 0 20px;
}

input[type="radio"] + label,
input[type="checkbox"] + label {
  display: block;
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
}

input[type="radio"],
input[type="checkbox"] {
  display: none;
}

input[type="radio"] + label:before,
input[type="checkbox"] + label:before {
  content: "\2714";
  border: 1px solid #000;
  border-radius: 3.2px;
  display: inline-block;
  width: 14px;
  line-height: 16px;
  height: 14px;
  font-size: 12px;
  padding-left: 3px;
  padding-bottom: 3px;
  margin-right: 0.2em;
  vertical-align: top;
  color: transparent;
  transition: 0.2s;
}

input[type="radio"] + label:active:before,
input[type="checkbox"] + label:active:before {
  transform: scale(0);
}

input[type="radio"]:checked + label:before,
input[type="checkbox"]:checked + label:before {
  background-color: #1339ff;
  border-color: #1339ff;
  color: #fff;
}

input[type="radio"]:disabled + label:before,
input[type="checkbox"]:disabled + label:before {
  transform: scale(1);
  border-color: #aaa;
}

input[type="radio"]:checked:disabled + label:before,
input[type="checkbox"]:checked:disabled + label:before {
  transform: scale(1);
  background-color: #1339ff;
  border-color: #1339ff;
}

input[type="radio"] + label span,
input[type="checkbox"] + label span {
  font-size: 13px;
  position: relative;
  bottom: 5px;
  padding-left: 12px;
  font-weight: 300;
}

.onboard-form .content.back .n-arrow .options {
  display: flex;
}

.onboard-form .content.back .n-arrow .options .card-inner {
  margin: 0 25px 0 0;
}

.onboard-form .content.back .n-arrow .options .card {
  width: 252px;
  text-align: center;
  background-color: #1339ff;
  color: white;
  padding: 0 0 22px;
  transition: all 0.5s ease;
}

.onboard-form .content.back .n-arrow .options .card-inner.show .card {
  transform: scale(0.95);
  background-color: #010c35;
}

.onboard-form .content.back .n-arrow .options .card:hover {
  background-color: #010c35;
}

.onboard-form .content.back .n-arrow .options .card-inner:nth-child(1) {
  margin: 0 25px 0 35px;
}

.onboard-form .content.back .n-arrow .options .card .title {
  color: white;
  font-size: 15px;
  padding: 20px 0 0;
  pointer-events: none;
}

.onboard-form .content.back .n-arrow .options .card .icon {
  font-size: 80px;
  pointer-events: none;
}

.onboard-form .content.back .n-arrow .options .card .price {
  font-size: 26px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  padding: 5px 0 0px;
  pointer-events: none;
}

.onboard-form .content.back .n-arrow .options .card .pricetag {
  font-size: 12px;
  font-weight: 300;
  padding: 0 0 25px;
  pointer-events: none;
}

.onboard-form .content.back .n-arrow .options .card .features {
  font-size: 15px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  padding: 0 0 5px;
  pointer-events: none;
}

.onboard-form .content.back .n-arrow .options .card .feature-list {
  font-size: 13px;
  font-weight: 100;
  padding: 0 0 3px;
  pointer-events: none;
}

.onboard-form .content.back .n-arrow p {
  margin: 30px 0 0;
  text-align: center;
  color: #2d2c2c;
  font-size: 14px;
  font-weight: 300;
}

.onboard-form .content.back .n-arrow p a {
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #2d2c2c;
}

.onboard-form .content.back .n-arrow p a:hover {
  font-weight: 500;
  font-family: "Inter", sans-serif;
  text-decoration: underline;
}

.onboard-form .content.back .n-arrow .feats {
  display: flex;
  padding: 75px 30px 35px;
  width: 780px;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.onboard-form .content.back .n-arrow .feats.skill {
  width: 100%;
}

.onboard-form.test .error {
  font-weight: 300;
  color: red;
  margin-left: 50px;
}

.onboard-form.test .content.back .n-arrow .feats .value {
  border: 1px solid #1339ff;
  margin: 10px;
  padding: 8px 30px;
  cursor: pointer;
  font-weight: 300;
  transition: all 0.5s ease;
}

.onboard-form.test .content.back .n-arrow .feats .value:hover {
  border: 1px solid #bfecff;
  background-color: #bfecff;
}

.onboard-form.test .content.back .n-arrow .feats .value.active {
  background-color: #010c35;
  color: white;
  border: 1px solid #010c35;
}

.onboard-form.test .content.back .n-arrow .feats .skill {
  padding: 19px 25px 14px;
  cursor: pointer;
  font-weight: 300;
  margin: 5px 0;
  position: relative;
}

.onboard-form.test .content.back .n-arrow .feats .skill.active {
  background-color: #fafad2;
}

.onboard-form.test .content.back .n-arrow .feats .skill:hover {
  background-color: #bfecff;
}

.onboard-form.test .content.back .n-arrow .feats .skill img {
  max-height: 50px;
  max-width: 420px;
}

.onboard-form.test .feats .skill .radio.test {
  position: absolute;
  top: -55px;
  border: 1px solid black;
  margin: 0;
  padding: 32px 0 12px;
  color: white;
  background-color: black;
  border-radius: 15px;
  display: none;
}

.onboard-form.test .feats .skill .radio.test.show {
  display: flex;
  flex-direction: row;
  padding: 2px 20px;
  top: -25px;
  z-index: 1;
}

.onboard-form.test .feats .skill .radio.test .tiny-tri {
  content: " ";
  position: absolute;
  bottom: -15px;
  border-top: 15px solid black;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: none;
}

.onboard-form.test .feats .skill .radio.test.show label {
  margin: 0 8px;
  z-index: 1;
  height: unset;
  width: unset;
  border: none;
  border-radius: 0;
  background: unset;
}

.onboard-form.test .feats .skill .radio.test.show label:before {
  border: 1px solid black;
  content: "\2714";
  border-radius: 3.2px;
  width: 14px;
  height: 14px;
  padding-left: 3px;
  padding-bottom: 3px;
  margin-right: 0.2em;
  bottom: -18px;
  left: 16px;
  transition: 0.2s;
}

.onboard-form.test .feats .skill .radio.test.show label span {
  font-size: 13px;
  bottom: 35px;
  padding: 0;
  font-weight: 300;
  left: unset;
}

.onboard-form.test .radio.test.show input[type="radio"]:checked + label:before {
  border: 1px solid #1339ff;
  background-color: unset;
}

.onboard-form.test
  .radio.test.show
  input[type="radio"]:checked
  + label:hover:before,
.onboard-form.test .radio.test.show input[type="radio"]:checked + label:before {
  background-color: #1339ff;
  border-color: #1339ff;
}

.onboard-form.test .radio.test.show input[type="radio"] + label:active:before {
  transform: scale(0);
}

.onboard-form .content.back .n-arrow .feats .feat-link {
  text-align: center;
  color: #202020;
  margin: 0 0 40px;
  width: 200px;
}

.onboard-form .content.back .n-arrow .feats .feat-link .icon {
  font-size: 35px;
  width: 75px;
  margin: auto;
}

.onboard-form .content.back .n-arrow .feats .feat-link .icon img {
  width: 100%;
}

.onboard-form .content.back .n-arrow .feats .feat-link .title {
  font-size: 20px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  color: #010c35;
  padding: 10px 0 5px;
}

.onboard-form .content.back .n-arrow .feats .feat-link .subtitle {
  font-size: 12px;
  font-weight: 300;
}

.auth-container {
  display: flex;
  background: #fff;
}

.auth-container .auth {
  width: calc(50% - 130px);
  padding: 35px 65px 50px;
}

.auth-container .auth .reset {
  margin-bottom: 150px;
}

.auth-container .auth .header img {
  width: 25px;
}

.auth-container .auth .header span {
  font-size: 30px;
  color: #1b1e20;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  padding: 0 0 0px 20px;
  position: relative;
  bottom: 4px;
}

.auth-container .auth .title {
  width: 100%;
  font-size: 53px;
  line-height: 53px;
  font-weight: 700;
  font-family: "Darker Grotesque", sans-serif;
  color: #202020;
  margin: 40px 0 20px;
}

.auth-container .auth .subheader {
  width: 100%;
  font-size: 25px;
  line-height: 32px;
  font-weight: 500;
  font-family: "Darker Grotesque", sans-serif;
  color: #111110;
}

.auth-container .auth .nam {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.auth-container .auth .nam .col {
  width: calc(50% - 10px);
}

.auth-container .auth .nam .col input {
  width: calc(100% - 40px);
}

.auth-container .auth label {
  display: block;
  font-size: 19px;
  padding: 0 0 5px;
  font-weight: 400;
  position: relative;
}

.auth-container .auth label .show {
  position: absolute;
  top: 105px;
  color: #1339ff;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
}

.auth-container .auth label .show:hover {
  text-decoration: none;
}

.auth-container .auth label.hide {
  display: none;
}

.auth-container .auth label img {
  width: 26px;
  position: relative;
  top: 57px;
  left: calc(100% - 100px);
}

.auth-container .auth label img.pas {
  left: calc(100% - 140px);
}

.auth-container .auth input {
  height: 45px;
  width: calc(100% - 40px);
  margin: 5px 0 10px;
  font-weight: 300;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 4px 20px;
  font-family: "Inter", sans-serif;
}

.auth-container .auth input.hide {
  display: none;
}

.auth-container .image {
  width: 50%;
  height: 100vh;
  position: fixed;
  right: 0;
  background: #e453d5;
}

.auth-container .image img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.auth-container .auth .links {
  margin: 65px 0 0;
}

.auth-container .auth .footnotes {
  color: #464646;
  padding: 13px 130px 0 0;
}

.auth-container .auth .footnotes .existing {
  font-size: 14px;
  margin: 0 0 5px;
}

.auth-container .auth .footnotes .forgot,
.auth-container .auth .footnotes .existing a {
  color: #0028ff;
  cursor: pointer;
}

.auth-container .auth .footnotes .forgot:hover,
.auth-container .auth .footnotes .existing a:hover {
  text-decoration: underline;
}

.auth-container .auth .footnotes .trouble {
  font-size: 13px;
  margin: 0 0 7px;
}

.auth-container .auth .footnotes .terms {
  font-size: 12px;
  margin: 0 0 12px;
}

.auth-container .auth .footnotes .alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #1339ff;
  padding: 50px;
  border-radius: 15px;
  color: white;
}

.auth-container .auth .footnotes .alert.hide {
  display: none;
}

.auth-container .auth .footnotes .alert .cancel {
  width: 55px;
  padding: 7px 30px;
  color: #1339ff;
  font-size: 15px;
  background: white;
  border-radius: 4px;
  margin: auto;
  cursor: pointer;
}

.auth-container .auth .alert-danger {
  width: calc(100% - 40px);
  border: 1px solid red;
  padding: 10px 20px;
  font-size: 13px;
  color: white;
  background-color: red;
  font-weight: 300;
  margin: 25px 0;
  transition: all 1s ease;
}

.dash-hero {
  display: flex;
}

.dash-nav {
  width: 15vw;
  min-width: 200px;
  background: #fff;
  color: black;
  padding: 30px 20px;
  position: fixed;
  top: 0px;
  left: 0px;
  height: calc(100vh - 46px);
  border-radius: 0 55px 0 0;
  transition: all 0.3s ease;
  z-index: 100;
}

.dash-nav.one {
  background: #0b1c83;
  color: #0b1c83;
  width: 10vw;
  min-width: 175px;
  padding: 10px 15px;
  height: calc(100vh - 20px);
  border-radius: 0;
}

.dash-nav .contain {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: scroll;
  height: 100%;
}

.dash-nav .contain .sm {
  display: none;
}

.dash-nav .top {
  display: flex;
  margin: 15px 0 50px;
}

.dash-nav .bottom {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.dash-nav .top .brand {
  display: flex;
  margin-left: 25px;
}

.dash-nav.one .top .brand {
  margin: 0;
}

.dash-nav .top .brand img {
  width: 35px;
  height: 35px;
  padding-top: 2px;
}

.dash-nav.one .top .brand img {
  width: 100px;
  height: unset;
}

.dash-nav .top .brand .title {
  position: relative;
  top: 5px;
  font-size: 30px;
  padding-left: 15px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.dash-nav.one .top .brand .title {
  top: 9px;
  font-size: 25px;
}

.dash-nav .top .cancel {
  display: none;
}

.dash-nav .top .icon i {
  position: relative;
  top: 3px;
  left: 3px;
}

.dash-nav a {
  color: #000;
}

.dash-nav .nav-list {
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  margin: 15px 0;
  padding: 0 10px;
  height: 45px;
  color: #5a4d4d;
  align-items: center;
  transition: all 0.3s ease;
}

.dash-nav.one .nav-list {
  color: #fff;
  margin: 8px 0;
}

.dash-nav .bottom .nav-list {
  margin: 0;
}

.dash-nav .nav-list.out {
  color: red;
}

.dash-nav.one .nav-list.out {
  color: #fff;
  margin-bottom: 20px;
}

.dash-nav .nav-list.out.hide {
  display: none;
}

.dash-nav .nav-list.alt.hide {
  opacity: 0;
  padding-left: 0;
  pointer-events: none;
  transition: none;
}

.dash-nav .nav-list .nav-item {
  padding-left: 15px;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
}

.dash-nav.one .nav-list .nav-item {
  font-size: 14px;
  font-weight: 300;
}

.dash-nav.one .nav-list.active .nav-item {
  font-weight: 400;
  color: #0b1c83;
}

.dash-nav .nav-list:hover {
  color: white;
  background: #0e183a;
}

.dash-nav .nav-list.active {
  color: white;
  background: #0e183a;
}

.dash-nav.one .nav-list.active {
  color: #1e1e21;
  background: #fff;
}

.dash-nav .nav-list .icon i.fa-headset,
.dash-nav .nav-list .icon i.fa-sign-out-alt,
.dash-nav .nav-list .icon i.fa-film {
  font-size: 18px;
}

.dash-nav .nav-list .icon {
  color: black;
  height: 25px;
  pointer-events: none;
  display: flex;
  align-items: center;
}

.dash-nav .nav-list .icon svg {
  fill: #fffbfb;
}

.dash-nav .nav-list.active .icon svg {
  fill: #18a0fb;
}

.dash-nav.one .nav-list.active .icon svg {
  /* fill: none; */
}

.dash-nav.one .bottom .nav-list .icon {
  position: relative;
}

.dash-nav .nav-list .icon img {
  width: 25px;
  pointer-events: none;
}

.dash-nav.one .bottom .nav-list img {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  transform: translateY(-50%);
  top: 50%;
  border-radius: 50%;
}

.dash-nav.one .bottom .nav-list .nav-item {
  padding-left: 50px;
}

.dash-hero .dash-container {
  width: calc(100% - 245px);
  position: absolute;
  left: 180px;
  padding-left: 65px;
  background-color: #e1e5ff;
}

.dash-hero .dash-container.one {
  background-color: #f4faff;
  /* background-color: #e1e5ff; */
  position: absolute;
  width: calc(100% - 305px);
  left: 240px;
}

.dash-hero .dash-container .top-nav {
  position: absolute;
  top: 0;
  right: 0px;
  display: flex;
  justify-content: end;
  width: 100%;
  height: 65px;
  transition: all 0.3s ease;
}

.dash-hero .dash-container .top-nav.close {
  width: calc(100% - 180px);
}

.dash-hero .dash-container .top-nav .profile {
  margin: 30px 8px 0 0;
  padding: 0;
  position: relative;
  cursor: pointer;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}

.dash-hero .dash-container .top-nav .profile.sm {
  display: none;
}

.dash-hero .dash-container .top-nav .profile.hide {
  display: none;
}

.dash-hero .dash-container .top-nav .profile i {
  border: 1px solid black;
  border-radius: 50%;
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: black;
}

.dash-hero .dash-container .top-nav .profile i.sm {
  display: none;
}

.result .top-nav .user .img img,
.onboard.test .content .top-nav .img img,
.mentor-page .home .img .gray img,
.history .item .form .info .img img,
.upcoming-info.earning .form .info .img img,
.side-nav .img img,
.history .container .item .img img,
.dash-hero .dash-container .top-nav .profile img {
  width: 100%;
  position: absolute;
  left: 0;
  transform: translateY(-50%);
  top: 50%;
  min-height: 100%;
}

.dash-container .course {
  margin: 65px 0;
  display: flex;
  padding-top: 20px;
  position: unset;
  opacity: 1;
  transition: all 1s ease;
}

.dash-container .course.hide {
  position: absolute;
  opacity: 0;
  transition: none;
}

.dash-container .course .top {
  width: 55%;
  padding: 45px 100px 0 25px;
}

.course .top .title {
  font-size: 30px;
  font-weight: 300;
  padding-bottom: 10px;
}

.course .top .description {
  font-weight: 300;
  padding-bottom: 30px;
}

.course .top p {
  font-weight: 300;
}

.course .top .links {
  margin-top: 50px;
}

.dash-container .course .bottom {
  width: 45%;
}

.course .bottom p.offered {
  font-weight: 300;
  padding-left: 40px;
}

.course .bottom .img {
  width: 90px;
  height: 90px;
  display: flex;
}

.course .bottom .img img {
  width: 100%;
}

.course .bottom .img .span {
  margin: auto;
}

.course .bottom .feats {
  display: flex;
}

.course .bottom .feats .icon {
  padding-top: 5px;
}

.course .bottom .feats .icon-text {
  padding-left: 30px;
}

.course .bottom .feats .icon-text .title {
  margin: 0;
  font-weight: 300;
  font-size: 20px;
}

.course .bottom .feats .icon-text .details {
  font-weight: 300;
  margin: 0;
  color: #393939;
  font-size: 14px;
  padding: 5px 0 25px;
}

.null {
  padding: 75px;
  display: flex;
}

.null .img {
  width: 45%;
  background: gray;
}

.null .content {
  width: 65%;
}

.null .content .title {
  margin: 25px 50px 0;
  font-size: 42px;
  line-height: 56px;
  font-weight: 300;
}

.null .content hr {
  margin: 50px 50px 30px;
  width: 100px;
}

.null .content .copy {
  margin: 20px 50px;
  font-weight: 300;
}

.null .content .links {
  margin: 35px 50px;
}

.available {
  display: flex;
  min-height: 100vh;
}

.available .container {
  width: 70%;
}

.available .container .title {
  font-size: 30px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  padding: 95px 170px 10px 75px;
}

.available .container .subtitle {
  padding: 0 170px 65px 75px;
  font-weight: 300;
  font-size: 20px;
}

.available .container form {
  display: flex;
  flex-direction: column;
  padding: 0 170px 65px 75px;
}

.available .container form label {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  padding-bottom: 10px;
}

.available .container form input,
.available .container form select {
  margin-bottom: 15px;
  height: 45px;
  padding-bottom: 0px;
}

.available .container form select {
  padding: 0 10px;
  font-size: 13px;
  color: #464646;
  font-weight: 100;
}

.available .container form input:placeholder-shown {
  font-weight: 100;
  font-size: 13px;
  padding: 0px 12px;
}

.available .container form .time {
  display: flex;
  justify-content: space-between;
}

.available .container form .time .container {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.available .slots {
  width: 30%;
  background: #010c35;
  color: white;
}

.available .slots .title {
  margin: 97px 25px 55px 30px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 22px;
}

.available .slots .card {
  margin: 15px 25px;
  background-color: white;
  height: 40px;
  line-height: 40px;
  padding: 5px 0 5px 10px;
  font-size: 15px;
  cursor: pointer;
  position: relative;
  font-weight: 300;
  color: black;
}

.available .slots .card i {
  position: absolute;
  right: 19px;
  top: 15px;
  font-size: 18px;
  color: red;
  display: none;
}

.available .slots .card:hover i {
  display: block;
}

.available .container form .links {
  margin: 30px 0;
}

.quiz .loader,
.dashpath.referral .info .popup .inner .btn .loader,
.exam-outer .btn .link .loader,
.assess .btn .link .loader,
.pathpage .content .link .loader,
.webinar .search .loader,
.firstpage .search .loader,
.referral .upcoming .session .gen .form .links .loader,
.upcoming-info.earning .form .links .loader,
.prof .content .links .loader,
.onboard-form .content .links .loader,
.waitform .submit .loader,
.onboard-form .content .links.submit .loader,
.auth-container .auth .links .loader {
  position: relative;
  display: inline-block;
  z-index: 999;
  margin: 10px 102.5px 0 72.5px;
}

.prof .content .links .loader,
.onboard-form .content .links .loader {
  margin: 10px 95px 0 60px;
}

.onboard-form .content .links.start .loader {
  margin: 20px 190px 0 150px;
}

.exam-outer .btn .link .loader {
  margin: 0px 100.5px 13px 58.5px;
}

.referral .upcoming .session .gen .form .links .loader,
.upcoming-info.earning .form .links .loader {
  margin: 10px 80.5px 0 40.5px;
}

.dashpath.referral .info .popup .inner .btn .loader {
  margin: 0 40px 0 0px;
}

.firstpage .search .loader {
  top: -12px;
  margin: 0;
}

.waitform .submit .loader {
  top: -14px;
  margin: 0;
}

.quiz .loader.hide,
.dashpath.referral .info .popup .inner .btn .loader.hide,
.exam-outer .btn .link .loader.hide,
.assess .btn .link .loader.hide,
.pathpage .content .link .loader.hide,
.referral .upcoming .session .gen .form .links .loader.hide,
.upcoming-info.earning .form .links .loader.hide,
.prof .content .links .loader.hide,
.onboard-form .content .links .loader.hide,
.waitform .submit .loader.hide,
.webinar .search .loader.hide,
.firstpage .search .loader.hide,
.onboard-form .content .links.submit .loader.hide,
.auth-container .auth .links .loader.hide {
  display: none;
}

.loader span,
.loader span:nth-child(1),
.loader span:nth-child(2) {
  position: absolute;
  top: 0;
  width: 8px;
  height: 8px;
  background: #1339ff;
  border-radius: 50%;
  left: 0;
  transition: all 0.3s ease;
}
.loader span {
  animation: bounce 1s infinite;
  -webkit-animation: bounce 1s infinite;
}
.loader span:nth-child(1) {
  left: 15px;
  animation: bounce 1s infinite;
  -webkit-animation: bounce 1s infinite;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.loader span:nth-child(2) {
  left: 30px;
  animation: bounce 1s infinite;
  -webkit-animation: bounce 1s infinite;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.dashpath.referral .info .popup .inner .btn .loader span,
.exam-outer .btn .link .loader span,
.assess .btn .link .loader span,
.pathpage .content .link .loader span,
.waitform .submit:hover .loader span,
.webinar .search label .loader span,
.firstpage .search label .loader span {
  background: white;
}

.dashpath.referral .popup .inner .btn:hover .loader span,
.exam-outer .btn .link:hover .loader span {
  background: #1339ff;
}

.assess .btn .link .loader,
.pathpage .content .link .loader {
  margin: 0 41px 14px 0;
}

.webinar .search .loader span,
.firstpage .search .loader span {
  left: 35px;
}

.webinar .search .loader span:nth-child(1),
.firstpage .search .loader span:nth-child(1) {
  left: 50px;
}

.webinar .search .loader span:nth-child(2),
.firstpage .search .loader span:nth-child(2) {
  left: 65px;
}

.waitform .submit .loader span {
  left: 10px;
}

.waitform .submit .loader span:nth-child(1) {
  left: 25px;
}

.waitform .submit .loader span:nth-child(2) {
  left: 40px;
}

/* Animation */
@keyframes bounce {
  0% {
    top: 0;
    -webkit-animation-timing-function: ease-in;
  }
  40% {
  }
  50% {
    top: 6px;
    -webkit-animation-timing-function: ease-out;
    width: 8px;
  }
  55% {
    top: 10px;
    -webkit-animation-timing-function: ease-in;
    width: 9px;
    height: 8px;
  }
  65% {
    top: 2px;
    -webkit-animation-timing-function: ease-out;
    width: 8px;
    height: 12px;
  }
  95% {
    top: 0;
    -webkit-animation-timing-function: ease-in;
  }
  100% {
    top: 0;
    -webkit-animation-timing-function: ease-in;
  }
}

.pathway {
  margin: 65px 20px 35px;
  z-index: 1;
  background: white;
}

.pathway .course-container {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.pathway .course-container::-webkit-scrollbar {
  display: none;
}

.pathway .course {
  flex-direction: column-reverse;
  width: 217px;
  margin: 25px 0 295px;
}

.pathway .course .illustration {
  display: flex;
  margin: 50px 0;
}

.pathway .course .illustration .rect {
  width: 62%;
  height: 25px;
  background-color: #010c35;
}

.pathway .course .illustration .big-circle {
  width: 55px;
  height: 55px;
  border: 7px solid #010c35;
  border-radius: 50%;
  position: relative;
  bottom: 20px;
  right: 4px;
}

.pathway .course .illustration .big-circle .circle {
  width: 25px;
  height: 25px;
  background-color: #010c35;
  border-radius: 50%;
  position: relative;
  bottom: -15px;
  left: 16px;
}

.pathway .course .illustration .pointer {
  width: 4px;
  height: 29px;
  background-color: #010c35;
  top: -49px;
  position: relative;
  right: 41px;
}

.pathway .course:nth-child(2n) .illustration .pointer {
  top: 49px;
}

.pathway .course .content {
  width: 270px;
  box-shadow: 0px 3px 5px 0px grey;
  margin: 0 0 0 40px;
  height: 300px;
  overflow: hidden;
  position: relative;
}

.pathway .course:nth-child(2n) .content {
  top: 428px;
}

.pathway .course .content .course-inner {
  bottom: -399px;
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 0;
  margin: 0;
  padding: 0;
  -webkit-transition: top 0.7s ease;
  -moz-transition: top 0.7s ease;
  -ms-transition: top 0.7s ease;
  -o-transition: top 0.7s ease;
  transition: bottom 0.7s ease;
}
.pathway .course .content:hover .course-inner {
  bottom: -100px;
  -webkit-transition: top 0.7s ease;
  -moz-transition: top 0.7s ease;
  -ms-transition: top 0.7s ease;
  -o-transition: top 0.7s ease;
  transition: bottom 0.7s ease;
}

.pathway .course .content img {
  width: 100%;
}

.pathway .course .content .title {
  font-size: 17px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  padding: 15px 20px 25px;
  color: #464646;
}

.pathway .course .content .details {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 15px;
  font-size: 12px;
  color: #464646;
  font-weight: 300;
}

.pathway .course .content .details .more i {
  padding: 0 0px 0 7px;
  color: blue;
}

.pathway .course .content .description {
  padding: 15px 20px 25px;
  font-size: 16px;
  font-weight: 300;
}

.mentor-page .home {
  display: flex;
  background: #f2f5ff;
  flex-direction: row-reverse;
  margin: 55px 0px 0px;
  align-items: center;
  min-height: 500px;
  padding: 25px 0;
}

.mentor-page .home .content {
  width: 50%;
  padding: 0 55px;
}

.mentor-page .home .content .title {
  font-size: 30px;
  margin: 15px 0;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.mentor-page .home .content .title.load {
  width: 500px;
  height: 44px;
}

.flicker {
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #d5d5d5 8%, #f2f2f2 38%, #d5d5d5 54%);
  background-size: 1000px 640px;

  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.mentor-page .home .content .subtitle {
  font-size: 18px;
  font-weight: 300;
}

.mentor-page .home .content .subtitle.load {
  width: 400px;
  height: 25px;
}

.mentor-page .home .content .description {
  margin: 50px 0 50px;
  max-width: 600px;
}

.mentor-page .home .content .description p {
  font-weight: 300;
  white-space: pre-wrap;
}

.mentor-page .home .content .description p.load {
  width: 600px;
  height: 50px;
}

.mentor-page .home .img {
  width: 50%;
}

.mentor-page .home .img .gray {
  width: 400px;
  height: 400px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  margin: auto;
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 30%);
}

.mentor-page .home .img .gray .icon {
  background: none;
  border: 3px solid black;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mentor-page .home .img .gray .icon i {
  font-size: 130px;
}

.schedule {
  margin: 60px 160px;
  position: unset;
  opacity: 1;
  transition: all 1s ease;
}

.schedule.hide {
  position: absolute;
  opacity: 0;
  transition: none;
}

.schedule .zone-input {
  border: 0;
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 30%);
  height: 40px;
  font-size: 18px;
  font-weight: 100;
  color: #464646;
  border-radius: 10px;
  padding: 3px 25px;
  width: 180px;
  margin: 0px 52px 20px;
}

.schedule .zone-input::placeholder {
  font-weight: 100;
}

.schedule .price {
  width: 142px;
  color: black;
  height: 0;
  position: relative;
  bottom: 63px;
  font-size: 27px;
  left: calc(100% - 195px);
}

.schedule .prai {
  width: 123px;
  color: black;
  margin: 20px auto 0;
  font-size: 23px;
}

.schedule .prai i {
  font-size: 23px;
  color: limegreen;
  padding-right: 10px;
}

.schedule .price i {
  font-size: 26px;
  color: limegreen;
  padding-right: 10px;
}

.schedule .container {
  display: flex;
  flex-wrap: wrap;
}

.schedule .timeslot {
  margin: 30px calc((100% - 720px) / 6);
  width: 200px;
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 30%);
  border-radius: 10px;
  padding: 20px;
  font-weight: 300;
}

.schedule .timeslot .small {
  display: none;
}

.schedule .timeslot .status {
  width: fit-content;
  padding: 5px 15px;
  background: lime;
  color: white;
  border-radius: 7px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  margin: 0 0 30px;
}

.schedule .timeslot .day {
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 20px;
}

.schedule .timeslot .time {
  margin: 30px 0 0;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.schedule .timeslot .zone {
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.schedule .radio {
  margin: 27px 0 0;
}

.schedule .radio p {
  display: inline;
  margin: 0 5px 0 0;
  position: relative;
  top: -1px;
}

.schedule .radio input[type="checkbox"] + label {
  display: inline;
}

.schedule .radio input[type="checkbox"] + label:before {
  border-radius: 50%;
  width: 15px;
  border: 2px solid limegreen;
  line-height: 19px;
  height: 18px;
  font-size: 13px;
  padding-left: 5px;
  padding-bottom: 2px;
  margin-right: 0.2em;
}

.schedule .radio input[type="checkbox"]:checked + label:before {
  background-color: #fff;
  border-color: limegreen;
  color: limegreen;
}

.schedule .button {
  display: flex;
  margin: 10px 0 0;
}

.schedule .button a {
  margin: 0 auto;
  padding: 7px 60px;
  line-height: 25px;
  background: #1339ff;
  color: white;
  font-weight: 100;
  border: 1px solid #1339ff;
}

.schedule .button a:hover {
  background: #fff;
  color: #1339ff;
}

.about {
  padding: 100px 50px;
  display: flex;
}

.about .img {
  width: calc(50% - 100px);
  margin-right: 100px;
}

.about .img img {
  height: 500px;
  margin-left: 165px;
}

.about .content {
  width: 50%;
}

.about .title {
  font-weight: 700;
  font-size: 35px;
}

.about .img .title {
  display: none;
}

.about .content p {
  font-size: 18px;
  font-weight: 300;
  line-height: 29px;
}

/* .break {
  padding: 50px;
  background-color: #010c35;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.break .title {
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

.break h1 {
  max-width: 1200px;
  width: 90%;
  text-align: center;
  font-weight: 300;
  font-size: 37px;
} */

.profile {
  padding: 35px 50px 75px;
  text-align: center;
}

.profile .title {
  font-size: 40px;
  color: #464646;
  margin: 0 0 65px;
  font-weight: 700;
}

.profile .container {
  display: flex;
  max-width: 1230px;
  margin: auto;
  justify-content: space-between;
}

.profile .container .item {
  margin: 0 35px;
}

.profile .container .item .img {
  width: 310px;
  margin: 0 auto 25px;
}

.profile .container .item .img img {
  width: 100%;
}

.profile .container .item .title {
  font-size: 35px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  color: #000;
  margin: 0;
}

.profile .container .item .title:hover {
  color: #0019ff;
}

.profile .container .item .role {
  font-size: 21px;
  line-height: 28px;
  font-weight: 400;
  margin: 9px auto 15px;
  color: black;
}

.waitsuccess {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.waitsuccess .inner .cancelBtn {
  width: 35px;
  height: 35px;
  border: 2px solid #0019ff;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  left: 495px;
  top: 0px;
}

.waitsuccess .inner .cancelBtn span {
  width: 24px;
  display: block;
  height: 2px;
  background: #0019ff;
  transform: rotate(45deg);
  top: 16.5px;
  left: 5px;
  position: relative;
}

.waitsuccess .inner .cancelBtn span:nth-child(2) {
  transform: rotate(-45deg);
  top: 14.5px;
}

.waitsuccess.hide {
  display: none;
}

.waitsuccess .inner {
  width: 560px;
  height: 310px;
  display: flex;
  justify-content: flex-end;
  background: white;
  border-radius: 20px;
  /* background: white
    url('https://dqe80zoqbuyqe.cloudfront.net/root/Artboard+28.png'); */
}

.waitsuccess .inner .container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.waitsuccess .inner .title {
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #010c35;
}

.waitsuccess .inner p {
  font-size: 17px;
  font-weight: 300;
  color: #464646;
  width: 415px;
  margin: 10px auto;
  z-index: 0;
  text-align: center;
}

.waitsuccess .inner .cta {
  width: 415px;
  margin: 35px auto 0px;
  text-align: center;
  font-weight: 300;
  color: #464646;
}

.o-video {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
}

.o-video.hide {
  display: none;
}

.o-video iframe {
  width: 743px;
  height: 418px;
  border: none;
  margin-top: 30px;
}

.o-video .cancel {
  position: relative;
  cursor: pointer;
  left: 435px;
  margin-top: 25px;
  transition: all 0.5s ease;
}

.o-video .cancel:hover {
  transform: scale(1.1);
}

.o-video .cancel span {
  background: white;
  display: block;
  height: 4px;
  width: 31px;
  border-radius: 30px;
  position: relative;
  transform: rotate(45deg);
}

.o-video .cancel span:nth-child(2) {
  background: white;
  display: block;
  height: 4px;
  width: 31px;
  border-radius: 30px;
  position: relative;
  transform: rotate(-45deg);
  top: -4px;
}

.available select.error,
.available input.error {
  border: 1px solid red;
}

.dash-container .home {
  padding: 40px;
  max-width: 1250px;
  margin: 0 auto;
}

.dash-container.one .mobile-head {
  display: none;
}

.dash-container .search {
  display: flex;
  justify-content: space-between;
  padding: 20px 25px;
  align-items: center;
}

.dash-container .search .up {
  position: relative;
  transition: all ease 0.3s;
}

.dash-container .search.full .up {
  width: 100%;
  min-width: 100%;
}

.dash-container .search .up input {
  width: calc(100% - 70px);
  height: 20px;
  padding: 10px 35px;
  border: 1px solid #ededed;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 100;
}

.dash-container .search .up input::placeholder {
  color: #d9d9d9;
}

.dash-container .search .up i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #d9d9d9;
  font-weight: 600;
  left: 10px;
  font-size: 14px;
}

.dash-container .search .up .results-tab {
  position: absolute;
  background: white;
  border-radius: 15px;
  margin-top: 20px;
  min-height: 540px;
  width: calc(100% - 20px);
  z-index: 1;
}

.dash-container .search .up svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  color: red;
  font-size: 20px;
  cursor: pointer;
}

.search.tutsie .highlight {
  position: absolute;
  background: white;
  max-width: 330px;
  width: calc(25% + 10px);
  min-width: 285px;
  height: 75px;
  z-index: 2;
  right: 0;
  top: 0;
  border-radius: 0 0 0 10px;
}

.inner-tut {
  position: absolute;
  width: 325px;
  left: calc(100% - 395px);
  height: 190px;
  top: 120px;
  z-index: 2;
  padding: 30px;
  font-family: "Darker Grotesque", sans-serif;
}

.two .inner-tut {
  left: 210px;
  top: 105px;
  position: fixed;
}

.three .inner-tut {
  left: 225px;
  top: 300px;
  position: fixed;
}

.main.four .inner-tut {
  width: 240px;
  left: calc(100% + 15px);
  top: 193px;
}

.five .inner-tut {
  left: 193px;
  top: 250px;
}

.six .inner-tut {
  left: calc(60% + 48px);
  top: 830px;
}

.seven .inner-tut {
  left: calc(75% - 451px);
  top: 265px;
}

.eight .inner-tut {
  width: 243px;
  left: calc(100% + 18px);
  top: 193px;
}

.nine .inner-tut {
  right: 338px;
  left: unset;
  top: -5px;
}

.ten .inner-tut {
  left: calc(100% - 372px);
  top: 11px;
  z-index: 3;
}

.eleven .inner-tut {
  left: 258px;
  top: 102px;
  position: fixed;
}

.twelve .inner-tut {
  right: 365px;
  left: unset;
  top: 8px;
}

.thirteen .inner-tut {
  left: 258px;
  position: fixed;
  top: 162px;
}

.fourteen .inner-tut {
  width: 250px;
  left: calc(100% + 21px);
  top: 30px;
  padding: 30px 15px 30px 30px;
}

.fifteen .inner-tut {
  position: fixed;
  top: 209px;
  left: 258px;
}

.profile-page .inner-tut {
  top: unset;
  bottom: 140px;
  left: calc(50% - 203px);
}

.profile-page .inner-tut .btn-group {
  margin: 30px 0 0 !important;
}

.inner-tut .back {
  margin-bottom: 10px;
  font-size: 14px;
  color: #111110;
  width: 90px;
  cursor: pointer;
}

.inner-tut .back .icon {
  width: 30px;
  height: 15px;
  display: inline-block;
}

.inner-tut .back .icon span {
  display: block;
  width: 18px;
  height: 1px;
  background: #111110;
  position: relative;
  top: 10px;
  border-radius: 1px;
}

.inner-tut .back .icon span:nth-child(1) {
  width: 7px;
  top: 9px;
  transform: rotate(-35deg);
}

.inner-tut .back .icon span:nth-child(3) {
  width: 7px;
  top: 11px;
  transform: rotate(35deg);
}

.inner-tut h3 {
  margin: 0;
  font-size: 24px;
  line-height: 24px;
  color: #111110;
}

.inner-tut p {
  font-size: 17px;
  font-weight: 500;
  line-height: 19px;
  color: #111110;
}

.search.tutsie .bottom {
  z-index: 2;
}

.dash-container .bottom {
  background: #18a0fb;
  border-radius: 10px;
  transition: all ease 0.3s;
  cursor: pointer;
}

.dash-container .bottom .back-active,
.dash-container .bottom .sm-filter {
  display: none;
}

.dash-container .search.full .bottom {
  width: 0;
  min-width: 0;
}

.card-container .inner .addResource,
.dash-container .bottom .addResource {
  text-align: center;
  color: white;
  font-size: 13px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 0.1s;
}

.card-container .inner .addResource {
  background: #18a0fb;
  border-radius: 10px;
  padding: 0 20px;
  border-radius: 15px;
  cursor: pointer;
  transition: all ease 0.35s;
}

.dash-container .search.full .bottom .addResource {
  opacity: 0;
  font-size: 1px;
}

.card-container .inner .addResource .iconcontainer,
.dash-container .side .learn-todo .iconcontainer,
.dash-container .bottom .addResource .iconcontainer {
  width: 19px;
  height: 19px;
  background: #00d1ff;
  border-radius: 4px;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
  transition: all ease 0.3s;
}

.card-container .inner .addResource:hover,
.dash-container .bottom:hover {
  background: #0e183a;
}

.card-container .inner .addResource:hover .iconcontainer,
.dash-container .side .learn-todo .iconcontainer:hover,
.dash-container .bottom:hover .addResource .iconcontainer {
  background: #000;
}

.inner .addResource .iconcontainer span,
.dash-container .side .iconcontainer span,
.dash-container .bottom .iconcontainer span {
  width: 11px;
  height: 2px;
  background: white;
  display: block;
  border-radius: 2px;
  top: 50%;
  left: 50%;
  position: absolute;
  pointer-events: none;
  transform: translate(-50%, -50%);
}

.inner .addResource .iconcontainer span:nth-child(1),
.dash-container .side .iconcontainer span:nth-child(1),
.dash-container .bottom .iconcontainer span:nth-child(1) {
  width: 2px;
  height: 11px;
}

.dash-container {
  padding: 10px 30px;
  /* display: flex;
  justify-content: space-between; */
}

.dash-container .search .up,
.main,
.one-home .main {
  min-width: calc(100% - 315px);
  /* width: calc(75% - 20px); */
  /* max-width: calc(100% - 270px); */
}

.main.fourteen,
.side.twelve,
.main.eight,
.one-home .main.five,
.one-home .main.six,
.one-home .main.four {
  position: relative;
}

.dash-container .bottom,
.plan .side,
.one-home .side {
  max-width: 295px;
  width: 25%;
  min-width: 250px;
}

.dash-container .sm-home,
.dash-container .sm-brief,
.dash-container .side.sm {
  display: none;
}

.welcome {
  display: flex;
  justify-content: space-between;
  background: #18a0fb;
  color: white;
  border-radius: 15px;
  align-items: center;
  height: 160px;
  margin-bottom: 20px;
}

.welcome .icon {
  width: 40%;
  height: 100%;
  display: flex;
}

.welcome .icon img {
  height: 100%;
  margin: auto;
}

.welcome .text {
  width: calc(60% - 10px);
}

.welcome .text .head {
  font-weight: 600;
  font-size: 24px;
  color: #ffd400;
  margin-bottom: 10px;
}

.welcome .text .head span span {
  display: none;
}

.welcome .text p {
  margin: 0;
  width: 90%;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.dash-container.one .progress {
  padding: 10px 0;
}

.dash-container.one .main.fourteen .progress,
.dash-container.one .main.eight .perform,
.dash-container.one .main.six .wish-container,
.dash-container.one .main.five .pace,
.dash-container.one .main.four .progress {
  z-index: 1;
  position: relative;
}

.main.four .highlight {
  position: absolute;
  top: 165px;
  z-index: 1;
  height: 380px;
  background: #f4faff;
  width: calc(100% + 37px);
  left: -30px;
  border-radius: 0 10px 10px 0;
}

.main.five .highlight {
  position: absolute;
  top: 560px;
  z-index: 1;
  height: 225px;
  background: #f4faff;
  width: calc(100% + 40px);
  left: -30px;
  border-radius: 0 10px 10px 0;
}

.main.six .highlight {
  position: absolute;
  top: 777px;
  z-index: 1;
  height: 448px;
  background: #f4faff;
  width: calc(60% + 36px);
  left: -30px;
  border-radius: 0 10px 10px 0;
}

.main.eight .highlight {
  position: absolute;
  top: 165px;
  z-index: 1;
  height: 385px;
  background: #f4faff;
  width: calc(100% + 37px);
  left: -30px;
  border-radius: 0 10px 10px 0;
}

.side .highlight.nine {
  position: absolute;
  top: -27px;
  z-index: 1;
  height: 385px;
  background: #f4faff;
  width: calc(100% + 43px);
  left: -13px;
  border-radius: 10px 0px 0px 10px;
}

.side .highlight.nine.alt {
  position: absolute;
  top: 358px;
  z-index: 2;
  height: 290px;
  background: rgba(201, 201, 201, 0.15);
  width: calc(100% + 43px);
  left: -13px;
  border-radius: 0;
}

.side .highlight.ten {
  position: absolute;
  top: 355px;
  z-index: 3;
  height: 295px;
  background: #f4faff;
  width: calc(100% + 43px);
  left: -13px;
  border-radius: 10px 0px 0px 10px;
}

.side .highlight.twelve {
  position: absolute;
  top: -15px;
  z-index: 1;
  height: 443px;
  background: #f4faff;
  width: calc(100% + 50px);
  left: -20px;
  border-radius: 10px 0px 0px 10px;
}

.main.fourteen .highlight {
  position: absolute;
  top: -12px;
  z-index: 1;
  height: 100%;
  background: #f4faff;
  width: calc(100% + 37px);
  left: -30px;
  border-radius: 0 10px 10px 0;
}

.profile-page .highlight {
  position: absolute;
  bottom: 0;
  z-index: 1;
  height: 75px;
  background: #f4faff;
  width: 300px;
  left: calc(50% - 150px);
  border-radius: 10px 10px 0 0;
}

.dash-container.one .progress .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}

.progress .top p {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: #534f4f;
}

.progress .top .btn {
  color: #18a0fb;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
}

.dash-container .search .up i .card-container {
  margin: 15px 0;
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
}

.dash-container.one .progress .card-container {
  margin: 15px 0;
  display: flex;
  flex-wrap: wrap;
}

.side .learn-todo .todo-container .inner,
.wishlist .wish-container .wish-card .inner,
.dash-container .card-container .inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.wishlist .wish-container .wish-card .inner .img {
  width: 250px;
}

.side .learn-todo .todo-container .inner .img {
  width: 200px;
}

.dash-container .card-container .inner .img {
  width: 180px;
}

.side .learn-todo .todo-container .inner .img img,
.wishlist .wish-container .wish-card .inner .img img,
.dash-container .card-container .inner .img img {
  width: 100%;
}

.dash-container .card-container .inner p {
  text-align: center;
  font-size: 15px;
  width: 500px;
  line-height: 20px;
}

.dash-container .card-container .inner .addResource p {
  width: 180px;
}

.wishlist .wish-container .wish-card .inner p {
  text-align: center;
  font-size: 14px;
  width: 400px;
  line-height: 20px;
}

.side .learn-todo .todo-container .inner p {
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  width: 100%;
  line-height: 19px;
}

.wishlist .wish-container .inner .add-wish {
  margin-top: 0;
}

.wishlist .wish-container .add-wish.null {
  display: none;
}

.progress .card-container .card {
  width: calc(33.3% - 30px);
  min-height: 260px;
  background: #f5f5f5;
  color: #181717;
  border-radius: 7px;
  padding: 10px;
  margin: 10px 5px;
  box-shadow: 0px 2px 7px 0px rgb(0 0 0 / 15%);
}

.progress .card-container .card.light {
  background: #343232;
}

.progress .card-container .card .date {
  font-size: 9px;
  font-weight: 600;
}

.progress .card-container .card .date.load {
  height: 10px;
  width: 65px;
}

.progress .card-container .card .img {
  margin: 3px 0 5px;
  height: 85px;
  border-radius: 10px;
  overflow: hidden;
}

.progress .card-container .card .img img {
  width: 100%;
  height: 100%;
}

.progress .card-container .card .band {
  display: flex;
  flex-direction: column;
  height: calc(100% - 96px);
  justify-content: space-between;
}

.progress .card-container .card .title {
  font-size: 21px;
  font-weight: 300;
  margin: 15px 0;
  line-height: 26px;
  position: relative;
  font-family: "Oswald", sans-serif;
}

.progress .card-container .card .title.load {
  height: 90px;
}

.progress .card-container .card .title .space {
  height: 17px;
  width: 22px;
  display: inline-block;
}

.progress .card-container .card .info .mskill.load {
  height: 10px;
  width: 60px;
}

.progress .card-container .card .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progress .card-container .card .info .mskill {
  font-size: 10px;
  line-height: 10px;
  font-weight: 600;
}

.progress .card-container .card .btn {
  position: absolute;
  font-weight: 500;
  color: #000000;
  font-size: 11px;
  cursor: pointer;
  height: 22px;
  bottom: -4px;
  right: 0;
}

.progress .card-container .card .btn .arrow {
  display: inline-block;
  border: 1px solid;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  position: relative;
  top: 3px;
}

.progress .card-container .card .btn .arrow span {
  display: block;
  width: 5px;
  height: 1px;
  position: absolute;
  top: 4px;
  border-radius: 10px;
  left: 6px;
  background: #000000;
}

.progress .card-container .card .btn .arrow span:nth-child(2) {
  width: 11px;
  height: 1px;
  top: 50%;
  left: 2px;
  transform: rotate(-45deg);
}

.progress .card-container .card .btn .arrow span:nth-child(3) {
  width: 5px;
  height: 1px;
  top: 7px;
  left: 9px;
  transform: translateY(-50%);
  transform: rotate(90deg);
}

.dash-container .pace {
  background: white;
  border-radius: 15px;
  padding: 20px;
  margin: 20px 0;
}

.pace .title {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: #534f4f;
}

.pace .pace-containers {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  flex-wrap: wrap;
}

.pace .pace-containers .card {
  background: #e49053;
  border-radius: 10px;
  padding: 10px;
  width: calc(20% - 30px);
  min-width: 115px;
  margin: 10px 5px;
}

.pace .pace-containers .card.bg {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.pace .pace-containers .card.sm {
  display: none;
}

.pace .pace-containers .card:nth-child(7),
.pace .pace-containers .card:nth-child(2) {
  background: #6398f9;
}

.pace .pace-containers .card:nth-child(8),
.pace .pace-containers .card:nth-child(3) {
  background: #e453d5;
}

.pace .pace-containers .card:nth-child(9),
.pace .pace-containers .card:nth-child(4) {
  background: #00ffd1;
}

.pace .pace-containers .card:nth-child(10),
.pace .pace-containers .card:nth-child(5) {
  background: #ffd400;
}

.pace .pace-containers .card .up {
  display: flex;
  justify-content: space-between;
}

.pace-containers .card .up p {
  font-size: 14px;
  font-weight: 600;
  color: #1e1c1c;
  margin: 0 5px 0 0;
}

.pace-containers .card img {
  width: 65px;
}

.pace .pace-containers .card:nth-child(2) img {
  width: 55px;
}

.pace .pace-containers .card:nth-child(5) img {
  width: 34px;
}

.pace .pace-containers .card .down {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
}

.pace .pace-containers .card .no {
  font-weight: 600;
  color: #1e1c1c;
  font-size: 30px;
}

.pace .pace-containers .card .down .arrow {
  border: 1px solid;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  position: relative;
  top: 3px;
  cursor: pointer;
}

.pace-containers .card .down .arrow span {
  display: block;
  width: 5px;
  height: 1px;
  position: absolute;
  top: 4px;
  left: 7px;
  transform: rotate(-12deg);
  background: #1e1c1c;
}

.pace-containers .card .down .arrow span:nth-child(2) {
  width: 11px;
  height: 1px;

  top: 8px;
  left: 2px;
  transform: rotate(-45deg);
}

.pace-containers .card .down .arrow span:nth-child(3) {
  width: 5px;
  height: 1px;

  top: 6px;
  left: 9px;
  transform: rotate(-78deg);
}

.dash-container .wishlist {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}

.dash-container .wishlist .wish-container {
  width: calc(60% - 50px);
  background: white;
  border-radius: 15px;
  padding: 20px;
}

.dash-container .wishlist .wish-container .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dash-container .wishlist .wish-container .top h3 {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: #534f4f;
}

.dash-container .wishlist .wish-container .top .btn {
  color: #18a0fb;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
}

.dash-container .wishlist .wish-container .wish-card {
  margin: 10px 0;
}

.wishlist .wish-container .wish-card .card {
  display: flex;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.wishlist .wish-container .wish-card .card .img {
  width: 65px;
  height: 65px;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
}

.wishlist .wish-container .wish-card .card .img img {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.wishlist .wish-container .wish-card .card p {
  width: calc(100% - 100px);
  margin: 0;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: #4d4343;
  padding-right: 20px;
}

.wishlist .wish-container .wish-card .card p.load {
  height: 65px;
}

.dash-container .side .learn-todo .todo-container .add-todo,
.wishlist .wish-container .add-wish {
  font-weight: 500;
  color: #18a0fb;
  font-size: 13px;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 25px;
}

.dash-container .side .learn-todo .todo-container .add-todo.null {
  margin-top: 0;
  text-align: center;
  padding: 0;
  width: 100%;
  justify-content: center;
}

.wishlist .marketing {
  width: calc(40% - 10px);
}

.wishlist .marketing .card {
  background: #f5f5f5;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all ease 0.3s;
}

.wishlist .marketing .card:hover {
  background: #bfbfbf;
}

.wishlist .marketing .card .img {
  width: 66px;
  display: flex;
}

.wishlist .marketing .card:nth-child(2) .img {
  width: 45px;
  margin-left: 15px;
}

.wishlist .marketing .card .img img {
  width: 100%;
}

.wishlist .marketing .card h4 {
  margin: 0;
  width: calc(100% - 80px);
  color: #534f4f;
  font-size: 17px;
  font-weight: 600;
}

.dash-container .skill-graph {
  border-radius: 15px;
  background: white;
  padding: 30px;
  margin: 20px 0;
}

.dash-container .skill-graph .title {
  font-weight: 600;
  font-size: 16px;
  color: #534f4f;
}

.skill-graph .title span {
  font-size: 11px;
  color: #18a0fb;
}

.skill-graph .container {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skill-graph .container .img {
  width: 40%;
}

.skill-graph .container .img img {
  width: 100%;
}

.skill-graph .container .content {
  width: calc(60% - 20px);
}

.skill-graph .container .content h2 {
  color: #4d4343;
  font-size: 20px;
  margin: 0;
}

.skill-graph .container .content p {
  width: 80%;
  color: #4d4343;
  font-size: 15px;
  line-height: 21px;
  margin: 8px 0;
}

.dash-container .side .marketing-card .btn,
.skill-graph .container .content .btn {
  font-weight: 400;
  margin-top: 20px;
  background: #18a0fb;
  width: 82px;
  text-align: center;
  padding: 7px 15px;
  font-size: 15px;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  transition: all ease 0.3s;
}

.skill-graph .container .content .btn {
  width: 150px;
}

.dash-container .side .marketing-card .btn:hover,
.skill-graph .container .content .btn:hover {
  background: #0e183a;
}

.dash-container .side .profile-head {
  border-radius: 15px;
  overflow: hidden;
  background: white;
  margin-bottom: 20px;
}

.dash-container .side .profile-head .img-cover {
  height: 60px;
  overflow: hidden;
}

.dash-container .side .profile-head .img-cover img {
  width: 100%;
}

.dash-container.one .mobile-head .profile {
  padding: 0;
}

.dash-container.one .mobile-head .profile-img {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
}

.dash-container.one .mobile-head .profile-img img {
  width: 40px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dash-container .side .profile-head .profile-img {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: -37.5px;
}

.dash-container .side .profile-head .profile-img img {
  width: 70px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dash-container .side .profile-head h2 {
  margin: -33px 0 0;
  color: #534f4f;
  font-size: 19px;
  text-align: center;
  font-weight: 600;
  padding: 0 5px;
}

.dash-container .side .profile-head p {
  margin: 0;
  padding: 1px 5px 13px;
  text-align: center;
  font-size: 13px;
  font-weight: 300;
  font-style: italic;
}

.dash-container .learn-todo {
  background: white;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 20px;
}

.ten.side,
.nine.side {
  position: relative;
}

.dash-container .nine .learn-todo,
.dash-container .seven .learn-todo {
  position: relative;
  z-index: 1;
}

.dash-container .learn-todo .todo-container {
  padding: 15px;
  border-radius: 15px;
  background: #f5f5f5;
}

.dash-container .learn-todo .todo-container.rem {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  justify-content: space-between;
}

.dash-container .learn-todo .todo-container .title {
  color: #534f4f;
  font-weight: 500;
}

.dash-container .learn-todo .todo-container .title span {
  color: #d9d9d9;
}

.dash-container .learn-todo .todo-container .list {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.dash-container .learn-todo .todo-container .list .no {
  color: #534f4f;
  font-weight: 500;
}

.dash-container .learn-todo .todo-container .list .no {
  width: 25px;
  height: 20px;
}

.dash-container .learn-todo .todo-container .list p {
  margin: 3px 0 0;
  font-size: 13px;
  line-height: 17px;
  width: calc(100% - 50px);
  color: #534f4f;
}

.dash-container .learn-todo .todo-container .list input[type="checkbox"] {
  display: block;
  margin: 5px 0 0;
  cursor: pointer;
  height: 13px;
  width: 13px;
  visibility: visible;
}

.dash-container .side .learn-todo .todo-container .input {
  display: flex;
  margin-top: 25px;
}

.dash-container .side .learn-todo .todo-container .input.null {
  margin-top: 0;
  padding: 0;
}

.dash-container .side .todo-container .input input {
  width: calc(100% - 60px);
  padding: 4px 10px;
  border: 1px solid gray;
  border-radius: 5px;
  height: 20px;
  font-size: 13px;
  color: #6d6060;
  font-weight: 200;
  margin-right: 10px;
}

.dash-container .side .todo-container .input .iconcontainer {
  width: 30px;
  height: 30px;
  margin: 0;
}

.dash-container .side .marketing-card {
  background: white;
  border-radius: 15px;
  padding: 20px;
}

.dash-container .side .marketing-card:nth-child(2) {
  margin: 20px 0;
}

.dash-container .side .marketing-card img {
  width: 85px;
}

.dash-container .side .marketing-card h3 {
  margin: 0 0 10px;
  color: #4d4343;
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
}

.dash-container .side .marketing-card h3 span {
  color: #18a0fb;
  font-size: 11px;
  font-weight: 400;
}

.dash-container .side .marketing-card p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #4d4343;
  line-height: 19px;
}

.dash-container .side .marketing-card p span {
  font-style: italic;
}

.dash-container .side .marketing-card .btn {
  width: calc(100% - 30px);
}

.dash-container .side .filter {
  background: white;
  border-radius: 15px;
  padding: 20px;
}

.dash-container .side.twelve .filter {
  position: relative;
  z-index: 1;
}

.dash-container .side .filter .title {
  font-weight: 600;
  font-size: 16px;
  color: #534f4f;
  margin-bottom: 10px;
}

.dash-container .side .filter .input {
  margin: 5px 0;
}

.dash-container .side .filter .slic {
  display: flex;
}

.dash-container .side .filter .slic .bottom {
  min-width: unset;
  width: 35px;
  height: 30px;
  margin: 2px 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dash-container .side .filter label {
  font-size: 13px;
  font-weight: 500;
  color: #534f4f;
}

.dash-container .side .filter .input input {
  width: calc(100% - 20px);
  padding: 5px 10px;
  border: 1px solid gray;
  border-radius: 5px;
  height: 16px;
  font-size: 13px;
  color: #6d6060;
  font-weight: 200;
  margin: 3px 0;
}

.dash-container .side .filter .input input[type="search"] {
  height: 28px;
  width: 100%;
  font-size: 12px;
}

.dash-container .side .filter .skills {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}

.dash-container .side .filter .skills .item {
  display: flex;
  align-items: center;
  border: 1px solid gray;
  border-radius: 12px;
  padding: 2px 8px 2px 6px;
  font-size: 13px;
  margin: 5px;
  transition: all ease 0.3s;
  color: #534f4f;
}

.dash-container .side .filter .skills .item .cross {
  position: relative;
  width: 20px;
  height: 20px;
  left: 4px;
  cursor: pointer;
}

.side .filter .skills .item .cross span {
  display: block;
  width: 12px;
  top: 10px;
  height: 1px;
  background: red;
  position: absolute;
  transform: rotate(45deg);
}

.side .filter .skills .item .cross span:nth-child(2) {
  transform: rotate(-45deg);
}

.side .filter .tab {
  display: flex;
}

.side .filter .tab input[type="checkbox"] {
  display: inline-block;
}

.side .filter .tab p {
  font-size: 13px;
  margin: 5px;
  transition: all ease 0.3s;
  color: #534f4f;
}

.plan .perform {
  margin: 20px 0;
  border-radius: 15px;
  background: white;
  padding: 20px;
}

.plan .perform .title {
  font-weight: 600;
  font-size: 16px;
  color: #534f4f;
  margin: 5px 0 15px;
}

.plan .perform .chart {
  display: flex;
  justify-content: space-between;
}

.plan .perform .chart .tile {
  width: calc((100% / 7) - 20px);
  text-align: center;
}

.plan .perform .chart .tile .bar {
  height: 240px;
  width: 100%;
  border-radius: 15px;
  background: #534f4f;
  display: flex;
  align-items: end;
  overflow: hidden;
}

.plan .perform .chart .tile .bar .inner {
  width: 100%;
  height: 0;
  background: #ffd400;
  border-radius: 15px 15px 0 0;
  transition: all cubic-bezier(0.83, 0.98, 0.86, 1.23) 0.5s;
}

.plan .perform .chart .tile .bar .inner.full {
  height: 76%;
}

.plan .perform .chart .tile .tag {
  margin: 15px 0 5px;
  color: #4d4343;
}

.dash-container .notification {
  background: #f5f5f5;
  border-radius: 15px;
  margin: 20px 0 10px;
  padding: 20px;
}

.dash-container .ten .notification {
  position: relative;
  z-index: 3;
}

.dash-container .learn-container.sm,
.dash-container .notification.sm {
  display: none;
}

.dash-container .notification .title {
  color: #534f4f;
  font-weight: 500;
}

.dash-container .notification p {
  margin: 0;
  font-size: 13px;
  line-height: 18px;
  color: #534f4f;
}

.dash-container .notification .toggle {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  border: 0;
}

.dash-container .notification .toggle .input {
  height: 20px;
}

.dash-container .notification .toggle .img {
  width: 65px;
  height: 30px;
}

.dash-container .notification .toggle .img img {
  height: 100%;
}

.dash-container .notification.sm input[type="checkbox"],
.dash-container .side .learn-todo input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  display: inline-block;
}

.dash-container .notification label {
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  height: 25px;
  background: grey;
  display: block;
  margin: -25px 0 0;
  border-radius: 25px;
  position: relative;
}

.dash-container .notification label:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 5px;
  width: 22.5px;
  height: 22.5px;
  background: #fff;
  border-radius: 22.5px;
  transition: 0.3s;
}

.dash-container .notification input:checked + label {
  background: #bada55;
}

.dash-container .notification input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.dash-container .notification label:active:after {
  width: 32.5px;
}

.dash-container .profile-page {
  padding: 35px 0px;
  position: relative;
}

.dash-container .profile-page p.retake {
  text-align: center;
  text-decoration: underline;
  color: #8b8787;
  font-size: 14px;
  margin: 35px auto 0;
  cursor: pointer;
  width: 250px;
}

.dash-container .profile-page p.retake.sixteen {
  position: relative;
  z-index: 2;
}

.dash-container .profile-page .cover-img {
  width: 100%;
  height: 140px;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  margin-bottom: 110px;
}

.dash-container .profile-page .cover-img img {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.dash-container .profile-page .cover-img .pencil {
  position: absolute;
  top: 92px;
  right: 16px;
  background: white;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dash-container .profile-page .cover-img .pencil svg {
  fill: #18a0fb;
  font-size: 17px;
}

.dash-container .profile-page .user-info {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 127px;
  margin-left: 120px;
}

.dash-container .profile-page .user-info .img {
  width: 130px;
  height: 130px;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
}

.dash-container .profile-page .user-info .img img {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.dash-container .profile-page .user-info .edit-btn {
  position: relative;
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  bottom: 35px;
  left: 100px;
  cursor: pointer;
}

.dash-container .profile-page .user-info .edit-btn span {
  display: block;
  width: 15px;
  height: 1px;
  background: #18a0fb;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 5px;
}

.dash-container .profile-page .user-info .edit-btn span:nth-child(2) {
  width: 1px;
  height: 15px;
}

.dash-container .profile-page .user-info .name {
  margin-left: 25px;
}

.dash-container .profile-page .user-info .name h3 {
  margin: 60px 0 0;
  color: #534f4f;
  font-weight: 500;
  font-size: 20px;
}

.dash-container .profile-page .user-info .name p {
  margin: 0;
  color: #534f4f;
  font-size: 15px;
  font-style: italic;
}

.dash-container .profile-page .input {
  text-align: center;
  margin: 0 0 20px;
  display: flex;
  justify-content: center;
  align-items: start;
}

.dash-container .profile-page .input label {
  margin: 10px 75px 0 0;
  font-size: 14px;
  color: #534f4f;
  width: 150px;
}

.dash-container .profile-page .input textarea,
.dash-container .profile-page .input input {
  width: 450px;
  padding: 8px 20px;
  border: 1px solid gray;
  border-radius: 5px;
  height: 20px;
  font-size: 14px;
  color: #6d6060;
  font-weight: 200;
}

.dash-container .profile-page .input textarea {
  height: 150px;
}

.dash-container .profile-page hr {
  width: 80%;
}

.dash-container .profile-page .btn-group {
  display: flex;
  justify-content: center;
  margin: 60px 0 25px;
}

.dash-container .profile-page .btn-group .save {
  cursor: pointer;
  width: 105px;
  text-align: center;
  margin: 0 35px 0 0;
  padding: 10px 35px;
  background: #18a0fb;
  color: white;
  font-weight: 400;
  font-size: 14px;
  border-radius: 5px;
  transition: all ease 0.3s;
}

.dash-container .profile-page .btn-group .save.out {
  background: #ffbcb8;
  color: #857a7a;
}

.outer-pop {
  position: fixed;
  top: 0;
  left: -140px;
  width: calc(100% + 140px);
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.outer-pop .inner {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.outer-pop .cancel-btn {
  border: 2px solid gray;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  display: flex;
  top: 20px;
  align-items: center;
  justify-content: center;
  color: gray;
  right: 20px;
  cursor: pointer;
}

.outer-pop .container {
  position: fixed;
  background: white;
  width: 475px;
  top: 50%;
  left: 50%;
  border-radius: 15px;
  overflow-y: scroll;
  transform: translate(-50%, -50%);
  transition: all ease 0.5s;
  padding: 10px 50px;
}

.outer-pop .container::-webkit-scrollbar {
  display: none;
}

.outer-pop.tutsie .container {
  left: 925px;
}

.outer-pop .container h2 {
  margin: 45px 0 25px;
  font-size: 19px;
  font-weight: 600;
  line-height: 25px;
  width: calc(100% - 35px);
}

.outer-pop .toggle {
  display: flex;
  border: 1px solid rgba(149, 143, 143, 0.5);
  border-radius: 5px;
  height: 42px;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.outer-pop .toggle .tog-item {
  width: 50%;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #817171;
  transition: all ease 0.5s;
}

.outer-pop .toggle .tog-item.active {
  color: white;
}

.outer-pop .toggle .back {
  position: absolute;
  width: 50%;
  background: #18a0fb;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  transform: translateX(0%);
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.outer-pop .toggle .back.forward {
  transform: translateX(100%);
}

.outer-pop .form-container {
  margin: 10px 0;
}

.outer-pop .form-container p {
  color: rgba(56, 51, 51, 0.8);
  font-size: 13px;
  font-weight: 600;
  margin: 5px 0 10px;
}

.outer-pop .form-container h2 {
  color: #111110;
  font-weight: 500;
  font-size: 17px;
}

.outer-pop .form-container p.quest {
  position: relative;
}

.outer-pop .form-container p.quest svg {
  position: absolute;
  font-size: 12px;
  top: 2px;
  left: 67px;
  cursor: pointer;
}

.outer-pop .form-container select,
.outer-pop .form-container input {
  width: calc(100% - 30px);
  padding: 10px 15px;
  border: 1px solid rgba(149, 143, 143, 0.5);
  border-radius: 5px;
  height: 20px;
  font-size: 13px;
  color: rgba(56, 51, 51, 0.8);
  font-weight: 300;
}

.outer-pop .form-container select {
  height: 42px;
  width: 100%;
}

.outer-pop .form-container input[type="search"] {
  height: 42px;
  width: calc(100% - 5px);
}

.outer-pop .form-container .item {
  margin: 15px 0;
}

.outer-pop .form-container .item .input {
  display: flex;
  justify-content: space-between;
}

.outer-pop .form-container .item .input .up {
  width: 100%;
}

.outer-pop .form-container .item .input .down {
  /* width: calc(40% - 5px); */
}

.outer-pop .form-container .item .input .top {
  width: calc(100% - 50px);
}

.outer-pop .form-container .item .input .bottom {
  align-items: center;
  display: flex;
  width: 42px;
  min-width: 42px;
  justify-content: center;
}

.outer-pop .form-container .item .input .bottom svg {
  fill: lightgray;
  cursor: pointer;
}

.outer-pop .form-container .reminder {
  display: flex;
}

.outer-pop .form-container .reminder p {
  font-size: 12px;
  font-weight: 500;
  width: 170px;
  margin: 0;
}

.outer-pop .form-container .reminder input {
  width: 20px;
  padding: 0;
  height: 13px;
  margin: 2px 0 0;
  display: inline-block;
}

.outer-pop .form-container .btn {
  margin: 30px 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 14px;
  align-items: center;
}

.outer-pop .form-container .btn .submit {
  cursor: pointer;
  margin: 0 20px 0 0;
  padding: 7px 35px;
  background: #18a0fb;
  font-size: 14px;
  color: white;
  font-weight: 400;
  border-radius: 5px;
  transition: all ease 0.3s;
}

.outer-pop .form-container .btn .submit {
  width: 60px;
  text-align: center;
}

.outer-pop .form-container .btn .cancel {
  cursor: pointer;
  padding: 6px 35px;
  background: #fff;
  border: 1px solid #041471;
  color: #041471;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  transition: all ease 0.3s;
}

.outer-pop .form-container .btn .submit:hover {
  background: #037ac9;
}

.outer-pop .form-container .skillset {
  display: flex;
  flex-wrap: wrap;
  margin: -3px 0 0;
}

.outer-pop .form-container .skillset .skill-item {
  display: flex;
  align-items: center;
  border: 1px solid gray;
  border-radius: 12px;
  padding: 2px 5px 2px 7px;
  font-size: 12px;
  pointer-events: none;
  margin: 2px;
  transition: all ease 0.3s;
}

.outer-pop .form-container .skillset .skill-item svg {
  fill: gray;
  cursor: pointer;
  font-size: 13px;
  pointer-events: auto;
  transition: all ease 0.3s;
}

.outer-pop .form-container .skillset .skill-item span {
  pointer-events: none;
  color: gray;
  font-weight: 300;
  padding-right: 1px;
  cursor: default;
  transition: all ease 0.3s;
}

.outer-pop .form-container .skillset .skill-item:hover {
  background: gray;
}

.outer-pop .form-container .skillset .skill-item:hover span {
  color: white;
}

.outer-pop .form-container .skillset .skill-item:hover svg {
  fill: white;
}

.outer-pop .error {
  background: red;
  color: white;
  opacity: 0.9;
  font-weight: 300;
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
}

.outer-pop .success {
  background: #05ff00;
  color: white;
  opacity: 0.9;
  font-weight: 300;
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
}

.btn_text {
  transition: all 0.2s;
}

.btn-load {
  position: relative;
}

.btn-load .btn_text {
  visibility: hidden;
  opacity: 0;
}

.btn-load::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.subscribe .btn-load::after {
  border-top-color: #000000;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.privacy-body {
  padding: 75px;
  font-family: "Inter", sans-serif;
}

.privacy-body li,
.privacy-body p {
  color: rgba(56, 51, 51, 0.8);
}

.privacy-body p span {
  font-weight: bold;
}

.privacy-body .link {
  display: block;
  margin: 10px 0;
}

.home-hero.new {
  font-family: "Darker Grotesque", sans-serif;
  background: white;
}

.home-lander {
  min-height: calc(100vh - 55px);
  padding-top: 55px;
  display: flex;
  border-radius: 0 0 25px 25px;
  overflow: hidden;
}

.home-lander .content {
  width: calc(50% - 130px);
  background: #18a0fb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px 65px;
}

.home-lander .content h1 {
  font-size: 55px;
  color: #eeeeee;
  margin: 0;
  line-height: 185px;
  animation: h1home ease 0.5s 1.5s forwards;
}

.home-lander .content .line:nth-child(2) h1 {
  animation: h1home ease 0.5s 1.75s forwards;
}

.home-lander .content .line:nth-child(3) h1 {
  animation: h1home ease 0.5s 2s forwards;
}

.home-lander .content .hone {
  margin: 30px 0 20px;
}

.home-lander .content .line {
  overflow: hidden;
}

.home-lander .content .hone .line {
  height: 52px;
}

.home-lander .content p {
  color: #ededed;
  font-size: 23px;
  margin: 0;
  line-height: 30px;
  font-weight: 500;
  top: -60px;
  position: relative;
  animation: phome ease 0.5s 1.75s forwards;
}

.home-lander .content .line:nth-child(3) p {
  animation: phome ease 0.5s 2s forwards;
}

.home-lander .content .line:nth-child(4) p {
  animation: phome ease 0.5s 2.25s forwards;
}

.home-lander .content .line:nth-child(5) p {
  animation: phome ease 0.5s 2.5s forwards;
}

.home-lander .content .sm {
  display: none;
}

.home-lander .content .btn-group {
  margin: 50px 0 0;
  display: flex;
  overflow: hidden;
}

.home-lander .content .btn-group .sign-up {
  color: #f1f1f1;
  border: 2px solid #f1f1f1;
  border-radius: 8px;
  padding: 13px 40px;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  top: 60px;
  opacity: 0;
  position: relative;
  transition: all 0.5s ease;
  animation: btnhome ease 0.5s 2.25s forwards;
}

.home-lander .content .btn-group .sign-up:hover {
  color: #000;
  background: #f1f1f1;
}

.home-lander .btn-group .chrome.btn {
  width: 200px;
  background: white;
  padding: 0;
  height: 57px;
  overflow: hidden;
  top: 60px;
  opacity: 0;
  position: relative;
  animation: btnhome ease 0.5s 2s forwards;
}

.home-lander .btn-group .chrome.btn img {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.home-lander .img {
  width: 50%;
  background: #ffd400;
  position: relative;
  opacity: 0;
  animation: imghome ease 0.5s 1.75s forwards;
}

.home-lander .img img {
  width: 100%;
  position: absolute;
  top: 0;
  animation: imghome ease 0.5s 1.75s forwards;
}

.home-lander .img img.main {
  height: calc(100% - 70px);
  position: absolute;
  width: unset;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-lander .img .tile {
  width: 165px;
  background: white;
  position: absolute;
  height: 185px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 12%;
  top: 30%;
  opacity: 0;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
  animation: tileintro ease 0.5s 1.5s forwards,
    tilehome ease 2s 3s alternate infinite;
}

.home-lander .img .tile.down {
  animation: tileintroone ease 0.5s 2s forwards,
    tilehomeone ease 2s 3s alternate infinite;
  left: 70%;
}

.home-lander .img .tile .blob {
  width: 75px;
  height: 75px;
  background: #00ffd1;
  border-radius: 50%;
  margin: 15px;
  position: relative;
}

.home-lander .img .tile .blob img {
  width: 140%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.home-lander .img .tile.down .blob {
  background: #e453d5;
}

.home-lander .img .tile p {
  font-size: 19px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  margin: 10px 0;
  width: 90%;
}

@keyframes h1home {
  0% {
    line-height: 140px;
  }
  100% {
    line-height: 35px;
  }
}

@keyframes phome {
  0% {
    top: -35px;
  }
  100% {
    top: 0;
  }
}

@keyframes btnhome {
  0% {
    top: 60px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes tileintro {
  0% {
    top: 30%;
    opacity: 0;
  }
  100% {
    top: 17%;
    opacity: 1;
  }
}

@keyframes tileintroone {
  0% {
    top: 30%;
    opacity: 0;
  }
  100% {
    top: 55%;
    opacity: 1;
  }
}

@keyframes tilehome {
  0% {
    top: 17%;
  }
  100% {
    top: 20%;
  }
}

@keyframes tilehomeone {
  0% {
    top: 55%;
  }
  100% {
    top: 52%;
  }
}

@keyframes imghome {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.partners-tab {
  padding: 75px 65px 65px;
}

.partners-tab h4 {
  margin: 10px 0 0;
  color: #39393c;
  font-size: 26px;
}

.partners-tab p {
  margin: 0;
  font-weight: 700;
  color: #39393c;
  font-size: 17px;
}

.partners-tab .learning-imgs {
  display: flex;
  margin: 65px -15px 0;
  flex-wrap: wrap;
  justify-content: space-between;
}

.partners-tab .learning-imgs img {
  margin: 0 15px;
}

.partners-tab .learning-imgs img.kha {
  width: 246px;
  height: 40px;
}

.partners-tab .learning-imgs img.edx {
  width: 84px;
  height: 40px;
}

.partners-tab .learning-imgs img.lin {
  width: 177px;
  height: 40px;
}

.partners-tab .learning-imgs img.ski {
  width: 80px;
  height: 40px;
}

.partners-tab .learning-imgs img.uda {
  height: 70px;
  margin: 0;
  top: -13px;
  position: relative;
}

.partners-tab .learning-imgs img.ude {
  height: 60px;
  top: -10px;
  position: relative;
}

.partners-tab .learning-imgs img.cou {
  height: 70px;
  top: -13px;
  position: relative;
}

.manage-tab {
  background: #ffd400;
  margin: 0 65px 0;
  border-radius: 20px;
  padding: 20px 60px;
  display: flex;
}

.manage-tab .img {
  position: relative;
  width: 50%;
  overflow: hidden;
}

.track-tab .img h1,
.manage-tab .img h1 {
  display: none;
}

.manage-tab .img .main {
  position: absolute;
  opacity: 0;
  width: 100%;
}

.manage-tab .img .moves {
  position: absolute;
  width: 26.387%;
  top: -11.848%;
  left: -9.099%;
  opacity: 0;
  z-index: 1;
  transition: all ease 1.5s;
}

.manage-tab .img .moves.one {
  top: 5.687%;
  left: -0.546%;
  opacity: 1;
  z-index: 2;
}

.manage-tab .img .moves.two {
  left: 24.932%;
  opacity: 1;
  top: 24.882%;
  z-index: 3;
}

.manage-tab .img .moves.three {
  opacity: 1;
  left: -0.546%;
  top: 43.839%;
  z-index: 4;
}

.manage-tab .img .moves.four {
  opacity: 1;
  left: 24.932%;
  top: 63.033%;
  z-index: 5;
}

.manage-tab .img .moves.five {
  opacity: 1;
  left: 73.703%;
  top: 62.796%;
}

.manage-tab .img .moves.six {
  opacity: 1;
  left: 73.885%;
  top: 24.645%;
}

.manage-tab .img .moves.seven {
  top: 4.976%;
  opacity: 1;
  left: 47.861%;
}

.manage-tab .img .moves.lap {
  opacity: 1;
  left: 46.952%;
  top: 40.758%;
  width: 30.937%;
  z-index: 0;
  cursor: pointer;
}

.manage-tab .content {
  width: 50%;
}

.track-tab .content h1,
.manage-tab .content h1 {
  font-size: 52px;
  line-height: 52px;
  margin: 30px 0 0;
}

.visualise-tab p,
.track-tab .content p,
.manage-tab .content p {
  color: #39393c;
  font-size: 26px;
  font-weight: 500;
  line-height: 28px;
}

.new .btn-group {
  display: flex;
  margin: 50px 0 20px;
  align-items: center;
  font-size: 21px;
}

.new .btn-group .chrome {
  font-weight: 600;
  background: #161617;
  color: #fff;
  padding: 10px 18px 14px;
  border-radius: 5px;
  margin-right: 20px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.new .btn-group .chrome:hover {
  color: #161617;
  background: #fff;
}

.new .btn-group .free {
  font-weight: 700;
  color: #363333;
  cursor: pointer;
}

.track-tab {
  margin: 65px;
  display: flex;
}

.track-tab .img,
.track-tab .content {
  width: 50%;
}

.track-tab .img {
  width: 50%;
  display: flex;
  justify-content: center;
}

.track-tab .img img {
  width: 400px;
}

.visualise-tab {
  background: #00ffd1;
  margin: 0 65px 0;
  border-radius: 20px;
  padding: 20px 60px;
}

.visualise-tab h1 {
  font-size: 52px;
  line-height: 52px;
  margin: 30px 0 0;
  width: 80%;
}

.visualise-tab p {
  width: 60%;
}

.visualise-tab p.sm {
  display: none;
}

.visualise-tab .img-group {
  position: relative;
  margin-top: 35px;
}

.visualise-tab .img-groupie {
  display: none;
}

.how-to .balls,
.visualise-tab .img-groupie .balls {
  display: flex;
  width: 100%;
  justify-content: center;
}

.how-to .balls {
  margin-bottom: 40px;
}

.how-to .balls span,
.visualise-tab .img-groupie .balls span {
  background: #716c6c;
  cursor: pointer;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 5px;
  transition: all ease 0.3s;
}

.how-to .balls span.fill,
.visualise-tab .img-groupie .balls span.fill {
  background: black;
}

.visualise-tab .img-group img:nth-child(1) {
  width: 35%;
  margin-left: -20px;
}

.visualise-tab .img-group img:nth-child(2) {
  width: 35%;
  margin-left: calc(5%);
}

.visualise-tab .img-group img:nth-child(3) {
  width: 20%;
  position: absolute;
  top: -80px;
  right: 0;
}

.perks-tab {
  margin: 0 65px 0;
  display: flex;
  justify-content: space-between;
}

.perks-tab .perks {
  background: #e453d5;
  border-radius: 20px;
  padding: 20px 60px;
  width: calc(45% - 130px);
}

.perks-tab .perks:nth-child(1) {
  width: calc(55% - 130px);
}

.perks-tab .perks:nth-child(2) {
  background: #ffd400;
}

.perks-tab .perks h1 {
  font-weight: 500;
  font-size: 46px;
  line-height: 46px;
  margin: 0 0 20px;
}

.perks-tab .perks ul {
  margin: 40px 0 25px;
}

.perks-tab .perks li {
  font-weight: 500;
  font-size: 26px;
  line-height: 24px;
  margin: 5px 0;
}

.perks-tab .perks li::marker {
  font-size: 15px;
}

.perks-tab .perks img {
  width: 235px;
}

.how-to {
  margin: 90px 65px 30px;
  display: flex;
  justify-content: space-between;
}

.trail-bank .img,
.trail-bank .content,
.how-to .img,
.how-to .content {
  width: calc(50% - 20px);
}

.how-to.sm img {
  width: 400px;
}

.how-to.sm p {
  font-size: 24px;
  width: 300px;
  white-space: normal;
  font-weight: 600;
  text-align: center;
  line-height: 26px;
  margin: 5px 0 30px;
}

.how-to .content {
  display: flex;
}

.how-to .content .indicator {
  width: 50px;
  display: flex;
  margin: 10px 15px 0 0;
  flex-direction: column;
  align-items: center;
}

.how-to .content .indicator .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.how-to .content .indicator .item .bulb {
  width: 20px;
  height: 20px;
  background: #716c6c;
  border-radius: 50%;
  transition: all ease 0.5s 0.5s;
}

.how-to .content .indicator .item .bulb.fill {
  background: #141414;
}

.how-to .content .indicator .item .line {
  width: 4px;
  height: 90px;
  background: #716c6c;
}

.how-to .content .indicator .item .line .inner {
  transition: all ease 0.5s;
  background: #141414;
  height: 0;
}

.how-to .content .indicator .item .line.fill .inner {
  height: 100%;
}

.how-to .content .steps p {
  font-size: 33px;
  font-weight: 600;
  line-height: 29px;
  margin: 0 0 25px 10px;
  color: #716c6c;
  height: 85px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.how-to .content .steps p.fill {
  color: #141414;
}

.how-to .img {
  position: relative;
}

.how-to .img img {
  width: 100%;
  opacity: 1;
  transition: all ease 0.5s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.how-to .img img.hide {
  opacity: 0;
}

.trail-bank {
  padding: 65px;
  display: flex;
  justify-content: space-between;
}

.trail-bank .img {
  display: flex;
  justify-content: center;
}

.trail-bank .img img {
  width: 400px;
}

.trail-bank .content h1 {
  font-size: 52px;
  line-height: 52px;
  margin: 30px 0 0;
}

.trail-bank .img h1 {
  display: none;
}

.trail-bank .content p {
  color: #39393c;
  font-size: 26px;
  font-weight: 500;
  line-height: 28px;
}

.trail-bank .content .input {
  margin: 45px 0 0;
  position: relative;
}

.trail-bank .content .input input {
  padding: 10px 155px 10px 25px;
  height: 40px;
  border-radius: 35px;
  border: 2px solid #494a4e;
  color: #39393c;
  font-size: 16px;
  font-weight: 500;
  width: 310px;
}

.trail-bank .content .input label {
  background: #39393c;
  color: #fff;
  border-radius: 25px;
  padding: 10px 30px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 342px;
  font-size: 19px;
  font-weight: 500;
}

.footer-tab {
  margin: 20px 65px;
  background: #6398f9;
  border-radius: 15px;
  color: #fff;
  padding: 60px 40px 20px;
}

.footer-tab .information {
  display: flex;
  justify-content: space-between;
}

.footer-tab .information .about-info {
  width: calc(35% - 10px);
}

.footer-tab .information .contact {
  width: calc(30% - 10px);
}

.footer-tab .information .subscribe {
  width: calc(35% - 10px);
}

.trail-bank .content .error,
.footer-tab .information .subscribe .error {
  font-size: 20px;
  font-weight: 600;
  background: none;
  color: red;
  line-height: 20px;
}

.footer-tab .information .about-info .brand {
  display: flex;
}

.footer-tab .information .about-info .brand h1 {
  font-size: 35px;
  margin: 0 0 0 15px;
  line-height: 35px;
}

.footer-tab .information .about-info p {
  font-size: 26px;
  width: 95%;
  line-height: 29px;
  margin: 20px 0 50px;
}

.footer-tab .information .subscribe h1,
.footer-tab .information .contact h1 {
  font-weight: 600;
  margin: 0 0 17px;
  font-size: 26px;
  text-transform: uppercase;
}

.footer-tab .information .contact p {
  font-size: 26px;
  font-weight: 500;
  margin: 5px 0;
}

.footer-tab .information .contact p a {
  color: #fff;
}

.footer-tab .subscribe .input {
  margin: 25px 0 0;
  position: relative;
}

.footer-tab .subscribe .input input {
  padding: 10px 110px 10px 25px;
  height: 30px;
  border-radius: 35px;
  border: 2px solid #fff;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  width: calc(100% - 140px);
  background: transparent;
}

.footer-tab .subscribe .input label {
  background: #fff;
  color: #39393c;
  border-radius: 25px;
  width: 72px;
  padding: 8px 20px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% - 120px);
  font-size: 19px;
  font-weight: 500;
}

.footer-tab hr {
  border: 1px solid white;
  margin: 20px 0;
}

.footer-tab .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-tab .bottom p {
  font-size: 20px;
}

.footer-tab .bottom .social a {
  color: white;
  font-size: 30px;
  margin: 0 15px;
}

.tutorial {
  position: absolute;
  width: calc(100% - 65px);
  height: 100%;
  min-height: 100vh;
  top: 0;
  z-index: 1;
  background: rgba(201, 201, 201, 0.6);
}

.tutorial.three,
.tutorial.two {
  z-index: 100;
  background: rgba(201, 201, 201, 0);
}

.tutorial.ten {
  z-index: 3;
}

.tutorial .popup {
  position: fixed;
  background: white;
  z-index: 2;
  top: 50vh;
  left: calc(50% - 190px);
  transform: translate(calc(-50% + 300px), -50%);
  width: 585px;
  padding: 80px 40px 45px;
  border-radius: 10px;
  font-family: "Darker Grotesque", sans-serif;
}

.tutorial.hide,
.tutorial .popup.hide {
  display: none;
}

.tutorial .background-float {
  position: fixed;
  background: white;
  z-index: 1;
  top: 50vh;
  left: calc(50% - 190px);
  transform: translate(calc(-50% + 300px), -50%);
  width: 665px;
  height: 428px;
  border-radius: 10px;
  transition: all cubic-bezier(0.92, 0.57, 0.56, 1.21) 0.5s;
}

.tutorial.one .background-float {
  position: absolute;
  top: 120px;
  height: 235px;
  transform: none;
  width: 405px;
  left: calc(100% - 405px);
}

.pin-extension {
  position: fixed;
  background: #d9d9d9;
  z-index: 1;
  padding: 20px;
  top: 40px;
  left: calc(100% - 375px);
  width: 285px;
  height: 265px;
  border-radius: 10px;
}

.pin-extension h2 {
  font-family: "Darker Grotesque", sans-serif;
  color: #2e2c2c;
  font-weight: 600;
  font-size: 19px;
  line-height: 20px;
}

.pin-extension .close {
  font-family: "Darker Grotesque", sans-serif;
  color: #2e2c2c;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

.tutorial.two .background-float {
  top: 115px;
  height: 275px;
  transform: none;
  width: 405px;
  left: 205px;
  position: fixed;
}

.tutorial.three .background-float {
  top: 300px;
  height: 275px;
  transform: none;
  width: 405px;
  left: 215px;
  position: fixed;
}

.tutorial.four .background-float {
  position: absolute;
  top: 300px;
  z-index: 0;
  height: 268px;
  transform: none;
  width: 300px;
  left: calc(100% - 327px);
}

.tutorial.five .background-float {
  position: absolute;
  top: 340px;
  height: 260px;
  transform: none;
  width: 405px;
  left: 215px;
}

.tutorial.six .background-float {
  position: absolute;
  top: 925px;
  height: 285px;
  transform: none;
  width: 405px;
  left: calc(60% - 160px);
}

.tutorial.seven .background-float {
  position: absolute;
  top: 260px;
  height: 285px;
  transform: none;
  width: 405px;
  left: calc(75% - 478px);
}

.tutorial.eight .background-float {
  position: absolute;
  top: 290px;
  height: 320px;
  transform: none;
  width: 297px;
  left: unset;
  right: 24px;
}

.tutorial.nine .background-float {
  position: absolute;
  top: 91px;
  height: 285px;
  transform: none;
  width: 405px;
  left: unset;
  right: 365px;
}

.tutorial.ten .background-float {
  position: absolute;
  top: 110px;
  height: 285px;
  transform: none;
  width: 405px;
  left: unset;
  right: 13px;
}

.tutorial.eleven .background-float {
  position: fixed;
  top: 97px;
  height: 285px;
  transform: none;
  width: 405px;
  left: 248px;
}

.tutorial.twelve .background-float {
  position: absolute;
  top: 97px;
  height: 274px;
  transform: none;
  width: 405px;
  left: unset;
  right: 389px;
}

.tutorial.thirteen .background-float {
  position: fixed;
  top: 153px;
  height: 285px;
  transform: none;
  width: 405px;
  left: 248px;
}

.tutorial.fourteen .background-float {
  position: absolute;
  top: 127px;
  z-index: 0;
  height: 300px;
  transform: none;
  width: 300px;
  left: unset;
  right: 20px;
}

.tutorial.fifteen .background-float {
  position: fixed;
  top: 205px;
  height: 263px;
  transform: none;
  width: 405px;
  left: 248px;
}

.tutorial.sixteen .background-float {
  position: absolute;
  top: unset;
  bottom: 118px;
  height: 263px;
  transform: none;
  width: 405px;
  left: calc(50% - 210px);
}

.pin-extension::before,
.tutorial.one .background-float::before {
  content: "";
  position: absolute;
  border-top: none;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  border-bottom: 30px solid #fff;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.pin-extension::before {
  left: 85%;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 20px solid #d9d9d9;
  top: -20px;
}

.tutorial.three .background-float::before,
.tutorial.two .background-float::before {
  content: "";
  position: absolute;
  border-top: 20px solid transparent;
  border-right: none;
  border-left: 30px solid #fff;
  border-bottom: 20px solid transparent;
  top: 50%;
  left: 405px;
  transform: translateY(-50%);
}

.tutorial.fourteen .background-float::before,
.tutorial.eight .background-float::before,
.tutorial.six .background-float::before,
.tutorial.four .background-float::before {
  content: "";
  position: absolute;
  border-top: 20px solid transparent;
  border-right: 30px solid #fff;
  border-left: none;
  border-bottom: 20px solid transparent;
  top: 50%;
  left: -14px;
  transform: translateY(-50%);
}

.tutorial.fifteen .background-float::before,
.tutorial.thirteen .background-float::before,
.tutorial.eleven .background-float::before {
  content: "";
  position: absolute;
  border-top: 20px solid transparent;
  border-right: 30px solid #fff;
  border-left: none;
  border-bottom: 20px solid transparent;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
}

.tutorial.sixteen .background-float::before,
.tutorial.ten .background-float::before,
.tutorial.five .background-float::before {
  content: "";
  position: absolute;
  border-top: 30px solid #fff;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  border-bottom: none;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.tutorial.twelve .background-float::before,
.tutorial.nine .background-float::before,
.tutorial.seven .background-float::before {
  content: "";
  position: absolute;
  border-top: 20px solid transparent;
  border-right: none;
  border-left: 30px solid #fff;
  border-bottom: 20px solid transparent;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
}

.tutorial .popup .containing {
  display: flex;
  justify-content: space-between;
}

.tutorial .popup .containing .img {
  width: 40%;
  display: flex;
}

.tutorial .popup .containing .img img {
  width: 100%;
  margin: auto;
}

.tutorial .popup .containing .content {
  width: 55%;
}

.tutorial .popup .containing .content h1 {
  font-size: 32px;
  line-height: 32px;
  margin: 0 0 25px;
  color: #111110;
}

.tutorial .popup .containing .content p {
  font-size: 20px;
  font-family: "Darker Grotesque", sans-serif;
  font-weight: 500;
  line-height: 24px;
  color: #111110;
}

.inner-tut .btn-group,
.tutorial .popup .btn-groups {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 0;
}

.inner-tut .btn-group .skip,
.tutorial .popup .btn-groups .skip {
  color: #3d3d3c;
  font-size: 24px;
  font-weight: 700;
  margin: 0 30px;
  cursor: pointer;
}

.inner-tut .btn-group .skip {
  font-size: 17px;
  margin: 0 20px;
}

.inner-tut .btn-group .start,
.tutorial .popup .btn-groups .start {
  background: #18a0fb;
  color: #fff;
  border-radius: 5px;
  padding: 10px 30px 16px;
  font-size: 24px;
  font-family: "Darker Grotesque", sans-serif;
  line-height: 18px;
  margin: 0 30px;
  cursor: pointer;
  font-weight: 600;
}

.inner-tut .btn-group .start {
  padding: 6px 30px 10px;
  font-size: 17px;
  margin: 0 20px;
}

.how-to.sm,
.home-sm.hide,
.home-sm,
.dash-container .search .up .results-tab.hide,
.dash-container .search .up svg.hide,
.outer-pop.hide,
.profile-page.hide,
.search.hide,
.plan.hide,
.one-home.hide,
.available.hide,
.home.hide {
  display: none;
}

.home .info {
  display: flex;
  justify-content: space-between;
  margin: 25px 0 0;
}

.home .info p {
  margin: 0;
  font-weight: 600;
  font-size: 30px;
}

.home .time {
  border-radius: 7px;
  padding: 10px 40px;
  font-weight: 400;
  background: #c3cafc;
  position: relative;
  top: 34px;
}

.home .time span {
  font-weight: 400;
}

.home .statis .title,
.home .upcoming p {
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 15px;
}

.home .upcoming p {
  margin: 3px 0 20px;
  color: #5a4d4d;
  font-size: 16px;
  font-weight: 400;
}

.home .upcoming .session {
  display: flex;
  justify-content: space-between;
}

.home .upcoming .session .earn-cover {
  display: flex;
  width: 47%;
}

.upcoming-info {
  display: flex;
  width: calc(100% - 40px);
  justify-content: space-between;
  border-radius: 15px;
  padding: 25px 20px 45px;
  position: relative;
  background: #fff;
}

.upcoming-info.sing {
  padding: 35px 20px;
  margin-left: 0;
}

.how-to .carousel,
.visualise-tab .carousel,
.upcoming-info .carousel {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
}

.upcoming-info .carousel.one {
  width: 100%;
}

.upcoming-info .carousel.nil {
  width: 100%;
  min-height: 203px;
}

.upcoming-info .carousel.nil .nil {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upcoming-info .carousel.nil .nil img {
  width: 170px;
}

.dashment .upcoming .session .info .nil .title,
.upcoming-info .carousel.nil .nil .title {
  font-size: 16px;
  font-weight: 500;
  white-space: normal;
  margin: 25px 0 10px;
}

.dashment .upcoming .session .info .nil .bio,
.upcoming-info .carousel.nil .nil .bio {
  width: 90%;
  white-space: normal;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 13px;
}

.how-to .carousel::-webkit-scrollbar,
.visualise-tab .carousel::-webkit-scrollbar,
.exam .carousel::-webkit-scrollbar,
.upcoming-info .carousel::-webkit-scrollbar {
  display: none;
}

.how-to .carousel.snap,
.visualise-tab .carousel.snap,
.exam .carousel.snap,
.upcoming-info .carousel.snap {
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch; /* Needed to work on iOS Safari */
}

.how-to .carousel.snap .container,
.visualise-tab .carousel.snap .container,
.upcoming-info .carousel.snap .ernij,
.exam .carousel.snap .container,
.upcoming-info .carousel.snap .container {
  scroll-snap-align: center;
}

/* 2015 spec - For Firefox, Edge, IE */
.how-to .carousel.snap,
.visualise-tab .carousel.snap,
.exam .carousel.snap,
.upcoming-info .carousel.snap {
  scroll-snap-type: mandatory;
  -ms-scroll-snap-type: mandatory;
  scroll-snap-points-x: repeat(100%);
  -ms-scroll-snap-points-x: repeat(100%);
}

.how-to .frame,
.visualise-tab .container,
.upcoming-info .container {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
}

.upcoming-info .icon {
  margin: 20% 0;
  border: 1px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  box-shadow: 0px 2px 7px 0px rgb(0 0 0 / 30%);
}

.upcoming-info .icon i {
  position: relative;
  top: -4px;
  left: 7px;
  font-size: 12px;
  color: #1339ff;
  pointer-events: none;
}

.upcoming-info .icon i.fa-chevron-left {
  top: -4px;
  left: 5px;
}

.upcoming-info .container .img span {
  display: none;
}

.upcoming-info .container .img .hodl {
  width: 100%;
  border-radius: 10px;
  height: 100px;
  overflow: hidden;
  position: relative;
  margin: 15px 0px;
}

.upcoming-info .container .img .hodl img {
  width: 100%;
  min-height: 100%;
  top: 50%;
  left: 0;
  position: absolute;
  transform: translateY(-50%);
}

.history .item .form .info .img .icon,
.upcoming-info.earning .form .info .img .icon,
.history .container .item .img .icon {
  margin: 0;
  background: none;
  border: 2px solid white;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upcoming-info .container .img .hodl .icon i {
  top: 0;
  left: 0;
  font-size: 60px;
  color: white;
  opacity: 1;
}

.upcoming-info .container .img .hodl.load i {
  opacity: 0;
}

.upcoming-info .container .img .hodl.load {
  border: none;
  background: linear-gradient(to right, #02114b 8%, #010c32 38%, #02114b 54%);
}

.upcoming-info .container .content .title {
  font-size: 15px;
  font-weight: 500;
  margin: 5px 0;
}

.upcoming-info .container .content .title a {
  color: white;
}

.upcoming-info .container .content .title.load {
  width: 200px;
  height: 21px;
}

.upcoming-info .container .img .circle.load {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.upcoming-info .container .img .title.load,
.upcoming-info .container .img .name.load,
.upcoming-info .container .content .deets .day.load,
.upcoming-info .container .content .deets .title.load {
  width: 100%;
  height: 15px;
}

.upcoming-info .container .content .desc.load {
  margin: 15px 0 40px;
  height: 40px;
  width: 250px;
}

.upcoming-info .container .content .title.note {
  margin: 15px 0 5px;
}

.upcoming-info .container .content .bio {
  font-weight: 100;
  min-height: 55px;
  white-space: normal;
}

.upcoming-info .container .content .bio.load {
  width: 350px;
  height: 72px;
}

.upcoming-info .container .content .info {
  margin: 25px 10px 0;
}

.upcoming-info .container .content .info .img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 auto 10px;
}

.upcoming-info .container .content .info .img img {
  width: 100%;
  opacity: 1;
}

.upcoming-info .container .content .info .img.load img {
  opacity: 0;
}

.upcoming-info .container .content .info .img.load {
  background-size: 1000px 640px;
}

.upcoming-info .container .content .info p {
  font-weight: 100;
  font-size: 15px;
  text-align: center;
  color: white;
}

.upcoming-info .bar {
  position: absolute;
  top: calc(100% - 27px);
  transform: translate(50%, 0);
  right: 50%;
  display: flex;
}

.upcoming-info .bar span {
  width: 10px;
  height: 10px;
  margin: 0 10px;
  border: 1px solid black;
  border-radius: 50%;
  display: block;
  cursor: pointer;
  transition: all ease 0.5s;
}

.upcoming-info .bar span.hide.fill,
.upcoming-info .bar span.hide {
  display: none;
}

.upcoming-info .bar span.fill {
  background: black;
}

.upcoming-info.earning {
  width: 200px;
  margin: 0 10px;
  min-width: 180px;
  position: relative;
  padding: 20px 25px 10px;
}

.upcoming-info .carousel .ernij {
  min-width: 100%;
}

.upcoming-info .carousel .ernij .img {
  width: 75px;
  height: 85px;
  border-radius: 50%;
  background-size: 1000px 640px;
  margin: 15px 0 35px;
}

.upcoming-info .carousel .ernij .img img {
  width: 70%;
  margin: 10px 0 10px;
}

.upcoming-info .carousel .ernij p {
  font-weight: 400;
  font-size: 20px;
  margin: 0;
}

.upcoming-info.mob .carousel .ernij .text {
  display: none;
  font-weight: 100;
  font-size: 20px;
  margin: 0 20px 0 0;
}

.upcoming-info .carousel .ernij p.load {
  width: 110px;
  height: 17px;
}

.upcoming-info .carousel .ernij .amount {
  margin: 20px 0 95px;
  font-size: 25px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: #fff;
  min-height: 36px;
}

.upcoming-info.mob .carousel .ernij .fa-angle-right {
  display: none;
}

.upcoming-info .carousel .ernij .amount.load {
  width: 170px;
  height: 29px;
}

.upcoming-info .carousel .ernij.down .amount {
  margin: 25px 0 5px;
}

.upcoming-info .carousel .ernij.test .amount {
  width: 100%;
  white-space: normal;
  font-size: 17px;
  font-weight: 300;
  margin: 15px 0 20px;
}

.upcoming-info .carousel .ernij.test .amount.next {
  min-height: 120px;
}

.upcoming-info .carousel .ernij.down .down {
  margin: 5px 0 65px;
  font-size: 17px;
  font-weight: 300;
  color: #fff;
}

.upcoming-info .carousel .ernij.down.full .down {
  margin: 0 0 53px;
}

.upcoming-info .carousel .ernij.test .down {
  margin: 0;
}

.upcoming-info .carousel .ernij.down .down.load {
  width: 150px;
  height: 20px;
}

.upcoming-info .carousel .ernij.down .avail.load {
  width: 90px;
  height: 18px;
}

.dashment .upcoming .session .info .nil a,
.upcoming-info .carousel.nil .nil a,
.upcoming-info .carousel .ernij a,
.upcoming-info .carousel .ernij .avail {
  color: #000;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
}

.dashment .upcoming .session .info .nil a i,
.upcoming-info .carousel.nil .nil a i,
.upcoming-info .carousel .ernij a i,
.upcoming-info .carousel .ernij .avail i {
  margin-left: 6px;
  font-size: 10px;
  color: white;
  background: #94a1ff;
  padding: 3px 5px;
  border-radius: 4px;
  pointer-events: none;
}

.upcoming-info .carousel.nil .nil a.hide {
  display: none;
}

.upcoming-info.earning .icon {
  position: absolute;
  margin: 0;
  top: 175px;
  right: 7px;
}

.upcoming-info.earning .icon.hide {
  display: none;
}

.upcoming-info.earning .icon:nth-child(1) {
  left: 7px;
}

.upcoming-info.earning .carousel {
  margin-left: 20px;
}

.upcoming-info.earning .carousel.one {
  margin: 0;
  width: 100%;
}

.upcoming-info.earning .menu .opt .cancel {
  position: absolute;
  right: 20px;
  top: 10px;
  width: 25px;
  height: 25px;
  border: 1px solid white;
  border-radius: 50%;
}

.upcoming-info.earning .menu .opt .cancel span {
  width: 15px;
  height: 2px;
  background: white;
  border: none;
  position: relative;
  top: 5px;
  left: 5px;
  transform: rotate(45deg);
}

.upcoming-info.earning .menu .opt .cancel span:nth-child(1) {
  top: 9px;
  transform: rotate(-45deg);
}

.home .statis {
  display: flex;
  margin: 70px 0 0;
}

.home .statis .title {
  margin: 15px 0;
}

.home .statis .earn:nth-child(2) {
  margin: 0 0 0 35px;
}

.home .statis .graph {
  width: 550px;
  margin-left: 95px;
  display: none;
}

.history {
  width: calc(75% - 50px);
  padding: 19px;
  background: white;
  border-radius: 10px;
  margin-right: 20px;
}

.history.hom {
  display: block;
}

.history .title {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 5px;
}

.history .container {
  height: 218px;
  overflow-x: hidden;
}

.history .container .nil {
  font-size: 14px;
  font-weight: 400;
}

.history .container::-webkit-scrollbar {
  width: 10px;
}

.history .container::-webkit-scrollbar-track {
  box-shadow: -1px 0px 3px 0px rgb(0 0 0 / 30%);
}

.history .container::-webkit-scrollbar-thumb {
  background-color: lightblue;
}

.history .container .item {
  display: flex;
  padding: 5px 10px 5px 0;
  font-weight: 300;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.history .container .item .sm {
  display: none;
}

.history .container .item .img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
}

.history .container .item .img .icon {
  background-color: black;
  border: 2px solid black;
}

.history .container .item .img .icon i {
  color: #fff;
  font-size: 14px;
}

.dashment .history .container .item .info,
.history .container .item .info {
  text-align: center;
  padding-top: 5px;
  width: calc(((100% - 220px) / 4));
}

.dashment .history .container .item .name,
.history .container .item .name {
  width: 150px;
  line-height: 20px;
}

.history .container .item .action {
  color: #fa9f47;
}

.history .container .item .action.complete {
  color: #34c240;
}

.history .container .item:hover {
  background: lightblue;
}

.referral .upcoming .session .gen .form.hide,
.upcoming-info.earning .form.hide,
.history .container .item .form.hide {
  display: none;
}

.referral .upcoming .session .gen .form,
.upcoming-info.earning .form,
.history .container .item .form {
  position: fixed;
  top: calc(50vh - 200px);
  left: calc(50% - 260px);
  width: 500px;
  height: 300px;
  background: rgba(0, 0, 0, 1);
  color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 50px;
  overflow-x: scroll;
  cursor: default;
  z-index: 1;
}

.referral .upcoming .session .gen .form.draw,
.upcoming-info.earning .form.draw {
  justify-content: center;
}

.referral .upcoming .session .gen .form::-webkit-scrollbar,
.upcoming-info.earning .form::-webkit-scrollbar,
.history .container .item .form::-webkit-scrollbar {
  display: none;
}

.referral .upcoming .session .gen .form .info,
.upcoming-info.earning .form .info,
.history .container .item .form .info {
  display: flex;
  width: 100%;
  padding: 0;
  text-align: left;
}

.referral .upcoming .session .gen .form .info {
  justify-content: space-between;
  margin: 25px 0;
}

.upcoming-info.earning .form .info .img,
.history .item .form .info .img {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 20px;
}

.history .item .form .info .img .icon,
.upcoming-info.earning .form .info .img .icon {
  top: 0;
  left: 0;
}

.history .item .form .info .img .icon i,
.upcoming-info.earning .form .info .img .icon i {
  top: 0;
  left: 0;
  font-size: 50px;
  color: white;
}

.referral .upcoming .session .gen .form .info p,
.upcoming-info.earning .form .info p,
.history .item .form .info p {
  font-size: 20px;
  font-weight: 300;
  width: calc(100% - 120px);
}

.referral .upcoming .session .gen .form p,
.upcoming-info.earning .form p,
.history .item .form p {
  font-size: 17px;
  font-weight: 100;
  white-space: pre-wrap;
}

.referral .upcoming .session .gen .form .error,
.upcoming-info.earning .form .error,
.history .item .form .error {
  font-weight: 300;
  color: red;
  font-size: 15px;
  margin-bottom: 10px;
}

.referral .upcoming .session .gen .form .success,
.upcoming-info.earning .form .success {
  font-weight: 300;
  color: limegreen;
  font-size: 15px;
  margin-bottom: 10px;
}

.referral .upcoming .session .gen .form input,
.upcoming-info.earning .form input,
.history .item .form input {
  background: transparent;
  border: 1px solid gray;
  padding: 10px 8px;
  font-size: 15px;
  color: white;
  font-weight: 100;
  margin: 0 0 25px;
}

.referral .upcoming .session .gen .form .input.err,
.upcoming-info.earning .form input.err,
.history .item .form input.err {
  border: 1px solid red;
}

.referral .upcoming .session .gen .form .links,
.upcoming-info.earning .form .links,
.history .item .form .links {
  display: flex;
  justify-content: center;
}

.referral .upcoming .session .gen .form .links .btn,
.upcoming-info.earning .form .links .btn,
.history .item .form .links .btn {
  padding: 7px 30px;
  border: 1px solid #1339ff;
  background: #1339ff;
  color: white;
  font-size: 13px;
  font-weight: 100;
  margin: 0 10px;
  cursor: pointer;
  transition: all 0.7s ease;
}

.referral .upcoming .session .gen .form .links .btn.hide,
.upcoming-info.earning .form .links .btn.hide {
  display: none;
}

.referral .upcoming .session .gen .form .links .back,
.upcoming-info.earning .form .links .back,
.history .item .form .links .back {
  padding: 7px 30px;
  border: 1px solid #1339ff;
  background: white;
  color: #1339ff;
  font-size: 13px;
  font-weight: 300;
  margin: 0 10px;
  cursor: pointer;
  transition: all 0.7s ease;
}

.referral .upcoming .session .gen .form .links .back:hover,
.upcoming-info.earning .form .links .back:hover,
.history .item .form .links .back:hover {
  background: #1339ff;
  color: #fff;
}

.referral .upcoming .session .gen .form .links .btn:hover,
.upcoming-info.earning .form .links .btn:hover,
.history .item .form .links .btn:hover {
  background: #fff;
  color: #1339ff;
}

.referral .upcoming .session .gen .form .cancel,
.upcoming-info.earning .form .cancel,
.history .item .form .cancel {
  width: 40px;
  height: 40px;
  border: 2px solid white;
  position: absolute;
  border-radius: 50%;
  top: 19px;
  right: 33px;
  cursor: pointer;
}

.referral .upcoming .session .gen .form .cancel span,
.upcoming-info.earning .form .cancel span,
.history .item .form .cancel span {
  width: 2px;
  height: 25px;
  display: block;
  background: white;
  position: relative;
  top: 7.5px;
  left: 19px;
  transform: rotate(45deg);
}

.referral .upcoming .session .gen .form .cancel span:nth-child(2),
.upcoming-info.earning .form .cancel span:nth-child(2),
.history .item .form .cancel span:nth-child(2) {
  transform: rotate(-45deg);
  top: -17.5px;
  left: 19px;
}

.prof {
  display: flex;
  min-height: 100vh;
  background-color: #e1e5ff;
}

.prof .content {
  width: 70%;
  padding: 70px 50px 50px;
}

.prof .content .title {
  font-size: 40px;
  font-weight: 500;
  color: #202020;
  padding: 0 0 15px;
}

.prof .content .desc {
  width: 80%;
  font-weight: 300;
  font-size: 18px;
  padding: 0 0 10px;
  margin-bottom: 45px;
}

.prof .content .error {
  font-weight: 300;
  color: red;
}

.prof .content label {
  display: block;
  margin: 14px 0;
  font-weight: 400;
  color: #000;
}

.blog .container .sale .mail .wrapper,
.prof .content .wrapper {
  position: relative;
  width: 75%;
  margin: 60px 0;
}

.prof .content .wrapper:nth-child(1) {
  margin: 0 0 60px;
}

.blog .container .sale .mail input {
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  font-size: 1em;
  box-sizing: border-box;
  padding: 12px 10px 8px;
  border-bottom: 2px solid #464646;
}

.prof .content textarea,
.prof .content input {
  background-color: white;
  border-radius: 8px;
  outline: none;
  width: 100%;
  font-size: 1em;
  box-sizing: border-box;
  padding: 12px 10px 8px;
  border: 1px solid transparent;
  height: 50px;
  transition: all 0.5s ease;
}

.prof .content textarea {
  height: 150px;
}

.prof .content .links .btn {
  border: 1px solid #010c35;
  background-color: #010c35;
}

.blog .container .sale .mail input:focus + .underline {
  width: 100%;
}

.blog .container .sale .mail .underline {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  width: 0;
  background-color: dodgerblue;
  transition: 0.5s;
}

.prof .content .input.accountno::-webkit-inner-spin-button,
.prof .content .input.accountno::-webkit-outer-spin-button {
  opacity: 1;
  display: none;
}

.prof .content .wrapper.error input {
  border: 1px solid red;
}

.prof .content .links {
  margin: 70px 0 0;
}

.prof .side {
  background: #c3cafc;
  width: 30%;
  min-width: 300px;
  border-radius: 55px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
}

.prof .side::-webkit-scrollbar {
  display: none;
}

.prof .side .img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  margin: 80px auto 15px;
  position: relative;
}

.prof .side .img i {
  color: black;
  font-size: 75px;
  position: relative;
  top: 20px;
  left: 27px;
}

.prof .side .img .cover {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: white;
  border-radius: 50%;
}

.prof .side .img img {
  width: 100%;
  position: absolute;
  left: 0;
  transform: translateY(-50%);
  top: 50%;
  min-height: 100%;
}

.prof .side .img .icon {
  position: absolute;
  width: 30px;
  height: 30px;
  background: blue;
  border-radius: 50%;
  bottom: 0;
  right: 15px;
  cursor: pointer;
}

.prof .side .img .icon span {
  display: block;
  width: 15px;
  height: 3px;
  background: white;
  position: absolute;
  top: 14px;
  right: 24%;
  border-radius: 5px;
  pointer-events: none;
}

.prof .side .img .icon span:nth-child(1) {
  height: 15px;
  width: 3px;
  top: 8px;
  left: 14px;
}

.prof .side .title {
  font-size: 27px;
  text-align: center;
  line-height: 35px;
  margin: 25px 0 0;
  font-weight: 600;
}

.prof .side .subtitle {
  text-align: center;
  font-weight: 400;
}

.prof .side .intro {
  margin: 35px 45px 0;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}

.prof .side p {
  margin: 0 45px;
  font-weight: 300;
  text-align: center;
  font-size: 15px;
  white-space: pre-wrap;
}

.prof .side .account p {
  margin: 25px 45px 5px;
}

.prof .side .account .no {
  display: flex;
  padding: 0px 45px;
  flex-wrap: wrap;
}

.prof .side .account .no p {
  margin: 5px 10px 5px 0;
  font-weight: 500;
}

.prof .side .upcoming .query .content {
  margin: 0 30px 30px;
  width: unset;
  padding: 25px;
}

.prof .upcoming .query .content .title {
  line-height: unset;
  margin: 15px 0 0;
}

.prof .upcoming .query .content .message {
  margin: 8px auto 0;
}

.land-1 {
  display: flex;
}

.land-1.alt1 {
  background: none;
}

.land-1.wait {
  flex-direction: row-reverse;
}

.land-1 .content {
  width: 50%;
  padding: 65px 50px;
}

.land-2 .content .title,
.land-1 .title {
  font-size: 35px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  margin: 12px 0 18px;
  line-height: 42px;
}

.land-1 .img .title.big,
.land-1 .img .subtitle.big {
  display: none;
}

.land-1 .subtitle {
  font-weight: 400;
  font-size: 19px;
}

.land-1 .content .duo .intro,
.land-2 .content .title.alt {
  margin: 35px 0 10px;
  color: #1339ff;
  font-size: 25px;
  font-weight: 500;
}

.land-1 .intro {
  margin: 50px 0 10px;
  font-size: 35px;
  line-height: 42px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.land-1 .intro.hide {
  display: none;
}

.land-1 .content .intro.alt {
  font-size: 23px;
  color: #1339ff;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  margin: 32px 0 15px;
  line-height: 44px;
}

.land-2 .content p,
.land-1 p {
  font-weight: 400;
  font-size: 19px;
  margin: 10px 0;
}

.land-1 p.up {
  margin: 30px 0 10px;
}

.blog .related a,
.blog .sale .fre a,
.res-cta .content a,
.land-2 .content a,
.land-1 a {
  color: #1339ff;
  font-weight: 500;
  cursor: pointer;
}

.land-1 a.up {
  line-height: 150px;
}

.blog .related a:hover,
.blog .sale .fre a:hover,
.res-cta .content a:hover,
.land-2 .content a:hover,
.land-1 a:hover {
  color: #173cb5;
}

.blog .related a:hover .icon,
.blog .sale .fre a:hover .icon,
.res-cta .content a:hover .icon,
.land-2 .content a:hover .icon,
.land-1 a:hover .icon {
  margin-left: 20px;
}

.blog .related .icon,
.blog .sale .fre .icon,
.res-cta .content .icon,
.land-2 .content .icon,
.land-1 .icon {
  display: inline;
  border: 1px solid;
  border-radius: 50%;
  padding: 0px 2px;
  margin: 0 10px;
  position: relative;
  top: 2px;
  transition: all 0.5s ease;
}

.blog .related .icon i,
.blog .sale .fre .icon i,
.res-cta .content .icon i,
.land-2 .content .icon i,
.land-1 .icon i {
  width: 16px;
  position: relative;
  left: 4px;
  font-size: 13px;
  padding: 0px 2px;
  top: -2px;
}

.land-1 .content .duo {
  display: flex;
}

.land-1 .content .duo p {
  width: 80%;
}

.land-1 .img .sm {
  display: none;
}

.land-1 .img {
  width: calc(45% - 100px);
  margin: 125px 50px 60px;
}

.land-1.wait .img {
  margin: 75px 50px 60px;
}

.land-2 .img img,
.land-1 .img img {
  width: 100%;
  max-width: 600px;
  max-height: 850px;
}

.land-1.wait .img img {
  max-width: 450px;
  max-height: 400px;
}

.land-2 .img img.sm {
  display: none;
}

.land-2.alt .img img {
  height: 725px;
  margin-left: 170px;
  width: unset;
}

.land-2 .container.alt,
.land-1 .container.alt {
  margin: 35px 0;
  display: block;
}

.land-2 .content .check,
.land-1 .content .check {
  display: flex;
  margin: 20px 0;
}

.land-2 .content .check .box,
.land-1 .content .check .box {
  min-width: 19px;
  height: 19px;
  background: blue;
  margin-right: 20px;
  margin-top: 4px;
  border-radius: 3px;
}

.land-2 .content .check .box::before,
.land-1 .content .check .box::before {
  content: "\2714";
  display: inline-block;
  width: 11px;
  line-height: 18px;
  height: 15px;
  font-size: 14px;
  padding-left: 4px;
  padding-bottom: 3px;
  margin-right: 0.2em;
  vertical-align: top;
  color: white;
}

.land-1.alt .content .container {
  margin: 30px 0;
}

.land-2 .content .check p,
.land-1 .content .check p {
  font-size: 17px;
  font-weight: 400;
  margin: -2px 0 0;
}

.land-2 {
  padding: 25px 50px 50px;
}

.land-2.alt {
  padding: 85px 50px;
}

.land-2 .header {
  font-family: "Inter", sans-serif;
  font-size: 39px;
  font-weight: 700;
  width: 50%;
  line-height: 48px;
  margin: 50px 0;
}

.land-2 .container {
  display: flex;
}

.land-2 .img {
  width: 40%;
  margin-right: 100px;
}

.land-2 .content {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.land-2.alt .content {
  margin-left: 70px;
}

.land-2 .content .title {
  margin: 0 0 18px;
  font-weight: 600;
  line-height: 42px;
  font-family: "Inter", sans-serif;
}

.land-2 .content .title.sub {
  margin: 30px 0 13px;
}

.land-2 .content .step {
  margin: 7px 0;
}

.land-2.alt .content .step .title {
  display: flex;
  margin: 0;
}

.land-2 .content .step .title .no {
  color: blue;
  font-size: 30px;
  margin-right: 40px;
}

.land-2 .content .step .title p {
  margin: 0;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: #000;
  font-size: 28px;
}

.land-2 .content .step .text {
  display: flex;
}

.land-2 .content .step .text .bord {
  width: 2px;
  background: blue;
  margin: 0px 60px 0 15px;
}

.land-2 .content .step .text p {
  margin: 0 0 35px;
  width: 65%;
}

.land-2.alt .content .title {
  color: #000;
  margin: 0 0 35px;
  font-weight: 700;
  max-width: 500px;
}

.land-2.alt .content .title span {
  color: #1339ff;
}

.land-2.alt .content .links {
  margin: 70px 0 0;
}

.upcoming-info.review {
  width: unset;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
  color: black;
  background: none;
  padding: 70px 50px 95px;
  height: 430px;
}

.upcoming-info.review .icon {
  margin: 215px 0;
  border: 1px solid black;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: white;
}

.upcoming-info.review .icon i {
  position: relative;
  top: 7px;
  left: 14px;
  font-size: 17px;
  color: black;
  pointer-events: none;
}

.upcoming-info.review .container.hide {
  display: none;
}

.upcoming-info.review .container .img {
  width: 50%;
  margin-right: 10%;
}

.upcoming-info.review .container .img img {
  width: 100%;
  height: 430px;
}

.upcoming-info.review .container .img img.sm {
  display: none;
}

.upcoming-info.review .container .content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10%;
}

.upcoming-info.review .container .content p {
  white-space: normal;
  font-size: 25px;
  font-weight: 300;
  line-height: 40px;
}

.upcoming-info.review .container .content p.mar {
  margin-bottom: 130px;
}

.upcoming-info.review .container .content .title {
  font-size: 30px;
  margin: 0 0 20px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.upcoming-info.review .container .head {
  font-size: 30px;
  font-family: "Inter", sans-serif;
  color: black;
  font-weight: 700;
}

.upcoming-info.review .container .img .head {
  display: none;
}

.news {
  padding: 80px 50px;
}

.news .title {
  font-size: 39px;
  font-weight: 700;
  color: #464646;
  margin-left: 15px;
}

.news .container {
  margin: 40px 0 0;
  display: flex;
}

.news .container .card {
  margin: 0 15px;
  background: lightgray;
  padding: 25px;
  border-radius: 15px;
}

.news .container .card .icon {
  margin-bottom: 40px;
}

.news .container .card .icon .img {
  width: 35px;
  height: 40px;
  background: gray;
  display: inline-block;
}

.news .container .card .icon .img:nth-child(2) {
  margin-left: 14px;
}

.news .container .card .title {
  font-size: 25px;
  font-weight: 500;
  color: #464646;
  line-height: 33px;
  margin: 0 0 20px 0;
}

.news .container .card .desc {
  font-weight: 300;
  font-size: 17px;
  line-height: 26px;
  margin-bottom: 45px;
}

.news .container .card a {
  color: #464646;
  font-size: 15px;
  font-weight: 300;
}

.news .container .card a span {
  border-radius: 50%;
  border: 1px solid gray;
  margin-left: 14px;
  position: relative;
  top: 2px;
}

.news .container .card a span i {
  font-size: 11px;
  padding: 0px 6px;
  position: relative;
  top: -2px;
}

.cta-break {
  display: flex;
  margin-top: 50px;
}

.cta-break .img {
  width: 35%;
  position: relative;
  max-width: 460px;
}

.cta-break .img::before {
  content: " ";
  position: absolute;
  top: -13px;
  left: -65px;
  transform: rotate(135deg);
  border-top: 85px solid #fff;
  border-right: 85px solid transparent;
  border-left: 85px solid transparent;
  border-bottom: none;
}

.cta-break .img img {
  width: 100%;
  height: 100%;
}

.cta-break .img img.sm {
  display: none;
}

.cta-break .content {
  min-width: 65%;
  width: calc(100% - 460px);
  background: #bfecff;
  position: relative;
  overflow: hidden;
}

.cta-break .content::after {
  content: " ";
  position: absolute;
  bottom: -18px;
  right: -60px;
  transform: rotate(-45deg);
  border-top: 85px solid #fff;
  border-right: 85px solid transparent;
  border-left: 85px solid transparent;
  border-bottom: none;
}

.cta-break .content .title {
  font-size: 40px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 50px;
  width: 710px;
  margin: 65px 0 15px 10%;
}

.cta-break .content p {
  font-weight: 400;
  width: 600px;
  font-size: 19px;
  margin: 0 0 0 10%;
}

.cta-break .content a:hover {
  color: #1339ff;
  background: #bfecff;
}

.cta-break .content .links {
  margin: 45px 0 0 10%;
}

.subs {
  padding: 100px 50px;
  background: #ccd5ff;
}

.subs .container {
  display: flex;
}

.subs .container .content {
  width: 65%;
}

.subs .container .img {
  width: calc(35% - 75px);
  margin-left: 75px;
  border-radius: 20px;
  background: gray;
}

.res-cta h1,
.subs .container .content h1 {
  font-size: 40px;
  margin: 0;
  width: 550px;
}

.res-cta p,
.subs .container .content p {
  width: 700px;
  color: #464646;
  font-size: 20px;
  font-weight: 300;
}

.subs .container .content .input {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
}

.subs .container .content .input input {
  width: calc(50% - 65px);
}

.subs .container .content input {
  width: calc(100% - 42px);
  height: 30px;
  background: transparent;
  border: 1px solid #464646;
  padding: 5px 20px;
}

.subs .cta {
  margin-top: 35px;
  display: flex;
}

.subs .cta span {
  font-size: 35px;
  font-weight: 700;
  margin-right: 25px;
}

.subs .cta .img {
  width: 80px;
  height: 50px;
  background: gray;
  margin: 0 14px;
}

.blog .container .sale .mail label,
.subs label {
  margin: 45px 0 0;
  font-weight: 300;
  color: #464646;
  font-size: 15px;
}

.subs input[type="checkbox"] + label:before {
  margin-right: 10px;
}

.res-cta {
  padding: 75px 50px 30px;
}

.res-cta .container {
  display: flex;
  margin: 40px 0 80px;
}

.res-cta .container .img {
  width: calc(65% - 75px);
  margin-right: 75px;
  background: gray;
}

.res-cta .container .content {
  width: 35%;
}

.res-cta .container .content .title {
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
}

.res-cta .container .content p {
  width: unset;
}

.blog {
  padding: 100px;
}

.blog .title {
  font-size: 50px;
  font-weight: 700;
  margin: 25px 0 18px;
  line-height: 65px;
}

.blog .subtitle {
  font-size: 20px;
  font-weight: 300;
  color: #464646;
}

.blog .info {
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
}

.blog .info .top,
.blog .info .bottom {
  display: flex;
}

.blog .info .top div {
  font-weight: 300;
  margin: 0 10px;
}

.blog .info .top div:nth-child(1) {
  margin: 0 10px 0 0;
}

.blog .info .bottom div {
  font-size: 20px;
  font-weight: 300;
  padding: 0 15px;
}

.blog .info .bottom a {
  border: 2px solid black;
  color: black;
  border-radius: 50%;
  padding: 0 6px;
  margin: 0 5px;
}

.blog .info .bottom a:nth-child(4) {
  padding: 0 9px;
}

.blog .img {
  width: 100%;
  height: 450px;
  background: gray;
  margin: 0 0 35px;
}

.blog .container {
  display: flex;
}

.blog .container .content {
  word-break: break-word;
  white-space: normal;
  width: 65%;
}

.blog .container .sale {
  width: calc(35% - 60px);
  margin-left: 60px;
}

.blog .container .content p {
  font-size: 20px;
  font-weight: 300;
  margin: 0 0 20px;
  color: #000;
}

.blog .container .content h2 {
  font-size: 3.5rem;
  font-weight: 500;
}

.blog .container .content h3 {
  font-size: 2.5rem;
  font-weight: 500;
}

.blog .container .content h4 {
  font-size: 24px;
  font-weight: 500;
}

.blog .container .content li {
  font-size: 19px;
  font-weight: 500;
}

.blog .container .content blockquote {
  border-left: 7px solid #b6b6b6;
  padding-left: 30px;
  font-style: italic;
}

.blog .container .content blockquote p {
  font-size: 18px;
}

.blog .container .content table {
  margin: 0 auto;
}

.blog .container .content td {
  font-size: 19px;
  font-weight: 300;
  margin: 0 0 20px;
}

.blog .container .content th {
  font-weight: bold;
  font-size: 19px;
}

.blog .container .content img {
  width: 70%;
  margin: 0 auto;
  display: block;
}

.blog .container .content figcaption {
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  text-align: center;
}

.blog .container .content .image-style-side img {
  width: 50%;
  margin: 0 30px 5px 20px;
  float: right;
}

.blog .container .content .image-style-side figcaption {
  text-align: left;
}

.blog .container .content .mid {
  width: 80%;
  margin: 0 auto;
}

.blog .container .content a {
  color: #464646;
  text-decoration: underline;
  transition: color 0.5s ease;
}

.blog .container .content a:hover {
  color: #1339ff;
}

.blog .container .content figure {
  margin: 0 0 20px;
}

.blog .container .content ol,
.blog .container .content ul {
  margin: 20px 0;
}

.blog .container .content strong {
  color: black;
  font-weight: 700;
}

.blog .container .sale .title {
  font-size: 25px;
  line-height: unset;
  font-weight: 500;
  margin: 0;
}

.blog .container .sale hr {
  width: calc(100% + 100px);
  background: black;
  height: 2px;
  margin: 0;
  border: 0;
}

.blog .container .sale .category {
  margin: 40px 0;
}

.blog .container .sale .title {
  font-size: 24px;
  font-weight: 300;
  margin: 15px 0;
}

.blog .related p,
.blog .container .sale .item {
  font-size: 18px;
  font-weight: 300;
  margin: 5px 0;
}

.blog .container .sale .mail {
  background: black;
  color: white;
  padding: 50px 25px;
  margin: 50px 0 85px;
}

.blog .container .sale .mail .title {
  font-size: 30px;
  font-weight: 500;
  margin: 15px 0 0;
}

.blog .container .sale .mail .item {
  font-weight: 100;
  margin: 5px 0 30px;
  font-size: 17px;
  line-height: 22px;
}

.blog .container .sale .mail .wrapper {
  width: 100%;
  margin: 40px 0;
}

.blog .container .sale .mail .wrapper label {
  font-weight: 500;
}

.blog .container .sale .mail .wrapper input {
  padding-top: 12px;
  font-weight: 100;
  color: white;
}

.blog .container .sale .mail input[type="checkbox"] + label:before {
  border: 1px solid #fff;
  margin-right: 10px;
}

.blog .container .sale .mail label {
  color: #fff;
  margin: 0;
  font-weight: 100;
}

.blog .container .sale .mail .links {
  margin-top: 60px;
}

.blog .container .sale .mail .btn:hover {
  border: 1px solid #1339ff;
  background: #1339ff;
  color: #fff;
}

.blog .container .sale .mail .btn {
  font-size: 15px;
  line-height: 37px;
  font-weight: 100;
  border: 1px solid #fff;
  background: #fff;
  color: #000;
}

.blog .container .sale .fre,
.blog .container .sale .rec {
  margin-top: 40px;
}

.blog .container .sale .fre .title {
  margin: 15px 0 50px;
}

.blog .container .sale .fre h3 {
  font-size: 25px;
  font-weight: 500;
}

.blog .container .sale .fre p {
  margin-bottom: 35px;
}

.blog hr {
  margin: 50px 0;
}

.blog .share .title {
  font-size: 35px;
  margin: 10px 0;
}

.blog .share .social-links {
  display: flex;
  justify-content: space-around;
  width: 700px;
  margin: 30px auto;
}

.blog .share .social-links a {
  border: 1px solid black;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: black;
}

.blog .share .social-links i {
  color: white;
  font-size: 25px;
  padding: 18px 0px 0 18px;
}

.blog .share .social-links a:nth-child(1) i {
  padding-left: 19px;
}

.blog .share .social-links a:nth-child(3) i {
  padding-left: 22px;
}

.blog .share .social-links a:nth-child(4) i {
  padding-left: 20px;
}

.blog .related {
  margin-top: 100px;
}

.blog .related h1 {
  font-size: 35px;
}

.blog .related .container {
  justify-content: space-between;
}

.blog .related .container .blog-item {
  width: 45%;
}

.blog .related .container .blog-item .img {
  margin: 0;
}

.blog .related .container .blog-item .info {
  margin: 10px 0;
  justify-content: unset;
}

.blog .related .container .blog-item .info div {
  margin: 0 10px;
  font-weight: 100;
}

.blog .related .container .blog-item .date {
  margin: 0 10px 0 0;
}

.blog .related .intro {
  font-weight: 700;
  font-size: 30px;
  margin: 0;
  line-height: 40px;
}

.blog .related p {
  margin: 20px 0 30px;
}

.values {
  padding: 75px 50px;
  display: flex;
}

.values .clicks {
  width: 40%;
}

.values .clicks .title {
  font-size: 40px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  width: 390px;
  line-height: 50px;
  margin: 20px 0;
}

.values .clicks p {
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  width: 370px;
  margin: 0 0 50px;
}

.values .clicks a {
  padding: 13px 40px;
  background: #010c35;
  font-weight: 100;
  color: white;
  font-size: 14px;
}

.values .items {
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.values .items .val {
  width: calc(50% - 65px);
  background: #f2f2f2;
  padding: 20px;
  margin: 0 0 50px;
  position: relative;
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 30%);
}

.values .items .val::before {
  content: " ";
  position: absolute;
  top: -13px;
  left: -50px;
  transform: rotate(135deg);
  border-top: 65px solid #fff;
  border-right: 65px solid transparent;
  border-left: 65px solid transparent;
  border-bottom: none;
}

.values .items .val::after {
  content: " ";
  position: absolute;
  bottom: -18px;
  right: -45px;
  transform: rotate(-45deg);
  border-top: 60px solid #fff;
  border-right: 60px solid transparent;
  border-left: 60px solid transparent;
  border-bottom: none;
}

.values .val .icon {
  display: block;
  width: 50px;
  height: 50px;
  margin: 58px 0 38px;
}

.values .val .icon img {
  width: 75px;
}

.values .val:nth-child(3) .icon img {
  width: 50px;
}
.values .val:nth-child(1) .icon img {
  width: 55px;
}

.values .val .title {
  font-size: 25px;
  font-weight: 500;
}

.values .val p {
  font-weight: 300;
  font-size: 17px;
  margin: 8px 0;
}

.radio.test {
  display: flex;
  margin: 35px 0 15px;
  flex-direction: column;
}

.exam .radio.test {
  margin: 20px 0 0;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  content: "";
  height: 48px;
  width: 606px;
  margin: 8px 0px;
  border: 1px solid gray;
  border-radius: 7px;
  background: white;
  padding: 0;
  bottom: 13px;
  left: -4px;
  position: relative;
  transition: all ease 0.5s;
}

.exam input[type="checkbox"] + label:before,
.exam input[type="radio"] + label:before {
  height: 100%;
  padding: 8px 0;
  position: absolute;
}

.radio.test label:hover {
  background-color: #bfecff;
}

input[type="checkbox"]:checked + label:before,
input[type="radio"]:checked + label:before {
  background-color: #010c35;
  border: 1px solid gray;
}

input[type="checkbox"]:checked + label span,
input[type="radio"]:checked + label span {
  color: white;
}

.exam input[type="checkbox"]:checked + label span span,
.exam input[type="radio"]:checked + label span span {
  border: 2px solid white;
}

.exam input[type="checkbox"]:checked + label span span .circle,
.exam input[type="radio"]:checked + label span span .circle {
  width: 9px;
  height: 9px;
  background: white;
  border-radius: 50%;
  margin: 3px;
}

input[type="checkbox"] + label:active:before,
input[type="radio"] + label:active:before {
  transform: none;
}

input[type="checkbox"]:checked + label:hover:before,
input[type="radio"]:checked + label:hover:before {
  background-color: #010c35;
  border: 1px solid gray;
}

.radio.test label {
  height: 40px;
  width: 600px;
  margin: 8px 0px;
  border: 1px solid gray;
  border-radius: 7px;
  background: white;
  transition: all ease 0.5s;
}

.exam .radio.test label {
  height: unset;
  width: unset;
  border: none;
  background: none;
  position: relative;
  margin: 32px 0 0;
}

.exam .radio.test label:hover {
  background-color: unset;
}

.radio.test label:hover:before {
  background-color: #bfecff;
}

.radio.test label span {
  display: block;
  padding: 0;
  position: relative;
  bottom: 87px;
  left: 50px;
  font-size: 17px;
  font-weight: 300;
}

.radio.test label span.fit {
  width: 140px;
  height: 0px;
}

.exam .radio.test label span.fit {
  width: 540px;
  height: unset;
  white-space: normal;
  left: 55px;
  bottom: 31px;
  font-weight: 400;
  font-size: 16px;
}

.radio.test label span span {
  bottom: -25px;
  left: -27px;
  font-size: 20px;
  font-weight: 600;
}

.exam .radio.test label span span {
  width: 15px;
  height: 15px;
  border: 2px solid #010c32;
  border-radius: 50%;
  margin-top: 7px;
  left: -36px;
  bottom: 50%;
  transform: translateY(50%);
  position: absolute;
}

.onboard-form.test .content .input .label span.secr {
  text-decoration: underline;
}

.result {
  background-color: #f2f5ff;
}

.result .content {
  padding: 50px 125px 50px 150px;
  width: 80vw;
  max-width: 1100px;
  margin: 0 auto;
}

.result .content .card-cover {
  position: relative;
  margin: 135px 0 60px 290px;
  transform: translate(-50%, -50%);
  width: 340px;
  height: 262px;
}

.result .content .card-cover .card {
  overflow: hidden;
  color: white;
  border-radius: 12px;
  position: absolute;
  transform: translateY(0);
}

.result .content .card-cover .card.top {
  left: 0;
  top: 0;
  width: 850px;
  height: 370px;
  animation: onTop 12s ease forwards;
  z-index: 2;
}

.result .content .card-cover .card.mid {
  left: -30px;
  top: 30px;
  width: 910px;
  height: 310px;
  z-index: 1;
  animation: onMiddle 1s 0.5s ease forwards;
}

.result .content .other .item.hide,
.result .content .card-cover .card.hide {
  display: none;
}

.result .content .card-cover .card.bottom {
  left: -60px;
  top: 60px;
  width: 970px;
  height: 250px;
  z-index: 0;
  animation: onBottom 1s 0.5s ease forwards;
}

@keyframes onTop {
  5% {
    transform: translateY(-300px);
    z-index: 1;
    left: -30px;
    top: 30px;
    width: 910px;
    height: 310px;
  }
  10% {
    transform: translateY(0);
    z-index: 0;
    left: -60px;
    top: 60px;
    width: 970px;
    height: 250px;
  }
  100% {
    transform: translateY(0);
    z-index: 0;
    left: -60px;
    top: 60px;
    width: 970px;
    height: 250px;
  }
}
@keyframes onMiddle {
  0% {
    transform: translateY(0);
    z-index: 1;
    left: -30px;
    top: 30px;
    width: 910px;
    height: 310px;
  }
  100% {
    transform: translateY(0);
    z-index: 2;
    left: 0;
    top: 0;
    width: 850px;
    height: 370px;
  }
}
@keyframes onBottom {
  0% {
    transform: translateY(0);
    z-index: 0;
    left: -60px;
    top: 60px;
    width: 970px;
    height: 250px;
  }
  100% {
    transform: translateY(0);
    z-index: 1;
    left: -30px;
    top: 30px;
    width: 910px;
    height: 310px;
  }
}

.result .content .card-cover .card img {
  position: absolute;
  z-index: -1;
  width: 104%;
  height: 105%;
  top: -10px;
  left: -18px;
}

.result .content .card-cover .card img.sm {
  display: none;
}

.result .content .card-cover .card .title {
  font-weight: 500;
  font-size: 40px;
  text-align: center;
  margin: 84px 0 15px;
}

.result .content .card-cover .card .no {
  font-size: 34px;
  font-weight: 500;
  margin-left: 40px;
}

.result .content .card-cover .card .bottom {
  display: flex;
  justify-content: space-between;
  margin: 75px 40px;
  text-transform: uppercase;
}

.result .content .card-cover .card .bottom .read p,
.result .content .card-cover .card .bottom .text {
  font-weight: 100;
  font-size: 18px;
}

.result .content .card-cover .card .bottom .rate p {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
}

.result .content .card-cover .card .bottom .read p {
  margin: 0;
}

.result .content .card-cover .card .bottom .read .val {
  font-weight: 500;
  font-size: 30px;
}

.result .content .swich .intro {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  font-family: "Inter", sans-serif;
}

.result .content .swich p {
  margin: 7px 0 20px;
  font-size: 18px;
  font-weight: 300;
}

.result .content .other {
  display: flex;
}

.result .content .other .item {
  margin: 10px 20px;
  cursor: pointer;
}

.result .content .other .item .img {
  width: 90px;
  height: 90px;
  margin: auto;
  pointer-events: none;
}

.result .content .other .item .img img {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.result .content .other .item .text {
  margin-top: 20px;
  font-weight: 600;
  width: 100px;
  text-align: center;
  line-height: 21px;
  font-family: "Inter", sans-serif;
  pointer-events: none;
}

.result .content .info {
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
}

.result .content .info .title {
  font-weight: 600;
  font-size: 19px;
  text-transform: uppercase;
}

.result .content .info p {
  font-size: 17px;
  font-weight: 300;
  width: 450px;
  margin: 10px 0;
}

.result .content .info .contain {
  background: linear-gradient(to top left, #293456, #071235);
  color: white;
  padding: 20px 30px;
  width: 390px;
  margin: 40px 0 0;
  border-radius: 15px;
}

.result .content .info .contain .img {
  height: 120px;
  display: flex;
  justify-content: center;
  margin: 15px 0 30px;
}

.result .content .info .contain .img img {
  height: 100%;
}

.result .content .info .contain .title {
  font-weight: 400;
  text-transform: none;
}

.result .content .info .contain p {
  width: 100%;
  font-weight: 100;
  font-size: 18px;
  margin: 10px 0 20px;
}

.result .content .info .contain a {
  color: white;
  font-weight: 400;
  font-size: 14px;
}

.result .content .info .contain a i {
  border: 1px solid white;
  border-radius: 50%;
  color: black;
  background: white;
  font-size: 9px;
  margin-left: 10px;
  padding: 3px 4px;
  position: relative;
  top: -1px;
}

.details {
  margin: 25px 75px 90px;
}

.details.hide {
  display: none;
}

.details h1 {
  width: 50%;
  padding: 10px 0 35px;
  font-size: 35px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.details img {
  float: left;
  margin: 0 calc(45% - 400px) 30px 0;
  width: 400px;
}

.details.alt img {
  float: right;
  margin: 0 0 30px calc(45% - 400px);
}

.details h3 {
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  font-family: "Inter", sans-serif;
}

.details p.sm,
.details h3.sm {
  display: none;
}

.pro p,
.details p {
  font-size: 18px;
  font-weight: 300;
  line-height: 29px;
}

.details .content .link {
  color: #1339ff;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.5s ease;
  margin-left: 45%;
}

.details.alt .content .link {
  margin-left: 0;
}

.details .content p.hide,
.details .content .link.hide {
  display: none;
}

.details .content .link i {
  border: 1px solid;
  border-radius: 50%;
  padding: 2px 4px 1px;
  margin-left: 8px;
  font-size: 12px;
  line-height: 13px;
  pointer-events: none;
}

.details .content .link:hover {
  color: #010c35;
}

.pro {
  margin: 25px 75px 50px;
}

.pro.hide {
  display: none;
}

.pro h1 {
  margin: 0;
  font-size: 35px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.pro .container {
  display: flex;
  justify-content: space-between;
}

.pro .container .content {
  width: 50%;
}

.pro .intro {
  margin: 40px 0 0;
  font-size: 25px;
  color: #1339ff;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.pro .img .intro,
.pro .img p {
  display: none;
}

.pro .container .img {
  height: 670px;
  margin-right: 45px;
}

.pro .container .img img {
  height: 100%;
}

.pro .container .img img.sm {
  display: none;
}

.stats {
  padding: 60px 75px 50px;
  background: #bfecff;
  position: relative;
  overflow: hidden;
}

.stats.hide {
  display: none;
}

.stats .pie {
  display: flex;
  justify-content: space-between;
}

.stats .ava {
  width: calc(33.3% - 40px);
  background: white;
  color: #010c35;
  overflow: hidden;
  position: relative;
}

.stats .ava::before {
  content: " ";
  position: absolute;
  top: -13px;
  left: -50px;
  transform: rotate(135deg);
  border-top: 60px solid #bfecff;
  border-right: 60px solid transparent;
  border-left: 60px solid transparent;
  border-bottom: none;
}

.stats .ava::after {
  content: " ";
  position: absolute;
  bottom: -25px;
  right: -60px;
  transform: rotate(-45deg);
  border-top: 70px solid #bfecff;
  border-right: 70px solid transparent;
  border-left: 70px solid transparent;
  border-bottom: none;
}

.stats .circle-wrap {
  margin: 30px auto;
  width: 150px;
  height: 150px;
  background: #1339ff;
  border-radius: 50%;
}

.stats .circle-wrap .circle .mask,
.stats .circle-wrap .circle .fill {
  width: 150px;
  height: 150px;
  position: absolute;
  border-radius: 50%;
}

.stats .circle-wrap .circle .mask {
  clip: rect(0px, 150px, 150px, 75px);
}

.stats .circle-wrap .circle .mask .fill {
  clip: rect(0px, 75px, 150px, 0px);
  background-color: limegreen;
}

.stats .full .circle-wrap .circle .mask .fill {
  background-color: #1339ff;
}

.stats .circle-wrap .circle .mask.full,
.stats .circle-wrap .circle .fill {
  animation: fill ease-in-out 3s;
  transform: rotate(126deg);
}

@keyframes fill {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(126deg);
  }
}

.stats .circle-wrap .inside-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #fff;
  line-height: 100px;
  text-align: center;
  margin-top: 25px;
  margin-left: 25px;
  position: absolute;
  z-index: 1;
  font-weight: 700;
  font-size: 32px;
}

.stats .circle-wrap .inside-circle .hr {
  margin: 20px 0 0;
  line-height: 35px;
}

.stats .circle-wrap .inside-circle .hr span {
  line-height: 20px;
  font-size: 12px;
  display: block;
}

.stats .ava .title {
  margin: 40px 0 10px 60px;
  font-size: 23px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.stats .pie .ava p {
  width: 75px;
  text-align: center;
  margin: 0 auto 30px;
  padding: 4px 27px;
  background: #010c35;
  color: white;
  font-weight: 300;
}

.stats .graph {
  display: flex;
  justify-content: space-between;
  margin: 65px 0 20px;
}

.stats .graph .ava .title {
  margin: 40px 0 10px 50px;
}

.stats .graph .ava.rem .title {
  margin: 40px 0 50px 50px;
}

.stats .graph .ava p {
  width: 150px;
  text-align: center;
  margin: 18px auto;
  padding: 9px 27px;
  background: #010c35;
  color: white;
  font-weight: 300;
}

.stats .graph .ava p i {
  border: 2px solid;
  border-radius: 50%;
  padding: 5px;
  font-size: 9px;
  color: limegreen;
  margin-left: 10px;
  position: relative;
  top: -2px;
}

.stats .graph .ava p i.fa-times {
  color: red;
  padding: 4px 6px;
  font-size: 11px;
}

.stats .plot {
  width: calc(60% - 20px);
}

.stats .plot .area {
  margin: 0 40px 60px;
  height: 150px;
  position: relative;
}

.stats .plot .area .axis {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  font-size: 15px;
  height: 100%;
  justify-content: space-between;
}

.stats .plot .area .x.axis {
  flex-direction: row;
  bottom: -20px;
  width: calc(100% - 75px);
  left: 30px;
  height: unset;
}

.stats .plot .area .x.axis .line {
  width: 100%;
  height: 3px;
}

.stats .plot .area .axis .line {
  position: absolute;
  width: 3px;
  height: 100%;
  background: #010c35;
  right: -10px;
}

.stats .plot .area svg {
  width: calc(100% - 77px);
  position: absolute;
  display: block;
  height: 100%;
  left: 42px;
  stroke-width: 0;
}

.nota .container {
  margin: 25px 0 0;
  display: flex;
  flex-wrap: wrap;
}

.nota .container .item {
  width: calc(33.3% - 70px);
  margin: 20px 70px 20px 0;
}

.nota .container .item .img {
  display: flex;
}

.nota .container .item .img img {
  margin: 0 auto;
  width: 100%;
}

.nota .container .item .img img.sm {
  display: none;
}

.nota .container .item .name {
  font-size: 32px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin: 20px 0 10px;
  text-align: center;
}

.nota .container .item .name a {
  color: #000;
  transition: all ease 0.5s;
}

.nota .container .item .name a:hover {
  color: #1339ff;
}

.nota .container .item .about {
  padding: 0;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 300;
}

.referral {
  background: #e1e5ff;
  width: calc(100% - 90px);
  min-height: calc(100vh - 100px);
  padding: 70px 45px 50px;
  max-width: 1250px;
  margin: auto;
}

.referral.hide {
  display: none;
}

.fullpath .info,
.referral .info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
}

.fullpath .info .time,
.referral .info .time {
  border-radius: 7px;
  padding: 10px 40px;
  font-weight: 400;
  background: #c3cafc;
  position: relative;
  top: 30px;
}

.fullpath h1,
.referral h1 {
  font-size: 30px;
  font-weight: 600;
  margin: 0;
}

.referral .upcoming {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.referral .upcoming .session .gen {
  width: 170px;
  border-radius: 15px;
  padding: 20px;
  position: relative;
  text-align: center;
  margin-right: 30px;
  background: white;
}

.referral .upcoming .session .earn-cover {
  border-radius: 0;
  overflow-x: hidden;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  width: 100%;
  display: flex;
}

.quiz .container .cover::-webkit-scrollbar,
.referral .upcoming .session .earn-cover::-webkit-scrollbar {
  display: none;
}

.referral .upcoming .session .info {
  width: 176px;
  margin: 0 10px;
  position: relative;
  text-align: unset;
  display: block;
  padding: 20px 20px 16px;
}

.dashpath .upcoming .session .info .popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 1;
  transition: all ease 0.5s;
}

.dashpath .upcoming .session .info .popup.hide {
  display: none;
  opacity: 0;
}

.dashpath .info .popup .inner {
  width: 500px;
  height: 275px;
  border-radius: 15px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  color: black;
}

.dashpath .info .popup .inner .cancel {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  right: 18px;
  border: 2px solid black;
  cursor: pointer;
}

.dashpath .info .popup .inner .cancel span {
  width: 17px;
  height: 2px;
  background: black;
  display: block;
  border-radius: 10px;
  transform: rotate(45deg);
  position: absolute;
  top: 12px;
  left: 4px;
  pointer-events: none;
}

.dashpath .info .popup .inner .cancel span:nth-child(1) {
  transform: rotate(-45deg);
}

.dashpath .info .popup .inner .bulb {
  width: calc(100% - 100px);
  padding: 90px 50px 10px;
  display: flex;
  justify-content: center;
}

.dashpath .info .popup .inner .bulb div {
  width: 100px;
  height: 30px;
  border: 2px solid black;
  cursor: pointer;
  transition: all ease 0.5s;
}

.dashpath .info .popup .inner .bulb .intense:hover {
  background: #ff8080;
}

.dashpath .info .popup .inner .bulb .intense.active {
  background: #ff0000;
}

.dashpath .info .popup .inner .bulb .slow:hover {
  background: #80ff80;
}

.dashpath .info .popup .inner .bulb .slow.active {
  background: #00ff00;
}

.dashpath .info .popup .inner .bulb .regular:hover {
  background: #8080ff;
}

.dashpath .info .popup .inner .bulb .regular.active {
  background: #0000ff;
}

.dashpath .info .popup .inner .bulb .regular {
  border-left: 0;
  border-right: 0;
}

.dashpath .info .popup .inner .text {
  width: calc(100% - 170px);
  margin: auto;
  display: flex;
  justify-content: center;
}

.dashpath .info .popup .inner .text p {
  min-height: 20px;
  margin: 0px 20px;
  font-size: 14px;
  font-weight: 500;
}

.dashpath.referral .info .popup .inner .btn {
  text-align: center;
  width: 101px;
  padding: 8px 30px;
  min-height: 20px;
  justify-content: center;
  margin: 40px auto 0px;
  border: 1px solid #010c32;
  background: #010c32;
  color: white;
  display: flex;
  transition: all 0.5s ease;
}

.dashpath.referral .info .popup .inner .btn .hide {
  display: none;
}

.dashpath .info .popup .inner .btn:hover {
  background: white;
  color: #010c32;
}

.referral .upcoming .session .info.left {
  margin: 0 10px 0 0;
}

.referral .upcoming .session .gen .title {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: left;
}

.referral .upcoming .session .gen .title.load {
  width: 100%;
  height: 23px;
}

.referral .upcoming .session .btn.load {
  width: 70%;
  height: 20px;
}

.referral .upcoming .session .gen h1 {
  margin: 0;
  font-size: 29px;
  width: 100%;
}

.referral .upcoming .session .gen p {
  font-weight: 400;
  font-size: 13px;
  margin: 0;
  text-align: left;
  transition: all 0.2s ease;
  white-space: normal;
  width: 180px;
}

.referral .upcoming .session .gen p.code {
  cursor: pointer;
}

.referral .upcoming .session .gen p.link {
  font-size: 13px;
  margin: 0 0 18px;
  color: #0022cc;
  cursor: pointer;
  text-align: left;
  overflow-wrap: break-word;
}

.referral .upcoming .session .gen p:active i,
.referral .upcoming .session .gen p.code:active,
.referral .upcoming .session .gen p.link:active {
  transform: scale(0.9);
}

.referral .upcoming .session .gen p i {
  padding-left: 9px;
  cursor: pointer;
  font-size: 15px;
  pointer-events: none;
  transition: all 0.2s ease;
}

.referral .upcoming .session .gen .amount {
  margin: 0px 0 47px;
  font-size: 20px;
  font-weight: 500;
}

.referral .upcoming .session .gen .down {
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  width: 150px;
  white-space: normal;
}

.referral .upcoming .session .gen .cos {
  font-size: 20px;
  margin: 0 0 10px;
  font-weight: 500;
}

.referral .upcoming .session .btn {
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  width: 100%;
  margin-top: 16px;
}

.referral .upcoming .session .btn i {
  margin-left: 6px;
  font-size: 10px;
  color: white;
  background: #94a1ff;
  padding: 3px 5px;
  border-radius: 4px;
  pointer-events: none;
}

.referral .upcoming .session .gen .img {
  width: 100%;
  height: 55px;
  background-size: 1000px 640px;
  margin-bottom: 15px;
}

.referral .upcoming .session .gen img {
  height: 50px;
  width: 50px;
}

.dashpath .upcoming .session .gen p {
  min-height: 80px;
}

.dashpath .upcoming .session .gen .status {
  font-weight: 400;
  font-size: 13px;
}

.dashpath .upcoming .session .gen .rate {
  font-weight: 600;
  font-size: 17px;
  min-height: 60px;
}

.referral .upcoming .session {
  margin-right: 25px;
  width: calc(100% - 310px);
  max-width: 80%;
}

.referral .upcoming .session .btn.sm,
.referral .upcoming .session .cover.sm {
  display: none;
}

.referral .upcoming .session p {
  margin: 20px 0;
  font-weight: 500;
}

.referral .upcoming .session .deets {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
}

.dashpath .upcoming .session .deets {
  font-size: 14px;
  font-weight: 400;
}

.referral .upcoming .session .deets.icon {
  background: white;
  border-radius: 12px;
  margin: 18px 0;
  padding: 8px 0px;
  color: #5a4d4d;
}

.referral .upcoming .session .deets.icon.load {
  background: linear-gradient(to right, #f2f2f2 8%, #e6e6e6 38%, #f2f2f2 54%);
  height: 45px;
}

.referral .deets .badg {
  width: 100px;
  text-align: left;
  display: flex;
  padding-left: 20px;
}

.referral .deets.icon .badg {
  padding: 0 0 0 30px;
  background: none;
}

.referral .deets .targ {
  width: 52px;
}

.dashpath .deets .targ {
  width: 180px;
  padding: 0;
  background: none;
}

.referral .deets .achi {
  width: 75px;
  padding: 0;
  background: none;
}

.referral .deets .rema {
  width: 87px;
}

.dashpath .deets .rema {
  width: 60px;
  color: black;
}

.dashpath .deets.icon .rema {
  cursor: pointer;
  padding: 0;
  background: none;
  position: relative;
  transition: all ease 3s;
}

.dashpath .deets.icon .rema i {
  pointer-events: none;
}

.dashpath .deets.icon .rema:hover {
  left: 2px;
}

.referral .deets .badg img {
  width: 30px;
}

.referral .deets.icon .stat {
  padding: 6px 37px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 12px;
  width: 46px;
  text-align: center;
}

.dashpath .deets.icon .stat {
  padding: 6px 37px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 12px;
  width: 60px;
  text-align: center;
}

.referral .deets.icon .stat.done {
  color: #00ff3d;
  background: #acffc0;
}

.referral .deets.icon .stat.low {
  color: #ff0000;
  background: #ffacac;
}

.referral .deets.icon .stat.pend {
  background: #e1e5ff;
  color: #0022ff;
}

.referral .deets .stat {
  width: 120px;
  margin-right: 15px;
}

.dashpath .deets .stat {
  margin-right: 0;
}

.referral .upcoming .progress {
  background: #c3cafc;
  padding: 20px 25px;
  border-radius: 15px;
  width: 20%;
  min-width: 240px;
}

.referral .upcoming .session .earn-cover .wrappr {
  display: flex;
}

.referral .upcoming .pie {
  display: flex;
  align-items: center;
  justify-content: center;
}

.referral .upcoming .progress .title {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}

.referral .upcoming .progress .value {
  position: absolute;
  font-size: 25px;
  font-weight: 700;
  top: 135px;
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);
}

.referral .pie svg {
  z-index: 2;
}

.referral .pie svg.alt {
  z-index: 1;
  position: absolute;
}

.referral .pie svg.alt circle {
  stroke: white;
}

.referral .upcoming circle {
  transition: all 0.1s ease;
  transform: rotate(-90deg);
  stroke-linecap: round;
  transform-origin: 50% 50%;
}

.referral .upcoming .progress .stat {
  display: flex;
  background: white;
  border-radius: 14px;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
  padding: 10px 13px;
}

.referral .progress .stat .icon img {
  width: 45px;
  height: 45px;
}

.referral .upcoming .progress .stat .no {
  padding: 5px 8px;
  background: #c3cafc;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 600;
  color: #5a4d4d;
}

.referral .upcoming .progress .stat .desc {
  width: 70px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

.referral .upcoming .progress .monitor {
  margin: 10px 0 0;
  position: relative;
}

.dashpath .upcoming .progress .bar {
  width: 100%;
  height: 5px;
  background: white;
  border-radius: 15px;
}

.dashpath .upcoming .progress .bar .inner {
  height: 100%;
  border-radius: 15px;
  background: #1339ff;
  transition: all 0.1s ease;
}

.dashpath .upcoming .progress .eta {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  margin: 20px 0 0;
}

.dashpath .upcoming .progress .eta span {
  font-weight: 600;
}

.pathpage {
  display: flex;
  height: calc(100vh - 150px);
  padding: 75px;
  align-items: center;
  justify-content: center;
  min-height: 485px;
}

.assess .pathpage {
  height: calc(100vh - 184px);
  padding: 75px 75px 20px;
}

.assess .error {
  margin: auto;
  text-align: center;
  padding: 0 20px;
  color: red;
  font-weight: 500;
  font-size: 17px;
}

.pathpage .img img {
  margin: 0px 0px 0 50px;
}

.assess .pathpage .img img {
  margin: 0px 0px 0 100px;
}

.pathpage .content {
  width: 50%;
}

.pathpage .content .cover {
  display: flex;
  justify-content: space-around;
  background: #010c32;
  color: white;
  border-radius: 10px;
  padding: 10px;
}

.pathpage .content .cover img {
  display: block;
  width: 50px;
  margin-bottom: 5px;
}

.pathpage .img {
  width: 50%;
}

.pathpage .content img {
  display: none;
}

.pathpage .content .title {
  font-size: 40px;
  font-weight: 700;
  color: #010c32;
}

.pathpage .content .title.load {
  height: 104px;
  margin-bottom: 20px;
}

.pathpage .content p {
  font-size: 18px;
  font-weight: 400;
  color: #010c32;
}

.pathpage .content p.load {
  height: 35px;
  margin: 10px 0;
}

.pathpage .content p.down {
  margin-bottom: 30px;
}

.pathpage .content .info {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  line-height: 24px;
  justify-content: space-around;
}

.pathpage .content i {
  color: #010c32;
  padding-right: 10px;
}

.assess .btn .link,
.pathpage .content .link {
  font-weight: 400;
  background-color: #1339ff;
  width: 100px;
  color: white;
  text-align: center;
  padding: 10px 45px;
  margin-top: 35px;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.assess .btn .link {
  margin: 0;
}

.assess .btn .link.back {
  padding: 8px 48px;
  border: 1px solid #1339ff;
  background: white;
  color: #1339ff;
}

.assess .btn .link.back:hover {
  background: #1339ff;
  color: #fff;
}

.assess .btn .link.load {
  height: 25px;
}

.pathpage .btn .link.load,
.pathpage .content .link.load {
  width: 150px;
  height: 30px;
}

.assess .btn .link:hover,
.pathpage .content .link:hover {
  background-color: #010c32;
}

.assess .btn .link span.hide,
.pathpage .content .link span.hide {
  display: none;
}

.pathpage .image {
  width: 50%;
}

.assess .btn {
  width: calc(100vw - 167px);
  padding: 0 75px;
  display: flex;
  margin: 50px 0;
  justify-content: space-between;
}

.exam {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
}

.exam .content {
  padding: 100px;
  width: calc(100% - 200px);
  text-align: center;
}

.exam .content h1 {
  font-size: 36px;
  margin: 10px 0;
}

.exam .content p {
  font-size: 17px;
  color: #010c32;
  font-weight: 400;
}

.exam .carousel {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
}

.exam .container {
  min-width: calc(100% - 150px);
  padding: 30px 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quiz .container input[type="file"],
.quiz .container input[type="text"],
.exam .container input[type="text"] {
  width: 855px;
  border: 1px solid gray;
  border-radius: 10px;
  height: 50px;
  padding: 20px;
  font-size: 16px;
  margin: 25px 0;
}

.quiz .container input[type="file"],
.quiz .container input[type="text"] {
  margin: 0;
  width: calc(100% - 100px);
  height: 30px;
  padding: 15px;
}

.quiz .container input[type="file"] {
  background: white;
  color: transparent;
  cursor: pointer;
}

.quiz .container input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

.exam-outer {
  padding-top: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.exam-outer h1 {
  font-size: 25px;
  color: #010c32;
  font-family: "Inter", sans-serif;
  margin: 50px 0;
}

.exam-outer .progress {
  display: flex;
  position: relative;
  margin: 10px 0 30px;
}

.exam-outer .progress .point {
  display: flex;
  align-items: center;
}

.exam-outer .progress .point .box {
  background: #dbdde3;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  transition: all 0.5s ease;
}

.exam-outer .progress .point.semi .box,
.exam-outer .progress .point.full .box {
  background: #010c32;
}

.exam-outer .progress .point .box i {
  display: none;
  color: white;
  font-size: 11px;
  position: relative;
  left: 10px;
}

.exam-outer .progress .point.full .box i {
  display: inline-block;
}

.exam-outer .progress .point .line {
  height: 1px;
  width: 160px;
  background: #dbdde3;
  transition: all 0.5s ease;
}

.exam-outer .progress .point .line .inner {
  height: 1px;
  width: 0;
  background: #010c32;
  transition: all 0.5s ease;
}

.exam-outer .progress .point.full .line .inner {
  width: 160px;
}

.exam-outer .progress .point .text {
  position: absolute;
  top: 30px;
  padding-left: 3px;
}

.exam .container p {
  font-size: 18px;
  font-weight: 400;
  color: #010c32;
  margin: 0;
  white-space: normal;
  max-width: 900px;
}

.exam-outer .btn {
  display: flex;
  width: calc(100% - 150px);
  justify-content: space-between;
  padding: 0 75px 30px;
}

.exam-outer .btn.pro {
  justify-content: flex-end;
}

.exam-outer .btn .link {
  width: 150px;
  padding: 11px 20px;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  border: 1px solid #010c32;
  color: #fff;
  background: #010c32;
  transition: all ease 0.5s;
}

.exam-outer .btn .link span {
  pointer-events: none;
}

.exam-outer .btn .link span.hide {
  display: none;
}

.exam-outer .btn .link:hover {
  border: 1px solid #010c32;
  background: #fff;
  color: #010c32;
}

.exam-outer .btn .link.back {
  border: 1px solid #1339ff;
  color: #1339ff;
  background: white;
}

.exam-outer .btn .link.back:hover {
  border: 1px solid #1339ff;
  color: #fff;
  background: #1339ff;
}

.exam-outer .popup {
  height: 100vh;
  width: 100vw;
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
}

.exam-outer .popup.hide {
  display: none;
}

.exam-outer .popup .inner {
  height: 180px;
  width: 500px;
  padding: 50px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 20px;
  text-align: center;
}

.exam-outer .popup .inner .header {
  font-weight: 500;
  font-size: 20px;
  font-family: "Inter", sans-serif;
}

.exam-outer .popup .inner p {
  font-size: 15px;
  margin: 15px 0 60px;
}

.exam-outer .popup .inner .btn {
  width: 100%;
  justify-content: space-around;
  padding: 0;
}

.exam-outer .popup .inner .btn .link {
  width: 120px;
  padding: 8px 20px;
}

.section {
  padding: 75px 50px 50px;
  color: white;
  background: #e1e5ff;
}

.section.hide {
  display: none;
}

.section .up {
  background: #010c32;
  position: absolute;
  width: calc(100vw - 197px);
  left: 0px;
  height: 580px;
  clip-path: polygon(0% 0, 100% 0%, 100% 400px, 0 100%);
  top: 0;
  z-index: 0;
}

.section .title {
  font-size: 28px;
  font-weight: 500;
  text-align: center;
  margin: 10px 0 35px;
  position: relative;
  z-index: 1;
}

.section .structure {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.section .structure .item {
  padding: 15px 40px;
  font-size: 13px;
  font-weight: 600;
  background: white;
  color: #010c32;
  cursor: pointer;
  margin: 15px 35px;
  text-align: center;
  transition: all ease 0.5s;
}

.section .structure .item:hover {
  background: #ccd5ff;
}

.section .structure .item.active {
  background: #1339ff;
  color: white;
}

.byte .card {
  background: none;
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 30%);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease;
}

.byte .card.load {
  height: 300px;
  background: linear-gradient(to right, #e6e6e6 8%, #ccc 38%, #e6e6e6 54%);
}

.byte .card:hover {
  transform: scale(0.96);
}

.byte .card .content {
  background: white;
}

.byte .card .cover {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  transition: all ease 0.4s;
}

.byte .card:hover .cover {
  background: rgba(0, 0, 0, 0.3);
}

.byte .card .cover i {
  font-size: 40px;
  color: #010c32;
}

.byte .card .img {
  width: 275px;
  height: 100px;
  overflow: hidden;
}

.byte .card .img img {
  width: 275px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.byte .card .desc {
  white-space: normal;
  padding: 0 20px;
  color: #5c6378;
  font-weight: 300;
  background: white;
}

.byte .card .title {
  text-align: left;
  padding: 18px 15px 13px;
  background: white;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: #010c32;
  margin: 0;
  white-space: normal;
}

.byte .card .btn {
  display: flex;
  justify-content: space-between;
  padding: 30px 20px 15px;
  align-items: center;
  background: white;
}

.byte .card .btn .mark {
  padding: 14px 23px;
  border: 1px solid #010c32;
  color: #010c32;
  border-radius: 25px;
  font-size: 11px;
  margin-right: 10px;
  font-weight: 500;
  transition: all 0.5s ease;
}

.byte .card .btn .mark.active,
.byte .card .btn .mark:hover {
  background: #010c32;
  color: #fff;
}

.byte .card .btn .mark i {
  padding-right: 6px;
  pointer-events: none;
}

.byte .card .btn p {
  color: #5c6378;
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}

.section .sixthpage {
  background: none;
  padding: 50px 50px 0;
  position: relative;
}

.section .sixthpage .nav-prev {
  left: -8px;
  color: #010c32;
  background: white;
  margin-top: 140px;
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 30%);
}

.section .sixthpage .nav-next {
  right: -24px;
  color: #010c32;
  background: white;
  margin-top: -217px;
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 30%);
}

.section .sixthpage .cata-sub-nav li:nth-child(1) {
  margin: 0 15px 0 0;
}

.section .sixthpage .cata-sub-nav {
  padding: 25px 15px 0px;
}

.section .sixthpage .cata-sub-nav ul {
  padding-bottom: 10px;
}

.sixthpage .tag.dark {
  color: #010c32;
  font-weight: 500;
  margin-top: 35px;
}

.section .sixthpage .cata-sub-nav li {
  margin: 0 15px;
}

.section .link {
  background: #1339ff;
  width: 180px;
  text-align: center;
  margin: 75px 0 30px calc(100% - 205px);
  padding: 13px 20px;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.5s ease;
}

.section .link:hover {
  background: #010c32;
}

.quiz {
  padding: 75px;
  background: #e1e5ff;
}

.quiz.og {
  min-height: 100vh;
}

.quiz.hide {
  display: none;
}

.quiz .title {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  color: #010c32;
  font-family: "Inter", sans-serif;
}

.quiz .container {
  margin: 60px 0;
}

.quiz .container p {
  font-size: 21px;
  font-weight: 400;
  color: #010c32;
  margin: 0 0 0 20px;
}

.quiz .container .intro {
  display: flex;
  margin-bottom: 35px;
}

.quiz .container .intro span {
  font-size: 21px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  margin-top: 3px;
}

.quiz .container .cover {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
}

.quiz .container .cover .inner {
  display: flex;
}

.quiz .container .cover img {
  max-height: 300px;
  margin: 0px 20px 35px;
}

.quiz .container p {
  font-size: 21px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.quiz .container p.load {
  width: 100%;
  height: 50px;
}

.quiz .container .form-control.load {
  width: calc(100% - 20px);
  height: 25px;
}

.quiz .container .input {
  margin-left: 30px;
  position: relative;
}

.quiz .container .input .file {
  position: absolute;
  top: calc(50% - 13px);
  left: 15px;
  color: gray;
  font-size: 15px;
  font-weight: 300;
  pointer-events: none;
}

.quiz .container .form-control {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.quiz .container .form-control span {
  width: calc(100% - 2em);
}

.quiz .container .form-control + .form-control {
  margin-top: 12px;
}

.quiz .container .form-control:focus-within {
  color: #010c32;
}

.quiz .container input[type="checkbox"],
.quiz .container input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #010c32;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #010c32;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s ease;
}

.quiz .container input[type="checkbox"]::before,
.quiz .container input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #010c32;
  background-color: #010c32;
}

.quiz .container input[type="checkbox"]:checked::before,
.quiz .container input[type="radio"]:checked::before {
  transform: scale(1);
}

.quiz .btn {
  width: 100px;
  text-align: center;
  padding: 8px 20px;
  background: #010c32;
  color: white;
  border-radius: 7px;
  cursor: pointer;
  margin: auto;
  transition: all ease 0.5s;
}

.quiz .btn:hover {
  background: #02114b;
}

.quiz .btn.hide {
  display: none;
}

.quiz .error {
  text-align: center;
  color: red;
  margin-bottom: 15px;
}

.quiz .box {
  width: 100px;
  margin: auto;
}

.quiz .loader {
  margin: 0 33px;
}

.quiz .score .details {
  margin: 0;
  display: flex;
  align-items: center;
}

.quiz .score .details .content h1 {
  width: 100%;
  margin: 0;
  font-family: "Poppins", sans-serif;
  line-height: 40px;
  font-size: 35px;
}

.quiz .score .details .content p {
  margin: 0;
}

.quiz .score .details .content .text {
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  margin-bottom: 15px;
}

.quiz .score .results {
  display: flex;
  margin: 30px auto;
  background: #010c32;
  color: white;
  width: fit-content;
  padding: 0px 5px;
  border-radius: 15px;
}

.quiz .score .results .info {
  padding: 20px 10px;
  text-align: center;
  border-right: 1px solid white;
}

.quiz .score .results .info .val {
  margin-bottom: 10px;
}

.quiz .score .results .info.last {
  border: 0;
}

.quiz .score .btn {
  margin-left: calc(100% - 180px);
  padding: 8px 35px;
}

.upcoming .learn {
  margin: 0 0px 30px 0;
  display: flex;
  justify-content: space-between;
  background: white;
  border-radius: 20px;
  padding: 20px 30px;
}

.upcoming .learn .wrappr {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.upcoming .learn .content {
  width: 35%;
}

.upcoming .learn .ref {
  display: none;
}

.upcoming .learn .box {
  width: 40%;
  background: #e1e5ff;
  padding: 15px;
  margin: 9px 0;
  border-radius: 15px;
}

.upcoming .learn .box.load {
  background: linear-gradient(to right, #e1e5ff 8%, #ccd3ff 38%, #e1e5ff 54%);
}

.upcoming .learn .box .title {
  font-size: 15px;
  margin-bottom: 8px;
  font-weight: 400;
}

.upcoming .session .ref .title,
.upcoming .session .schedule .up .title,
.upcoming .learn .content .title {
  font-size: 15px;
  margin-bottom: 13px;
  font-weight: 500;
  color: black;
}

.upcoming .learn .content .btn.load,
.upcoming .learn .content .title.load {
  width: 168px;
  height: 23px;
}

.upcoming .session .ref .desc.ref,
.upcoming .session .ref .icon img.ref,
.upcoming .session .ref .icon.ref,
.upcoming .session .ref .title.ref {
  padding: 0;
}

.upcoming .learn .content .icon {
  width: 60px;
  height: 60px;
}

.upcoming .learn .content .icon img {
  width: 55px;
}

.upcoming .session .ref .icon,
.upcoming .learn .box .icon {
  width: 60px;
  height: 50px;
}

.upcoming .session .ref .icon img,
.upcoming .learn .box .icon img {
  width: 55px;
}

.upcoming .learn .content .desc {
  margin: 10px 0;
  font-size: 14px;
  min-height: 70px;
  color: black;
}

.upcoming .learn .content .desc.load {
  width: 168px;
  height: 70px;
}

.upcoming .session .ref .desc {
  margin: 16px 0;
  font-size: 13px;
  color: black;
}

.upcoming .learn .box .desc {
  margin: 10px 0;
  font-size: 12px;
}

.upcoming-info .container .btn,
.upcoming .session .ref .btn,
.upcoming .learn .btn {
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  color: black;
}

.upcoming .learn .btn.path {
  width: 100%;
}

.upcoming .session .ref .btn {
  margin: 20px 0 0;
  padding: 0;
}

.upcoming .learn .content .btn {
  margin-top: 20px;
}

.upcoming-info .container .btn i,
.dashment .become .btn i,
.dashment .info .meeting .btn i,
.dashment .group .btn i,
.dashment .upcoming .track .info i,
.upcoming .session .ref .btn i,
.upcoming .learn .btn i {
  margin-left: 6px;
  font-size: 10px;
  color: white;
  background: #94a1ff;
  padding: 3px 5px;
  border-radius: 4px;
  pointer-events: none;
}

.dashment .upcoming .track .info i {
  cursor: pointer;
  pointer-events: unset;
}

.referral .upcoming p.sm,
.referral .upcoming .progre.sm,
.upcoming .session p.sm {
  display: none;
}

.upcoming .session .path {
  width: calc(100% - 500px);
}

.upcoming .session .indir {
  width: 190px;
}

.upcoming .session .schedule {
  width: 225px;
  margin: 0;
  background: #c3cafc;
  padding: 25px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upcoming .session .ref {
  margin: 0 5px 25px;
  background: #fff;
  padding: 20px;
  border-radius: 15px;
}

.upcoming .session .schedule .up .card {
  display: flex;
  background: white;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  margin: 15px 0;
}

.upcoming .up .card .content {
  width: 75%;
}

.upcoming .up .card .content .title {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}

.upcoming .up .card .content .desc {
  font-size: 14px;
  line-height: 17px;
}

.upcoming .up .card .date {
  background: #c3cafc;
  padding: 6px 9px;
  border-radius: 9px;
  height: 35px;
}

.upcoming .up .card .date .day {
  text-align: center;
  font-weight: 600;
  font-size: 19px;
  line-height: 17px;
}

.upcoming .up .card .date .no {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
}

.upcoming .query .bulb {
  height: 60px;
  width: 60px;
  background: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
  top: 30px;
}

.upcoming .query .bulb .inner {
  height: 40px;
  width: 40px;
  background: #c3cafc;
  border-radius: 50%;
}

.upcoming .query .bulb .inner img {
  width: 34px;
  height: 34px;
  position: relative;
  top: 3px;
  left: 3px;
}

.upcoming .query .content {
  background: white;
  border-radius: 12px;
  padding: 40px 25px 25px;
}

.upcoming .query .content .title {
  font-size: 13px;
  text-align: center;
  font-weight: 300;
  margin-bottom: 15px;
}

.upcoming .query .content .message {
  background: #e1e5ff;
  color: #5a4d4d;
  font-size: 15px;
  width: 150px;
  padding: 5px 12px;
  border-radius: 5px;
  margin: 25px auto 0;
  cursor: pointer;
  transition: all 0.5s ease;
}

.upcoming .query .content .message:hover {
  background: #c3cafc;
}

.dash-container .dashment {
  padding: 40px;
  max-width: 1250px;
  margin: 0 auto;
}

.dash-container .dashment.hide {
  display: none;
}

.dash-container .dashment .info {
  display: flex;
  justify-content: space-between;
  margin: 25px 0 0;
}

.dashment .info h1 {
  margin: 0;
  font-weight: 600;
  font-size: 30px;
}

.dashment .info .time {
  border-radius: 7px;
  padding: 10px 40px;
  font-weight: 400;
  background: #c3cafc;
  position: relative;
  top: 34px;
}

.dashment .upcoming {
  display: flex;
  margin-top: 45px;
}

.dashment .upcoming .track {
  width: 20%;
  min-width: 205px;
  background: #c3cafc;
  border-radius: 10px;
  padding: 20px 25px;
}

.dashment .upcoming .track.sm {
  display: none;
}

.dashment .upcoming .track .titl {
  font-size: 15px;
  margin-bottom: 8px;
  font-weight: 500;
}

.dashment .upcoming .track .info {
  margin: 15px 0;
  align-items: center;
  background: white;
  padding: 20px;
  border-radius: 12px;
}

.dashment .upcoming .track .info p {
  margin: 0;
  font-weight: 500;
  font-size: 15px;
  color: black;
  line-height: 18px;
}

.dashment .upcoming .session {
  display: flex;
  flex-direction: column;
  margin-right: 25px;
  width: calc(80% - 75px);
}

.dashment .upcoming .session p.sm {
  display: none;
}

.dashment .upcoming .session .disg {
  display: none;
}

.dashment .upcoming .session .info {
  margin: 0;
  width: 100%;
}

.dashment .upcoming .session .info .nil {
  background: white;
  border-radius: 10px;
  padding: 25px;
  display: flex;
  width: 492px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.dashment .upcoming .session .become,
.dashment .upcoming .session .group {
  width: 20%;
  background: white;
  border-radius: 10px;
  padding: 16px;
}

.dashment .become .title,
.dashment .info .meeting .content .title,
.dashment .info .title {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 5px;
}

.dashment .become img,
.dashment .group img {
  width: 55px;
}

.dashment .become p,
.dashment .info p {
  font-size: 13px;
  font-weight: 400;
  margin: 10px 0;
}

.dashment .become .btn,
.dashment .info .meeting .btn,
.dashment .group .btn {
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  color: #000;
  line-height: 17px;
}

.dashment .become .btn {
  font-size: 12px;
  font-weight: 600;
}

.dashment .info .btn {
  font-size: 14px;
}

.dashment .upcoming .session .info .meeting {
  margin-right: 20px;
  background: white;
  border-radius: 10px;
  padding: 19px;
  display: flex;
  width: calc(75% - 50px);
}

.upcoming-info.sing .container .content,
.dashment .info .meeting .content {
  width: 70%;
}

.upcoming-info .container .content {
  width: calc(65% - 10px);
}

.upcoming-info .container .content .desc,
.dashment .info .meeting .content .desc {
  font-size: 14px;
  font-weight: 400;
  width: 95%;
  white-space: normal;
  margin-bottom: 10px;
}

.upcoming-info .container .content .desc {
  margin-bottom: 5px;
}

.dashment .meeting .content .deets {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}

.upcoming-info .container .content .deets {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.upcoming-info .content .deets .block,
.dashment .meeting .content .deets .block {
  background: #e1e5ff;
  border-radius: 10px;
  padding: 10px;
  margin-right: 10px;
  width: 90px;
}

.upcoming-info .content .deets .block .title,
.dashment .content .deets .block .title {
  font-size: 13px;
  margin-bottom: 5px;
}

.upcoming-info .content .deets .block img,
.dashment .content .deets .block img {
  width: 25px;
  margin-left: 3px;
}

.upcoming-info .content .deets .block .day,
.dashment .content .deets .block .day {
  font-size: 11px;
  line-height: 14px;
  font-weight: 500;
}

.upcoming .track .query .content {
  padding: 40px 15px 25px;
}

.upcoming-info .container .img,
.dashment .info .meeting .img {
  background: #e1e5ff;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: unset;
  justify-content: space-between;
}

.upcoming-info .container .img {
  margin-right: 10px;
}

.dashment .info .meeting .img .up,
.upcoming-info .container .img .up {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.upcoming-info .container .img img,
.dashment .info .meeting .img img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0 auto;
}

.upcoming-info .container .img .name,
.dashment .info .meeting .img .name {
  font-size: 13px;
  text-align: center;
  line-height: 15px;
  margin: 10px 0;
}

.dashment .info .meeting .img .name a,
.upcoming-info .container .img .name a {
  color: #000;
}

.upcoming-info .container .img .title,
.dashment .info .meeting .img .title {
  font-size: 12px;
  text-align: center;
  line-height: 13px;
  margin: 5px 0 25px;
}

.dashment .upcoming .session .hist {
  display: flex;
  margin: 30px 0;
}

.fullpath {
  width: calc(100% - 90px);
  min-height: calc(100vh - 100px);
  padding: 70px 45px 50px;
}

.fullpath.hide {
  display: none;
}

.fullpath ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.fullpath ul li {
  display: inline-table;
  margin: 20px calc((100% - 810px) / 6);
}

.fullpath ul li .byte {
  width: 270px;
}

.home-hero {
  background: #f8f9ff;
}

.firstland {
  height: calc(100vh - 53px);
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 75px;
  border-radius: 0 0 60px 60px;
  background: no-repeat center/cover
    url(https://dqe80zoqbuyqe.cloudfront.net/root/New+Iteration-07.png);
}

.firstland h1 {
  font-size: 60px;
  width: 800px;
  line-height: 80px;
  text-align: center;
  margin: 0;
}

.firstland h1 span {
  color: #1339ff;
  position: relative;
}

.firstland h1 span::after {
  content: "";
  position: absolute;
  background: #1339ff;
  height: 3px;
  bottom: 6px;
  left: 0;
  animation: move ease 0.5s 1.5s forwards;
}

.firstland h1 span:nth-child(2):after {
  animation: move ease 0.5s 2s forwards;
}

.firstland p {
  width: 600px;
  text-align: center;
  font-size: 19px;
  font-weight: 400;
}

.firstland p.sm {
  display: none;
  font-size: 14px;
}

.thirdland .content .btn,
.firstland .btn {
  font-size: 18px;
  color: #1339ff;
  border: 2px solid #1339ff;
  border-radius: 15px;
  padding: 13px 43px;
  margin-top: 20px;
  transition: all 0.5s ease;
}

.thirdland .content .btn:hover,
.firstland .btn:hover {
  background: #fff;
}

@keyframes move {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.secondland {
  padding: 75px 75px 30px;
}

.secondland .title {
  font-size: 35px;
  font-weight: 600;
}

.secondland p {
  font-size: 20px;
  font-weight: 300;
  width: 600px;
  margin: 8px 0;
  line-height: 27px;
}

.seventhland .faq,
.secondland .ben-cov {
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  max-width: 2100px;
}

.seventhland .faq::-webkit-scrollbar,
.secondland .ben-cov::-webkit-scrollbar {
  display: none;
}

.seventhland .faq .inner,
.secondland .ben-cov .inner {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}

.secondland .ben-cov .card {
  width: calc(25% - 80px);
  border: 2px solid black;
  border-radius: 15px;
  padding: 20px;
  background: white;
}

.secondland .ben-cov .card .icon {
  display: flex;
  justify-content: center;
}

.secondland .ben-cov .card .icon img {
  width: 65px;
}

.secondland .ben-cov .card h3 {
  white-space: normal;
  font-size: 17px;
  text-align: center;
  min-height: 46px;
}

.secondland .ben-cov .card p {
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  white-space: normal;
}

.thirdland {
  margin: 100px 75px;
  border: 3px solid black;
  display: flex;
  border-radius: 20px;
  background: white;
}

.thirdland .img {
  width: 40%;
  margin: 20px;
}

.thirdland .img img {
  width: 100%;
}

.thirdland .img img.sm {
  display: none;
}

.thirdland .content {
  width: calc(60% - 110px);
  margin: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.thirdland .content h2 {
  font-size: 38px;
  line-height: 47px;
  margin: 0 0 15px 0;
}

.thirdland .content h2 span {
  color: #1339ff;
}

.thirdland .content p {
  font-size: 17px;
  margin: 28px 0;
}

.thirdland .content .more {
  display: none;
}

.thirdland .content .btn {
  margin: 20px 0;
}

.thirdland.alt {
  flex-direction: row-reverse;
}

.thirdland .content .cover {
  margin: 50px 0 30px;
}

.fourthland {
  margin: 75px;
}

.fourthland .title {
  font-size: 38px;
  font-weight: 600;
  margin: 0 0 30px;
}

.fourthland .pri-cov {
  display: flex;
  justify-content: space-between;
}

.fourthland .pri-cov .card {
  width: calc(50% - 150px);
  border: 2px solid black;
  border-radius: 25px;
  padding: 30px 50px 50px;
  background: white;
}

.fourthland .pri-cov .card .title {
  font-size: 28px;
  font-weight: 500;
  margin: 0 0 10px;
}

.fourthland .pri-cov .card .price {
  font-size: 46px;
  font-weight: 700;
  line-height: 50px;
}

.fourthland .pri-cov .card p {
  margin: 8px 0 32px;
  font-size: 18px;
}

.fourthland .pri-cov .card a {
  margin: 0 0;
  border: 2px solid black;
  padding: 8px 30px;
  border-radius: 10px;
  color: black;
  font-weight: 500;
}

.fifthland {
  padding: 75px;
  background: #010c32;
  border-radius: 60px;
  color: white;
}

.fifthland .title {
  font-size: 35px;
  font-weight: 600;
}

.seventhland .arrow,
.secondland .arrow,
.sixthland .arrow,
.fifthland .arrow {
  position: absolute;
  right: 75px;
  width: 70px;
  height: 30px;
  cursor: pointer;
}

.seventhland .arrow,
.secondland .arrow {
  display: none;
}

.seventhland .arrow span,
.secondland .arrow span,
.sixthland .arrow span,
.fifthland .arrow span {
  background: white;
  display: block;
  height: 4px;
  border-radius: 10px;
  transition: all ease-in-out 0.5s;
  pointer-events: none;
  position: absolute;
}

.seventhland .arrow span:nth-child(1),
.secondland .arrow span:nth-child(1),
.sixthland .arrow span:nth-child(1),
.fifthland .arrow span:nth-child(1) {
  width: 25px;
  right: -1px;
  top: 6px;
  position: absolute;
  transform: rotate(35deg);
}

.seventhland .arrow span:nth-child(2),
.secondland .arrow span:nth-child(2),
.sixthland .arrow span:nth-child(2),
.fifthland .arrow span:nth-child(2) {
  width: 70px;
  left: 0px;
  top: 13px;
}

.seventhland .arrow span:nth-child(3),
.secondland .arrow span:nth-child(3),
.sixthland .arrow span:nth-child(3),
.fifthland .arrow span:nth-child(3) {
  width: 25px;
  position: absolute;
  transform: rotate(-35deg);
  top: 20px;
  right: -1px;
}

.seventhland .arrow span,
.secondland .arrow span,
.sixthland .arrow span {
  background: black;
}

.sixthland .rev-cov,
.fifthland .cata-sub-nav {
  padding: 25px 15px;
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  max-width: 2100px;
}

.sixthland .rev-cov::-webkit-scrollbar,
.fifthland .cata-sub-nav::-webkit-scrollbar {
  display: none;
}

.sixthland .rev-cov .inner,
.fifthland .cata-sub-nav ul {
  margin: 0;
  display: flex;
  -webkit-padding-start: 0px;
}

.fifthland .cata-sub-nav ul .card .img {
  width: 300px;
  position: relative;
  height: 330px;
  border: 3px solid white;
  border-radius: 20px;
  overflow: hidden;
  margin: auto;
}

.fifthland ul .card .img img {
  width: 100%;
  position: absolute;
  left: 0;
  transform: translateY(-50%);
  top: 50%;
  min-height: 100%;
}

.fifthland ul .card .img .name {
  position: absolute;
  background: #1339ff;
  border: 2px solid white;
  padding: 5px 20px 5px 10px;
  font-weight: 300;
  width: 165px;
  text-align: center;
  top: 66px;
  left: -83px;
  transform: rotate(-90deg);
}

.fifthland ul .card .title {
  font-size: 26px;
  font-weight: 400;
  margin: 25px auto 5px;
  text-align: center;
}

.fifthland ul .card .company {
  font-size: 22px;
  text-align: center;
  font-weight: 500;
}

.fifthland ul .card a {
  color: white;
  border: 2px solid white;
  border-radius: 15px;
  padding: 8px 20px;
  margin: 10px auto;
}

.fifthland ul .card {
  margin: 0 20px;
}

.fifthland ul .card:nth-child(1) {
  margin: 0 20px 0 0;
}

.fifthland .service-cards {
  margin: 50px 0 0;
}

.fifthland ul .card .cover {
  margin: 25px 20px 0;
  text-align: center;
}

.sixthland {
  padding: 75px 75px 0;
}

.sixthland .title {
  font-size: 35px;
  font-weight: 600;
}

.sixthland .rev-cov {
  margin: 45px 0 0;
  padding: 25px 15px 150px;
}

.sixthland .rev-cov .card {
  width: 400px;
  position: relative;
  background: white;
  border: 3px solid black;
  border-radius: 20px 20px 0 0;
  height: 400px;
  position: relative;
  margin: 0 30px;
  color: white;
}

.sixthland .rev-cov .card:nth-child(1) {
  margin: 0 30px 0 0;
}

.sixthland .rev-cov .card .card-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  position: relative;
  border: 3px solid black;
  height: 350px;
  top: 10px;
  left: 9px;
  border-radius: 20px;
  border-radius: 20px 20px 20px 0;
  background: #010c32;
  width: 360px;
  z-index: 1;
}

.sixthland .rev-cov .card .triangle {
  width: 0;
  height: 0;
  border-bottom: 83px solid transparent;
  border-right: 83px solid black;
  transform: rotate(-90deg);
  position: absolute;
  bottom: -83px;
  left: -3px;
}

.sixthland .rev-cov .card .triangle .tri-inner {
  position: relative;
  top: 3px;
  left: 7px;
  width: 0;
  height: 0;
  border-bottom: 80px solid transparent;
  border-right: 80px solid white;
}

.sixthland .rev-cov .card .card-inner .triangle .tri-inner {
  border-bottom: 43px solid transparent;
  border-right: 43px solid #010c32;
}

.sixthland .rev-cov .card .card-inner .triangle {
  border-bottom: 40px solid transparent;
  border-right: 40px solid black;
  bottom: -43px;
}

.sixthland .rev-cov .card .card-inner p {
  white-space: normal;
  font-size: 18px;
}

.sixthland .rev-cov .card .card-inner .tag {
  font-size: 20px;
  font-weight: 500;
}

.seventhland {
  padding: 0 75px 75px;
}

.seventhland .title {
  font-size: 35px;
  font-weight: 600;
}

.seventhland .card {
  border: 2px solid black;
  border-radius: 15px;
  padding: 20px;
  width: 230px;
}

.seventhland .card .title {
  font-size: 23px;
  font-weight: 600;
  line-height: 26px;
  margin: 0 0 20px;
  white-space: normal;
}

.seventhland .card .answer {
  white-space: normal;
  font-size: 18px;
}

.webinar {
  padding: 150px 75px 75px;
  background: #d9d9d9;
  height: calc(100vh - 225px);
  min-height: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.webinar h1 {
  font-size: 55px;
  font-weight: 700;
  line-height: 65px;
  width: 720px;
  margin: 0;
  text-align: center;
  color: #003dff;
}

.webinar p {
  margin: 35px 0;
  width: 720px;
  text-align: center;
  font-size: 18px;
  line-height: 32px;
  color: #090808;
}

.webinar .search label {
  width: unset;
  padding: 14px 20px 17px;
  background: transparent;
  border: 2px solid #003dff;
  border-left: none;
  font-weight: 400;
  text-align: center;
  border-radius: 0 30px 30px 0;
  padding: 7px 7px 7px;
  height: unset;
}

.webinar .search label .inner {
  width: 145px;
  padding: 12px 0 11px;
  background: #003dff;
  border-radius: 20px;
  pointer-events: none;
  transition: all 0.3s ease;
}

.webinar .search input {
  background: transparent;
  border-radius: 30px 0 0 30px;
  border: 2px solid #003dff;
  border-right: none;
  width: 375px;
  height: 55px;
}

.webinar .search {
  align-items: center;
}

.webinar .search label:hover + input {
  border: 2px solid black;
  border-right: none;
}

.webinar .search label:hover {
  border: 2px solid #000;
  border-left: none;
}

.webinar .search label:hover .inner {
  background: black;
}

.webinar .search .loader {
  top: -12px;
  margin: 0px 110px 0 0;
}

.webinar .form .error {
  color: red;
}

.webinar .form .err {
  color: red;
  padding-left: 30px;
}

.webinar .search.error label {
  border: 2px solid red;
  border-left: none;
  color: white;
}

@media (max-width: 1800px) {
}

@media (max-width: 1440px) {
  .thirdpage {
    height: 63vh;
    min-height: 400px;
  }

  .thirdpage .content .title {
    font-size: 33px;
    width: 100%;
    line-height: 42px;
  }

  .thirdpage .img img {
    margin: auto;
  }

  .thirdpage .img {
    width: 465px;
  }

  .thirdpage:nth-child(2n - 1) .content {
    width: 465px;
  }

  .thirdpage .content {
    width: calc(465px - 100px);
  }

  .thirdpage .content .intro-text {
    font-size: 17px;
    padding: 0px 0 8px;
  }

  .thirdpage .content .title {
    font-size: 27px;
    padding: 0 0 14px;
    width: 100%;
    line-height: 37px;
  }

  .thirdpage .content .description {
    font-size: 17px;
    line-height: 27px;
    padding: 0 0 20px;
  }

  .fourthpage .card-cover {
    padding: 50px 30px 0;
  }

  .waitfeature .benefits .feature {
    width: calc(50vw - 240px);
  }

  .waitfeature .benefits .feature:nth-child(2n - 1) .icon {
    margin: 0 0 50px;
  }

  .waitfeature .benefits .feature:nth-child(2n - 1) .title,
  .waitfeature .benefits .feature:nth-child(2n - 1) .description {
    margin: 0 0 15px;
  }

  .waitfeature .benefits .feature .title,
  .waitfeature .benefits .feature .description {
    width: 425px;
  }

  .waitfeature .benefits .feature:nth-child(2n - 1) a {
    margin: 0;
  }

  .firstpage .content {
    width: 600px;
  }

  .firstpage .content h1 {
    font-size: 46px;
    line-height: 55px;
    max-width: 600px;
  }

  .firstpage .content p {
    width: 80%;
    max-width: 510px;
  }

  .land-2.alt .img img {
    margin-left: 95px;
  }

  .fourthpage h1 {
    font-size: 36px;
  }

  .firstpage .image img.abo {
    margin: 100px 0 0;
  }

  .about .img img {
    margin-left: 65px;
  }

  .cta-break .content .title {
    width: 80%;
  }

  .cta-break .content p {
    width: 70%;
  }

  .result .content {
    padding: 50px 50px 50px 75px;
    width: calc(100vw - 125px);
  }

  .result .content .info {
    justify-content: space-around;
  }

  .result .content .swich {
    margin-left: 40px;
  }

  .onboard-form .content.back .n-arrow .feats {
    padding: 75px 0px 35px;
  }

  .firstpage .image img.tin {
    padding: 35px 0 0;
  }

  .tutorial.four .background-float {
    left: calc(100% - 300px);
  }

  .tutorial.eight .background-float {
    left: calc(75% - 16px);
    right: unset;
  }

  .tutorial.fourteen .background-float {
    right: 0;
  }

  .auth-container .auth .title {
    width: 440px;
    font-size: 43px;
    line-height: 44px;
    margin: 35px 0 30px;
  }

  .auth-container .auth .subheader {
    width: 485px;
    margin: -10px 0 30px;
    font-size: 20px;
    line-height: 24px;
  }

  .auth-container .auth label {
    font-size: 15px;
  }

  .auth-container .auth label img {
    left: calc(100% - 90px);
  }

  .auth-container .auth label img.pas {
    left: calc(100% - 120px);
  }

  .auth-container .auth .links .btn {
    font-size: 16px;
  }
}

@media (max-width: 1350px) {
  .home-lander .content h1 {
    font-size: 48px;
  }

  .home-lander .content .hone .line {
    height: 46px;
  }

  .home-lander .content p {
    font-size: 18px;
    line-height: 25px;
  }

  @keyframes h1home {
    0% {
      line-height: 130px;
    }
    100% {
      line-height: 35px;
    }
  }

  .tutorial.fourteen .background-float {
    width: 277px;
  }

  .fourteen .inner-tut {
    left: calc(100% + 10px);
    padding: 30px 6px 30px 30px;
  }
}

@media (max-width: 1300px) {
  .card-container .card .info .mskill span,
  .upcoming-info .container .img {
    display: none;
  }

  .upcoming-info .container .content {
    width: calc(100% - 10px);
  }

  .tutorial.four .background-float {
    left: calc(100% - 280px);
    width: 275px;
  }

  .main.four .inner-tut {
    width: 225px;
    padding: 30px 5px 30px 30px;
  }

  .main.five .highlight {
    height: 343px;
  }

  .main.six .highlight {
    top: 901px;
  }

  .tutorial.six .background-float {
    left: calc(60% - 133px);
  }

  .eight .inner-tut {
    width: 231px;
    padding: 30px 10px 30px 30px;
  }

  .tutorial.eight .background-float {
    width: 270px;
    left: calc(75% - 12px);
  }
}

@media (max-width: 1152px) {
  .thirdpage .img img {
    margin: 0;
    width: 372px;
  }

  .thirdpage:nth-child(2n - 1) .content {
    margin: 0 0 0 50px;
  }

  .thirdpage .content {
    width: calc(465px - 85px);
    margin: 0 0 0 0px;
  }

  .fourthpage .card-cover {
    padding: 50px 200px 0;
    flex-wrap: wrap;
  }

  .fourthpage .card-cover {
    padding: 50px 60px 0;
    flex-wrap: wrap;
  }

  .fourthpage .card-cover .card {
    margin: 0 30px 40px;
    min-height: 215px;
  }

  .waitform {
    background-color: #010c35;
    color: white;
    padding: 50px;
  }

  .waitfeature {
    padding: 75px 25px;
  }

  .waitfeature .benefits .feature {
    margin: 0 0 65px 35px;
    width: calc(50vw - 69px);
  }

  .waitfeature .benefits .feature:nth-child(2n - 1) {
    margin: 0 35px 65px 0;
  }

  .waitfeature .benefits .feature .title,
  .waitfeature .benefits .feature .description {
    width: 333px;
  }

  .waitfeature .benefits .feature:nth-child(2n - 1) a {
    margin: 0 0 0 calc(100% - 333px);
  }

  .waitfeature .benefits .feature:nth-child(2n - 1) .title,
  .waitfeature .benefits .feature:nth-child(2n - 1) .description {
    margin: 0 0 15px calc(100% - 333px);
  }

  .waitfeature .benefits .feature:nth-child(2n - 1) .icon {
    margin: 0 0 50px calc(100% - 333px);
  }

  .profile {
    padding: 35px 0px 75px;
  }

  .firstpage .content h1 {
    font-size: 41px;
    line-height: 45px;
    margin: 0 0 15px;
  }

  .firstpage .content .links {
    width: 100%;
  }

  .firstpage .content {
    width: calc(55vw - 55px);
    padding: 0 0 0 55px;
  }

  .waitpage p {
    width: 90%;
  }

  .land-2 {
    padding: 0px 50px 50px;
  }

  .land-2 .header {
    width: 70%;
    margin: 0 0 50px;
  }

  .land-2.alt .img img {
    margin-left: 30px;
  }

  .land-2 .img {
    margin-right: 30px;
  }

  .upcoming-info.review {
    height: unset;
  }

  .firstpage .image img.abo {
    width: 90%;
    height: unset;
    min-width: unset;
  }

  .about .img img {
    margin-left: 0;
    height: unset;
    width: 100%;
  }

  .values {
    flex-direction: column;
    padding: 25px 50px;
  }

  .values .clicks {
    width: 100%;
    margin-bottom: 50px;
  }

  .values .clicks .title {
    width: 100%;
  }

  .values .clicks p {
    width: 50%;
  }

  .values .items {
    width: 100%;
  }

  .values .val p {
    max-width: 95%;
  }

  .profile .container .item .img {
    width: 250px;
  }

  .auth-container .auth input {
    height: 45px;
  }

  .auth-container .auth .nam {
    width: unset;
    flex-direction: column;
  }

  .auth-container .auth .nam .col {
    width: 100%;
  }

  .mentor-page .home .img .gray {
    width: 330px;
    height: 330px;
  }

  .mentor-page .home .content .description {
    width: 95%;
    max-width: unset;
  }

  .schedule .zone-input {
    margin: 0px 20px 20px;
  }

  .schedule {
    margin: 60px 50px;
  }

  .onboard .img {
    display: none;
  }

  .onboard.test .img {
    display: block;
  }

  .onboard.test .content .top-nav .progress-bar {
    width: 490px;
    top: 30px;
    left: calc((100vw - 490px) / 2);
  }

  .onboard-form.test .content.back .n-arrow .feats .skill {
    padding: 19px 10px 14px;
  }

  .onboard-form.test .content.back .n-arrow .feats .skill img {
    max-width: 300px;
  }

  .result .content .card-cover {
    margin: 135px 0 60px 265px;
  }

  .result .content .card-cover .card.top {
    width: calc(85vw - 120px);
    height: calc((85vw - 120px) * 0.46);
  }

  .result .content .card-cover .card.mid {
    width: calc(85vw - 60px);
    height: calc(((85vw - 120px) * 0.46) - 60px);
  }

  .result .content .card-cover .card.bottom {
    width: 85vw;
    height: calc(((85vw - 120px) * 0.46) - 120px);
  }

  @keyframes onTop {
    5% {
      transform: translateY(-300px);
      z-index: 1;
      left: -30px;
      top: 30px;
      width: calc(85vw - 60px);
      height: calc(((85vw - 120px) * 0.46) - 60px);
    }
    10% {
      transform: translateY(0);
      z-index: 0;
      left: -60px;
      top: 60px;
      width: 85vw;
      height: calc(((85vw - 120px) * 0.46) - 120px);
    }
    100% {
      transform: translateY(0);
      z-index: 0;
      left: -60px;
      top: 60px;
      width: 85vw;
      height: calc(((85vw - 120px) * 0.46) - 120px);
    }
  }

  @keyframes onMiddle {
    0% {
      transform: translateY(0);
      z-index: 1;
      left: -30px;
      top: 30px;
      width: calc(85vw - 60px);
      height: calc(((85vw - 120px) * 0.46) - 60px);
    }
    100% {
      transform: translateY(0);
      z-index: 2;
      left: 0;
      top: 0;
      width: calc(85vw - 120px);
      height: calc((85vw - 120px) * 0.46);
    }
  }

  @keyframes onBottom {
    0% {
      transform: translateY(0);
      z-index: 0;
      left: -60px;
      top: 60px;
      width: 85vw;
      height: calc(((85vw - 120px) * 0.46) - 120px);
    }
    100% {
      transform: translateY(0);
      z-index: 1;
      left: -30px;
      top: 30px;
      width: calc(85vw - 60px);
      height: calc(((85vw - 120px) * 0.46) - 60px);
    }
  }

  .result .content .card-cover .card .bottom {
    margin: 0 40px 0;
    position: absolute;
    width: calc(100% - 80px);
    bottom: 22px;
  }

  .result .content .card-cover {
    margin: 135px 0 0px 265px;
  }

  .result .content .info .item {
    width: 45%;
  }

  .result .content .info p {
    width: 100%;
  }

  .result .content .info .contain {
    width: calc(100% - 40px);
    padding: 20px;
  }

  .home .upcoming .session {
    flex-wrap: wrap;
    margin: 28px 0 0;
  }

  .upcoming-info {
    margin: 0;
  }

  .home .upcoming .session .earn-cover {
    width: 560px;
    margin-top: 30px;
  }

  .prof .side .intro {
    margin: 35px 25px 0;
  }

  .prof .side p {
    margin: 25px;
  }

  .prof .side .out {
    margin: 75px 25px 25px;
  }

  .prof .side {
    min-width: 310px;
  }

  .prof .side .account p {
    margin: 25px 25px 5px;
  }

  .prof .side .account .no {
    padding: 0px 25px;
  }

  .prof .content {
    padding: 70px 25px 50px;
  }

  .prof .content label {
    margin: 0;
  }

  .prof .content .wrapper:nth-child(1) {
    margin: 0 0 35px;
  }

  .prof .content .wrapper {
    margin: 46px 0;
  }

  .prof .content .desc {
    margin-bottom: 30px;
  }

  .prof .content textarea,
  .prof .content input {
    padding: 8px 10px 10px;
  }

  .available .container .title {
    padding: 95px 60px 10px 35px;
  }

  .available .container .subtitle,
  .available .container form {
    padding: 0 60px 65px 35px;
  }

  .firstpage.alt .lead .img {
    width: calc(100vw - 150px);
  }

  .details {
    margin: 25px 25px 90px;
  }

  .details img {
    margin: 0px calc(45% - 340px) 30px 0;
    width: 340px;
  }

  .details.alt img {
    margin: 0 0 30px calc(45% - 340px);
  }

  .pro .container .img {
    margin-right: 0;
    width: 43%;
  }

  .pro .container .img img {
    height: 100%;
    width: 100%;
  }

  .stats .ava {
    width: calc(33.3% - 15px);
  }

  .stats .graph .ava p {
    width: 135px;
    padding: 9px 20px;
  }

  .stats .graph .ava.rem .title {
    margin: 40px 0 20px 50px;
  }

  .stats .ava.plot {
    width: calc(60% - 20px);
  }

  .stats .pie {
    flex-wrap: wrap;
  }

  .nota .container .item .img img {
    width: 100%;
  }

  .referral h1 {
    width: 100%;
  }

  .referral .upcoming .session {
    flex-direction: column;
  }

  .referral .upcoming .session .gen {
    width: calc(100% - 35px);
  }

  .referral .upcoming .session .earn-cover {
    margin-top: 30px;
  }

  .referral .upcoming .session .earn-cover {
    width: 100%;
  }

  .referral .upcoming .session .info {
    width: 210px;
  }

  .upcoming .session .path {
    width: calc(100% - 205px);
  }

  .upcoming .session .schedule {
    width: 100%;
  }

  .upcoming .session .schedule .up .card {
    padding: 10px 20px;
  }

  .upcoming .query .content {
    display: flex;
    flex-direction: column;
  }

  .upcoming .query .content .message {
    margin: 0 auto;
  }

  .upcoming-info .container .img {
    display: flex;
  }

  .upcoming-info .container .content {
    width: calc(65% - 10px);
  }

  .referral .info .time,
  .dashment .upcoming .session .hist .become,
  .dashment .upcoming .session .info .group {
    display: none;
  }

  .dashment .upcoming .session .disg {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }

  .history,
  .dashment .upcoming .session .info .meeting {
    margin-right: 0px;
    width: 100%;
  }

  .dashment .upcoming .session .become,
  .dashment .upcoming .session .group {
    width: 40%;
  }

  .dashment .upcoming .session .hist {
    margin: 20px 0;
  }

  .referral .upcoming {
    flex-direction: column;
  }

  .referral .upcoming .session {
    margin-right: 0px;
    width: 100%;
    max-width: 100%;
  }

  .referral .upcoming .progress {
    width: calc(100% - 50px);
    margin: 30px 0 0;
  }

  .fullpath .info,
  .referral .info {
    margin-bottom: 0px;
  }

  .section {
    padding: 75px 40px 50px;
  }

  .section .structure .item {
    padding: 15px 30px;
    margin: 15px 20px;
  }

  .section .sixthpage .cata-sub-nav li:nth-child(1) {
    margin: 0 30px 0 0;
  }

  .section .sixthpage .cata-sub-nav li {
    margin: 0 10px;
  }

  .section .sixthpage .cata-sub-nav li:nth-child(1) {
    margin: 0 10px 0 0;
  }

  .fullpath ul li {
    margin: 20px 10px;
  }

  .fullpath .info .time {
    display: none;
  }

  .secondland .ben-cov {
    flex-wrap: wrap;
    margin: 30px 0 0;
  }

  .secondland .ben-cov .card {
    width: calc(50% - 65px);
    margin: 10px;
    min-width: 235px;
  }

  .secondland .ben-cov .card:nth-child(1) {
    margin: 10px 10px 10px 0;
  }

  .seventhland .arrow,
  .secondland .arrow {
    display: block;
  }

  .seventhland .card {
    min-width: 230px;
    margin: 30px 15px 0;
  }

  .seventhland .card:nth-child(1) {
    margin: 30px 15px 0 0;
  }

  .seventhland {
    padding: 0 35px 75px;
  }

  .secondland {
    padding: 50px 35px 0;
  }

  .secondland .ben-cov .card h3 {
    min-height: 23px;
  }

  .thirdland .content .more {
    display: block;
    position: absolute;
    color: #1339ff;
    text-decoration: underline;
    cursor: pointer;
  }

  .thirdland .content .more.hide,
  .thirdland .content p.hide {
    display: none;
  }

  .thirdland {
    margin: 65px 35px;
  }

  .fourthland .pri-cov .card {
    width: calc(50% - 63px);
    padding: 20px 20px 35px;
  }

  .sixthland {
    padding: 75px 35px 0;
  }

  .fourthland {
    margin: 75px 35px;
  }

  .fifthland {
    padding: 75px 35px 65px;
  }

  .fifthland .service-cards {
    margin: 35px 0 0;
  }

  .seventhland .title {
    margin-bottom: 20px;
  }

  .pathpage .img {
    margin: 0;
  }

  .pathpage .img img {
    margin: 0px 0px 0 50px;
  }

  .assess .pathpage .img img {
    margin: 0;
    width: 380px;
  }

  .webinar {
    padding: 0 35px 75px;
  }

  .progress .card-container .card {
    width: calc(50% - 30px);
  }

  .welcome .text {
    width: calc(52% - 10px);
  }

  .pace .pace-containers {
    justify-content: unset;
  }

  .pace .pace-containers .card {
    width: calc(50% - 30px);
    min-width: 115px;
    margin: 10px 5px;
  }

  .dash-container .wishlist {
    flex-direction: column;
  }

  .dash-container .wishlist .wish-container {
    width: calc(100% - 40px);
  }

  .wishlist .marketing {
    width: 100%;
    margin: 20px 0;
  }

  .home-lander .content {
    width: calc(50% - 60px);
    padding: 35px 30px;
  }

  .nav.alt {
    padding: 0 5px;
    width: calc(100% - 10px);
  }

  .nav.alt .nav-bottom .nav-info {
    margin: auto 20px;
  }

  .home-lander .content h1 {
    font-size: 45px;
  }

  .home-lander .content p {
    font-size: 17px;
    line-height: 21px;
  }

  .home-lander .content .btn-group .sign-up {
    padding: 13px 35px;
  }

  .home-lander .img .tile {
    left: 6%;
  }

  .home-lander .img .tile.down {
    left: 62%;
  }

  @keyframes tileintro {
    0% {
      top: 20%;
      opacity: 0;
    }
    100% {
      top: 12%;
      opacity: 1;
    }
  }

  @keyframes tilehome {
    0% {
      top: 12%;
    }
    100% {
      top: 15%;
    }
  }

  @keyframes tileintroone {
    0% {
      top: 40%;
      opacity: 0;
    }
    100% {
      top: 50%;
      opacity: 1;
    }
  }

  @keyframes tilehomeone {
    0% {
      top: 50%;
    }
    100% {
      top: 47%;
    }
  }

  .partners-tab .learning-imgs {
    justify-content: flex-start;
  }

  .visualise-tab .img-group {
    margin-top: 0px;
  }

  .visualise-tab .img-group img:nth-child(2) {
    width: 35%;
    margin: 0 calc(5%);
  }

  .visualise-tab .img-group img:nth-child(3) {
    position: unset;
  }

  .visualise-tab h1 {
    width: 100%;
  }

  .trail-bank .content .input label {
    left: calc(100% - 155px);
  }

  .trail-bank .content .input input {
    width: calc(100% - 185px);
  }

  .auth-container .auth .subheader {
    width: 100%;
  }

  .auth-container .auth .title {
    width: 100%;
  }
}

@media (max-width: 960px) {
  .waitpage p {
    font-size: 35px;
    margin: 25px 0;
  }

  .thirdpage {
    padding: 0 25px;
  }

  .thirdpage:nth-child(2n - 1) .content {
    width: 340px;
    margin: 0;
  }

  .thirdpage .content {
    width: calc(340px);
    margin: 0 0 0 75px;
  }

  .thirdpage .content .intro-text {
    font-size: 16px;
    padding: 0px 0 5px;
  }

  .thirdpage .content .title {
    font-size: 23px;
    padding: 0 0 10px;
    line-height: 34px;
  }

  .thirdpage .content .description {
    font-size: 17px;
    line-height: 27px;
    padding: 0 0 20px;
    width: 90%;
  }

  .thirdpage .img {
    width: 300px;
  }

  .thirdpage .img img {
    width: 100%;
  }

  .waitform .form .career input {
    width: 85%;
  }

  .waitform {
    background-color: #010c35;
    color: white;
    padding: 50px 25px;
  }

  .waitform .form .career textarea {
    width: 90%;
  }

  .break h1 {
    max-width: 1200px;
    width: 100%;
    font-size: 35px;
  }

  .profile .title {
    font-size: 35px;
    margin: 0 0 20px;
  }

  .firstpage .image img {
    min-width: unset;
  }

  .waitsuccess .inner p {
    width: 380px;
  }

  .o-video iframe {
    width: 580px;
    height: 326px;
  }

  .o-video .cancel {
    left: 350px;
  }

  .nav .navbar-header a .brand {
    margin: auto 20px auto 5px;
  }

  .nav .nav-bottom .nav-info {
    margin: auto 10px;
  }

  .firstpage .content {
    padding: 0;
  }

  .upcoming-info.review .container .content .head,
  .about .content .title,
  .land-1 .content .bg {
    display: none;
  }

  .land-1 .img .sm.cont,
  .upcoming-info.review .container .img .head,
  .about .img .title {
    display: block;
  }

  .upcoming-info.review .container .img .head,
  .about .img .title {
    margin: 0 0 30px;
  }

  .land-1 .content {
    padding-bottom: 0;
  }

  .land-1 .img {
    margin-bottom: 0;
  }

  .land-2 .header {
    width: 100%;
    margin: 25px 0 35px;
  }

  .land-2 .container {
    flex-direction: column;
    align-items: center;
  }

  .land-2 .img {
    width: 65%;
    margin: 0 0 25px;
    display: flex;
    justify-content: center;
  }

  .land-2 .content {
    width: 100%;
  }

  .upcoming-info .container {
    flex-direction: column;
  }

  .upcoming-info.review .container .img {
    width: 80%;
    margin: auto;
  }

  .upcoming-info.review .container .content {
    width: 90%;
    margin: auto;
  }

  .upcoming-info.review .icon {
    margin: 350px 0;
  }

  .upcoming-info.review {
    padding: 50px;
  }

  .fifthpage {
    padding: 80px 15px;
  }

  .fifthpage h1 {
    font-size: 33px;
  }

  .firstpage.alt .image {
    min-width: unset;
  }

  .about {
    flex-direction: column;
    padding: 0px 50px 30px;
  }

  .about .img {
    width: 80%;
    margin: 0 auto 30px;
    display: flex;
    flex-direction: column;
  }

  .about .img img {
    width: 80%;
    margin: auto;
  }

  .about .content {
    width: 100%;
  }

  .profile .container {
    justify-content: center;
    flex-wrap: wrap;
  }

  .profile .container .item {
    margin: 20px 35px;
  }

  .profile {
    padding: 35px 0px 0px;
  }

  .firstpage.rot .image img {
    width: 195px;
    height: 480px;
    margin: 45px 0px 0 15px;
  }

  .firstpage.alt .content .lead {
    margin: 95px 0 0;
  }

  .cta-break .content .title {
    font-size: 35px;
    line-height: 38px;
    margin: 30px 0 15px 10%;
  }

  .cta-break .content .links {
    margin: 30px 0 0 10%;
  }

  .cta-break .content {
    min-height: 390px;
  }

  .auth-container .image {
    display: none;
  }

  .auth-container .auth {
    width: 100%;
    padding: 35px 29px 50px;
  }

  .mentor-page .home {
    flex-direction: column-reverse;
    height: unset;
    min-height: unset;
  }

  .mentor-page .home .content {
    width: 90%;
    padding: 0;
  }

  .mentor-page .home .content .description {
    width: 100%;
  }

  .mentor-page .home .img {
    width: 100%;
  }

  .mentor-page .home .content .subtitle {
    text-align: center;
    margin: 0 0 25px;
  }

  .mentor-page .home .content .title {
    text-align: center;
    margin: 25px 0 0;
  }

  .schedule .timeslot {
    margin: 30px calc((100% - 630px) / 6);
    width: 170px;
  }

  .onboard-form .content .input input {
    height: 35px;
    width: 70vw;
  }

  .onboard-form.test .content .intro img {
    display: none;
  }

  .onboard-form.test .content .hom p {
    width: 60%;
  }

  .onboard-form.test .content .detail {
    flex-wrap: wrap;
  }

  .onboard-form.test .content .detail .info {
    width: 100%;
    margin: 0;
  }

  .onboard-form.test .content .intro h1 {
    width: 100%;
    margin: 15px 0 0 50px;
  }

  .onboard-form.test .content .title {
    margin-left: 25px;
  }

  .onboard-form.test .content .input {
    margin-left: 25px;
  }

  .onboard-form.test .content .links {
    margin-top: 30px;
    margin-left: 25px;
  }

  .onboard-form.test .content .input .label {
    margin-bottom: 22px;
  }

  .onboard-form.test .error {
    margin-left: 25px;
  }

  input[type="checkbox"] + label:before,
  input[type="radio"] + label:before {
    width: 100%;
  }

  .exam .radio.test label span.fit {
    width: calc(100% - 66px);
  }

  .radio.test label {
    width: 74vw;
  }

  .onboard.test .content .top-nav .progress-bar {
    width: 60vw;
    left: 20vw;
  }

  .onboard-form .content.back .n-arrow .feats {
    width: 80vw;
  }

  .onboard-form .content.back .n-arrow .feats.skill {
    width: 85vw;
    padding: 30px 5px 5px;
  }

  .result .content .card-cover .card .no {
    position: absolute;
    top: 38%;
  }

  .result .content .card-cover .card .title {
    position: absolute;
    width: 100%;
    top: 14%;
    margin: 0;
  }

  .result .content .card-cover {
    margin: 135px 0 0px 250px;
  }

  .home .upcoming .session .upcoming-info .container {
    flex-direction: row;
  }

  .history {
    background: none;
    width: calc(100% - 40px);
  }

  .prof .content .wrapper {
    width: 100%;
  }

  .nota,
  .hire,
  .secondpage {
    padding: 37px 25px;
  }

  .sim {
    padding: 0 25px;
  }

  .nota .title,
  .sim .title,
  .hire .title,
  .secondpage .title {
    width: 100%;
  }

  .hire p,
  .secondpage p {
    width: 75%;
  }

  .onboard-form .content .input textarea {
    width: 100%;
  }

  .onboard-form {
    height: 710px;
  }

  .upcoming-info.review .container .img img.sm,
  .land-2 .img img.sm {
    display: block;
  }

  .upcoming-info.review .container .img img.bg,
  .land-2 .img img.bg {
    display: none;
  }

  .firstpage.alt .lead .img {
    width: calc(100vw - 96px);
  }

  .upcoming-info.review .container .content p.mar {
    margin-bottom: 0;
  }

  .details img {
    margin: 0px 5% 20px 0;
    width: 40%;
  }

  .details.alt img {
    margin: 0 0 20px 5%;
  }

  .pro {
    margin: 25px 25px 50px;
  }

  .stats .ava {
    width: calc(50% - 15px);
  }

  .stats .ava.full {
    margin-top: 50px;
  }

  .stats .graph {
    flex-wrap: wrap;
  }

  .stats .ava.rem {
    width: calc(50% - 15px);
    margin-bottom: 50px;
    padding-bottom: 30px;
  }

  .stats .graph .ava.rem .title {
    margin: 50px 0 20px 50px;
  }

  .stats .ava.plot {
    width: 100%;
  }

  .nota .container {
    justify-content: space-between;
  }

  .nota .container .item {
    width: calc(50% - 50px);
  }

  .nota .container .item .about {
    flex-direction: unset;
  }

  .hire .company-logo .img,
  .secondpage .company-logo .img {
    height: unset;
    width: unset;
  }

  .home .time {
    display: none;
  }

  .upcoming .session .path {
    width: 100%;
  }

  .upcoming .session .indir {
    width: 100%;
    margin-top: 20px;
    display: flex;
  }

  .upcoming .learn {
    margin: 0px 0px 20px 0;
  }

  .dashment .upcoming {
    flex-direction: column;
    margin-top: 15px;
  }

  .dashment .upcoming .session {
    margin-right: 0;
    width: 100%;
  }

  .dashment .upcoming .track {
    width: calc(100% - 50px);
    min-width: unset;
  }

  .dashpath .deets .rema,
  .dashment .info .time {
    display: none;
  }

  .quiz .score .details {
    flex-direction: column;
  }

  .firstland h1 {
    font-size: 46px;
    width: 725px;
    line-height: 65px;
  }

  .thirdland,
  .thirdland.alt {
    flex-direction: column;
  }

  .thirdland .content {
    width: calc(100% - 70px);
    margin: 0 35px 35px;
  }

  .thirdland .img {
    width: calc(100% - 40px);
  }

  .pathpage .img,
  .thirdland .img img.bg {
    display: none;
  }

  .thirdland .img img.sm {
    display: block;
  }

  .pathpage {
    flex-direction: column;
    height: unset;
    min-height: unset;
  }

  .pathpage .content {
    width: 100%;
  }

  .pathpage .content img {
    display: block;
    margin: auto;
  }

  .webinar {
    flex-direction: column;
  }

  .webinar .form {
    width: 100%;
  }

  .webinar h1 {
    width: 100%;
    margin: 0;
  }

  .webinar .search input {
    width: calc(100% - 150px);
  }

  .exam .container input[type="text"] {
    width: 855px;
  }

  .quiz .container input[type="file"],
  .quiz .container input[type="text"] {
    width: 100%;
  }

  .dash-container .side {
    display: none;
  }

  .dash-container .side.sm {
    display: block;
    width: 100%;
    min-width: 100%;
  }

  .plan .main,
  .one-home .main {
    min-width: 100%;
    width: 100%;
  }

  .dash-container .bottom {
    width: 200px;
    min-width: 200px;
  }

  .dash-container .search .up {
    min-width: calc(100% - 220px);
    width: calc(100% - 220px);
    max-width: calc(100% - 220px);
  }

  .plan .perform .chart .tile {
    width: calc((100% / 7) - 5px);
  }

  .plan .perform .chart .tile .bar .inner {
    background: #58d965;
  }

  .dash-container .learn-container.sm,
  .dash-container .notification.sm {
    display: block;
  }

  .dash-container .learn-container.sm .learn-todo {
    padding: 0;
  }

  .dash-container .learn-todo .todo-container {
    background: white;
    padding: 0;
  }

  .dash-container .learn-todo .todo-container .add-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
  }

  .dash-container .learn-todo .todo-container .add-header .add {
    width: 20px;
    height: 20px;
    border: 1px solid black;
    border-radius: 50%;
    cursor: pointer;
  }

  .dash-container .todo-container .add-header .add span {
    display: block;
    width: 10px;
    height: 2px;
    background: black;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .dash-container .todo-container .add-header .add span:nth-child(2) {
    width: 2px;
    height: 10px;
  }

  .dash-container .plan .todo-container .add-header .add span:nth-child(2) {
    top: 40%;
  }

  .dash-container .learn-todo .todo-container .list {
    background: #f0f0f0;
    padding: 10px;
  }

  .dash-container .profile-page .user-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: -65px 0 0;
    position: unset;
  }

  .dash-container .profile-page .user-info .name,
  .dash-container .profile-page .cover-img {
    margin: 0;
  }

  .dash-container .profile-page .user-info .name h3 {
    margin: 0;
    text-align: center;
  }

  .dash-container .profile-page .user-info .img-cove {
    height: 130px;
  }

  .dash-container .profile-page .user-info .name p {
    margin: 0 0 20px;
    text-align: center;
  }

  .dash-container .profile-page .input {
    flex-direction: column;
    margin: 0 0 10px;
  }

  .dash-container .profile-page .input label {
    margin: 10px 0;
    font-weight: 500;
    width: unset;
    color: rgba(109, 96, 96, 0.8);
  }

  .dash-container .profile-page .input textarea,
  .dash-container .profile-page .input input {
    width: calc(100% - 40px);
  }

  .dash-container .profile-page hr {
    display: none;
  }

  .dash-container .profile-page .btn-group {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 40px 0 25px;
  }

  .dash-container .profile-page .btn-group .save {
    margin: 0 0 15px;
    width: 220px;
  }

  .side-nav.alt .hamburger span {
    background: #fff;
  }

  .side-nav.alt .nav-info.hide {
    display: none;
  }

  .home-lander {
    flex-direction: column;
  }

  .home-lander .content {
    width: calc(100% - 60px);
    align-items: center;
  }

  .home-lander .img {
    height: 470px;
    width: 100%;
  }

  .home-lander .img .tile.down {
    left: 71%;
  }

  .partners-tab .learning-imgs img {
    margin: 15px;
  }

  .partners-tab .learning-imgs img.uda {
    top: 0;
  }

  .partners-tab .learning-imgs {
    justify-content: space-between;
    margin: 30px -15px 0;
  }

  .partners-tab {
    padding: 50px 30px 20px;
  }

  .partners-tab h4 {
    margin: 10px 0 5px;
    line-height: 29px;
  }

  .manage-tab {
    margin: 0 30px 0;
    padding: 20px 30px;
    flex-direction: column-reverse;
  }

  .manage-tab .img {
    width: 100%;
    height: calc((100vw - 60px) * 0.77443);
    margin-top: 40px;
    overflow: unset;
  }

  .track-tab .content,
  .manage-tab .content {
    width: 100%;
  }

  .track-tab .content h1,
  .manage-tab .content h1 {
    display: none;
  }

  .manage-tab .img h1 {
    display: block;
    position: relative;
    top: -56px;
    font-size: 39px;
    line-height: 40px;
  }

  .manage-tab .content p {
    margin: 0;
  }

  .track-tab {
    margin: 30px;
    flex-direction: column;
  }

  .track-tab .img {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .track-tab .img h1 {
    display: block;
    width: 100%;
    font-size: 39px;
  }

  .visualise-tab h1 {
    font-size: 39px;
    line-height: 38px;
  }

  .visualise-tab p {
    width: 100%;
    margin: 10px 0;
  }

  .visualise-tab .img-group {
    display: none;
  }

  .visualise-tab .img-groupie {
    display: block;
    margin: 20px 0 0;
  }

  .visualise-tab {
    margin: 0 30px 0;
    padding: 20px 30px;
  }

  .how-to .carousel .frame {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .visualise-tab .carousel .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .visualise-tab .carousel .container img {
    margin: auto;
    height: calc(((100vw - 60px) * 0.4) * 1.71875);
    width: 40%;
  }

  .visualise-tab .carousel .container:nth-child(2) img {
    width: 70%;
    height: calc(((100vw - 60px) * 0.7) * 0.84086);
  }

  .visualise-tab .carousel .container:nth-child(3) img {
    width: 70%;
    height: calc(((100vw - 60px) * 0.7) * 0.77443);
  }

  .how-to.bg {
    display: none;
  }

  .how-to.sm {
    display: block;
  }

  .how-to {
    margin: 40px 30px 30px;
  }

  .perks-tab {
    margin: 0 30px 0;
    flex-direction: column;
  }

  .perks-tab .perks:nth-child(1) {
    width: calc(100% - 60px);
    margin-bottom: 20px;
  }

  .perks-tab .perks {
    width: calc(100% - 60px);
    padding: 20px 30px;
  }

  .perks-tab .perks h1 {
    font-size: 39px;
    line-height: 40px;
    margin: 0;
  }

  .perks-tab .perks ul {
    margin: 20px 0;
  }

  .trail-bank {
    padding: 30px;
    flex-direction: column;
  }

  .trail-bank .img h1 {
    display: block;
    width: 100%;
    font-size: 39px;
    line-height: 38px;
  }

  .trail-bank .content h1 {
    display: none;
  }

  .trail-bank .img,
  .trail-bank .content {
    width: calc(100% - 20px);
  }

  .trail-bank .img {
    align-items: center;
    flex-direction: column;
  }

  .footer-tab {
    margin: 20px 30px;
  }

  .footer-tab .information {
    flex-direction: column;
  }

  .footer-tab .information .subscribe,
  .footer-tab .information .contact,
  .footer-tab .information .about-info {
    width: 100%;
    margin-bottom: 30px;
  }

  .footer-tab .information .about-info p {
    width: 100%;
    margin: 20px 0 0;
  }

  .footer-tab .information .subscribe h1,
  .footer-tab .information .contact h1 {
    margin: 0;
  }

  .footer-tab .information .contact p {
    margin: 0;
    line-height: 30px;
  }

  .footer-tab .information .subscribe h1,
  .footer-tab .information .contact h1 {
    margin: 0 0 10px;
  }
}

@media (max-width: 812px) {
  .fourthpage .card-cover {
    padding: 50px 10px 0;
    flex-wrap: wrap;
  }

  .fourthpage .card-cover .card {
    margin: 0 10px 40px;
  }

  .waitfeature .benefits .feature {
    margin: 0 0 65px 40px;
  }

  .waitfeature .benefits .feature:nth-child(2n - 1) {
    margin: 0 0 65px;
  }

  .onboard-form .content.back .n-arrow .feats .feat-link {
    margin: 0 10px 40px;
  }
}

@media (max-width: 768px) {
  .cta-break .img img.bg,
  .nav .nav-link,
  .nav .nav-auth .login,
  .nav .nav-bottom .nav-info,
  .nav .nav-auth .signup {
    display: none;
  }

  .cta-break .img img.sm {
    display: block;
  }

  .side-nav .hamburger {
    opacity: 1;
    pointer-events: unset;
  }

  .side-nav .nav-bottom .nav-info {
    text-align: center;
  }

  .side-nav .nav-bottom a {
    color: #464646;
  }

  .waitpage {
    padding: 100px 50px;
    background: url("https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Illustration+Assets-49.jpg")
      no-repeat center fixed;
    background-size: cover;
  }

  .waitpage .video {
    margin: 35px 0 0;
  }

  .thirdpage {
    height: 63vh;
    min-height: 420px;
  }

  .thirdpage .img {
    width: 275px;
  }

  .fourthpage h1 {
    font-size: 33px;
    padding: 25px 0 0 75px;
  }

  .fourthpage p {
    margin: 5px 0 0 75px;
  }

  .waitform .form {
    flex-direction: column;
  }

  .waitform .form .career {
    width: 100%;
    margin: 0 0 15px;
  }

  .waitform .form {
    margin: 0 0 25px;
  }

  .waitform .form .career input,
  .waitform .form .career textarea {
    width: calc(100% - 30px);
  }

  .waitfeature .title,
  .waitform .title {
    font-size: 33px;
  }

  .waitfeature .title {
    padding: 0;
  }

  .waitfeature .benefits {
    margin: 50px 0 0;
  }

  .waitfeature .benefits .feature:nth-child(2n - 1) .icon {
    margin: 0 0 25px calc(100% - 250px);
  }

  .waitfeature .benefits .feature .icon img {
    width: 75px;
  }

  .waitfeature .benefits .feature:nth-child(2n - 1) .title,
  .waitfeature .benefits .feature:nth-child(2n - 1) .description {
    margin: 0 0 10px calc(100% - 250px);
  }

  .waitfeature .benefits .feature .title {
    font-size: 25px;
    line-height: 32px;
    margin: 0 0 10px;
  }

  .waitfeature .benefits .feature .description {
    margin: 0 0 15px;
    font-size: 17px;
  }

  .waitfeature .benefits .feature .icon {
    margin: 0 0 25px;
  }

  .waitfeature .benefits .feature {
    width: calc(50vw - 54px);
  }

  .waitfeature .benefits .feature .title,
  .waitfeature .benefits .feature .description {
    width: 250px;
  }

  .waitfeature .benefits .feature:nth-child(2n - 1) a {
    margin: 0 0 0 calc(100% - 250px);
  }

  .footer {
    padding: 20px 30px 0;
  }

  .about {
    padding: 100px 25px;
  }

  .profile .container .item .role {
    font-size: 18px;
  }

  .profile {
    padding: 35px 15px 0px;
  }

  .profile .container .item .role {
    height: 52px;
  }

  .waitsuccess .inner p {
    width: 308px;
  }

  .waitsuccess .inner .cta {
    width: 308px;
    margin: 7px auto 0px;
  }

  .o-video .cancel {
    display: none;
  }

  .waitpage p {
    width: 95vw;
  }

  .footer .footer-list {
    flex-direction: column;
  }

  .footer .footer-list .group {
    margin: 10px 0;
  }

  .footer .footer-list .title {
    font-size: 21px;
  }

  .firstpage.alt,
  .firstpage {
    flex-direction: column-reverse;
    height: unset;
  }

  .firstpage .content {
    margin: 40px auto 45px;
    width: 580px;
  }

  .firstpage .image img.bg {
    display: none;
  }

  .firstpage .image img.sm {
    display: block;
  }

  .firstpage .image,
  .firstpage .image img {
    width: 100vw;
    height: unset;
    min-width: unset;
    max-width: unset;
  }

  .land-1 .content .bg {
    display: block;
  }

  .pro .container .img img.bg,
  .pro .content .sm,
  .available .slots,
  .firstpage.alt .content .lead,
  .land-1 .content .title.big,
  .land-1 .content .subtitle.big,
  .land-1 .img .bg {
    display: none;
  }

  .pro .container .img img.sm,
  .pro .img .intro,
  .pro .img p,
  .land-1 .img .sm,
  .firstpage.alt .image .lead,
  .land-1 .img .title.big,
  .land-1 .img .subtitle.big {
    display: block;
  }

  .available .container {
    width: 100%;
  }

  .land-1 {
    flex-direction: column-reverse;
  }

  .land-1 .img {
    width: 80%;
    margin: 25px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .land-1 .img img {
    width: 450px;
    margin-top: 35px;
  }

  .land-1 .content {
    width: 80%;
    padding: 0;
    margin: 0 auto;
  }

  .land-1 .content .intro.bg {
    margin: 20px 0 10px;
  }

  .land-2 .content .title,
  .land-2 .header {
    font-size: 35px;
    line-height: 40px;
  }

  .land-1.alt .img {
    width: 85%;
    margin: 0px auto;
  }

  .upcoming-info.review .container .img img {
    height: unset;
  }

  .upcoming-info.review {
    padding: 50px 25px;
  }

  .fifthpage h1 {
    width: 90%;
    margin: auto;
    max-width: 420px;
  }

  .footer .contact .info {
    margin: 20px 0 0;
  }

  .footer .footer-list .over {
    width: 100%;
    margin: 25px 0 0;
  }

  .footer .footer-list .title {
    padding: 0 0 10px;
  }

  .firstpage.alt .content {
    margin: 0 0 0 30px;
  }

  .firstpage.alt .image {
    width: 100%;
  }

  .firstpage .image img.abo {
    margin: 40px auto 0;
    width: 65%;
  }

  .firstpage.alt .content h1 {
    margin: 65px 0 30px;
  }

  .firstpage.rot .image img {
    margin: 40px auto 0;
  }

  .firstpage.alt .lead {
    margin: 50px auto 0;
    width: 80%;
  }

  .firstpage.alt .lead p {
    font-size: 28px;
  }

  .firstpage.alt .lead .img {
    margin: 10px 0 15px;
    flex-wrap: wrap;
    width: unset;
    justify-content: center;
  }

  .firstpage.alt .lead .img .img-icon:nth-child(1),
  .firstpage.alt .lead .img .img-icon {
    margin: 15px 20px 0 0;
  }

  .firstpage.alt .lead .img img {
    max-width: 100%;
    padding: 0 18px 0 0;
    margin: 0;
    height: 40px;
    transform: none;
  }

  .cta-break {
    flex-direction: column;
  }

  .cta-break .content {
    min-height: unset;
    width: 100%;
    min-width: unset;
  }

  .cta-break .img {
    width: 100%;
    max-width: unset;
    margin: auto;
    background: #003f5b;
  }

  .cta-break .content .links {
    margin: 30px 0 50px 10%;
  }

  .sixthpage h1 {
    padding: 0;
  }

  .sixthpage {
    padding: 50px 35px;
  }

  .land-1 .img .sm.cont,
  .auth-container .image {
    display: none;
  }

  .schedule {
    margin: 60px 25px;
  }

  .schedule .timeslot {
    margin: 30px 20px;
  }

  .onboard-form .content .title,
  .onboard-form .content .sub-title {
    width: 75vw;
  }

  .onboard .content .onboard-nav {
    margin: 40px 5px 20px 120px;
  }

  .onboard .content .onboard-nav .nav-info .line {
    width: 110px;
  }

  .onboard-form.test .content .intro h1 {
    margin: 10px 0 0 25px;
  }

  .onboard-form.test .content {
    width: calc(100% - 90px);
    padding: 20px 25px 20px 65px;
  }

  .onboard-form.test .content .hom p {
    margin: 10px 30px 0 25px;
  }

  .onboard-form.test .content .detail {
    margin: 25px 25px 55px;
  }

  .onboard-form .content.back .n-arrow .feats {
    padding: 30px 30px 5px;
  }

  .onboard-form.test .content.back .n-arrow .feats .value {
    margin: 7px;
    padding: 8px 15px;
  }

  .onboard-form.test .content.back .n-arrow .feats .skill {
    padding: 10px;
  }

  .onboard-form.test .content.back .n-arrow .feats .skill img {
    max-height: 45px;
    max-width: 240px;
  }

  .onboard-form.test .feats .skill .radio.test.show {
    flex-direction: column;
    padding: 2px 4px;
  }

  .onboard-form.test .feats .skill .radio.test.show label {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .onboard-form.test .feats .skill .radio.test.show label:before {
    margin: 8px auto;
    left: 0;
  }

  .onboard-form.test .feats .skill .radio.test.show {
    top: -115px;
  }

  .onboard-form.test .feats .skill .radio.test .tiny-tri {
    bottom: 140px;
    border-top: 15px solid transparent;
    border-right: 15px solid black;
    border-bottom: 15px solid transparent;
    border-left: none;
  }

  .onboard-form.test .feats .skill .radio.test .tiny-tri.alt {
    border-top: 15px solid transparent;
    border-right: none;
    border-bottom: 15px solid transparent;
    border-left: 15px solid black;
  }

  .result .content .info {
    flex-direction: column;
    margin: 25px 25px 0 50px;
  }

  .result .content .info .item {
    width: 100%;
    margin: 25px 0;
  }

  .result .content {
    padding: 50px 25px 50px 50px;
    width: calc(100vw - 75px);
  }

  .result .content .other {
    flex-wrap: wrap;
  }

  .result .content .card-cover {
    width: 240px;
    height: 300px;
    margin: 135px 0 0px 225px;
  }

  .result .content .card-cover .card.top {
    width: calc(80vw - 120px);
    height: 400px;
  }

  .result .content .card-cover .card.mid {
    width: calc(80vw - 60px);
    height: 340px;
  }

  .result .content .card-cover .card.bottom {
    width: 80vw;
    height: 280px;
  }

  @keyframes onTop {
    5% {
      transform: translateY(-300px);
      z-index: 1;
      left: -30px;
      top: 30px;
      width: calc(80vw - 60px);
      height: 340px;
    }
    10% {
      transform: translateY(0);
      z-index: 0;
      left: -60px;
      top: 60px;
      width: 80vw;
      height: 280px;
    }
    100% {
      transform: translateY(0);
      z-index: 0;
      left: -60px;
      top: 60px;
      width: 80vw;
      height: 280px;
    }
  }

  @keyframes onMiddle {
    0% {
      transform: translateY(0);
      z-index: 1;
      left: -30px;
      top: 30px;
      width: calc(80vw - 60px);
      height: 340px;
    }
    100% {
      transform: translateY(0);
      z-index: 2;
      left: 0;
      top: 0;
      width: calc(80vw - 120px);
      height: 400px;
    }
  }

  @keyframes onBottom {
    0% {
      transform: translateY(0);
      z-index: 0;
      left: -60px;
      top: 60px;
      width: 80vw;
      height: 280px;
    }
    100% {
      transform: translateY(0);
      z-index: 1;
      left: -30px;
      top: 30px;
      width: calc(80vw - 60px);
      height: 340px;
    }
  }

  .result .content .card-cover .card img {
    width: calc(100% + 40px);
    height: calc(100% - 70px);
  }

  .result .content .card-cover .card .no {
    display: none;
  }

  .result .content .card-cover .card .bottom {
    background: white;
    color: black;
    padding: 10px 40px;
    margin: 0;
    width: calc(100% - 80px);
    bottom: 0;
  }

  .result .content .card-cover .card {
    box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 30%);
    background: rgba(0, 0, 0, 0.3);
  }

  .home .upcoming .session .earn-cover {
    width: 100%;
  }

  .dash-container .home {
    padding: 50px 20px;
  }

  .home .statis .earn:nth-child(2) {
    margin: 0 0 0 10px;
  }

  .home .statis .upcoming-info.earning {
    width: 183px;
  }

  .prof .side {
    min-width: 190px;
  }

  .referral .upcoming .session .gen .form,
  .upcoming-info.earning .form,
  .history .container .item .form {
    width: 425px;
    padding: 50px 30px;
  }

  .onboard-form .content.back .arrow .bach {
    left: 39px;
  }

  .onboard-form .content .input textarea {
    width: calc(100% - 40px);
  }

  .onboard-form .content.back .n-arrow .feats .feat-link .title {
    width: unset;
  }

  .onboard-form .content.back .n-arrow .feats {
    padding: 30px 0 5px;
  }

  .onboard-form .content .title {
    margin: auto;
  }

  .onboard-form .content.back .n-arrow .feats .feat-link .icon {
    width: 55px;
  }

  .onboard-form .content.back .n-arrow .feats .feat-link {
    width: 170px;
  }

  .onboard-form .content.back .n-arrow .feats {
    padding: 50px 0;
  }

  .firstpage .image img.tin {
    height: unset;
    margin: 25px 30px 30px;
    width: calc(100% - 60px);
  }

  .pro .container {
    flex-direction: column-reverse;
  }

  .pro .container .img,
  .pro .container .content {
    width: 100%;
    height: unset;
  }

  .pro .container .img img {
    height: unset;
  }

  .pro .img .intro {
    margin: 20px 0 0;
  }

  .stats .ava.rem,
  .stats .ava {
    width: 320px;
  }

  .stats .ava {
    margin-top: 50px;
  }

  .stats .ava.rem {
    margin: 0;
  }

  .stats .graph,
  .stats .pie {
    justify-content: center;
  }

  .result .content .info .contain .img {
    display: block;
    margin: 15px 0;
  }

  .side-nav .hamburger.dash {
    opacity: 0;
  }

  .side-nav .hamburger.dash.open {
    opacity: 1;
  }

  .referral .upcoming .session .gen .form .info p,
  .upcoming-info.earning .form.draw .info p {
    width: 100%;
  }

  .land-1.wait {
    flex-direction: column-reverse;
  }

  .land-1 a.up {
    line-height: 90px;
  }

  .land-1 p.up {
    margin: 0px 0 10px;
  }

  .land-1.wait .img {
    margin: 75px 50px 35px;
  }

  .dash-nav {
    width: 15vw;
    min-width: 165px;
  }

  .dash-hero .dash-container {
    width: calc(100% - 205px);
    left: 140px;
  }

  .upcoming .learn .content {
    width: 100%;
  }

  .upcoming .learn .box {
    display: none;
  }

  .dashment .info .meeting .img,
  .upcoming-info .container .img {
    display: none;
  }

  .dashment .info .meeting .content {
    width: 100%;
  }

  .upcoming-info.sing .container .content,
  .upcoming-info .container .content {
    width: calc(100% - 10px);
  }

  .dash-container .dashment {
    padding: 20px;
  }

  .dashment .info h1 {
    width: 200px;
    line-height: 35px;
  }

  .dashment .upcoming .session .info {
    width: calc(100% - 40px);
  }

  .dashment .history .container .item .name,
  .history .container .item .name {
    width: 130px;
  }

  .history .container .item .img {
    width: 40px;
    height: 40px;
  }

  .dashment .history .container .item .info,
  .history .container .item .info {
    width: calc(((100% - 135px) / 4));
  }

  .referral .deets .badg {
    display: none;
  }

  .section .up {
    width: calc(100vw - 140px);
  }

  .section .structure .item {
    padding: 15px 25px;
    margin: 15px 5px;
  }

  .firstland h1 {
    font-size: 43px;
    width: calc(100vw - 40px);
    line-height: 60px;
    padding: 0 20px;
  }

  .secondland p {
    width: 100%;
  }

  .exam-outer .progress .point.full .line .inner,
  .exam-outer .progress .point .line {
    width: calc((100vw - 180px) / 4);
  }

  .exam-outer h1 {
    width: 90vw;
    text-align: center;
    margin: 30px 0;
  }

  .exam-outer .exam {
    width: 95%;
  }

  .exam-outer .exam .carousel {
    width: 95%;
  }

  .exam-outer .exam .container {
    min-width: calc(100% - 20px);
    padding: 30px 10px;
    margin-right: 35px;
  }

  .exam .container input[type="text"] {
    width: calc(100% - 35px);
  }

  .exam-outer .exam .container p {
    width: 100%;
    white-space: normal;
  }

  .exam-outer .exam .container .input {
    width: 100%;
  }

  .exam-outer .exam .radio.test label {
    white-space: normal;
  }

  .exam .radio.test label span.fit {
    width: 85%;
  }

  .exam-outer .btn {
    width: calc(100% - 40px);
    padding: 0 20px 30px;
  }

  .exam-outer .popup .inner {
    width: calc(90vw - 100px);
  }

  .section .sixthpage .service-cards .arrow {
    display: none;
  }

  .section .sixthpage {
    padding: 50px 0px 0;
  }

  .dash-container .bottom .addResource .iconcontainer {
    margin: 0;
  }

  .dash-container .bottom p {
    display: none;
  }

  .dash-container .bottom .addResource {
    padding: 11px 0;
  }

  .dash-container .bottom {
    width: 70px;
    min-width: 45px;
  }

  .dash-container .search .up {
    min-width: calc(100% - 90px);
    width: calc(100% - 90px);
  }

  .welcome {
    height: 100px;
  }

  .welcome .text p {
    display: none;
  }

  .dash-container .search {
    padding: 20px 10px;
  }

  .dash-container .plan {
    padding: 0px 20px;
  }

  .dash-container.one .progress {
    padding: 10px 0;
  }

  .dash-container.one .progress .top {
    padding: 0 10px;
  }

  .dash-container .search .up i .card-container,
  .dash-container.one .progress .card-container {
    margin: 5px 0;
    padding: 0 5px;
  }

  .progress .card-container .card .info .btn span {
    display: none;
  }

  .skill-graph .container {
    flex-direction: column;
  }

  .dash-container .skill-graph {
    padding: 20px;
    margin: 0 0 20px;
  }

  .skill-graph .container .img {
    width: 100%;
  }

  .skill-graph .container .content {
    width: calc(100% - 20px);
  }

  .skill-graph .container .content .btn {
    margin: 20px auto;
  }

  .home-lander .img .tile {
    width: 130px;
    height: 150px;
  }

  .home-lander .img .tile .blob {
    width: 50px;
    height: 50px;
  }

  .home-lander .img .tile p {
    font-size: 18px;
    line-height: 16px;
    margin: 5px 0;
  }

  .manage-tab .img h1 {
    top: -69px;
    font-size: 33px;
    line-height: 36px;
  }

  .manage-tab .img {
    margin-top: 45px;
  }

  .side-nav.alt .hamburger {
    top: 27px;
  }
}

@media (max-width: 600px) {
  .side-nav {
    height: calc(100vh - 100px);
    width: 250px;
    padding: 55px 25px 45px;
  }

  .side-nav .nav-info {
    margin: 25px 0;
  }

  .side-nav.open {
    right: -340px;
  }

  .waitpage .error {
    width: 100%;
  }

  .firstpage .search {
    width: 100%;
  }

  .firstpage .search label {
    padding: 13px 15px 16px;
  }

  .firstpage .search input {
    width: calc(100% - 176px);
    height: 43px;
  }

  .firstpage .search.error label {
    padding: 12px 14px 15px;
  }

  .waitpage p {
    font-size: 32px;
    line-height: 42px;
  }

  .thirdpage,
  .thirdpage:nth-child(2n - 1) {
    flex-direction: column;
    justify-content: center;
    height: unset;
    min-height: unset;
    margin: 30px 0;
  }

  .thirdpage .img {
    width: 100%;
    margin: 40px 0;
  }

  .thirdpage .img img {
    width: 75%;
    margin: auto;
  }

  .thirdpage .content,
  .thirdpage:nth-child(2n - 1) .content {
    width: 100%;
    margin: 20px 0;
    text-align: center;
  }

  .thirdpage .content .description {
    margin: auto;
  }

  .thirdpage .content a {
    margin: 0 calc(50% - 74px);
  }

  .thirdpage .content .title {
    padding: 0 0 10px;
    width: 80%;
    margin: auto;
  }

  .thirdpage .content .intro-text {
    padding: 0px 0 10px;
  }

  .thirdpage .content .title {
    padding: 0 0 15px;
  }

  .thirdpage .content .description {
    padding: 0 0 30px;
  }

  .fourthpage .card-cover .card {
    min-height: unset;
  }

  .waitfeature .benefits {
    flex-direction: column;
    margin: 60px 0 0;
  }

  .waitfeature .benefits .feature:nth-child(2n - 1),
  .waitfeature .benefits .feature {
    width: calc(100vw - 45px);
    margin: 0 0 65px;
  }

  .waitfeature .benefits .feature .icon,
  .waitfeature .benefits .feature:nth-child(2n - 1) .icon {
    margin: 0 auto 25px;
  }

  .waitfeature .benefits .feature .title,
  .waitfeature .benefits .feature .description,
  .waitfeature .benefits .feature:nth-child(2n - 1) .title,
  .waitfeature .benefits .feature:nth-child(2n - 1) .description {
    margin: 0 auto 10px;
    width: 80%;
  }

  .waitfeature .benefits .feature a,
  .waitfeature .benefits .feature:nth-child(2n - 1) a {
    margin: 0 0 0 10%;
  }

  .footer .contact {
    flex-direction: column;
  }

  .footer .contact .rights {
    margin: 0 auto 20px;
  }

  .footer .contact .social-links {
    margin: 0 auto;
  }

  .footer hr {
    margin: 35px 0;
  }

  .footer {
    padding: 75px 30px 0;
  }

  .break {
    padding: 50px 20px;
  }

  .break h1 {
    font-size: 30px;
  }

  .profile .container .item .role {
    height: unset;
  }

  .waitsuccess .inner {
    width: 400px;
    height: 350px;
    background: white
      url(https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Illustration+Assets-53.jpg)
      no-repeat center;
    background-size: contain;
  }

  .waitsuccess .inner .container {
    width: 100%;
  }

  .waitsuccess .inner .cancelBtn {
    display: none;
  }

  .waitsuccess .inner p {
    font-size: 17px;
    line-height: unset;
  }

  .waitsuccess .inner .title {
    margin-top: 110px;
  }

  .o-video iframe {
    width: 460px;
    height: 258px;
  }

  .firstpage .content {
    width: 460px;
  }

  .land-1 .duo .da {
    width: 80%;
  }

  .land-2 {
    padding: 0px 50px 20px;
  }

  .land-1 .img .title.big {
    font-size: 35px;
    line-height: 40px;
  }

  .land-1.alt .img {
    width: 90%;
  }

  .fourthpage p,
  .fourthpage h1 {
    width: 80%;
  }

  .upcoming-info.review .icon {
    display: none;
  }

  .upcoming-info .carousel {
    width: 100%;
  }

  .fifthpage {
    padding: 60px 15px;
  }

  .footer .contact .info {
    display: flex;
    justify-content: space-around;
  }

  .values .items .val {
    width: 100%;
  }

  .values .clicks p {
    width: 100%;
  }

  .profile .container .item {
    margin: 10px 0;
    width: 100%;
  }
  .upcoming-info .container .content .title,
  .sixthpage .arrow {
    display: none;
  }

  .sixthpage {
    padding: 50px 15px;
  }

  .auth-container .auth input {
    width: 70vw;
  }

  .auth-container .auth label img.pas {
    left: calc(100% - 115px);
  }

  .auth-container .auth label img {
    left: calc(100% - 90px);
  }

  .auth-container .auth label {
    width: calc(70vw + 40px);
  }

  .auth-container .auth .nam .col {
    width: calc(70vw + 40px);
  }

  .schedule .timeslot {
    width: 200px;
  }

  .schedule .container {
    justify-content: center;
  }

  .onboard .content .onboard-nav {
    margin: 40px 5px 20px 75px;
  }

  .onboard-form {
    height: 658px;
  }

  .onboard-form .content {
    width: unset;
    right: -35px;
  }

  .onboard-form .content .input input {
    width: 65vw;
  }

  .onboard-form .content .title {
    font-size: 35px;
  }

  .onboard-form.test .content .intro h1 {
    line-height: 45px;
    font-size: 35px;
  }

  .onboard-form .content.back .n-arrow .feats {
    padding: 30px 10px 5px;
  }

  .onboard-form.test .content.back .n-arrow .feats .skill img {
    max-height: 45px;
    max-width: 190px;
  }

  .result .content .card-cover .card.top {
    width: calc(80vw - 60px);
  }

  .result .content .card-cover .card.mid {
    left: -15px;
    top: 15px;
    width: calc(80vw - 30px);
    height: 370px;
  }

  .result .content .card-cover .card.bottom {
    left: -30px;
    top: 30px;
    height: 340px;
  }

  @keyframes onTop {
    5% {
      transform: translateY(-300px);
      z-index: 1;
      left: -15px;
      top: 15px;
      width: calc(80vw - 30px);
      height: 370px;
    }
    10% {
      transform: translateY(0);
      z-index: 0;
      left: -30px;
      top: 30px;
      width: 80vw;
      height: 340px;
    }
    100% {
      transform: translateY(0);
      z-index: 0;
      left: -30px;
      top: 30px;
      width: 80vw;
      height: 340px;
    }
  }
  @keyframes onMiddle {
    0% {
      transform: translateY(0);
      z-index: 1;
      left: -15px;
      top: 15px;
      width: calc(80vw - 30px);
      height: 370px;
    }
    100% {
      transform: translateY(0);
      z-index: 2;
      left: 0;
      top: 0;
      width: calc(80vw - 60px);
      height: 400px;
    }
  }
  @keyframes onBottom {
    0% {
      transform: translateY(0);
      z-index: 0;
      left: -30px;
      top: 30px;
      width: 80vw;
      height: 340px;
    }
    100% {
      transform: translateY(0);
      z-index: 1;
      left: -15px;
      top: 15px;
      width: calc(80vw - 30px);
      height: 370px;
    }
  }

  .result .content .card-cover {
    margin: 135px 0 0px 184px;
  }

  .home .upcoming .session .earn-cover {
    flex-direction: column;
  }

  .upcoming-info.earning {
    margin: 10px 0px;
  }

  .dashment .info .meeting,
  .home .upcoming .session .upcoming-info .container {
    flex-direction: column-reverse;
    margin: 0 10px;
  }

  .dashment .info .meeting .img,
  .upcoming-info .container .img {
    width: calc(100% - 60px);
    margin: auto;
    display: flex;
    align-items: center;
    border-radius: 25px 25px 0 0;
    background: white;
    padding: 20px 30px 0;
  }

  .dashment .info .meeting .content .desc {
    margin: 0;
    background: white;
    padding: 10px 30px;
    width: calc(100% - 60px);
    font-size: 17px;
  }

  .dashment .info .meeting .img .up,
  .upcoming-info .container .img .up {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
  }

  .dashment .info .meeting .img .up .deets,
  .upcoming-info .container .img .up .deets {
    width: calc(100% - 60px);
  }

  .dashment .info .meeting .btn,
  .upcoming-info .container .btn {
    display: none;
  }

  .upcoming-info .container .img .hodl {
    width: 150px;
    height: 150px;
    margin: 15px 15px 15px 0;
  }

  .upcoming-info .container .img span {
    margin: 0;
    font-size: 28px;
    font-weight: 700;
    font-family: "Inter", sans-serif;
    display: block;
  }

  .upcoming-info .container .img span a {
    color: white;
    white-space: normal;
  }

  .upcoming-info .container .content {
    padding: 0;
    width: 100%;
  }

  .upcoming-info .container .content .desc {
    background: white;
    margin: 0;
    width: calc(100% - 130px);
    padding: 0 100px 20px 30px;
    font-size: 17px;
  }

  .upcoming-info .container .img .title,
  .dashment .info .meeting .img .title {
    margin: 5px 0 20px;
    text-align: left;
  }

  .upcoming-info .container .img .name,
  .dashment .info .meeting .img .name {
    font-size: 17px;
    font-weight: 500;
    margin: 20px 0 5px;
    text-align: left;
  }

  .dashment .meeting .content .deets,
  .upcoming-info .container .content .deets {
    background: #c3cafc;
    border-radius: 0 0 25px 25px;
    margin: 0;
    justify-content: center;
  }

  .upcoming-info .content .deets .block,
  .dashment .meeting .content .deets .block {
    background: transparent;
    flex-direction: column;
    height: 65px;
    justify-content: space-around;
    align-items: center;
    display: flex;
  }

  .dashment .upcoming .session .info .meeting {
    margin: 0;
    padding: 0;
    background: transparent;
  }

  .upcoming-info .container .content .title.note {
    display: block;
  }

  .upcoming-info .container .content .bio {
    min-height: unset;
  }

  .upcoming-info .icon {
    margin: 202px 0px;
  }

  .upcoming-info {
    padding: 5px 25px 45px;
    min-width: unset;
    width: calc(100% - 50px);
    background: #e1e5ff;
  }

  .home .statis {
    flex-direction: column;
  }

  .home .statis .upcoming-info.earning {
    width: 200px;
  }

  .side-nav .cancel-nav {
    width: calc(100% - 362px);
  }

  .referral .upcoming .session .gen .form,
  .upcoming-info.earning .form,
  .history .container .item .form {
    width: 75vw;
    padding: 50px 30px;
    left: 0;
    margin: 0 calc((25vw - 60px) / 2);
  }

  .upcoming-info .carousel.nil .nil img {
    width: 130px;
  }

  .prof .side {
    display: none;
  }

  .prof .content {
    width: calc(100% - 50px);
  }

  .available .container .title {
    padding: 60px 25px 10px 25px;
  }

  .available .container .subtitle,
  .available .container form {
    padding: 0 25px 65px 25px;
  }

  .hire .company-logo .img,
  .secondpage .company-logo .img {
    width: 115px;
  }

  .onboard-form .content.back .arrow .bach {
    display: none;
  }

  .onboard-form .content .title {
    width: 80%;
  }

  .details h1 {
    width: 100%;
    padding: 10px 0 0px;
    margin: 20px 0;
  }

  .details img {
    float: unset;
    margin: 0 0 20px;
    width: 100%;
  }

  .details.alt img {
    margin: 10px 0 30px 0;
  }

  .result .content .card-cover .card img.sm,
  .details p.sm,
  .details h3.sm {
    display: block;
  }

  .result .content .card-cover .card img.bg,
  .details p.bg,
  .details .content h3 {
    display: none;
  }

  .details {
    margin: 25px 25px 60px;
  }

  .details .content .link {
    margin-left: 0;
  }

  .stats {
    padding: 0px 25px 50px;
  }

  .nota .container .item {
    width: 100%;
    margin: 20px 0;
  }

  .upcoming-info .carousel .ernij.test .amount.next {
    min-height: unset;
  }

  .firstpage .image img.load {
    height: calc(100vw - 60px);
    width: calc(100% - 60px);
  }

  .firstpage .content p {
    width: 100%;
  }

  .sim p {
    margin: 10px;
    font-size: 20px;
    width: unset;
    line-height: 30px;
  }

  .auth-container .auth .title {
    width: calc(100% - 29px);
    font-size: 35px;
    line-height: 35px;
  }

  .auth-container .auth .alert-danger {
    width: 70vw;
  }

  .referral .upcoming .session .earn-cover {
    flex-direction: column;
  }

  .referral .upcoming .session .info {
    margin: 20px 0 0;
  }

  .referral .upcoming .session .gen .form,
  .upcoming-info.earning .form.draw {
    height: 220px;
  }

  .dash-nav {
    width: 50vw;
    border-radius: 0;
  }

  .dash-nav.hide {
    left: -70vw;
  }

  .dash-nav .top .cancel {
    display: block;
    position: relative;
    cursor: pointer;
    top: 18px;
  }

  .dash-nav .top .cancel span {
    display: block;
    width: 30px;
    height: 4px;
    background: black;
    display: block;
    border-radius: 25px;
    transform: rotate(45deg);
    pointer-events: none;
  }

  .dash-nav .top .cancel span:nth-child(1) {
    transform: rotate(-45deg);
    position: relative;
    top: 3px;
  }

  .dash-nav .top {
    justify-content: space-between;
  }

  .dash-hero .dash-container {
    width: 100%;
    left: 0px;
    padding: 0;
    background: white;
  }

  .dash-hero .dash-container .top-nav .profile i.bg,
  .dash-hero .dash-container .top-nav .profile.bg {
    display: none;
  }

  .dash-hero .dash-container .top-nav .profile i.sm {
    display: inline-block;
  }

  .dash-hero .dash-container .top-nav .open {
    position: fixed;
    top: 35px;
    left: 25px;
    z-index: 40;
  }

  .dash-hero .dash-container .top-nav .open span {
    display: block;
    width: 30px;
    height: 4px;
    background: black;
    border-radius: 15px;
    position: relative;
  }

  .dash-hero .dash-container .top-nav .open span:nth-child(2) {
    top: 4px;
  }

  .dash-hero .dash-container .top-nav .open span:nth-child(3) {
    top: 8px;
  }

  .dash-container .home {
    padding: 70px 0 0;
  }

  .home .info {
    margin: 25px 20px 0;
  }

  .home .upcoming p {
    margin: 3px 20px 20px;
  }

  .upcoming .session .path {
    background: #e1e5ff;
    border-radius: 25px 25px 0 0;
  }

  .upcoming .learn {
    width: calc(100% - 35px);
    border-radius: 0;
    overflow-x: hidden;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    background: #e1e5ff;
    padding: 0;
    margin: 25px 0 0 30px;
  }

  .dashment .upcoming .track .wrappr .deet,
  .upcoming .learn .wrappr {
    width: unset;
    display: flex;
  }

  .upcoming .session .ref .btn,
  .dashment .upcoming .track .wrappr::-webkit-scrollbar,
  .upcoming .learn::-webkit-scrollbar,
  .upcoming .learn .content .btn,
  .upcoming .session .indir {
    display: none;
  }

  .upcoming .learn .content {
    width: 200px;
    background: white;
    border-radius: 15px;
    padding: 20px;
    margin-right: 5px;
    height: 179px;
  }
  .upcoming .learn .content .desc,
  .upcoming .session .ref .desc {
    white-space: normal;
  }

  .upcoming .learn .ref {
    width: 200px;
    margin: 0 5px 10px;
    display: block;
  }

  .referral .upcoming p.sm,
  .dashment .upcoming .session p.sm,
  .upcoming .session p.sm {
    display: block;
    font-size: 17px;
    font-weight: 500;
    color: black;
    margin: 20px 25px;
  }

  .referral .upcoming p.sm,
  .dashment .upcoming .session p.sm {
    margin: 20px 5px 10px;
  }

  .upcoming .session .schedule {
    background: #e1e5ff;
    border-radius: 0;
    padding: 0px 25px 25px;
  }

  .dashpath .upcoming .session .deets,
  .dashment .info .meeting .content .title,
  .upcoming .query {
    display: none;
  }

  .dash-container .dashment {
    padding: 70px 0 0;
  }

  .dashment .info h1 {
    width: unset;
    padding: 0 20px;
  }

  .dashment .upcoming {
    background: #e1e5ff;
    border-radius: 25px 25px 0 0;
  }

  .dashment .upcoming .session {
    width: calc(100% - 50px);
    margin: 30px 25px;
  }

  .dashment .upcoming .session .info {
    width: 100%;
  }

  .dashment .upcoming .session .group p,
  .dashment .become p {
    font-size: 16px;
    font-weight: 400;
    margin: 10px 0 5px;
  }

  .dashment .upcoming .track .wrappr {
    border-radius: 0;
    overflow-x: hidden;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }

  .dashment .upcoming .track.bg {
    display: none;
  }

  .dashment .upcoming .track.sm {
    display: block;
    padding: 0;
    margin: 20px 0;
    background: transparent;
    width: 100%;
  }

  .dashment .upcoming .session .track .info {
    width: 100px;
    flex-direction: column;
    align-items: flex-start;
    height: 85px;
    margin: 0 10px;
  }

  .referral.og .upcoming .progre.sm .card:nth-child(1),
  .dashment .upcoming .session .track .info:nth-child(1n) {
    background: blue;
  }

  .referral.og .upcoming .progre.sm .card:nth-child(2),
  .dashment .upcoming .session .track .info:nth-child(2n) {
    background: orange;
  }

  .dashment .upcoming .session .track .info:nth-child(3n) {
    background: purple;
  }

  .referral.og .upcoming .progre.sm .card:nth-child(3),
  .dashment .upcoming .session .track .info:nth-child(4n) {
    background: green;
  }

  .dashment .upcoming .session .track .info:nth-child(5n) {
    background: violet;
  }

  .dashment .upcoming .track .info p {
    white-space: normal;
    color: white;
    width: 100px;
    font-weight: 300;
  }

  .dashment .upcoming .session .hist {
    margin: 0;
  }

  .history .container .item {
    background: white;
    border-radius: 10px;
  }

  .history {
    background: none;
    padding: 0;
    width: calc(100% - 0px);
  }

  .referral {
    background: none;
    padding: 70px 0 0px;
    width: 100%;
  }

  .referral .upcoming {
    background: #e1e5ff;
    border-radius: 25px 25px 0 0;
  }

  .fullpath .info,
  .referral .info {
    margin: 25px;
  }

  .referral .upcoming .session {
    margin-right: 0px;
    width: calc(100% - 50px);
    max-width: calc(100% - 50px);
    margin: 0 25px;
    padding-bottom: 40px;
  }

  .referral .upcoming .session .gen {
    height: 190px;
    margin: 0 10px;
  }

  .referral .upcoming .session .gen .btn {
    display: none;
  }

  .dashpath .upcoming .session .gen .status {
    width: 180px;
  }

  .referral .upcoming .session .gen .path {
    background: none;
  }

  .dashpath .upcoming .session .deets {
    display: flex;
  }

  .referral .upcoming .progre.sm {
    display: flex;
    background: white;
    border-radius: 15px;
    position: relative;
    justify-content: space-between;
  }

  .referral .upcoming .progre.sm .value {
    position: absolute;
    top: 44%;
    left: 90px;
    font-size: 30px;
    font-weight: 600;
  }

  .referral .pie svg.alt circle {
    stroke: #c3cafc;
  }

  .referral .upcoming .pie.smm {
    display: none;
  }

  .referral .upcoming .pie.sm {
    display: flex;
  }

  .referral .progre .content {
    width: calc(100% - 240px);
    background: #c3cafc;
    border-radius: 15px;
    margin: 25px 25px 25px -15px;
  }

  .referral .progre .content .stat {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .referral .progre .content .stat img {
    width: 45px;
    margin: 0 20px;
  }

  .referral .progre .content .stat .text {
    margin-right: 20px;
  }

  .referral .progre .content .stat .text .no {
    font-size: 22px;
    font-weight: 600;
  }

  .referral .progre .content .stat .text .desc {
    line-height: 20px;
  }

  .referral .upcoming .progress {
    display: none;
  }

  .referral .upcoming .session .deets.icon {
    padding: 8px 10px;
  }

  .referral .upcoming .session .btn.sm {
    display: block;
    margin: 0 20px;
  }

  .referral .upcoming .session .cover.sm {
    display: flex;
    margin: 30px 20px 10px;
    align-items: center;
  }

  .referral .upcoming .session .cover.sm img {
    width: 50px;
  }

  .referral .upcoming .session .cover.sm .amount {
    font-size: 22px;
    font-weight: 600;
    margin: 0 15px;
  }

  .referral.og .deets .rema,
  .referral.og .deets .stat,
  .referral .upcoming .session .gen.bg {
    display: none;
  }

  .referral.og .upcoming .session .earn-cover {
    flex-direction: row;
  }

  .referral.og .deets .badg {
    display: block;
  }

  .referral.og .upcoming .progre.sm {
    background: none;
  }

  .referral.og .upcoming .progre.sm .card {
    position: relative;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: calc(33% - 10px);
  }

  .referral.og .upcoming .progre.sm .card img {
    position: absolute;
    width: 70px;
    bottom: 0;
    right: 0;
  }

  .referral.og .upcoming .progre.sm .card p {
    font-size: 17px;
    margin: 20px;
    font-weight: 500;
  }

  .referral.og .upcoming .progre.sm .card .no {
    font-size: 20px;
    font-weight: 600;
    margin: 20px;
  }

  .section {
    padding: 75px 0px 50px;
  }

  .section .up {
    width: 100vw;
  }

  .section .sixthpage .cata-sub-nav {
    padding: 25px 5px 0px;
  }

  .section .sixthpage .nav-prev {
    left: 9px;
  }

  .section .sixthpage .nav-next,
  .section .sixthpage .nav-prev {
    width: 25px;
    height: 25px;
  }

  .section .sixthpage .nav-prev .fas {
    top: 0px;
    left: 7px;
  }

  .section .sixthpage .nav-next {
    right: 10px;
    margin-top: -196px;
  }

  .section .sixthpage .arrow .fas {
    font-size: 16px;
    pointer-events: none;
  }

  .section .sixthpage .nav-next .fas {
    top: 1px;
    left: 9px;
  }

  .section .link {
    margin: 75px 0 30px calc(100% - 265px);
  }

  .section .title,
  .section .structure {
    padding: 0 20px;
  }

  .section .sixthpage .arrow {
    display: block;
  }

  .quiz {
    padding: 75px 20px;
  }

  .quiz .container {
    margin: 35px 0;
  }

  .quiz .container .intro {
    display: flex;
    margin-bottom: 15px;
  }

  .firstland p {
    width: calc(100vw - 50px);
    margin: 15px 25px;
  }

  .firstland h1 {
    font-size: 40px;
    line-height: 55px;
  }

  .secondland p {
    width: 100%;
  }

  .fourthland .pri-cov {
    flex-direction: column;
  }

  .fourthland .pri-cov .card {
    width: calc(100% - 40px);
    margin: 15px 0;
  }

  .fourthland .title {
    margin: 0 0 15px;
  }

  .fourthland {
    margin: 65px 35px;
  }

  .fifthland ul .card {
    margin: 0 10px;
  }

  .fifthland ul .card:nth-child(1) {
    margin: 0 10px 0 0;
  }

  .fifthland .cata-sub-nav ul .card .img {
    width: 240px;
    height: 254px;
  }

  .fifthland ul .card .title {
    width: 255px;
    white-space: normal;
    line-height: 30px;
  }

  .sixthland .rev-cov .card {
    width: 300px;
    height: 422px;
  }

  .sixthland .rev-cov .card .card-inner {
    height: 370px;
    width: 265px;
  }

  .sixthland .rev-cov {
    padding: 25px 15px 130px;
  }

  .sixthland .rev-cov .card .card-inner p {
    margin: 0;
    font-size: 16px;
  }

  .fifthland {
    border-radius: 35px;
  }

  .pathpage {
    padding: 80px 35px 50px;
  }

  .assess .pathpage {
    height: unset;
    padding: 75px 25px 20px;
  }

  .assess .pathpage .content .cover .info {
    margin: 0px 0;
    justify-content: space-between;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    height: 100px;
  }

  .pathpage .content .cover img {
    display: block;
    width: 38px;
    margin: auto;
  }

  .dash-hero .dash-container .top-nav .side-nav.open {
    display: none;
  }

  .upcoming .session img.ref {
    border-radius: 0;
  }

  .upcoming-info.sing {
    background: #fff;
    width: calc(100% - 100px);
    margin: 0 auto 25px;
  }

  .dashpath .info .popup .inner {
    width: 80vw;
  }

  .exam-outer .popup .inner {
    width: calc(90vw - 50px);
    padding: 25px;
    height: unset;
  }

  .exam-outer .popup .inner p {
    font-size: 15px;
    margin: 15px 0 30px;
  }

  .exam-outer .popup .inner .btn .link {
    width: 90px;
  }

  .fullpath .byte .card .btn {
    align-items: flex-start;
  }

  .fullpath ul {
    flex-direction: center;
  }

  .assess .btn {
    width: calc(100vw - 30px);
    padding: 0 15px;
  }

  .pathpage .content p {
    font-size: 16px;
  }

  .assess .btn .link.back {
    padding: 8px 25px;
    border: 2px solid #1339ff;
  }

  .assess .btn .link,
  .pathpage .content .link {
    padding: 10px 25px;
  }

  .quiz .container input[type="file"],
  .quiz .container input[type="text"] {
    width: calc(100% - 50px);
  }

  .upcoming-info .container .img .title.load,
  .upcoming-info .container .img .name.load {
    width: calc(100% - 20px);
  }

  .upcoming-info .container .img .circle.load {
    width: 80px;
    height: 80px;
  }

  .upcoming-info .container .content .desc.load {
    margin: 15px 0;
    height: 40px;
    width: calc(100% - 130px);
    background: linear-gradient(to right, #f2f2f2 8%, #e6e6e6 38%, #f2f2f2 54%);
  }

  .quiz .container .cover img {
    margin: 0px 10px 20px;
  }

  .dash-nav.one .top .brand {
    margin: 0;
  }

  .dash-nav.one .top .brand img {
    width: 30px;
    height: 30px;
  }

  .dash-nav.one .top .brand .title {
    font-size: 22px;
    padding-left: 9px;
  }

  .dash-nav.one .top .cancel {
    top: 14px;
  }

  .dash-hero .dash-container.one {
    width: 100%;
    left: 0;
    background: white;
  }

  .pace .pace-containers .card .no {
    font-size: 40px;
  }

  .dash-container.one .mobile-head {
    display: flex;
    justify-content: space-between;
    padding: 25px 10px 10px;
    align-items: center;
    padding: 15px 20px 10px;
  }

  .dash-container.one .mobile-head .name {
    color: #534f4f;
    font-weight: 600;
  }

  .dash-container.one .mobile-head .menu-op {
    position: relative;
    cursor: pointer;
    top: -10px;
  }

  .dash-container.one .mobile-head .menu-op span {
    position: absolute;
    display: block;
    width: 30px;
    background: black;
    height: 4px;
    border-radius: 5px;
  }

  .dash-container.one .mobile-head .menu-op span:nth-child(2) {
    top: 7px;
    width: 22px;
  }

  .dash-container.one .mobile-head .menu-op span:nth-child(3) {
    width: 15px;
    top: 14px;
  }

  .dash-container .search {
    flex-direction: column;
    padding: 10px 10px 5px;
  }

  .dash-container .search .up input {
    background: #d9d9d9;
    border: 0px solid gray;
    font-weight: 400;
  }

  .dash-container .bottom .back-active {
    background: #d9d9d9;
  }

  .dash-container .search .up i {
    color: black;
  }

  .dash-container .search .up {
    min-width: 100%;
    width: 100%;
    margin-bottom: 10px;
  }

  .dash-container .bottom {
    width: 100%;
    min-width: 100%;
    display: flex;
    background: none;
    justify-content: space-between;
    margin: 5px 10px;
    position: relative;
    align-items: center;
  }

  .dash-container .bottom .back-active {
    position: absolute;
    width: 40px;
    height: 24px;
    left: 0;
    background: gray;
    border-radius: 5px;
    display: block;
    transition: left 1s ease, width 1s cubic-bezier(0.4, 1.9, 0.41, 1.57);
  }

  .dash-container .bottom .back-active.cor {
    width: 83px;
    left: calc(50% - 17px);
  }

  .dash-container .bottom .back-active.vid {
    width: 63px;
    left: calc(25% - 8px);
  }

  .dash-container .bottom .back-active.art {
    left: calc(75% - 27px);
    width: 78px;
  }

  .dash-container .bottom:hover {
    background: none;
  }

  .dash-container .bottom:hover .addResource .iconcontainer {
    background: white;
  }

  .dash-container .bottom .sm-filter {
    z-index: 1;
    padding: 0 10px;
    color: #534f4f;
    font-size: 13px;
    font-weight: 500;
    display: block;
    width: 60px;
  }

  .dash-container .bottom .addResource .iconcontainer {
    width: 25px;
    height: 25px;
    background: white;
    border-radius: 50%;
    border: 1px solid #494141;
  }

  .dash-container .bottom .iconcontainer span {
    width: 12px;
    height: 2px;
    background: #494141;
  }

  .dash-container .bottom .iconcontainer span:nth-child(1) {
    width: 2px;
    height: 12px;
  }

  .dash-container .bottom .addResource {
    padding: 0;
  }

  .welcome .icon {
    display: none;
  }

  .welcome {
    height: unset;
    background: none;
  }

  .welcome .text .head {
    color: #534f4f;
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 500;
    display: flex;
  }

  .welcome .text .head span {
    display: flex;
    line-height: 30px;
  }

  .welcome .text .head span:nth-child(1) {
    margin-right: 7px;
  }

  .welcome .text .head span:nth-child(2) {
    font-weight: 600;
  }

  .pace .pace-containers {
    margin: 0px -5px;
  }

  .welcome .text .head span span {
    display: inline-block;
    padding-left: 9px;
  }

  .dash-container .sm-brief {
    display: block;
  }

  .dash-container .sm-brief .tile {
    display: flex;
    justify-content: space-between;
    background: #f0f0f0;
    border-radius: 15px;
    align-items: center;
    padding: 10px;
    color: #534f4f;
    margin: 15px 0;
  }

  .dash-container .sm-brief .tile .img {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 8px;
  }

  .dash-container .sm-brief .tile .img img {
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .dash-container .sm-brief .tile .info {
    width: calc(100% - 130px);
  }

  .dash-container .sm-brief .tile .info .title {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .dash-container .sm-brief .tile .info p {
    font-size: 13px;
    margin: 0;
    line-height: 14px;
  }

  .dash-container .sm-brief .val {
    display: flex;
    align-items: center;
  }

  .dash-container .sm-brief .val .no {
    font-size: 18px;
    font-weight: 600;
  }

  .dash-container .sm-brief .val .icon {
    position: relative;
    margin-left: 10px;
  }

  .dash-container .sm-brief .val .icon span {
    display: block;
    width: 14px;
    height: 3px;
    background: #353131;
    transform: rotate(25deg);
    position: relative;
    top: -1px;
    border-radius: 3px;
  }

  .dash-container .sm-brief .val .icon span:nth-child(2) {
    transform: rotate(-24deg);
    top: 1px;
  }

  .dash-container .side.sm {
    display: none;
  }

  .dash-container .sm-home {
    display: block;
    text-align: center;
    width: 70%;
    margin: 25px auto;
    font-weight: 500;
    color: #18a0fb;
    font-size: 13px;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 25px;
  }

  .pace .pace-containers .card.bg,
  .dash-container.one .progress,
  .pace .pace-containers .card .down .arrow,
  .dash-container .skill-graph,
  .dash-container .wishlist {
    display: none;
  }

  .pace .pace-containers .card.sm {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px 5px 0px;
  }

  .pace .pace-containers .card .up {
    flex-direction: column-reverse;
  }

  .dash-container .pace {
    padding: 0;
  }

  .dash-container .search.sm-hide {
    display: none;
  }

  .dash-container .home-sm {
    padding: 0 10px;
    display: block;
  }

  .dash-container .home-sm .load-more {
    margin: 10px 0;
    text-align: center;
    font-weight: 500;
    color: #18a0fb;
    font-size: 13px;
    cursor: pointer;
    text-decoration: underline;
  }

  .dash-container .home-sm .card-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .dash-container .home-sm .card-container .card {
    width: calc(50% - 40px);
    background: #f0f0f0;
    border-radius: 5px;
    padding: 15px;
    color: black;
    margin: 10px 0;
  }

  .dash-container .home-sm .card-container .card .img {
    width: 40px;
    height: 40px;
    overflow: hidden;
  }

  .dash-container .home-sm .card-container .card .img img {
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .home-sm .card-container .card .band {
    display: flex;
    flex-direction: column;
    height: calc(100% - 30px);
    justify-content: space-between;
  }

  .home-sm .card-container .card .title {
    font-size: 19px;
    font-weight: 400;
    margin: 10px 0 25px;
    line-height: 21px;
    font-family: "Oswald", sans-serif;
  }

  .home-sm .card-container .card .title.load {
    height: 45px;
  }

  .home-sm .card-container .card .info .mskill.load {
    height: 10px;
    width: 60px;
  }

  .home-sm .card-container .card .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .home-sm .card-container .card .info .mskill {
    font-size: 8px;
    line-height: 10px;
    font-weight: 300;
  }

  .dash-container .search.full,
  .dash-container .welcome.sm {
    display: none;
  }

  .outer-pop .container {
    width: calc(90vw - 60px);
  }

  .outer-pop .form-container .btn {
    flex-direction: column;
  }

  .outer-pop .form-container .btn .submit {
    width: 140px;
    margin: 0;
  }

  .outer-pop .form-container .btn .cancel {
    width: 138px;
    text-align: center;
    margin: 10px 0;
  }

  .outer-pop .form-container .item .input .bottom {
    min-width: unset;
    background: #18a0fb;
    height: 33px;
    margin: 0;
  }

  .outer-pop .form-container .item .input .down {
    min-width: 158px;
  }

  .outer-pop .form-container .item .input .up {
    max-width: calc(100% - 168px);
  }

  .pace-containers .card:nth-child(10) img {
    width: 45px;
  }

  .dash-container .home-sm.hide {
    display: none;
  }

  .dash-container .learn-todo .todo-container .title span {
    color: #58d965;
  }

  .dash-container .card-container .inner .img {
    width: 75%;
    margin-top: 20px;
  }

  .dash-container .card-container .inner p {
    width: 90%;
  }

  .dash-container .learn-todo .todo-container .list input[type="checkbox"] {
    height: 18px;
    width: 18px;
    border: 2px solid #534f4f;
    border-radius: 5px;
  }

  .dash-container .learn-todo .todo-container .list .check {
    display: flex;
    align-items: center;
  }

  .dash-container .learn-todo .todo-container .list-container .img {
    width: 75%;
    display: flex;
    margin: auto;
  }

  .dash-container .learn-todo .todo-container .list-container .img img {
    width: 100%;
  }

  .dash-container .learn-todo .todo-container .list-container .inner {
    margin-bottom: 30px;
  }

  .dash-container .learn-todo .todo-container .list-container .inner p {
    font-size: 13px;
    line-height: 18px;
    color: #534f4f;
    text-align: center;
  }

  .dash-container .profile-page {
    padding: 5px 20px;
  }

  .dash-container .profile-page .cover-img {
    height: 95px;
    border-radius: 8px;
  }

  .dash-container .profile-page .cover-img .pencil {
    position: absolute;
    top: 63px;
    right: 12px;
    background: white;
    width: 20px;
    height: 20px;
  }

  .dash-container .profile-page .cover-img .pencil svg {
    font-size: 10px;
  }

  .dash-container .profile-page .user-info .img {
    width: 70px;
    height: 70px;
  }

  .dash-container .profile-page .user-info .img-cove {
    height: 70px;
  }

  .dash-container .profile-page .user-info {
    margin: -37px 0 0;
  }

  .dash-container .profile-page .user-info .edit-btn {
    width: 15px;
    height: 15px;
    bottom: 22px;
    left: 57px;
  }

  .dash-container .profile-page .user-info .edit-btn span {
    width: 8px;
  }

  .dash-container .profile-page .user-info .edit-btn span:nth-child(2) {
    height: 8px;
  }

  .dash-container .profile-page .user-info .name p {
    margin: 0 0 5px;
  }

  .dash-container .profile-page .input textarea,
  .dash-container .profile-page .input input {
    background: #f0f0f0;
    border: navajowhite;
  }

  .privacy-body {
    padding: 60px 30px;
  }

  .home-lander .content h1 {
    font-size: 44px;
  }

  .home-lander .content .hone {
    margin: 60px 0 20px;
  }

  .home-lander .img {
    height: 440px;
  }

  .manage-tab .img h1 {
    top: -93px;
    font-size: 33px;
    line-height: 31px;
  }

  .manage-tab .img {
    margin-top: 65px;
  }

  .new .btn-group .chrome {
    font-size: 18px;
    padding: 10px 10px 14px;
    margin-right: 10px;
  }

  .footer-tab .bottom {
    flex-direction: column;
  }

  .footer-tab .bottom p {
    font-size: 18px;
    width: 100%;
  }

  .footer-tab .information p {
    font-size: 23px;
    line-height: 23px;
  }

  .trail-bank .content .input label {
    left: calc(100% - 122px);
    padding: 9px 20px;
  }

  .trail-bank .content .input input {
    width: calc(100% - 131px);
    padding: 10px 115px 10px 25px;
  }

  .footer-tab {
    padding: 30px 30px 20px;
  }

  .footer-tab .information .subscribe h1,
  .footer-tab .information .contact h1 {
    line-height: 29px;
  }

  .footer-tab .subscribe .input label {
    width: 66px;
    padding: 3px 13px 7px;
    left: calc(100% - 107px);
    font-size: 17px;
  }

  .trail-bank .content p,
  .visualise-tab p,
  .track-tab .content p,
  .manage-tab .content p {
    font-size: 23px;
    line-height: 26px;
  }

  .perks-tab .perks li {
    font-size: 22px;
    line-height: 22px;
  }

  .footer-tab .information .about-info p {
    font-size: 24px;
    line-height: 24px;
  }

  .footer-tab .information .contact p {
    font-size: 23px;
    font-weight: 400;
    line-height: 24px;
  }

  .footer-tab .bottom p {
    font-size: 15px;
  }

  .trail-bank .img h1,
  .how-to.sm h1 {
    font-size: 33px;
    line-height: 32px;
  }

  .track-tab {
    margin: 40px 30px;
  }

  .how-to {
    margin: 40px 30px 50px;
  }
}

@media (max-width: 480px) {
  .side-nav .nav-info {
    margin: 25px 0;
  }

  .waitpage {
    padding: 100px 20px;
  }

  .waitpage p {
    font-size: 30px;
    line-height: 39px;
    margin: 15px 0;
  }

  .waitpage p .line {
    margin: 5px 0;
    font-weight: 500;
  }

  .waitpage p .sub {
    margin: 20px auto 30px;
    font-size: 16px;
    font-weight: 300;
  }

  .firstpage .search {
    width: 100%;
    margin: 15px 0 0;
  }

  .thirdpage .content .title {
    width: 90%;
  }

  .thirdpage .content .description {
    width: 80%;
  }

  .fourthpage h1 {
    font-size: 25px;
  }

  .fourthpage p {
    font-size: 16px;
  }

  .waitform .title {
    font-size: 25px;
    margin: 10px 0;
  }

  .waitform p {
    font-size: 16px;
  }

  .waitfeature .title {
    font-size: 25px;
  }

  .waitfeature .benefits {
    margin: 35px 0 0;
  }

  .waitfeature .benefits .feature .title {
    font-size: 22px;
    line-height: 32px;
  }

  .waitfeature .benefits .feature:nth-child(2n - 1),
  .waitfeature .benefits .feature {
    margin: 0 0 50px;
  }

  .waitfeature .benefits .feature .title,
  .waitfeature .benefits .feature .description,
  .waitfeature .benefits .feature:nth-child(2n - 1) .title,
  .waitfeature .benefits .feature:nth-child(2n - 1) .description {
    width: 100%;
  }

  .waitfeature .benefits .feature a,
  .waitfeature .benefits .feature:nth-child(2n - 1) a {
    margin: 0;
  }

  .waitfeature {
    padding: 50px 25px;
  }

  .break h1 {
    font-size: 25px;
  }

  .profile {
    padding: 10px 15px 30px;
  }

  .profile .title {
    font-size: 25px;
  }

  .firstpage .content {
    width: calc(100vw - 40px);
    padding: 0 20px 0;
  }

  .firstpage.alt .content {
    width: calc(100vw - 50px);
  }

  .firstpage .content {
    margin: 0 0 30px;
  }

  .land-1 p.up {
    margin: 25px 0 10px;
  }

  .stats::before,
  .fourthpage::before {
    top: -45px;
    left: -55px;
  }

  .stats::after,
  .fourthpage::after {
    bottom: -45px;
    right: -49px;
  }

  .land-1 .img .title.big,
  .firstpage .content h1 {
    font-size: 30px;
    line-height: 34px;
  }

  .o-video iframe {
    width: 90vw;
    height: calc(0.56 * 90vw);
  }

  .thirdpage .img img.sm {
    display: unset;
  }

  .thirdpage .img img.bg {
    display: none;
  }

  .waitsuccess .inner .cta,
  .waitsuccess .inner {
    width: 90%;
  }

  .waitsuccess .inner .cta {
    width: 80%;
  }

  .footer .footer-list .title {
    font-size: 17px;
    font-weight: 400;
  }

  .firstpage .content {
    margin: 50px 0 45px;
  }

  .firstpage .content p {
    margin: 0 0 35px;
    font-weight: 300;
  }

  .fifthpage .description,
  .fourthpage .card-cover .card .description,
  .land-2 .content p,
  .land-1 p,
  .land-1 .subtitle,
  .land-2 .content .check p,
  .land-1 .content .check p {
    font-weight: 300;
  }

  .land-1 .img {
    width: calc(100% - 50px);
    margin: 25px auto;
  }

  .land-1 .img img {
    width: 95%;
  }

  .onboard-form .content .title,
  .sixthpage h1,
  .values .clicks .title,
  .upcoming-info.review .container .img .head,
  .about .title,
  .land-2 .content .title,
  .land-2 .header,
  .land-1 .intro,
  .land-1 .title {
    font-size: 25px;
    line-height: 30px;
  }

  .land-1 .content .duo {
    flex-direction: column;
  }

  .land-1 .content {
    width: calc(100% - 50px);
  }

  .land-1 {
    padding: 20px 5px;
  }

  .land-2 {
    padding: 30px;
  }

  .land-1.wait .img {
    margin: 0px 30px 35px;
    width: calc(100% - 60px);
  }

  .land-2.alt .content {
    margin-left: 0px;
  }

  .land-2.alt .content .links {
    margin: 45px 0 0;
  }

  .land-2.alt {
    padding: 65px 20px;
  }

  .land-2 .img {
    width: 100%;
  }

  .land-2.alt .img img {
    margin-left: 0;
    height: unset;
    width: 100%;
  }

  .fourthpage .card-cover {
    padding: 35px 10px 0;
  }

  .fourthpage .card-cover .card {
    width: 80%;
    max-width: 320px;
  }

  .fourthpage .card-cover .card .title {
    min-height: unset;
  }

  .fifthpage .description {
    width: 100%;
  }

  .fifthpage h1 {
    width: 95%;
    font-size: 35px;
  }

  .footer .contact .info {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 10px 0 0;
  }

  .footer .contact .info a,
  .footer .contact .info a:nth-child(1) {
    margin: 10px 0 0 0;
  }

  .firstpage .image img.abo {
    width: 75%;
  }

  .firstpage.alt .content {
    margin: 40px 0 -30px 10px;
  }

  .firstpage.err .content {
    margin: 40px 0 35px 10px;
  }

  .firstpage.rot .content {
    margin: 35px 0 20px 10px;
  }

  .firstpage.alt .content h1 {
    margin: 20px 0 15px;
  }

  .about .img img,
  .about .img {
    width: 100%;
  }

  .about {
    padding: 50px 25px;
  }

  .values .items .val {
    margin: 0 0 35px;
  }

  .values .val:nth-child(1) .icon img {
    width: 48px;
  }

  .values .val .icon img {
    width: 60px;
  }

  .values .val:nth-child(3) .icon img {
    width: 40px;
  }

  .values .val .icon {
    margin: 58px 0 25px;
  }

  .profile .container .item .img {
    width: 90%;
  }

  .firstpage.rot .image img {
    width: 150px;
    height: 367px;
  }

  .firstpage.alt .lead {
    width: calc(100% - 50px);
  }

  .firstpage.alt .lead .img .img-icon:nth-child(1),
  .firstpage.alt .lead .img .img-icon {
    margin: 10px 5px;
  }

  .cta-break .content .title {
    font-size: 25px;
    line-height: 30px;
    margin: 25px 0 10px 30px;
    width: 90%;
  }

  .cta-break .content p {
    width: 80%;
    margin: 0 0 0 30px;
  }

  .cta-break .content .links {
    margin: 20px 0 30px 30px;
  }

  .auth-container .auth {
    width: calc(100% - 70px);
    padding: 40px 35px 0;
  }

  .auth-container .auth .title {
    margin: 60px 0 10px;
  }

  .auth-container .auth .links .btn {
    padding: 0px 40px;
  }

  .auth-container .auth .links {
    flex-direction: column;
  }

  .auth-container .auth .links .google {
    margin-top: 15px;
    padding: 0 20px;
    text-align: center;
  }

  .auth-container .auth .links .google.bac {
    padding: 0 40px;
  }

  .auth-container .auth .footnotes {
    text-align: center;
    width: 85vw;
    padding: 25px 0px 0 0;
  }

  .auth-container .auth .header {
    position: absolute;
    left: 20px;
  }

  .mentor-page .home .content .links {
    justify-content: center;
  }

  .nota .container .item .img img.bg,
  .history.hom,
  .dash-nav .nav-list.alt.hide,
  .side-nav,
  .onboard-form.test .content .intro h1,
  .upcoming-info.mob .carousel .ernij p,
  .schedule .timeslot .big {
    display: none;
  }

  .nota .container .item .img img.sm,
  .history.show,
  .side-nav.mob .mot .icons.show,
  .side-nav .item img,
  .upcoming-info.mob .carousel .ernij .fa-angle-right,
  .upcoming-info.mob .carousel .ernij .text,
  .onboard-form.test .content .intro img,
  .onboard-form.test .content .sm,
  .schedule .timeslot .small {
    display: block;
  }

  .schedule .timeslot {
    width: 100%;
    display: flex;
    align-items: center;
    color: white;
    background: linear-gradient(to right, #071235, #242d4b);
    margin: 6px 0px;
  }

  .schedule .timeslot .date {
    width: 50px;
    height: 45px;
    padding: 2px 5px;
    border-radius: 5px;
    background: white;
    color: #010c35;
    transition: all ease 0.5s;
  }

  .schedule .timeslot .day {
    font-weight: 500;
    font-size: 24px;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    text-align: center;
  }

  .schedule .timeslot .date p {
    margin: -4px 0 0;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    font-family: "Inter", sans-serif;
    text-transform: uppercase;
  }

  .schedule .timeslot .moment {
    width: 80%;
    padding: 0 0 0 20px;
  }

  .schedule .radio {
    margin: 3px 0 0 0px;
  }

  .schedule .timeslot .moment .moda {
    font-weight: 500;
    font-family: "Inter", sans-serif;
  }

  .schedule .timeslot .time {
    margin: 0;
    font-weight: 100;
  }

  .schedule .radio input[type="checkbox"]:checked + label:before {
    background-color: limegreen;
    border-color: limegreen;
    color: white;
  }

  .schedule .zone-input {
    margin: 0px 0px 30px;
    width: 160px;
  }

  .schedule {
    margin: 40px 20px;
  }

  .schedule .timeslot.nil {
    color: #010c35;
    background: white;
  }

  .schedule .timeslot.nil .date {
    background: #010c35;
    color: white;
  }

  .onboard .content .onboard-nav {
    margin: 30px 5px 20px 25px;
  }

  .onboard-form .content {
    padding: 15px 20px 100px;
    position: absolute;
    width: calc(100% - 40px);
    right: unset;
    left: 0;
    transition: all 1s ease;
    opacity: 1;
  }

  .onboard-form .content .title,
  .onboard-form .content .sub-title {
    width: 91vw;
  }

  .onboard-form .content .input input {
    width: 75vw;
  }

  .onboard .content .onboard-nav .nav-info .line {
    width: 80px;
  }

  .onboard-form.test .content {
    padding: 20px 25px 20px 20px;
  }

  .onboard-form.test .content .intro img {
    margin: 0 auto;
    transform: rotate(14deg);
  }

  .result .top-nav .back,
  .onboard.test .content .top-nav .back {
    margin: 20px 0 0 25px;
  }

  .onboard.test.alt {
    background-color: #010c35;
  }

  .onboard.test .content .top-nav .back {
    border: 2px solid white;
  }

  .onboard.test .content .top-nav .back i {
    color: white;
  }

  .onboard-form.test .content {
    padding: 0px;
    width: 100%;
  }

  .onboard-form.test .content .sm {
    background: #010c35;
    color: white;
    font-size: 23px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 24px 45px 0;
    width: calc(100% - 90px);
  }

  .dash-nav .contain {
    width: calc(100% - 40px);
  }

  .dash-nav .nav-list .icon {
    margin-bottom: 0;
  }

  .result .top-nav,
  .onboard.test .content .top-nav {
    height: 56px;
  }

  .onboard-form.test .content .intro {
    clip-path: ellipse(80vw 270px at 69% 23%);
    padding-bottom: 63px;
    background: linear-gradient(to top, #242d4b, #010c35);
  }

  .onboard-form.test .content .links {
    margin-top: 20px;
  }

  .onboard-form.test .content .detail {
    margin: 25px 25px 35px;
  }

  .onboard-form.test .content .hom {
    margin-top: 40px;
    margin-right: 20px;
    align-items: center;
  }

  .onboard-form.test .content .hom p {
    margin: 10px 15px 0 25px;
    width: unset;
  }

  .onboard-form .content .links.start .continue {
    padding: 0 30px;
    margin: 0;
  }

  .onboard.test .content .top-nav .progress-bar {
    left: 30px;
  }

  .onboard-form.test .error {
    width: 90vw;
  }

  .onboard-form.test .content .input .label {
    width: calc(100% - 25px);
  }

  .onboard-form.test .content.hide {
    left: -500px;
  }

  .onboard-form.test .content.show {
    left: 500px;
  }

  input[type="checkbox"] + label:before,
  input[type="radio"] + label:before {
    width: calc(100vw - 57px);
  }

  .radio.test label {
    width: calc(100vw - 55px);
  }

  .onboard-form.test {
    height: 935px;
  }

  .onboard-form .content.back .n-arrow .feats {
    width: calc(100% - 20px);
    padding: 25px 10px 5px;
  }

  .onboard-form .content.back .n-arrow .feats.skill {
    width: 95vw;
  }

  .onboard-form.test .content.back .n-arrow .feats .value {
    margin: 7px;
    padding: 5px 10px;
  }

  .onboard.test .content .top-nav .line {
    width: 100vw;
    position: absolute;
    height: 5px;
    background: #010c35;
    top: 53px;
    z-index: 1;
  }

  .onboard.test .content .top-nav .line.dow {
    top: 112px;
  }

  .onboard-form.test .content.back .n-arrow .feats .skill {
    padding: 5px;
  }

  .onboard-form.test .content.back .n-arrow .feats .skill img {
    max-height: 55px;
    max-width: 240px;
  }

  .result .content {
    margin: 0;
    padding: 50px 25px;
    width: calc(100vw - 50px);
  }

  .result .content .swich {
    margin-left: 0;
  }

  .result .content .other .item {
    margin: 10px 10px;
  }

  .result .content .info {
    margin: 0;
  }

  .result .content .card-cover {
    margin: 135px 0 0px 155px;
  }

  .result .content .card-cover .card.top {
    width: calc(85vw - 60px);
  }

  .result .content .card-cover .card.mid {
    width: calc(85vw - 30px);
  }

  .result .content .card-cover .card.bottom {
    width: 85vw;
  }

  @keyframes onTop {
    5% {
      transform: translateY(-300px);
      z-index: 1;
      left: -15px;
      top: 15px;
      width: calc(85vw - 30px);
      height: 370px;
    }
    10% {
      transform: translateY(0);
      z-index: 0;
      left: -30px;
      top: 30px;
      width: 85vw;
      height: 340px;
    }
    100% {
      transform: translateY(0);
      z-index: 0;
      left: -30px;
      top: 30px;
      width: 85vw;
      height: 340px;
    }
  }
  @keyframes onMiddle {
    0% {
      transform: translateY(0);
      z-index: 1;
      left: -15px;
      top: 15px;
      width: calc(85vw - 30px);
      height: 370px;
    }
    100% {
      transform: translateY(0);
      z-index: 2;
      left: 0;
      top: 0;
      width: calc(85vw - 60px);
      height: 400px;
    }
  }
  @keyframes onBottom {
    0% {
      transform: translateY(0);
      z-index: 0;
      left: -30px;
      top: 30px;
      width: 85vw;
      height: 340px;
    }
    100% {
      transform: translateY(0);
      z-index: 1;
      left: -15px;
      top: 15px;
      width: calc(85vw - 30px);
      height: 370px;
    }
  }

  .dash-hero .dash-container {
    width: calc(100% - 0px);
    left: 0;
  }

  .home .info {
    flex-direction: column;
  }

  .home .time {
    width: 205px;
    margin: 15px 0 0;
  }

  .upcoming-info .container .content .info {
    flex-direction: row;
  }

  .history .title {
    font-size: 20px;
    text-align: center;
    margin: 7px 0;
  }

  .history {
    width: calc(100% - 40px);
    min-height: calc(100vh - 90px);
    padding: 20px;
    box-shadow: none;
  }

  .history .container {
    overflow-x: unset;
    height: unset;
  }

  .history .container .item {
    padding: 20px;
    width: calc(100% - 40px);
    border-radius: 15px;
    background: linear-gradient(to top left, #293456, #071235);
    color: white;
    margin: 10px 0;
    justify-content: space-between;
    align-items: center;
  }

  .history .container .item.complete {
    background: white;
    color: #010c35;
    border: 2px solid #010c35;
    width: calc(100% - 44px);
    padding: 16px 20px;
  }

  .side-nav.mob .mot .icons i.fa-money-check.hide,
  .side-nav.mob .mot .icons,
  .side-nav .item .bg,
  .upcoming-info.mob .carousel .ernij .bg,
  .upcoming-info.mob .carousel .ernij p,
  .upcoming-info.mob .carousel .ernij .amount,
  .upcoming-info.mob .carousel .ernij .down,
  .history .container .item .bg {
    display: none;
  }

  .history .container .item .sm {
    display: block;
    width: 65vw;
    margin-left: 20px;
  }

  .history .container .item .sm span {
    margin: 0 10px 0 0;
  }

  .history .container .item .img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }

  .history .container .item .name {
    width: unset;
    font-size: 20px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    margin-bottom: 9px;
  }

  .history .container .item.complete .sm {
    width: calc(65vw - 80px);
    margin: 0;
  }

  .history .container .item.complete .check.sm {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: limegreen;
    display: block;
  }

  .history .container .item.complete .check.sm::before {
    content: "\2714";
    display: inline-block;
    line-height: 37px;
    height: 15px;
    font-size: 21px;
    padding-left: 9px;
    padding-bottom: 3px;
    margin-right: 0.2em;
    vertical-align: top;
    color: white;
  }

  .history .container .item .check.sm {
    display: none;
  }

  .home .statis .upcoming-info.earning.mob {
    width: calc(100% - 50px);
    padding: 0px 25px;
  }

  .upcoming-info.earning.mob {
    width: calc(100% - 50px);
    padding: 15px 25px;
  }

  .upcoming-info .carousel .ernij {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .upcoming-info .carousel .ernij.ile {
    display: flex;
    flex-direction: column;
    align-items: unset;
    min-width: 101%;
  }

  .upcoming-info .carousel .ernij .img {
    height: unset;
    margin: auto 0;
  }

  .upcoming-info .carousel.nil .nil a i,
  .upcoming-info .carousel .ernij a i,
  .upcoming-info .carousel .ernij .avail i {
    border: none;
    border-radius: unset;
    font-size: 30px;
    padding: 0;
    margin-left: 0;
    color: white;
    background: none;
    top: 0;
  }

  .upcoming-info .carousel .ernij.ile a i,
  .upcoming-info .carousel .ernij.ile .avail i {
    border: 2px solid white;
    color: black;
    background: white;
    border-radius: 50%;
    font-size: 10px;
    padding: 5px 5px;
    margin-left: 10px;
    position: relative;
    top: -3px;
  }

  .upcoming-info .container .content .info p {
    white-space: normal;
    margin: 0 10px;
  }

  .side-nav.mob {
    left: 0;
    width: calc(100vw - 50px);
    padding: 35px 25px 60px;
    height: calc(100vh - 95px);
    display: none;
  }

  .side-nav.active {
    top: 0;
    display: flex;
  }

  .side-nav.mob .mot {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .side-nav.mob .mot .bac {
    opacity: 0;
    border: 0;
    padding: 0 6px;
    height: 24px;
    pointer-events: none;
    cursor: pointer;
  }

  .side-nav.mob .mot .bac i {
    pointer-events: none;
  }

  .side-nav.mob .mot p {
    font-size: 18px;
    width: 20px;
  }

  .side-nav.mob .mot .edy {
    border: 2px solid #010c35;
    border-radius: 16px;
    padding: 5px 22px;
    font-size: 13px;
    cursor: pointer;
    display: none;
  }

  .side-nav.mob .mot .edy.show {
    display: block;
  }

  .side-nav.mob .mot .icons i {
    border: 2px solid #010c35;
    color: #010c35;
    border-radius: 50%;
    padding: 6px 7px;
    cursor: pointer;
  }

  .side-nav.mob .mot .icons i.fa-money-check {
    padding: 9px 8px;
    font-size: 12px;
    margin-left: 8px;
    top: -2px;
    width: unset;
    position: relative;
  }

  .side-nav.mob h1 {
    font-family: "Inter", sans-serif;
    font-weight: 700;
  }

  .side-nav.mob p.abo {
    width: calc(100% - 40px);
    white-space: pre-wrap;
    background: linear-gradient(to bottom right, #021764, #071235);
    color: white;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    font-weight: 100;
  }

  .side-nav.mob .account {
    width: 100%;
  }

  .side-nav .item img {
    width: 45px;
    height: 45px;
  }

  .side-nav .item {
    background: linear-gradient(to bottom right, #021764, #071235);
    padding: 20px;
    align-items: center;
    border-radius: 15px;
    color: white;
  }

  .side-nav .item .info {
    margin: 0 20px;
  }

  .side-nav .item .date {
    box-shadow: none;
    background: none;
  }

  .side-nav .item .date .no {
    color: white;
  }

  .side-nav .settings {
    display: none;
  }

  .side-nav .logout {
    margin: 20px 0 40px;
    width: calc(100% - 30px);
    text-align: center;
    color: white;
    background: #ff8000;
    padding: 15px;
    border-radius: 10px;
  }

  .prof .content {
    padding: 70px 25px 85px;
  }

  .dash-container .home {
    padding: 50px 0px 0;
  }

  .available .container form .time {
    flex-direction: column;
  }

  .available .container form .time .container {
    width: 100%;
  }

  .onboard-form .content.hide {
    left: -800px;
  }

  .onboard-form .content.show {
    left: 800px;
  }

  .onboard-form .content.back .n-arrow .feats .feat-link {
    margin: 0 10px 20px;
    width: 140px;
  }

  .side-nav.bar {
    display: flex;
  }

  .land-1 .content .duo p {
    width: 100%;
  }

  .stats .ava.rem,
  .stats .ava {
    width: 100%;
  }

  .stats {
    padding: 50px 25px 50px;
  }

  .sixthpage .card {
    width: 275px;
  }

  .sixthpage .img img {
    width: 218px;
  }

  .result .content .card-cover .card .bottom {
    background: white;
    color: black;
    padding: 10px 15px;
    margin: 0;
    width: calc(100% - 30px);
    bottom: 0;
  }

  .upcoming-info .carousel.nil .nil a.cir i {
    border: 1px solid white;
    border-radius: 50%;
    font-size: 15px;
    background: white;
    padding: 3px;
    margin-left: 10px;
    color: black;
  }

  .upcoming-info .carousel .ernij .amount {
    margin: 20px 0 80px;
  }

  .home .statis {
    margin: 10px 0 0;
  }

  .stats .plot .area {
    margin: 25px 25px 60px;
  }

  .land-2 .content a,
  .land-1 a {
    font-size: 13px;
  }

  .land-2 .content .icon,
  .land-1 .icon {
    display: inline-block;
    width: 10px;
    padding: 0px 3px;
    margin: 0px 6px;
    top: 2px;
    line-height: 17px;
  }

  .land-2 .content .icon i,
  .land-1 .icon i {
    width: 12px;
    left: 2px;
    font-size: 9px;
    padding: 0px 0px;
    top: -3px;
  }

  .side-nav {
    width: 60vw;
  }

  .profile .container .item .title {
    font-size: 31px;
  }

  .onboard .content .alert-danger {
    padding-left: 20px;
    color: red;
  }

  .result .content .card-cover .card .title {
    font-weight: 700;
    font-size: 30px;
    padding: 0 13px;
    width: calc(100% - 26px);
    font-family: "Inter", sans-serif;
  }

  .result .content .card-cover .card .bottom .read p,
  .result .content .card-cover .card .bottom .text {
    font-size: 15px;
  }

  .result .content .card-cover .card .bottom .rate p {
    font-size: 16px;
  }

  .result .content .card-cover .card .bottom .read .val {
    font-size: 27px;
    padding-top: 4px;
  }

  .result .content .card-cover .card .bottom {
    padding: 20px 25px;
    width: calc(100% - 50px);
  }

  .result .content .card-cover .card img {
    height: calc(100% - 90px);
  }

  .result .content .info .contain .img {
    height: 80px;
  }

  .sim p {
    margin: 10px 10px 0;
  }

  .nota .title,
  .sim .title,
  .hire .title,
  .secondpage .title,
  .pro h1 {
    font-size: 33px;
    line-height: 40px;
  }

  .sim .company-logo,
  .hire .company-logo,
  .secondpage .company-logo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .nota .container .item .img {
    width: 80%;
    margin: auto;
  }

  .prof .content .links .loader,
  .onboard-form .content .links .loader,
  .waitform .submit .loader,
  .firstpage .search .loader,
  .onboard-form .content .links.submit .loader,
  .auth-container .auth .links .loader {
    margin: 0px auto 35px;
    width: 50px;
  }

  .schedule .price {
    bottom: 70px;
    font-size: 24px;
    left: calc(100% - 130px);
  }

  .radio.test label {
    transition: all ease 0.3s;
  }

  input[type="checkbox"] + label:before,
  input[type="radio"] + label:before {
    transition: all ease 0.5s;
  }

  .referral {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 70px 0px 0px;
  }

  .referral h1 {
    line-height: 40px;
  }

  .side-nav .logout.ref {
    display: block;
    margin: 20px 0 0px;
    background: limegreen;
  }

  .upcoming-info .container .content .desc {
    background: white;
    margin: 0;
    width: calc(100% - 60px);
    padding: 0 30px 20px;
    font-size: 17px;
  }

  .dash-nav {
    width: 100vw;
  }

  .dash-nav.hide {
    left: -110vw;
  }

  .referral .upcoming .pie.smm {
    display: flex;
  }

  .referral .upcoming .pie.sm {
    display: none;
  }

  .referral .progre .content {
    width: calc(100% - 180px);
  }

  .referral .upcoming .progre.sm .value {
    top: 117px;
    left: 75px;
    font-size: 25px;
  }

  .referral .progre .content .stat img {
    width: 35px;
  }

  .referral .progre .content .stat .text .no {
    font-size: 18px;
  }

  .referral.og .upcoming .progre.sm .card {
    width: 100%;
    margin: 10px 0;
  }

  .byte .card .btn {
    flex-direction: column;
  }

  .byte .card .btn .mark {
    margin: 0 0 10px;
  }

  .section .sixthpage .nav-next {
    margin-top: -240px;
  }

  .quiz .score .results .info {
    padding: 10px;
  }

  .firstland p.sm {
    display: block;
  }

  .firstland p.sm a {
    color: #1339ff;
  }

  .secondland .ben-cov .card {
    padding: 15px;
  }

  .firstland {
    background: no-repeat center/cover
      url(https://dqe80zoqbuyqe.cloudfront.net/root/New+Iteration+mobile-12.png);
  }

  .thirdland .content {
    width: calc(100% - 40px);
    margin: 0 20px 20px;
  }

  .seventhland .card {
    padding: 10px;
  }

  .pathpage .content .title {
    font-size: 35px;
    line-height: 38px;
  }

  .pathpage {
    padding: 80px 25px 50px;
  }

  .thirdland .content h2 {
    font-size: 30px;
    line-height: 37px;
  }

  .firstland h1 span::after {
    bottom: 2px;
  }

  .firstland {
    border-radius: 0 0 30px 30px;
  }

  .secondland {
    padding: 50px 15px 0;
  }

  .secondland .title {
    font-size: 30px;
  }

  .seventhland .arrow span:nth-child(2),
  .secondland .arrow span:nth-child(2),
  .sixthland .arrow span:nth-child(2),
  .fifthland .arrow span:nth-child(2) {
    width: 40px;
    left: 0px;
    top: 13px;
  }

  .seventhland .arrow,
  .secondland .arrow,
  .sixthland .arrow,
  .fifthland .arrow {
    right: 30px;
    width: 40px;
  }

  .seventhland .arrow span:nth-child(1),
  .secondland .arrow span:nth-child(1),
  .sixthland .arrow span:nth-child(1),
  .fifthland .arrow span:nth-child(1) {
    width: 20px;
    top: 7px;
  }

  .seventhland .arrow span:nth-child(3),
  .secondland .arrow span:nth-child(3),
  .sixthland .arrow span:nth-child(3),
  .fifthland .arrow span:nth-child(3) {
    width: 20px;
    top: 19px;
  }

  .thirdland .content .cover {
    margin: 35px 0 30px;
  }

  .fourthland .pri-cov .card .title {
    font-size: 26px;
    margin: 0 0 5px;
  }

  .fifthland {
    padding: 75px 0px 65px 10px;
    border-radius: 20px;
  }

  .fifthland ul .card .img .name {
    left: -86px;
  }

  .sixthland {
    padding: 40px 15px 0;
  }

  .sixthland .title {
    font-size: 30px;
    font-weight: 600;
  }

  .seventhland {
    padding: 0 15px 75px;
  }

  .seventhland .title {
    font-size: 30px;
    margin-bottom: 0;
  }

  .seventhland .card {
    padding: 15px;
  }

  .footer .footer-list .over {
    justify-content: space-between;
  }

  .footer .footer-list .over .group {
    width: 150px;
  }

  .fullpath h1,
  .referral h1,
  .dashment .info h1,
  .home .info p {
    font-size: 25px;
  }

  .prof .content .title {
    margin: 10px 0;
    font-size: 25px;
  }

  .referral .upcoming .progre.sm {
    flex-direction: column;
  }

  .referral.dashpath .upcoming .progre.sm {
    flex-direction: row;
  }

  .dashment .upcoming .session .group p,
  .dashment .become p {
    font-size: 14px;
  }

  .referral .progre .content .stat .text .desc {
    font-size: 14px;
  }

  .fullpath {
    width: calc(100% - 30px);
    padding: 70px 15px 50px;
  }

  .byte .card .title {
    padding: 18px 7px 13px;
  }

  .byte .card .desc {
    padding: 0 7px;
  }

  .byte .card .btn {
    padding: 20px 10px 15px;
  }

  .webinar {
    padding: 0px 15px 60px;
  }

  .webinar h1 {
    font-size: 27px;
    line-height: 35px;
  }

  .webinar .search label {
    width: 80px;
    min-width: 80px;
    border-radius: 0 5px 5px 0;
    padding: 21px 10px 20px;
  }

  .webinar .search input {
    padding: 0 15px 5px;
    border-radius: 5px 0 0 5px;
    width: calc(100% - 135px);
  }

  .dash-container .bottom .sm-filter {
    width: unset;
  }

  .dash-container .bottom .back-active.vid {
    left: calc(25% - 31px);
  }

  .dash-container .bottom .back-active.cor {
    left: calc(50% - 40px);
  }

  .dash-container .bottom .back-active.art {
    left: calc(75% - 36px);
  }

  .dash-nav.one {
    min-width: 200px;
  }

  .dash-nav.one .contain {
    width: 100%;
  }

  .home-sm .card-container .card .info {
    height: 23px;
  }

  .plan .perform {
    padding: 10px 20px;
    background: #f0f0f0;
  }

  .dash-nav.one {
    background: #eaeaea;
    border-radius: 0;
  }

  .dash-nav.one .top .brand .title {
    color: #000;
  }

  .dash-nav.one .nav-list.active {
    color: #f4faff;
    background: #000;
  }

  .dash-nav.one .bottom .nav-list .nav-item,
  .dash-nav.one .nav-list {
    color: #000;
  }

  .dash-nav.one .nav-list .nav-item {
    font-weight: 400;
  }

  .home-lander .img {
    height: 370px;
  }

  .home-lander .img .tile p {
    font-size: 15px;
    line-height: 14px;
  }

  .home-lander .img .tile .blob {
    width: 50px;
    height: 50px;
    margin: 15px 15px 10px;
  }

  .home-lander .img .tile {
    width: 110px;
    height: 130px;
  }

  .home-lander .img img.main {
    left: 45%;
  }

  @keyframes tileintro {
    0% {
      top: 15%;
      opacity: 0;
    }
    100% {
      top: 5%;
      opacity: 1;
    }
  }

  @keyframes tilehome {
    0% {
      top: 5%;
    }
    100% {
      top: 8%;
    }
  }

  .home-lander .img .tile.down {
    left: 67%;
  }

  .home-lander .content h1 {
    font-size: 36px;
  }

  .home-lander .content .hone .line {
    height: 39px;
  }

  .home-lander .content .sm {
    display: block;
  }

  .home-lander .content .bg {
    display: none;
  }

  .nav.alt .navbar-header a .brand {
    font-size: 20px;
  }

  .home-lander .content .line.pee,
  .home-lander .content .hone {
    width: 100%;
  }

  .home-lander .content p {
    top: -55px;
  }

  .home-lander .btn-group .chrome.btn {
    display: none;
  }

  .home-lander .content {
    align-items: unset;
  }

  .home-lander .content .btn-group {
    margin: 50px 0 15px;
  }

  .home-lander .content .btn-group .sign-up {
    background: white;
    color: #18a0fb;
    padding: 13px 35px;
    font-size: 18px;
    font-weight: 400;
  }

  .partners-tab .learning-imgs img.kha {
    width: 184.5px;
    height: 30px;
  }

  .partners-tab .learning-imgs img.edx {
    width: 63px;
    height: 30px;
  }

  .partners-tab .learning-imgs img.lin {
    width: 132.75px;
    height: 30px;
  }

  .partners-tab .learning-imgs img.ski {
    width: 60px;
    height: 30px;
  }

  .partners-tab .learning-imgs img.cou,
  .partners-tab .learning-imgs img.uda {
    height: 60px;
  }

  .partners-tab .learning-imgs img.ude {
    height: 45px;
  }

  .partners-tab .learning-imgs {
    justify-content: start;
  }

  .new .btn-group .free {
    font-size: 19px;
  }

  .new .btn-group .chrome {
    font-size: 16px;
    font-weight: 500;
  }

  .new .btn-group {
    margin: 35px 0 20px;
  }

  .manage-tab .img h1 {
    top: -90px;
  }

  .manage-tab .img {
    margin-top: 95px;
  }

  .track-tab .img h1,
  .manage-tab .img h1 {
    width: 100%;
    font-size: 33px;
    line-height: 32px;
    margin: 0 0 30px;
  }

  .track-tab .img img {
    width: 70%;
  }

  .track-tab .img {
    align-items: unset;
  }

  .visualise-tab h1 {
    margin: 10px 0 0;
    font-size: 33px;
    line-height: 32px;
  }

  .visualise-tab p.sm {
    display: block;
  }

  .visualise-tab p.bg {
    display: none;
  }

  .how-to .balls span,
  .visualise-tab .img-groupie .balls span {
    width: 10px;
    height: 10px;
  }

  .how-to .balls,
  .visualise-tab .img-groupie .balls {
    margin: 10px 0 30px;
  }

  .how-to.sm img {
    width: 100%;
  }

  .how-to .img h1 {
    font-size: 33px;
    line-height: 32px;
    margin: 0;
  }

  .perks-tab .perks h1 {
    font-size: 33px;
    line-height: 32px;
    margin: 15px 0;
  }

  .trail-bank .img img {
    width: 100%;
  }

  .auth-container .auth .subheader {
    margin: 15px 0 0px;
  }
}

@media (max-width: 400px) {
  .firstpage .search input {
    width: calc(100% - 156px);
  }

  .firstpage .search input {
    padding: 0 10px 5px;
  }

  .waitpage .video {
    margin: 30px 0 0;
    font-size: 20px;
  }

  .waitpage .video i {
    font-size: 20px;
  }

  .firstpage .search label {
    padding: 13px 10px 16px;
    width: 107px;
  }

  .waitpage {
    padding: 100px 10px;
  }

  .side-nav {
    height: calc(100vh - 100px);
    width: calc(80% - 85px);
    min-width: 160px;
    padding: 55px 25px 45px 25px;
    align-items: center;
  }

  .thirdpage {
    padding: 0 10px;
  }

  .footer {
    padding: 30px 10px 0;
  }

  .waitsuccess .inner .links .join {
    padding: 0 24px;
    height: 35px;
    line-height: 35px;
  }

  .fourthpage p,
  .fourthpage h1 {
    width: 65%;
  }

  .upcoming-info.review {
    padding: 50px 10px;
  }

  .mentor-page .home .img .gray {
    width: 85vw;
    height: 85vw;
  }

  .mentor-page .home .content .links {
    flex-direction: column;
    align-items: center;
  }

  .mentor-page .home .content .links .join {
    margin: 0;
    width: 150px;
    text-align: center;
  }

  .mentor-page .home .content .links .video {
    margin: 15px 0 0;
    width: 179px;
    text-align: center;
  }

  .onboard-form {
    height: 855px;
  }

  .onboard-form .content .title,
  .onboard-form .content .sub-title {
    width: 85vw;
  }

  .onboard-form .content .links {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .onboard-form .content .links .home {
    margin-top: 15px;
  }

  .onboard .content .onboard-nav .nav-info {
    margin: auto 40px auto 0;
  }

  .onboard .content .onboard-nav .nav-info .line {
    width: 50px;
  }

  .onboard-form.test .content .intro img {
    width: 255px;
  }

  .onboard-form.test .content .intro {
    padding-bottom: 85px;
  }

  .onboard-form.test .content .links {
    flex-direction: row;
  }

  .available .container form .links,
  .onboard-form.test .content .links {
    flex-direction: column;
    align-items: center;
  }

  .onboard-form.test .content .links .continue {
    margin: 0;
    width: 140px;
    text-align: center;
    border-radius: 7px;
  }

  .onboard-form.test .content .links .continue.hom {
    width: 195px;
  }

  .onboard-form.test .content .links .home {
    margin: 10px 0 0 !important;
    width: 200px;
    padding: 0 10px;
    text-align: center;
    border-radius: 7px;
  }

  .result .content .card-cover .card.top {
    opacity: 0;
  }

  .result .content .card-cover .card.mid {
    left: -15px;
    top: 15px;
    width: 85vw;
    height: 370px;
    opacity: 1;
  }

  .result .content .card-cover .card.bottom {
    left: -30px;
    top: 30px;
    height: 340px;
    opacity: 0;
  }

  @keyframes onTop {
    5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes onMiddle {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes onBottom {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  .result .content .card-cover {
    margin: 105px 0 0px 135px;
    height: 280px;
  }

  .upcoming-info.mob .carousel .ernij .text {
    white-space: normal;
    margin: 0 10px;
  }

  .prof .content .links {
    flex-direction: column;
    text-align: center;
  }

  .prof .content .links .disc {
    margin-top: 15px;
  }

  .prof .content .links .loader,
  .onboard-form .content .links .loader {
    margin: 0px auto 30px;
    width: 40px;
  }

  .available .container form .links .home {
    margin: 15px auto 0;
  }

  .available .container form .links a {
    margin: 0 auto;
  }

  .onboard-form .content .links .home {
    margin: 15px 0 0;
  }

  .onboard-form .content .links .continue {
    margin: 0;
  }

  .onboard-form .content .links.submit {
    width: 80%;
    margin: auto;
  }

  .onboard-form.test {
    height: 980px;
  }

  .land-2 .content .title.sub {
    margin: 50px 0 13px;
  }

  .schedule .price {
    display: none;
  }

  .dashment .upcoming .session .disg {
    flex-direction: column;
  }

  .dashment .upcoming .session .become,
  .dashment .upcoming .session .group {
    width: calc(100% - 30px);
  }

  .dashment .upcoming .session .group {
    margin-bottom: 20px;
  }

  .referral .upcoming .progre.sm .value {
    top: 72px;
    left: 141px;
  }

  .referral.dashpath .upcoming .progre.sm {
    flex-direction: column;
  }

  .referral .progre .content {
    width: unset;
    margin: 0 auto 15px;
  }

  .home-lander .img {
    height: 320px;
  }

  .home-lander .img .tile.down {
    left: 63%;
  }

  .home-lander .content .hone .line {
    height: 35px;
  }

  .home-lander .content h1 {
    font-size: 30px;
  }

  .home-lander .content p {
    font-size: 14px;
    line-height: 19px;
  }

  .new .btn-group {
    flex-direction: column;
  }

  .new .btn-group .chrome {
    margin: 0 0 8px;
  }
}

@media (max-width: 350px) {
  .firstpage .content {
    width: calc(100vw - 20px);
    padding: 0 10px 0;
  }

  .content .links a {
    padding: 0 10px !important;
    margin: 0 5px 0 0 !important;
  }

  .onboard-form .content.back .n-arrow .feats .feat-link {
    margin: 0 0 20px;
    width: 100%;
  }

  .onboard-form .content.back .n-arrow .feats .feat-link .title {
    padding: 5px 0;
  }

  .onboard-form {
    height: 750px;
  }

  .onboard-form.test .content.back .n-arrow .feats .skill {
    margin: 2px 0;
  }

  .onboard-form.test .content .links .continue.hom {
    width: 170px;
  }

  .onboard-form.test .content .links {
    margin-left: 0px;
  }

  .onboard-form.test {
    height: 1035px;
  }

  .referral .upcoming .session .info {
    width: calc(100% - 43px);
  }

  .dash-container .home-sm .card-container .card {
    width: 100%;
  }

  .home-lander .content h1 {
    font-size: 26px;
  }

  .home-lander .content .hone .line {
    height: 32px;
  }

  .home-lander .content p {
    font-size: 12px;
    line-height: 17px;
  }

  .home-lander .img {
    height: 287px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,400;1,700;1,900&display=swap");

.hamburger {
  position: absolute;
  top: 1.35rem;
  right: 20px;
  padding: 0;
  display: none;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
  top: 0.05em;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 28px;
  height: 3px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease,
    transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease,
    transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

html {
  font-size: 62.5%;
}

.enterpriseContainer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-family: "Lato", sans-serif;
}

.bodyContainer {
  padding: 0 82px;
  box-sizing: border-box;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 82px;
  color: black;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-width: 1px;
}

.brandTitle {
  width: 86px;
  height: 34px;
}

.navbarLinks ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbarLinks ul li:first-child, .navbarLinks ul li:nth-child(2) {
  margin-right: 26px;
}

.navbarLinks ul li:nth-child(3) {

  margin-right: 56px;
}
.navbarLinks ul li a {
  text-decoration: none;
  color: black;
  padding: 1rem;
  display: block;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
}

.navbarLinks ul li:nth-child(3) a {

  color: #18a0fb;
  
}

.navbarLinks li:hover {
  cursor: pointer;
}


.requestDemo {
  color: #deedf8;
  border: none;
  background-color: #18a0fb;
  font-size: 10px;
  outline: none;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #1400ff;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 32px;
  text-align: center;
  margin: 4px 2px;
  text-decoration: none;
}

button.requestDemo:active, .demo-button:active {
  transform: scale(0.99);
}

.demo-button {
  background-color: #18a0fb; 
  border: none; /* Remove border */
  color: #deedf8; /* White text */
  padding: 8px 32px; /* Some padding */
  text-align: center; /* Center-align text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Make the button inline */
  font-size: 12px; /* Set font size */
  margin: 4px 2px; /* Add some margin */
  cursor: pointer; /* Add a pointer cursor on hover */
  outline: none;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #1400ff;
  border-radius: 4px;
}


.heroDocs {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  margin-top: 87px;
  text-align: center;
}

.heroDocs h2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  width: 841px;
  text-align: center;
  margin: 0 auto;

  color: #010101;
}

.heroDocs .nextLevel {
  color: #18a0fb;
}

.heroDocs div p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 151.52%;

  text-align: center;
  letter-spacing: -0.01em;
  width: 433px;

  color: #1b1b1b;
  margin: 0 auto;
}

.heroDocs div {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.heroDocs .highlight {
  width: 33.57px;
}
.pointer {
  align-self: flex-start;
}

.bookDemo {
  color: #deedf8;
  border: none;
  background-color: #18a0fb;
  font-size: 18px;
  outline: none;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #1400ff;
  border-radius: 4px;
  cursor: pointer;
  padding: 18px 32px;
  text-align: center;
  margin: 4px 2px;
  text-decoration: none;
  width: 324px;
}

.heroImages {
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
  margin-top: 87px;
  text-align: center;
}

.carousel {
  display: none;
}

.webCarousel {
  width: 100%;
  height: 389px;
  border-radius: 16px;
  background-color: #18a0fb;
  margin-top: 87px;
  display: flex;
  justify-content: space-evenly;
}

.wcContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 56px auto 30px auto;
}

.wcContent .contentHeader {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.01em;
  width: 294px;
  color: #ffffff;
  margin: 0;
  /* margin-bottom: 28px; */
}

.wcContent .contentBody {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  width: 289px;
  margin: 0;
  margin-top: -8px;

  text-align: center;

  color: #ffffff;
}

.companyPotential {
  margin-top: 86px;
  margin-bottom: 34px;
}
.companyPotential h3 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  width: 540px;
}

.companyPotential h3 .cpSpan {
  color: #18a0fb;
}

.companyPotential h3 .highlight {
  width: 33px;
}

.cpCards {
  display: grid;
  row-gap: 26px;
  column-gap: 27px;
  grid-template-columns: auto auto;
  grid-template-rows: 625px 625px;
}

.cpCards .grid {
  box-sizing: border-box;
  margin: 0;
  width: auto;
  height: 625px;
  background: rgba(24, 160, 251, 0.1);
  border-width: 0.6px 0.6px 2.5px 0.6px;
  border-style: solid;
  border-color: #18a0fb;
  border-radius: 16px;
  padding: 37px;
}

.lastGrid {
  box-sizing: border-box;
  margin: 0;
  margin-top: 26px;
  width: auto;
  height: 625px;
  background: rgba(24, 160, 251, 0.1);
  border-width: 0.6px 0.6px 2.5px 0.6px;
  border-style: solid;
  border-color: #18a0fb;
  border-radius: 16px;
  padding: 37px;
  display: flex;
  width: 100%;
  gap: 400px;
  overflow: hidden;

  position: relative;
  z-index: 0;
}

.lastGrid .gridImage {
  display: none;
}

.lastGridElement2 {
  display: flex;
  align-self: flex-end;
}

.gridFullView.laptop {
  width: 500px;
  height: 300px;

  position: absolute;
  top: 69%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gridFullView.phone {
  width: 202px;
  height: 412px;
}

.gridContent {
  display: flex;
  flex-direction: column;
}

.gridTitle {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 106%;
  color: #0078c9;
}

.gridSubtitle {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  letter-spacing: -0.01em;

  color: #3a3636;
  margin: 0;
}

.gridDescription {
  width: 547px;
  height: 70px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  color: #39393c;
}

img.gridImage {
  width: 485px;
  height: 355px;
  margin-top: 44px;
}

.transformWorkforceAndBusiness {
  margin-top: 110px;
  margin-bottom: 110px;
  display: flex;
  gap: 100px;
  align-items: center;
}

.transformWorkforceAndBusiness div:first-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.transformWorkforceAndBusiness div:nth-child(2) .ultimateTool {
  display: block;
  width: 100%;
  height: 336px;
  align-self: center;
}

.readyToTransform {
  font-family: "Lato";
  font-style: italic;
  font-weight: 100;
  font-size: 18px;
  line-height: 19px;
  color: #323540;
  margin-bottom: 12px;
}

.transformWorkforceAndBusiness div:first-child h4 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  width: 544px;
  height: 141px;
  letter-spacing: -0.01em;
  color: #323540;
  margin-top: 0;
  margin-bottom: 12px;
}

.unlockBusiness {
  width: 454px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 29px;

  color: #323540;
  margin-top: 0;
  margin-bottom: 10px;
}



.enterpriseFooter {
  box-sizing: border-box;
  /* height: 384px; */
  padding: 20px 82px 0 82px;

  background: #18a0fb;
}

.enterpriseFooter .bottomNav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.enterpriseFooter .bottomNav  .footerImg {
  vertical-align: center;
  width: 145px;
  height: 56px;
}



.enterpriseFooter .bottomNav .boostBusiness {
  align-self: center;
  width: 300px;
  height: 100px;

  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;

  color: #ffffff;
}

.enterpriseFooter .bottomNav .contactUs {
  align-self: center;

  display: flex;
  flex-direction: column;

}

.enterpriseFooter .bottomNav .contactUs .contactHeader {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  

  text-align: right;
  letter-spacing: -0.01em;

  color: #ffffff;
}

.enterpriseFooter .bottomNav .contactUs .email {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;


  text-align: right;
  letter-spacing: -0.01em;

  color: #ffffff;
}

.enterpriseFooter .bottomNav .contactUs .number {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;

  text-align: right;
  letter-spacing: -0.01em;

  color: #ffffff;
}

.enterpriseFooter .divider {
  border: 1px solid #ffffff;
  margin-top: 81px;
  margin-bottom: 48px;
}

.enterpriseFooter .socialsBox {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.enterpriseFooter .socialsBox .trademark {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  /* line-height: 21px; */
  width: 341px;
  height: 41px;

  color: #ffffff;
}

.enterpriseFooter .socialsBox .socialMediaIcons {
  display: flex;
  gap: 40px;
}

.enterpriseFooter .socialsBox .socialMediaIcons a img {
  width: 20px;
  height: 20px;
}

.enterpriseFooter .socialsBox .socialMediaIcons a:hover {
  cursor: pointer;
}

@media (max-width: 1130px) {
  .hamburger {
    display: inline-block;
  }
  .bodyContainer {
    padding: 0 32px;
  }

  .brandTitle {
    width: 86px;
    height: 34px;
  }

  .navbarLinks {
    display: none;
    width: 100%;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 32px;
    z-index: 2;
  }

  .navbarLinks ul {
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }

  .navbarLinks ul li:first-child {
    margin-right: 0;
  }
  
  .navbarLinks ul li:nth-child(3) {
    margin-right: 0;
  }

  .navbarLinks li {
    text-align: center;
  }

  .navbarLinks li a {
    padding: 0.5rem 1rem;
  }

  .navbarLinks.active {
    display: flex;
  }

  .heroDocs h2 {
    font-size: 28px;
    line-height: 36px;
    width: auto;
  }

  .heroDocs div p {
    font-family: 14px;
    line-height: 21px;
    width: auto;
  }

  .heroDocs div .pointer {
    width: 10px;
    height: 12px;
  }

  .bookDemo {
    width: auto;
  }
  .laptopActive {
    display: none;
  }


  .heroImages {
    gap: 0;
  }
  .heroImages img {
    width: clamp(350px, 50%, 100%);
  }

  .webCarousel {
    display: none;
  }

  .carousel {
    display: block;
    margin-top: 56px;
  }

  .carouselContent {
    background-color: #18a0fb;
    width: 100%;
    height: 263px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 27px 0px;
  }

  .carouselContent .contentHeader {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    width: 203px;

    text-align: center;
    letter-spacing: -0.01em;
    color: #ffffff;
  }

  .carouselContent .contentBody {
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    width: 201px;
    height: 74px;

    text-align: center;

    color: #ffffff;
  }

  .companyPotential h3 {
    font-size: 16px;
    width: auto;
    text-align: center;
  }

  .cpCards {
    gap: 18px;
    width: min-content;
    grid-template-columns: auto;
    grid-template-rows: 355px;
    margin: 0 auto;
  }

  .cpCards .grid {
    width: 100%;
    height: 355px;
    grid-column: 1/3;
    padding: 12px 12px 0 12px;
  }

  .gridContent {
    gap: 8px;
  }

  .gridTitle,
  .gridSubtitle,
  .gridDescription {
    margin: 0;
  }

  img.gridImage {
    display: block;
    margin: 0 auto;
    margin-top: 4px;
    width: 282px;
    height: 50%;
  }

  .lastGrid {
    width: min-content;
    height: 355px;
    margin: 0 auto;
    margin-top: 18px;
    padding: 12px 12px 0 12px;
    display: block;
  }

  .lastGrid .gridImage {
    display: block;
  }

  .gridFullView {
    display: none;
  }
  .transformWorkforceAndBusiness {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
  }

  .transformWorkforceAndBusiness div:nth-child(2) .ultimateTool {
    width: 100%;
    height: 183px;
    align-self: center;
  }

  .readyToTransform {
    font-family: "Lato";
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    line-height: 13px;

    color: #323540;
    margin-bottom: 8px;
  }

  .transformWorkforceAndBusiness div:first-child {
    justify-content: center;
    align-items: flex-start;
  }

  .transformWorkforceAndBusiness div:first-child h4 {
    font-size: 24px;
    line-height: 28px;
    width: auto;
    height: fit-content;

    color: #323540;
    /* margin-bottom: 12px; */
  }

  .unlockBusiness {
    width: auto;
    /* height: 66px; */

    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;

    color: #323540;
    margin-bottom: 10px;
  }

  .enterpriseFooter {
    height: auto;
    padding: 40px 32px;

    background: #18a0fb;
  }

  .enterpriseFooter .bottomNav {
    flex-direction: column;
    align-items: flex-start;
  }

  .enterpriseFooter .bottomNav  .footerImg {
    width: 86px;
    height: 34px;
  }

  .enterpriseFooter .bottomNav .boostBusiness {
    align-self: flex-start;
    width: auto;
    height: auto;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 24px;
  }

  .enterpriseFooter .bottomNav .contactUs {
    align-self: flex-start;
  }

  .enterpriseFooter .bottomNav .contactUs .contactHeader {
    text-align: left;
    font-size: 18px;
  }

  .enterpriseFooter .bottomNav .contactUs .email {
    text-align: left;
    font-size: 14px;
  }

  .enterpriseFooter .bottomNav .contactUs .number {
    text-align: left;
    font-size: 14px;
  }

  .enterpriseFooter .socialsBox {
    flex-direction: column;
    gap: 40px;
  }

  .enterpriseFooter .socialsBox .socialMediaIcons {
    align-self: center;
  }

  .companyInfoOne {
    display: none;
  }

  .enterpriseFooter .bottomNav .companyInfo {
    gap: 4px;
  }
  .gridTitle {
    font-size: 14px;
  }

  .gridSubtitle {
    font-size: 18px;
  }

  .gridDescription {
    width: auto;
    height: 80px;
    font-size: 14px;
  }
}

.profile-page * {
    margin: 0;
    padding: 0;
}

.profile-page * {
    box-sizing: border-box;
}

.profile-page {
    min-height: 100vh;
}


.profile-page-body {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.settingsDiv {
    background: #FFFFFF;
    border-radius: 10px;
    width: 692px;
    padding: 30px;
}

.menuItemDiv {
    background: #FFFFFF;
    border-radius: 10px;
    width: 428px;
    padding: 25px;
}

.menuItemDiv .menuItemHeader {
    display: flex;
    gap: 16px;
    align-items: center;
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(141, 144, 145, 0.2);
}

.menuItemDiv .menuItem {
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    color: #023059;
}


.settingsDiv .settingsMain {
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    color: #023059;
    padding-bottom: 12px;
    
}

 .settingMenuItem {
    padding: 25px;
    border-top: 1px solid  rgba(141, 144, 145, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.settingMenuItem:hover, .activeMenu {
    cursor: pointer;
    background-color: #F4FAFF;
    border-radius: 10px;
    border-top: none;
}

.settingMainDetail {
    display: flex;
    gap: 12px;
    align-items: center;
}

.settingMainText {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.settingMainText p:first-child {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
}

.settingMainText p:last-child {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #8D9091;
}

.editProfile {
    margin-top: 21px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.editProfile .img {
    display: flex;
    gap: 8px;
}

.editProfile .img p {
    align-self: flex-end;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    color: #034CBC;
    cursor: pointer;
}

.editProfile .addLearningPlanModal form {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.editProfile .addLearningPlanModal {
    min-height: fit-content;
}

.editProfile .logOutDiv {
    margin-top: 12px;
    border-top: 1px solid rgba(141, 144, 145, 0.2);
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}


.editProfile .logOutDiv .logOutMainText {
    font-weight: 700;
    font-size: 14px;
    color: #333333;
}

.editProfile .logOutDiv .logOutSubText {
    font-weight: 400;
    font-size: 12px;
    color: #8D9091;
}


.editProfile .logOutDiv .logOutButton {
    
    display: flex;
    justify-content: flex-end;
}


.editProfile .logOutDiv .logOutButton button {
    border: none;
    outline: none;
    background-color: #C5292A;
    padding: 10px 50px;
    border-radius: 6px;
    color: white;
    cursor: pointer;
}

.profile-page-body .howReminders {
    margin-top: 18px;
    border: none;
}


.missingSkillsDiv p {
    margin: 0;
    padding: 0;
}
.missingSkillsDiv {
    background: #F9EEEE;
    border: 1px solid #E49999;
    border-radius: 50px;
    padding: 8px 16px;
    width: fit-content;
    cursor: pointer;
    font-weight: 600;
    font-size: 10px;
    text-align: center;
    color: #B9281C;
}

.missingSkillsDiv:active {
    transform: translateY(2px);
}

.allToggleDiv {
    background: #FFFFFF;

border: 1px solid #D8DDE6;
color: #1C2533;
}

.missingSkillsEmpty {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
    color: #000000;
    mix-blend-mode: normal;
    opacity: 0.4;
    text-align: center;
}